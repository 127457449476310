import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import searchb from "../../images/search.png";
import refund from "../../images/refund.png";
import trash from "../../images/trash.png";
import file from "../../images/files.png";
import download from "../../images/download.png";
import axios from "axios";
import Sidebar from "../Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../screens/footer";
import Paymentmodal from "../modals/paymentmodal";
import formatDate from "../functions/timeformat";
import Delmodal from "../modals/delmodal";
import Refundnote from "../modals/refundNote";
import client from "../../images/placeholder.svg";
import Agentrefundmodal from "../modals/agentrefundmodal";
import Refundmodal from "../modals/refundmodal";
import Ginvoice from "../common/invoice";

function Hajj({ alldata, type, allunique }) {
  console.log(allunique);
  const [search, setSearch] = useState("");
  const [csearch, setCsearch] = useState("regular");

  const [photo, setPhoto] = useState("");
  const [banks, setBanks] = useState([]);
  const [stype, setStype] = useState("");
  const [refundb, setRefundb] = useState(0);
  const [re, setRe] = useState(0);
  const [abank, setAbank] = useState("");

  const [uname, setUname] = useState("");
  const [upay, setUpay] = useState("");
  const [paid, setPaid] = useState("");
  const [uid, setUid] = useState(0);
  const [view, setView] = useState("cash");

  const [vname, setVname] = useState("");
  const [serial, setSerial] = useState("");
  const [base, setBase] = useState("");

  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("cash");
  const [tamount, setTamount] = useState(0);
  const [phistory, setPhistory] = useState([]);
  const [acid, setAcid] = useState("");
  const [acit, setAcit] = useState("");

  const [allven, setAllven] = useState([]);
  const [agents, setAgents] = useState([]);

  const [note, setNote] = useState("");

  const [snote, setSnote] = useState("");
  const [samount, setSamount] = useState("");

  const [agent, setAgent] = useState("");

  const [gross, setGross] = useState("");

  const [role, setRole] = useState(false);

  const [status, setStatus] = useState("");

  const [paymodal, setPaymondal] = useState(false);
  const [rpaymodal, setRpaymodal] = useState(false);

  const [rnotemodal, setRnotemodal] = useState(false);
  const [agentrefundmodal, setAgentrefundmodal] = useState(false);
  const [invoiceId, setInvoiceid] = useState("");
  const [invoiceservice, setInvoiceservice] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setRole(res.data.message[0].role);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorfetch3"
      )
      .then((res) => setAllven(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function upstatus2(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upstatus2",
        {
          id: x,
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function upstatus(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upstatushajj",
        {
          id: x,
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function refundhistory() {
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    document.getElementById("spfrm2").reset();

    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", tamount);
    data.append("refundb", re);
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("name", uname);
    data.append("vname", vname);
    data.append("serial", serial);
    data.append("base", base);
    data.append("gross", gross);
    data.append("note", note);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundhistory",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    toast.success("Refund Addedd", {
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      toastId: "success4",
      autoClose: 2000,
    });

    setPaid(tamount);
    setRefundb(re);
    refundfetch(uid, stype);

    setHbank("");
    setAmount("");
    setTamount(0);
  }

  function refundfetch(x, y) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundfetch",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => {
        setPhistory(res.data.message);
        setSnote(res.data.message[0].note);
        setSamount(res.data.message[0].amount);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }

  return (
    <>
      <ToastContainer />

      <Ginvoice id={invoiceId} service={invoiceservice} />
      <div className="noprint">
        <Paymentmodal
          active={paymodal}
          uname={uname}
          upay={upay}
          paid={paid}
          setPaid={setPaid}
          uid={uid}
          stype={stype}
          serial={serial}
          status={status}
          close={setPaymondal}
        />

        <Refundnote
          samount={samount}
          snote={snote}
          active={rnotemodal}
          uname={uname}
          close={setRnotemodal}
        />

        <div class="row search-mb">
          <div class="col-lg-12 col-12 border px-0">
            <div class="border-bottom bg-light mx-0">
              <h4 class="p-2 mb-0">Hajj</h4>
            </div>
            <div class="row mx-auto bg-light p-2 border-bottom">
              <div class="d-flex form-group has-search col-lg-9 col-9 m-0 p-0">
                <img src={searchb} class="sChooseIcon2" alt="..." />
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control searchbox"
                  required="required"
                  placeholder="Search Customer"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div class="col-lg-3 col-3 pr-0">
                <div class="col-12">
                  <input
                    type="date"
                    class="filtBTN-3 m-0 ml-3"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="row mx-auto">
              <ul
                class="nav nav-pills p-0 text-center col-lg-12"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item col-lg-6 col-6 px-0">
                  <a
                    class="nav-link active"
                    id="pills-home-tab"
                    onClick={() => setCsearch("regular")}
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Regular
                  </a>
                </li>
                <li class="nav-item col-lg-6 col-6 px-0">
                  <a
                    class="nav-link"
                    id="pills-profile-tab"
                    onClick={() => setCsearch("agent")}
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="true"
                  >
                    Agent
                  </a>
                </li>
              </ul>
              <hr />
            </div>

            {type == "hajj" ? (
              <>
                {allunique

                  .filter((item) => {
                    if (
                      item.cname.toLowerCase().includes(search.toLowerCase()) ||
                      item.cnum.includes(search.toLowerCase()) ||
                      item.cserial.toString().includes(search.toLowerCase()) ||
                      item.date.includes(search.toLowerCase()) ||
                      item.passport
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <>
                      {item.ctype == csearch ? (
                        <div class="card border-0 rounded-0">
                          <div class="card-body p-0 m-0">
                            <div class="row m-0 p-0 border-bottom border-top">
                              <div class="col-lg-2 col-4 p-2 my-auto">
                                {item.cphoto == "" ? (
                                  <img src={client} class="clientpp" />
                                ) : (
                                  <img
                                    src={
                                      "https://concorde-api.flydocx.com/" +
                                      process.env.REACT_APP_URL +
                                      "/images/" +
                                      item.cphoto
                                    }
                                    class="clientpp"
                                    alt="..."
                                  />
                                )}
                              </div>
                              <div class="col-lg-3 col-8 p-1 my-auto">
                                <div class="col">
                                  <p class="m-0">
                                    <span class="sldesign py-0">
                                      <small>#{item.trackno}</small>
                                    </span>
                                  </p>
                                  <p class="font-weight-bold c_name m-0">
                                    {item.cname}
                                  </p>
                                  <p class="m-0 font-weight-bold cusData">
                                    {item.cnum}
                                  </p>
                                  <p class="m-0 cusData" row="2">
                                    {item.cadd}
                                  </p>
                                  <p class="m-0 cusData">
                                    Passport:{" "}
                                    <span class="font-weight-bold">
                                      {item.passport}
                                    </span>
                                  </p>
                                  <p class="m-0 cusData">
                                    Visa Number:{" "}
                                    <span class="font-weight-bold">
                                      {item.visa}
                                    </span>
                                  </p>
                                  <Link
                                    class="dprofile"
                                    to={"/umrahprofile/" + item.id}
                                  >
                                    Download Data
                                  </Link>
                                </div>
                              </div>
                              <div class="col-lg-7 col-12 dflight_data p-2 pl-3">
                                {alldata.map((data) => {
                                  if (data.trackno == item.trackno) {
                                    return (
                                      <>
                                        {data.htype
                                          .toLowerCase()
                                          .includes("enrollment") ? (
                                          <div
                                            style={{ backgroundColor: "white" }}
                                            className="p-3 m-2 rounded"
                                          >
                                            <div
                                              class="accordion"
                                              id="accordionExample"
                                            >
                                              <div class="card border-0">
                                                <div
                                                  class="p-2"
                                                  id="headingOne"
                                                >
                                                  <h2 class="mb-0">
                                                    <button
                                                      class="btn btn-block text-left p-0 d-flex justify-content-between align-items-center"
                                                      type="button"
                                                      data-toggle="collapse"
                                                      data-target={
                                                        "#se" + data.id
                                                      }
                                                      aria-expanded="true"
                                                      aria-controls="collapseOne"
                                                    >
                                                      Enrollment
                                                      <i class="fa-solid fa-chevron-down"></i>
                                                    </button>
                                                  </h2>
                                                </div>

                                                <div
                                                  id={"se" + data.id}
                                                  class="collapse border-top"
                                                  aria-labelledby="headingOne"
                                                  data-parent="#accordionExample"
                                                >
                                                  <div class="card-body">
                                                    <div className="row">
                                                      <div className="col-sm-6">
                                                        {data.endate == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Enrollment date :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.endate}
                                                            </span>
                                                          </p>
                                                        )}
                                                        {data.ngno == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Ng no :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.ngno}
                                                            </span>
                                                          </p>
                                                        )}
                                                        {data.trackno == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Tracking no :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.trackno}
                                                            </span>
                                                          </p>
                                                        )}
                                                      </div>
                                                      <div className="col-sm-6">
                                                        <div>
                                                          {data.ctype ==
                                                          "agent" ? (
                                                            <div class="anbtn w-100 my-1">
                                                              {getagent(
                                                                data.aid
                                                              )}
                                                            </div>
                                                          ) : (
                                                            <div
                                                              onClick={() => {
                                                                setUname(
                                                                  data.cname
                                                                );
                                                                setUpay(
                                                                  Number(
                                                                    data.gross
                                                                  )
                                                                );
                                                                setPaid(
                                                                  data.paid
                                                                );
                                                                setUid(data.id);
                                                                setStype(
                                                                  "hajj"
                                                                );

                                                                setStatus(
                                                                  data.done
                                                                );

                                                                // if(data.refund == 0){
                                                                //   setStatus(1)
                                                                // }

                                                                // if(data.refund != 0){
                                                                //   setStatus(0)
                                                                // }

                                                                setPaymondal(
                                                                  true
                                                                );
                                                              }}
                                                              class="btn statusbttn w-100 my-1"
                                                              type="button"
                                                              data-toggle="modal"
                                                              data-target="#Cpaymentstatus"
                                                            >
                                                              Payment Status
                                                            </div>
                                                          )}

                                                          <>
                                                            {data.visa !==
                                                              "" && (
                                                              <>
                                                                {" "}
                                                                {data.done ==
                                                                1 ? (
                                                                  <button
                                                                    class="btn upbttn w-100 my-1"
                                                                    disabled
                                                                  >
                                                                    Update Info
                                                                  </button>
                                                                ) : (
                                                                  <Link
                                                                    to={
                                                                      "/updateha/" +
                                                                      data.id
                                                                    }
                                                                    class="btn upbttn w-100 my-1"
                                                                  >
                                                                    Update Info
                                                                  </Link>
                                                                )}
                                                              </>
                                                            )}
                                                          </>

                                                          <div class="row col-12 m-0 p-0 my-1 justify-content-between">
                                                            <>
                                                              {data.done ==
                                                              1 ? (
                                                                <button
                                                                  class="btn btn-primary w-100"
                                                                  data-toggle="modal"
                                                                  data-target="#refundnote"
                                                                  onClick={() => {
                                                                    refundfetch(
                                                                      data.id,
                                                                      "hajj"
                                                                    );
                                                                    setUname(
                                                                      data.cname
                                                                    );
                                                                    setRnotemodal(
                                                                      true
                                                                    );
                                                                  }}
                                                                >
                                                                  Refunded
                                                                </button>
                                                              ) : (
                                                                <div class="row col-12 m-0 p-0 my-1 justify-content-between">
                                                                  <div class="sStatus text-center">
                                                                    {data.ctype ==
                                                                    "agent" ? (
                                                                      <img
                                                                        onClick={() => {
                                                                          setUname(
                                                                            data.cname
                                                                          );

                                                                          setAgent(
                                                                            data.aid
                                                                          );

                                                                          setUpay(
                                                                            data.gross
                                                                          );
                                                                          setBase(
                                                                            data.base
                                                                          );

                                                                          setGross(
                                                                            data.gross
                                                                          );

                                                                          setPaid(
                                                                            data.paid
                                                                          );
                                                                          setUid(
                                                                            data.id
                                                                          );
                                                                          setStype(
                                                                            "hajj"
                                                                          );
                                                                          setRefundb(
                                                                            data.refund
                                                                          );
                                                                          setVname(
                                                                            data.vendor
                                                                          );
                                                                          setSerial(
                                                                            data.serial
                                                                          );
                                                                          refundfetch(
                                                                            data.id,
                                                                            "hajj"
                                                                          );
                                                                          setAgentrefundmodal(
                                                                            true
                                                                          );
                                                                        }}
                                                                        src={
                                                                          refund
                                                                        }
                                                                        class="staticon"
                                                                        type="button"
                                                                        data-toggle="modal"
                                                                        // data-target="#agentrefund"
                                                                        alt="..."
                                                                      />
                                                                    ) : (
                                                                      <img
                                                                        onClick={() => {
                                                                          setUname(
                                                                            data.cname
                                                                          );

                                                                          setAgent(
                                                                            data.aid
                                                                          );

                                                                          setUpay(
                                                                            data.gross
                                                                          );
                                                                          setBase(
                                                                            data.base
                                                                          );

                                                                          setGross(
                                                                            data.gross
                                                                          );

                                                                          setPaid(
                                                                            data.paid
                                                                          );
                                                                          setUid(
                                                                            data.id
                                                                          );
                                                                          setStype(
                                                                            "hajj"
                                                                          );
                                                                          setRefundb(
                                                                            data.refund
                                                                          );
                                                                          setVname(
                                                                            data.vendor
                                                                          );
                                                                          setSerial(
                                                                            data.serial
                                                                          );
                                                                          refundfetch(
                                                                            data.id,
                                                                            "hajj"
                                                                          );
                                                                          setRpaymodal(
                                                                            true
                                                                          );
                                                                        }}
                                                                        src={
                                                                          refund
                                                                        }
                                                                        class="staticon"
                                                                        type="button"
                                                                        data-toggle="modal"
                                                                        // data-target="#Cpaymentstatus2"
                                                                        alt="..."
                                                                      />
                                                                    )}
                                                                  </div>

                                                                  {role ==
                                                                  "admin" ? (
                                                                    <div
                                                                      class=" sStatus-R2 text-center"
                                                                      data-toggle="modal"
                                                                      onClick={() => {
                                                                        setAcid(
                                                                          data.id
                                                                        );
                                                                        setAcit(
                                                                          "hajj"
                                                                        );
                                                                      }}
                                                                      data-target="#delmodel"
                                                                    >
                                                                      <img
                                                                        src={
                                                                          trash
                                                                        }
                                                                        class="staticon"
                                                                        alt="..."
                                                                      />
                                                                    </div>
                                                                  ) : null}

                                                                  <button
                                                                    onClick={() => {
                                                                      setInvoiceid(
                                                                        data.id
                                                                      );
                                                                      setInvoiceservice(
                                                                        "hajj"
                                                                      );

                                                                      setTimeout(
                                                                        () => {
                                                                          window.print();
                                                                        },
                                                                        100
                                                                      );
                                                                    }}
                                                                    class=" sStatus text-center"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        download
                                                                      }
                                                                      class="staticon mt-0"
                                                                      alt="..."
                                                                    />
                                                                  </button>
                                                                </div>
                                                              )}
                                                            </>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            style={{ backgroundColor: "white" }}
                                            className="p-3 m-2 rounded"
                                          >
                                            <div
                                              class="accordion"
                                              id="accordionExample"
                                            >
                                              <div class="card border-0">
                                                <div
                                                  class="p-2"
                                                  id="headingTwo"
                                                >
                                                  <h2 class="mb-0">
                                                    <button
                                                      class="btn btn-block text-left collapsed p-0 d-flex justify-content-between align-items-center"
                                                      type="button"
                                                      data-toggle="collapse"
                                                      data-target={
                                                        "#en" + data.id
                                                      }
                                                      aria-expanded="false"
                                                      aria-controls="collapseTwo"
                                                    >
                                                      Service
                                                      <i class="fa-solid fa-chevron-down"></i>
                                                    </button>
                                                  </h2>
                                                </div>
                                                <div
                                                  id={"en" + data.id}
                                                  class="collapse show border-top"
                                                  aria-labelledby="headingTwo"
                                                  data-parent="#accordionExample"
                                                >
                                                  <div class="card-body">
                                                    <div className="row">
                                                      <div className="col-sm-6">
                                                        {data.endate == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Enrollment date :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.endate}
                                                            </span>
                                                          </p>
                                                        )}
                                                        {data.ngno == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Ng no :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.ngno}
                                                            </span>
                                                          </p>
                                                        )}
                                                        {data.trackno == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Tracking no :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.trackno}
                                                            </span>
                                                          </p>
                                                        )}

                                                        {data.idate == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Issue Date :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.idate}
                                                            </span>
                                                          </p>
                                                        )}

                                                        {data.airplane == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Airplane :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.airplane}
                                                            </span>
                                                          </p>
                                                        )}

                                                        {data.fno == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Flight :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.fno}
                                                            </span>
                                                          </p>
                                                        )}

                                                        {data.dloca == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Departure :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.dloca}{" "}
                                                              {data.departure}
                                                            </span>
                                                          </p>
                                                        )}

                                                        {data.aloca == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Arrival :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.aloca}{" "}
                                                              {data.arrival}
                                                            </span>
                                                          </p>
                                                        )}

                                                        {data.hname == "" ? (
                                                          <></>
                                                        ) : (
                                                          <p class="m-0 cusData">
                                                            Hotel Name :{" "}
                                                            <span class="font-weight-bold">
                                                              {data.hname}
                                                            </span>
                                                          </p>
                                                        )}
                                                      </div>

                                                      <div className="col-sm-6">
                                                        <div>
                                                          {data.ctype ==
                                                          "agent" ? (
                                                            <div class="anbtn w-100 my-1">
                                                              {getagent(
                                                                data.aid
                                                              )}
                                                            </div>
                                                          ) : (
                                                            <div
                                                              onClick={() => {
                                                                setUname(
                                                                  data.cname
                                                                );
                                                                setUpay(
                                                                  Number(
                                                                    data.gross
                                                                  )
                                                                );
                                                                setPaid(
                                                                  data.paid
                                                                );
                                                                setUid(data.id);
                                                                setStype(
                                                                  "hajj"
                                                                );

                                                                setStatus(
                                                                  data.done
                                                                );

                                                                // if(data.refund == 0){
                                                                //   setStatus(1)
                                                                // }

                                                                // if(data.refund != 0){
                                                                //   setStatus(0)
                                                                // }

                                                                setPaymondal(
                                                                  true
                                                                );
                                                              }}
                                                              class="btn statusbttn w-100 my-1"
                                                              type="button"
                                                              data-toggle="modal"
                                                              data-target="#Cpaymentstatus"
                                                            >
                                                              Payment Status
                                                            </div>
                                                          )}

                                                          <>
                                                            {data.done == 1 ? (
                                                              <button
                                                                class="btn upbttn w-100 my-1"
                                                                disabled
                                                              >
                                                                Update Info
                                                              </button>
                                                            ) : (
                                                              <Link
                                                                to={
                                                                  "/updateha/" +
                                                                  data.id
                                                                }
                                                                class="btn upbttn w-100 my-1"
                                                              >
                                                                Update Info
                                                              </Link>
                                                            )}
                                                          </>

                                                          <div class="row col-12 m-0 p-0 my-1 justify-content-between">
                                                            <>
                                                              {data.done ==
                                                              1 ? (
                                                                <button
                                                                  class="btn btn-primary w-100"
                                                                  data-toggle="modal"
                                                                  data-target="#refundnote"
                                                                  onClick={() => {
                                                                    refundfetch(
                                                                      data.id,
                                                                      "hajj"
                                                                    );
                                                                    setUname(
                                                                      data.cname
                                                                    );
                                                                    setRnotemodal(
                                                                      true
                                                                    );
                                                                  }}
                                                                >
                                                                  Refunded
                                                                </button>
                                                              ) : (
                                                                <div class="row col-12 m-0 p-0 my-1 justify-content-between">
                                                                  <div class="sStatus text-center">
                                                                    {data.ctype ==
                                                                    "agent" ? (
                                                                      <img
                                                                        onClick={() => {
                                                                          setUname(
                                                                            data.cname
                                                                          );

                                                                          setAgent(
                                                                            data.aid
                                                                          );

                                                                          setUpay(
                                                                            data.gross
                                                                          );
                                                                          setBase(
                                                                            data.base
                                                                          );

                                                                          setGross(
                                                                            data.gross
                                                                          );

                                                                          setPaid(
                                                                            data.paid
                                                                          );
                                                                          setUid(
                                                                            data.id
                                                                          );
                                                                          setStype(
                                                                            "hajj"
                                                                          );
                                                                          setRefundb(
                                                                            data.refund
                                                                          );
                                                                          setVname(
                                                                            data.vendor
                                                                          );
                                                                          setSerial(
                                                                            data.serial
                                                                          );
                                                                          refundfetch(
                                                                            data.id,
                                                                            "hajj"
                                                                          );
                                                                          setAgentrefundmodal(
                                                                            true
                                                                          );
                                                                        }}
                                                                        src={
                                                                          refund
                                                                        }
                                                                        class="staticon"
                                                                        type="button"
                                                                        data-toggle="modal"
                                                                        // data-target="#agentrefund"
                                                                        alt="..."
                                                                      />
                                                                    ) : (
                                                                      <img
                                                                        onClick={() => {
                                                                          setUname(
                                                                            data.cname
                                                                          );

                                                                          setAgent(
                                                                            data.aid
                                                                          );

                                                                          setUpay(
                                                                            data.gross
                                                                          );
                                                                          setBase(
                                                                            data.base
                                                                          );

                                                                          setGross(
                                                                            data.gross
                                                                          );

                                                                          setPaid(
                                                                            data.paid
                                                                          );
                                                                          setUid(
                                                                            data.id
                                                                          );
                                                                          setStype(
                                                                            "hajj"
                                                                          );
                                                                          setRefundb(
                                                                            data.refund
                                                                          );
                                                                          setVname(
                                                                            data.vendor
                                                                          );
                                                                          setSerial(
                                                                            data.serial
                                                                          );
                                                                          refundfetch(
                                                                            data.id,
                                                                            "hajj"
                                                                          );
                                                                          setRpaymodal(
                                                                            true
                                                                          );
                                                                        }}
                                                                        src={
                                                                          refund
                                                                        }
                                                                        class="staticon"
                                                                        type="button"
                                                                        data-toggle="modal"
                                                                        // data-target="#Cpaymentstatus2"
                                                                        alt="..."
                                                                      />
                                                                    )}
                                                                  </div>

                                                                  {role ==
                                                                  "admin" ? (
                                                                    <div
                                                                      class=" sStatus-R2 text-center"
                                                                      data-toggle="modal"
                                                                      onClick={() => {
                                                                        setAcid(
                                                                          data.id
                                                                        );
                                                                        setAcit(
                                                                          "hajj"
                                                                        );
                                                                      }}
                                                                      data-target="#delmodel"
                                                                    >
                                                                      <img
                                                                        src={
                                                                          trash
                                                                        }
                                                                        class="staticon"
                                                                        alt="..."
                                                                      />
                                                                    </div>
                                                                  ) : null}

                                                                  <button
                                                                    onClick={() => {
                                                                      setInvoiceid(
                                                                        data.id
                                                                      );
                                                                      setInvoiceservice(
                                                                        "hajj"
                                                                      );

                                                                      setTimeout(
                                                                        () => {
                                                                          window.print();
                                                                        },
                                                                        100
                                                                      );
                                                                    }}
                                                                    class=" sStatus text-center"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        download
                                                                      }
                                                                      class="staticon mt-0"
                                                                      alt="..."
                                                                    />
                                                                  </button>
                                                                </div>
                                                              )}
                                                            </>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    );
                                  }
                                })}
                              </div>

                              {/* <div class="col-lg-3 col-9 my-auto">
                                {item.ctype == "agent" ? (
                                  <div class="anbtn w-100 my-1">
                                    {getagent(item.aid)}
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      setUname(item.cname);
                                      setUpay(Number(item.gross));
                                      setPaid(item.paid);
                                      setUid(item.id);
                                      setStype("hajj");

                                      setStatus(item.done);

                                      // if(item.refund == 0){
                                      //   setStatus(1)
                                      // }

                                      // if(item.refund != 0){
                                      //   setStatus(0)
                                      // }

                                      setPaymondal(true);
                                    }}
                                    class="btn statusbttn w-100 my-1"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#Cpaymentstatus"
                                  >
                                    Payment Status
                                  </div>
                                )}

                                <>
                                  {item.done == 1 ? (
                                    <button
                                      class="btn upbttn w-100 my-1"
                                      disabled
                                    >
                                      Update Info
                                    </button>
                                  ) : (
                                    <Link
                                      to={"/updateha/" + item.id}
                                      class="btn upbttn w-100 my-1"
                                    >
                                      Update Info
                                    </Link>
                                  )}
                                </>

                                <div class="row col-12 m-0 p-0 my-1 justify-content-between">
                                  <>
                                    {item.done == 1 ? (
                                      <button
                                        class="btn btn-primary w-100"
                                        data-toggle="modal"
                                        data-target="#refundnote"
                                        onClick={() => {
                                          refundfetch(item.id, "hajj");
                                          setUname(item.cname);
                                          setRnotemodal(true);
                                        }}
                                      >
                                        Refunded
                                      </button>
                                    ) : (
                                      <div class="row col-12 m-0 p-0 my-1 justify-content-between">
                                        <div class="sStatus text-center">
                                          {item.ctype == "agent" ? (
                                            <img
                                              onClick={() => {
                                                setUname(item.cname);

                                                setAgent(item.aid);

                                                setUpay(item.gross);
                                                setBase(item.base);

                                                setGross(item.gross);

                                                setPaid(item.paid);
                                                setUid(item.id);
                                                setStype("hajj");
                                                setRefundb(item.refund);
                                                setVname(item.vendor);
                                                setSerial(item.serial);
                                                refundfetch(item.id, "hajj");
                                                setAgentrefundmodal(true);
                                              }}
                                              src={refund}
                                              class="staticon"
                                              type="button"
                                              data-toggle="modal"
                                              // data-target="#agentrefund"
                                              alt="..."
                                            />
                                          ) : (
                                            <img
                                              onClick={() => {
                                                setUname(item.cname);

                                                setAgent(item.aid);

                                                setUpay(item.gross);
                                                setBase(item.base);

                                                setGross(item.gross);

                                                setPaid(item.paid);
                                                setUid(item.id);
                                                setStype("hajj");
                                                setRefundb(item.refund);
                                                setVname(item.vendor);
                                                setSerial(item.serial);
                                                refundfetch(item.id, "hajj");
                                                setRpaymodal(true);
                                              }}
                                              src={refund}
                                              class="staticon"
                                              type="button"
                                              data-toggle="modal"
                                              // data-target="#Cpaymentstatus2"
                                              alt="..."
                                            />
                                          )}
                                        </div>

                                        {role == "admin" ? (
                                          <div
                                            class=" sStatus-R2 text-center"
                                            data-toggle="modal"
                                            onClick={() => {
                                              setAcid(item.id);
                                              setAcit("hajj");
                                            }}
                                            data-target="#delmodel"
                                          >
                                            <img
                                              src={trash}
                                              class="staticon"
                                              alt="..."
                                            />
                                          </div>
                                        ) : null}

                                        <button
                                          onClick={() => {
                                            setInvoiceid(item.id);
                                            setInvoiceservice("hajj");

                                            setTimeout(() => {
                                              window.print();
                                            }, 100);
                                          }}
                                          class=" sStatus text-center"
                                        >
                                          <img
                                            src={download}
                                            class="staticon mt-0"
                                            alt="..."
                                          />
                                        </button>
                                      </div>
                                    )}
                                  </>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
              </>
            ) : null}
          </div>
        </div>

        <Refundmodal
          active={rpaymodal}
          uname={uname}
          upay={upay}
          paid={paid}
          setPaid={setPaid}
          uid={uid}
          stype={stype}
          serial={serial}
          status={status}
          close={setRpaymodal}
        />

        <Agentrefundmodal
          active={agentrefundmodal}
          uname={uname}
          upay={upay}
          paid={paid}
          setPaid={setPaid}
          uid={uid}
          stype={stype}
          serial={serial}
          status={status}
          agents={agents}
          agent={agent}
          close={setAgentrefundmodal}
        />

        <Delmodal acid={acid} acit={acit} />
        <Footer />
      </div>
    </>
  );
}

export default withRouter(Hajj);
