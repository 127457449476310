import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Navigation2 from "../navigation2";
import axios from "axios";
import Sidebar from "../common/sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../screens/footer";
import Ticket from "./ticket";
import Passport from "./passport";
import Visa from "./visa";
import Hotel from "./hotel";
import Manpower from "./manpower";
import Umrah from "./umrah";
import Hajj from "./hajj";
import Medical from "./medical";
import Ginvoice from "../common/invoice";
import Packages from "./packages";

function Customer(props) {
  var type = props.match.params.type;
  const [alldata, setAlldata] = useState([]);
  const [allunique, setAllunique] = useState([]);
  const [previlage, setPrevilage] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setPrevilage(res.data.previlage[0]?.previlage);
        console.log(res.data.previlage[0]?.previlage);
      })
      .catch((err) => console.log(err));
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    if (type == "hotel") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/fetchhotel"
        )
        .then((res) => setAlldata(res.data.message))
        .catch((err) => console.log(err));
    }

    if (type == "visa") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/fetchvisa"
        )
        .then((res) => setAlldata(res.data.message))
        .catch((err) => console.log(err));
    }

    if (type == "international") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/fetchinter"
        )
        .then((res) => {
          setAlldata(res.data.message);
        })
        .catch((err) => console.log(err));
    }

    if (type == "domestic") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/fetchdomestic"
        )
        .then((res) => setAlldata(res.data.message))
        .catch((err) => console.log(err));
    }

    if (type == "passport") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/fetchpassport"
        )
        .then((res) => setAlldata(res.data.message))
        .catch((err) => console.log(err));
    }

    if (type == "manpower") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/fetchman"
        )
        .then((res) => setAlldata(res.data.message))
        .catch((err) => console.log(err));
    }

    if (type == "umrah") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/umrahfetch"
        )
        .then((res) => setAlldata(res.data.message))
        .catch((err) => console.log(err));
    }

    if (type == "hajj") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/hajjfetch"
        )
        .then((res) => {
          setAlldata(res.data.message);
          setAllunique(res.data.unique);
        })
        .catch((err) => console.log(err));
    }

    if (type == "medical") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/medicalfetch"
        )
        .then((res) => setAlldata(res.data.message))
        .catch((err) => console.log(err));
    }

    if (type == "packages") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/packages/fetchpackages"
        )
        .then((res) => {
          setAlldata(res.data.message);
        })
        .catch((err) => console.log(err));
    }
  }, [type, alldata]);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div class="container-fluid pb-2">
        <div class="row ">
          <div className="col-lg-2 col-9">
            <Sidebar />
          </div>

          <div class="col-lg-10 col-12 my-4">
            {type == "passport" &&
            previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
              <Passport alldata={alldata} type={type} />
            ) : null}

            {type == "domestic" &&
            previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
              <Ticket alldata={alldata} type={type} />
            ) : null}

            {type == "international" &&
            previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
              <Ticket alldata={alldata} type={type} />
            ) : null}

            {type == "visa" &&
            previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
              <Visa alldata={alldata} type={type} />
            ) : null}

            {type == "hotel" &&
            previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
              <Hotel alldata={alldata} type={type} />
            ) : null}

            {type == "medical" &&
            previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
              <Medical alldata={alldata} type={type} />
            ) : null}

            {type == "manpower" &&
            previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
              <Manpower alldata={alldata} type={type} />
            ) : null}

            {type == "umrah" &&
            previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
              <Umrah alldata={alldata} type={type} />
            ) : null}

            {type == "hajj" &&
            previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
              <Hajj alldata={alldata} type={type} allunique={allunique} />
            ) : null}

            {type == "packages" &&
            previlage.toLocaleLowerCase().includes(type.toLocaleLowerCase()) ? (
              <Packages alldata={alldata} type={type} />
            ) : null}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default withRouter(Customer);
