import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import noflight from "../images/noflights.svg";
import axios from "axios";
function Sidebar() {
  const [cin, setCin] = useState(0);
  const [cout, setCout] = useState(0);

  const [bin, setBin] = useState(0);
  const [bout, setBout] = useState(0);
  const [ex, setEx] = useState(0);
  const [pass, setPass] = useState(0);
  const [flight, setFlight] = useState([]);

  const [role, setRole] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/urole",
          {
            token: localStorage.getItem("token"),
          }
        )
        .then((res) => {
          if (res.data.message == "") {
            localStorage.removeItem("token");
          } else {
            setRole(res.data.message[0].role);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcash2"
      )
      .then((res) => {
        setCin(res.data.cin[0].total);
        setCout(
          res.data.cout[0].total2 +
            res.data.refund[0].total3 +
            res.data.expense[0].total4
        );
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchdbank2"
      )
      .then((res) => {
        setBin(res.data.bin[0].total);
        setBout(
          res.data.bout[0].total2 +
            res.data.brefund[0].total3 +
            res.data.expense[0].total4
        );
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    var d = new Date();
    var m = d.getMonth() + 1;
    var day = d.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    if (m < 10) {
      m = "0" + m;
    }
    var x = d.getFullYear() + "-" + m + "-" + day;

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/flightToday",
        {
          date: x,
        }
      )
      .then((res) => {
        setFlight(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/cusfetch"
      )
      .then((res) => {
        setPass(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  function dicash(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/dfetchcash2",
        {
          date: x,
        }
      )
      .then((res) => {
        setCin(res.data.cin[0].total);
        setCout(
          res.data.cout[0].total2 +
            res.data.refund[0].total3 +
            res.data.expense[0].total4
        );
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/dfetchdbank2",
        {
          date: x,
        }
      )
      .then((res) => {
        setBin(res.data.bin[0].total);
        setBout(
          res.data.bout[0].total2 +
            res.data.brefund[0].total3 +
            res.data.expense[0].total4
        );
      })
      .catch((err) => console.log(err));
  }
  return (
    <div class="row pt-4" id="sidebar">
      <div class="col-lg-12 rightitem">
        {/* <div class="card addcsbutton mb-2 right-bar-1 mbaddcs">
          <Link to="/adcustomer" class="right-bar-2 my-auto">
            Add Customer
          </Link>
        </div>

        <div class="card addcsbutton mb-2 right-bar-1">
          <Link to="/allcustomer" class="right-bar-2 my-auto">
            Customer Data
          </Link>
        </div> */}

        <div class="rightn p-3">
          <h5 class="text-left">Flights Today</h5>

          <div class="rightcdata pr-1">
            {flight.map((item) => (
              <div class="card my-1" style={{ borderRadius: 5 }}>
                <div class="row px-3 py-2 ">
                  <div class="col-lg-12 text-left">
                    <p class="mb-0">
                      <b>{item.cname}</b>
                    </p>
                    <p class="m-0" style={{ fontSize: 14 }}>
                      Airplane: {item.airplane}
                    </p>
                    <p class="m-0" style={{ fontSize: 14 }}>
                      Flight: {item.date}
                    </p>
                  </div>
                </div>
              </div>
            ))}

            {flight.length == 0 ? (
              <div>
                <img src={noflight} style={{ width: 220, marginTop: 100 }} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div
        class="col-12 closeside"
        onClick={() => {
          document.getElementById("sidebar").style.width = "0%";
        }}
      >
        <i class="fa-solid fa-arrow-right-long"></i>
      </div>
    </div>
  );
}

export default Sidebar;
