import axios from "axios";
import { useState } from "react";
import formatDate from "../functions/timeformat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import DelpaymentModal from "./delpayment";
import Loading from "../common/loading";

function Paymentmodal({
  uname,
  upay,
  paid,
  setPaid,
  uid,
  stype,
  serial,
  status,
  active,
  close,
}) {
  const [phistory, setPhistory] = useState([]);
  const [view, setView] = useState("cash");
  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("cash");
  const [tamount, setTamount] = useState(0);
  const [banks, setBanks] = useState([]);
  const [mload, setMload] = useState(false);
  const [abank, setAbank] = useState("");
  const [coun, setCoun] = useState(0);
  const [delpayModal, setDelpayModal] = useState(false);

  const [activeId, setActiveId] = useState("");

  function delpay(activeId) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delpayment",
        {
          id: activeId,
        }
      )
      .then((res) => {
        console.log(res.data.message);
        setCoun(coun + 1);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function historyfetch(uid, stype) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/historyfetch",
        {
          tid: uid,
          stype: stype,
        }
      )
      .then((res) => {
        setPhistory(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }

  function payhistory() {
    setMload(true);
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });

      setMload(false);
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        setMload(false);
        return false;
      }
    }

    document.getElementById("spfrm").reset();

    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", tamount);
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("abank", abank);
    data.append("name", uname);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/payhistory",
        data
      )
      .then((res) => {
        console.log(res.data.message);
        setMload(false);
      })
      .catch((err) => console.log(err));

    setPaid(tamount);
    historyfetch(uid, stype);

    setHbank("");
    setAmount("");
    setTamount(0);
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/historyfetch",
        {
          tid: uid,
          stype: stype,
        }
      )
      .then((res) => {
        console.log(res.data.message);
        setPhistory(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [uid, coun]);

  return (
    <div className={active ? "mp-Modal-backdrop" : ""}>
      <div class={active ? "mp-Modal mp-Modal-active" : "mp-Modal"}>
        <Loading load={mload} />
        <DelpaymentModal
          active={delpayModal}
          activeId={activeId}
          coun={coun}
          setCoun={setCoun}
        />

        <div>
          <div class="">
            <div class="mHeader">
              <i
                onClick={() => {
                  close(false);
                  setView("cash");
                  setPtype("cash");
                  setHbank("");
                  setAmount("");
                  setTamount(0);
                  document.getElementById("pills-home-tab").click();
                }}
                className="fa fa-close cusIcon"
              ></i>
              <p class="modal-title pModalheadear" id="CpaymentModalLabel">
                Payment Status
              </p>
            </div>
            <div class="pModalbody py-4">
              <div class="row">
                <div class="col-lg-5 fontTypA">
                  <p class="mb-2">Customer Name</p>
                </div>
                <div class="col-lg-7">
                  <p class="mb-2 font-weight-bold">{uname}</p>
                </div>
              </div>

              {status == 0 ? (
                <>
                  <div class="row">
                    <div class="col-lg-5 fontTypA">
                      <p class="mb-2">Total Payable</p>
                    </div>

                    <div class="col-lg-7">
                      <p class="mb-2 font-weight-bold">{upay} BDT</p>
                    </div>
                  </div>

                  <div class="row CastPay">
                    <div class="col-lg-5 fontTypA">
                      <p class="m-0">Total Paid</p>
                    </div>

                    <div class="col-lg-7">
                      <p class="mb-2 font-weight-bold">{paid} BDT</p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div class="row">
                    <div class="col-lg-5 fontTypA">
                      <p class="mb-2 font-weight-bold">Total Paid</p>
                    </div>

                    <div class="col-lg-7">
                      <p class="mb-2 font-weight-bold">{paid} BDT</p>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div class="pModalbody">
              {status == 1 ? (
                <></>
              ) : (
                <>
                  <p class="font-weight-bold border-bottom">New Payment</p>
                  <div class="row border mx-auto justify-content-center">
                    <ul
                      class="nav nav-pills text-center p-0 col-lg-12 col-12"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li class="nav-item w-50">
                        <a
                          class="nav-link active"
                          onClick={() => {
                            setView("cash");
                            setPtype("Cash");
                          }}
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Cash
                        </a>
                      </li>
                      <li class="nav-item w-50">
                        <a
                          class="nav-link"
                          onClick={() => {
                            setView("bank");
                            setPtype("bank");
                          }}
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#pills-profile"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Bank
                        </a>
                      </li>
                    </ul>

                    <form class="mb-2 mt-2 col-lg-12 col-12" id="spfrm">
                      {view == "cash" ? (
                        <div class="row align-items-center justify-content-center my-4">
                          <div class="col-lg-6 col-4 ">
                            <label for="_0" class="form-check-label fontTypA">
                              Cash Payment
                            </label>
                          </div>
                          <div class="col-lg-6 col-6 d-flex">
                            <input
                              id=""
                              name=""
                              type="text"
                              class="form-control"
                              required="required"
                              onChange={(e) => {
                                setAmount(e.target.value);
                                setTamount(
                                  Number(paid) + Number(e.target.value)
                                );
                              }}
                            />
                            <p class="ml-2 fontTypA">BDT</p>
                          </div>
                        </div>
                      ) : null}

                      {view == "bank" ? (
                        <div class="row align-items-center justify-content-center my-4">
                          <div class="col-lg-6 col-4">
                            <label for="_0" class="form-check-label fontTypA">
                              Bank Payment
                            </label>
                          </div>
                          <div class="col-lg-6 col-6 d-flex">
                            <input
                              id=""
                              name=""
                              type="text"
                              class="form-control"
                              required="required"
                              onChange={(e) => {
                                setAmount(e.target.value);
                                setTamount(
                                  Number(paid) + Number(e.target.value)
                                );
                              }}
                            />
                            <p class=" ml-2 fontTypA">BDT</p>
                          </div>
                          <div class="col-lg-6"></div>
                          <div class="col-lg-6 mt-2 col-11 d-flex">
                            <div class="input-group">
                              <select
                                id="inputState"
                                class="form-select form-control"
                                onChange={(e) => setHbank(e.target.value)}
                              >
                                <option class="form-control" selected value="">
                                  Choose Bank...
                                </option>
                                {banks.map((item) => (
                                  <option value={item.id + "/" + item.bank}>
                                    {item.bank}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </form>
                  </div>

                  <div class="justify-content-between my-3">
                    <button
                      type="button"
                      class="btn paymentUpdate w-100"
                      onClick={() => {
                        payhistory();
                      }}
                    >
                      Make Payment
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="pModalbody mt-4">
          <p class="border-bottom font-weight-bold mb-0">Payment history</p>
          <table class="table border mt-0" style={{ fontSize: 13 }}>
            <thead class="bg-light">
              <tr>
                <th scope="col" class="py-1">
                  Date
                </th>
                <th scope="col" class="py-1">
                  Payment type
                </th>
                <th scope="col" class="py-1">
                  Amount
                </th>
                <th scope="col" class="py-1">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {phistory.map((item, index) => (
                <tr>
                  <td>{formatDate(item.date)}</td>
                  <td>
                    {item.type}{" "}
                    {item.type == "bank" ? "(" + item.bank + ")" : null}
                  </td>
                  <td class="text-center">{item.amount}</td>
                  <td class="text-center">
                    <i
                      data-toggle="modal"
                      data-target="#delModelinside"
                      onClick={() => {
                        setActiveId(item.id);
                      }}
                      className="fa fa-trash"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Paymentmodal;
