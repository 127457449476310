import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import searchb from "../../images/search.png";
import refund from "../../images/refund.png";
import trash from "../../images/trash.png";
import file from "../../images/files.png";
import download from "../../images/download.png";
import reissue from "../../images/reissue.png";
import waiting from "../../images/flightWaiting.png";
import Land from "../../images/flightLand.png";
import done from "../../images/done.png";
import axios from "axios";
import Sidebar from "../Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../screens/footer";
import Paymentmodal from "../modals/paymentmodal";
import formatDate from "../functions/timeformat";
import Delmodal from "../modals/delmodal";
import Refundnote from "../modals/refundNote";
import Refundmodal from "../modals/refundmodal";
import Agentrefundmodal from "../modals/agentrefundmodal";
import Ginvoice from "../common/invoice";

function Ticket({ alldata, type }) {
  const [search, setSearch] = useState("");
  const [csearch, setCsearch] = useState("regular");

  const [photo, setPhoto] = useState("");
  const [banks, setBanks] = useState([]);
  const [stype, setStype] = useState("");
  const [refundb, setRefundb] = useState(0);
  const [re, setRe] = useState(0);
  const [abank, setAbank] = useState("");

  const [uname, setUname] = useState("");
  const [upay, setUpay] = useState("");
  const [paid, setPaid] = useState("");
  const [uid, setUid] = useState(0);
  const [view, setView] = useState("cash");

  const [vname, setVname] = useState("");
  const [serial, setSerial] = useState("");
  const [base, setBase] = useState("");

  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("cash");
  const [tamount, setTamount] = useState(0);
  const [phistory, setPhistory] = useState([]);
  const [acid, setAcid] = useState("");
  const [acit, setAcit] = useState("");

  const [allven, setAllven] = useState([]);
  const [agents, setAgents] = useState([]);

  const [note, setNote] = useState("");

  const [snote, setSnote] = useState("");
  const [samount, setSamount] = useState("");

  const [agent, setAgent] = useState("");

  const [gross, setGross] = useState("");

  const [role, setRole] = useState(false);

  const [status, setStatus] = useState("");

  const [paymodal, setPaymondal] = useState(false);
  const [rnotemodal, setRnotemodal] = useState(false);
  const [rpaymodal, setRpaymodal] = useState(false);
  const [agentrefundmodal, setAgentrefundmodal] = useState(false);
  const [invoiceId, setInvoiceid] = useState("");
  const [invoiceservice, setInvoiceservice] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setRole(res.data.message[0].role);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorfetch3"
      )
      .then((res) => setAllven(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function upstatus2(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upstatus2",
        {
          id: x,
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function upstatus(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upstatus",
        {
          id: x,
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function refundhistory() {
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    document.getElementById("spfrm2").reset();

    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", tamount);
    data.append("refundb", re);
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("name", uname);
    data.append("vname", vname);
    data.append("serial", serial);
    data.append("base", base);
    data.append("gross", gross);
    data.append("note", note);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundhistory",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    toast.success("Refund Addedd", {
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      toastId: "success4",
      autoClose: 2000,
    });

    setPaid(tamount);
    setRefundb(re);
    refundfetch(uid, stype);

    setHbank("");
    setAmount("");
    setTamount(0);
  }

  function refundfetch(x, y) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundfetch",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => {
        setPhistory(res.data.message);
        setSnote(res.data.message[0].note);
        setSamount(res.data.message[0].amount);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }

  return (
    <>
      <ToastContainer />

      <Ginvoice id={invoiceId} service={invoiceservice} />
      <div className="noprint">
        <Paymentmodal
          active={paymodal}
          uname={uname}
          upay={upay}
          paid={paid}
          setPaid={setPaid}
          uid={uid}
          stype={stype}
          serial={serial}
          status={status}
          close={setPaymondal}
        />

        <Refundnote
          samount={samount}
          snote={snote}
          active={rnotemodal}
          uname={uname}
          close={setRnotemodal}
        />

        <div class="row search-mb">
          <div class="col-lg-12 col-12 border px-0">
            <div class="border-bottom bg-light mx-0">
              <h4 class="p-2 mb-0">Ticket</h4>
            </div>

            <div class="row mx-auto bg-light p-2 border-bottom">
              <div class="d-flex form-group has-search col-lg-9 col-9 m-0 p-0">
                <img src={searchb} class="sChooseIcon2" alt="..." />
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control searchbox"
                  required="required"
                  placeholder="Search Customer"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div class="col-lg-3 col-3 pr-0">
                <div class="col-12">
                  <input
                    type="date"
                    class="filtBTN-3 m-0 ml-3"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="row mx-auto">
              <ul
                class="nav nav-pills p-0 text-center col-lg-12"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item col-lg-6 col-6 px-0">
                  <a
                    class="nav-link active"
                    id="pills-home-tab"
                    onClick={() => setCsearch("regular")}
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Regular
                  </a>
                </li>
                <li class="nav-item col-lg-6 col-6 px-0">
                  <a
                    class="nav-link"
                    id="pills-profile-tab"
                    onClick={() => setCsearch("agent")}
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="true"
                  >
                    Agent
                  </a>
                </li>
              </ul>
              <hr />
            </div>

            {type == "domestic" ? (
              <>
                {alldata
                  .filter((item) => {
                    if (
                      item.cname.toLowerCase().includes(search.toLowerCase()) ||
                      item.cnum.toLowerCase().includes(search.toLowerCase()) ||
                      item.cid.toString().includes(search.toLowerCase()) ||
                      item.airplane
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item.date
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item.serial.toString().includes(search.toLowerCase()) ||
                      item.passport.toString().includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <>
                      {item.ctype == csearch ? (
                        <div class="card border-0 rounded-0">
                          <div class="card-body p-0 m-0">
                            <div class="row m-0 p-0 border-bottom border-top">
                              {item.status == 0 ? (
                                <></>
                              ) : (
                                <div class="ribbon">
                                  <span>All Paid</span>
                                </div>
                              )}
                              <div class="col-lg-4 col-12 p-2">
                                <div class="row p-2">
                                  <div class="col my-auto">
                                    <p class="m-0">
                                      <span class="sldesign py-0">
                                        <small>#{item.cserial}</small>
                                      </span>
                                    </p>
                                    <p class="m-0 font-weight-bold c_name">
                                      {item.cname}
                                    </p>
                                    <p class="m-0 font-weight-bold cusData">
                                      {item.cnum}
                                    </p>
                                    <p class="m-0 cusData" row="2">
                                      {item.cadd}
                                    </p>
                                    <Link
                                      class="dprofile"
                                      to={"/ticket/" + item.id}
                                    >
                                      Download Data
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-12 dflight_data my-auto p-2 pl-3">
                                <p class="m-0 cusData">
                                  Vendor :{" "}
                                  <span class="font-weight-bold">
                                    {allven.map((y) => (
                                      <>
                                        {y.id == item.vendor ? y.vname : null}
                                      </>
                                    ))}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Serial/PNR :{" "}
                                  <span class="font-weight-bold">
                                    {item.gpnr}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Airlines :{" "}
                                  <span class="font-weight-bold">
                                    {item.airplane}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Flight :{" "}
                                  <span class="font-weight-bold">
                                    {item.flight}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Departure :{" "}
                                  <span class="font-weight-bold">
                                    {item.departure}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Arrival :{" "}
                                  <span class="font-weight-bold">
                                    {item.arrival}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Issue Date :{" "}
                                  <span class="font-weight-bold">
                                    {item.idate}
                                  </span>
                                </p>
                              </div>

                              <div class="col-lg-3 col-8 my-auto borderRight">
                                {item.ctype == "agent" ? (
                                  <div class="anbtn w-100 my-1">
                                    {getagent(item.aid)}
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      setUname(item.cname);
                                      setUpay(item.gross);
                                      setPaid(item.paid);
                                      setUid(item.id);
                                      setStype("ticket");
                                      setSerial(item.serial);
                                      setStatus(item.done);

                                      setPaymondal(true);
                                    }}
                                    class="btn statusbttn w-100 my-1"
                                    type="button"
                                    // data-toggle="modal"
                                    // data-target="#Cpaymentstatus"
                                  >
                                    Payment Status
                                  </div>
                                )}
                                {item.done == 1 ? (
                                  <button
                                    class="btn upbttn w-100 my-1"
                                    disabled
                                  >
                                    Update Info
                                  </button>
                                ) : (
                                  <Link
                                    to={"/updatet/" + item.id}
                                    class="btn upbttn w-100 my-1"
                                  >
                                    Update Info
                                  </Link>
                                )}

                                {item.status == 0 ? (
                                  <>
                                    {item.done == 1 ? (
                                      <button
                                        class="btn btn-primary w-100"
                                        data-toggle="modal"
                                        data-target="#refundnote"
                                        onClick={() => {
                                          refundfetch(item.id, "ticket");
                                          setUname(item.cname);
                                          setRnotemodal(true);
                                        }}
                                      >
                                        Refunded
                                      </button>
                                    ) : (
                                      <div class="row col-12 m-0 p-0 my-1 justify-content-between">
                                        <div class="sStatus text-center">
                                          {item.ctype == "agent" ? (
                                            <img
                                              onClick={() => {
                                                setUname(item.cname);

                                                setAgent(item.aid);

                                                setUpay(item.gross);
                                                setBase(item.base);

                                                setGross(item.gross);

                                                setPaid(item.paid);
                                                setUid(item.id);
                                                setStype("ticket");
                                                setRefundb(item.refund);
                                                setVname(item.vendor);
                                                setSerial(item.serial);
                                                refundfetch(item.id, "ticket");
                                                setAgentrefundmodal(true);
                                              }}
                                              src={refund}
                                              class="staticon"
                                              type="button"
                                              // data-toggle="modal"
                                              // data-target="#agentrefund"
                                              alt="..."
                                            />
                                          ) : (
                                            <img
                                              onClick={() => {
                                                setUname(item.cname);
                                                setUpay(item.gross);
                                                setBase(item.base);
                                                setGross(item.gross);
                                                setPaid(item.paid);
                                                setUid(item.id);
                                                setStype("ticket");
                                                setRefundb(item.refund);
                                                setVname(item.vendor);
                                                setSerial(item.serial);
                                                refundfetch(item.id, "ticket");
                                                setRpaymodal(true);
                                              }}
                                              src={refund}
                                              class="staticon"
                                              type="button"
                                              // data-toggle="modal"
                                              // data-target="#Cpaymentstatus2"
                                              alt="..."
                                            />
                                          )}
                                        </div>

                                        <Link
                                          to={"/reissue/" + item.id}
                                          class="sStatus text-center"
                                        >
                                          <img
                                            src={reissue}
                                            class="staticon"
                                            style={{ cursor: "pointer" }}
                                            alt="..."
                                          />
                                        </Link>

                                        {/* <Link
                                              to={
                                                "/ginvoice/" +
                                                item.id +
                                                "/" +
                                                item.type
                                              }
                                              class="sStatus text-center"
                                            >
                                              <img
                                                src={download}
                                                class="staticon"
                                                style={{ cursor: "pointer" }}
                                                alt="..."
                                              />
                                            </Link> */}
                                        <a
                                          onClick={() => {
                                            setInvoiceid(item.id);
                                            setInvoiceservice(item.type);
                                            setTimeout(() => {
                                              window.print();
                                            }, 100);
                                          }}
                                          class=" sStatus text-center"
                                        >
                                          <img
                                            src={download}
                                            class="staticon"
                                            alt="..."
                                          />
                                        </a>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div
                                    class="sStatusI text-center my-1"
                                    onClick={() => {
                                      setInvoiceid(item.id);
                                      setInvoiceservice(item.type);
                                      setTimeout(() => {
                                        window.print();
                                      }, 100);
                                    }}
                                  >
                                    <a class="text-center">
                                      <img
                                        src={download}
                                        class="staticon"
                                        alt="..."
                                      />
                                    </a>
                                  </div>
                                )}
                              </div>

                              <div class="col-lg-1 col-4 my-auto">
                                {item.status == 0 ? (
                                  <div class="row col-12 m-0 p-0 align-items-center">
                                    <div
                                      class=" sStatusOK-n w-100 text-center my-auto"
                                      onClick={(e) => upstatus(item.id)}
                                    >
                                      <p
                                        class="mb-2 mt-2 text-white"
                                        style={{ fontSize: 13 }}
                                      >
                                        <i class="fa-solid fa-check mx-1"></i>{" "}
                                        Paid
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {role == "admin" ? (
                                  <div
                                    class="sStatus-R w-100 text-center my-1"
                                    data-toggle="modal"
                                    onClick={() => {
                                      setAcid(item.id);
                                      setAcit("ticket");
                                    }}
                                    data-target="#delmodel"
                                  >
                                    <img
                                      src={trash}
                                      class="staticon-2"
                                      alt="..."
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
              </>
            ) : null}

            {type == "international" ? (
              <>
                {alldata
                  .filter((item) => {
                    if (
                      item.cname.toLowerCase().includes(search.toLowerCase()) ||
                      item.date
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item.cnum.toLowerCase().includes(search.toLowerCase()) ||
                      item.cserial
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item.airplane
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item.passport
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                      item.serial.toString().includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <>
                      {item.ctype == csearch ? (
                        <div class="card border-0 rounded-0">
                          <div class="card-body p-0 m-0">
                            {item.status == 0 ? (
                              <>
                                <div class="row m-0 p-0 border-bottom border-top">
                                  <div class="col-lg-4 col-12 p-2">
                                    <div class="row p-2">
                                      <div class="col my-auto">
                                        <p class="m-0">
                                          <span class="sldesign py-0">
                                            <small>#{item.cserial}</small>
                                          </span>
                                        </p>
                                        <p class="font-weight-bold c_name m-0">
                                          {item.cname}
                                        </p>
                                        <p class="m-0 font-weight-bold cusData">
                                          {item.cnum}
                                        </p>
                                        <p class="m-0 cusData" row="2">
                                          {item.cadd}
                                        </p>
                                        <p class="m-0 cusData">
                                          Passport:{" "}
                                          <span class="font-weight-bold">
                                            {item.passport}
                                          </span>
                                        </p>
                                        <p class="m-0 cusData">
                                          Visa:{" "}
                                          <span class="font-weight-bold">
                                            {item.visa}
                                          </span>
                                        </p>
                                        <Link
                                          class="dprofile"
                                          to={"/ticket/" + item.id}
                                        >
                                          Download Data
                                        </Link>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-4 col-12 dflight_data p-2 pl-3">
                                    <p class="m-0 cusData">
                                      Vendor :{" "}
                                      <span class="font-weight-bold">
                                        {allven.map((y) => (
                                          <>
                                            {y.id == item.vendor
                                              ? y.vname
                                              : null}
                                          </>
                                        ))}
                                      </span>
                                    </p>

                                    <p class="m-0 cusData">
                                      Serial/PNR :{" "}
                                      <span class="font-weight-bold">
                                        {item.gpnr}
                                      </span>
                                    </p>
                                    <p class="m-0 cusData">
                                      Airlines :{" "}
                                      <span class="font-weight-bold">
                                        {item.airplane}
                                      </span>
                                    </p>
                                    <p class="m-0 cusData">
                                      Flight :{" "}
                                      <span class="font-weight-bold">
                                        {item.flight}
                                      </span>
                                    </p>
                                    <p class="m-0 cusData">
                                      Departure :{" "}
                                      <span class="font-weight-bold">
                                        {item.departure}
                                      </span>
                                    </p>
                                    <p class="m-0 cusData">
                                      Arrival :{" "}
                                      <span class="font-weight-bold">
                                        {item.arrival}
                                      </span>
                                    </p>
                                    <p class="m-0 ">
                                      Issue Date :{" "}
                                      <span class="font-weight-bold">
                                        {item.idate}
                                      </span>
                                    </p>
                                  </div>

                                  <div class="col-lg-3 col-6 my-auto borderRight">
                                    {item.ctype == "agent" ? (
                                      <div class="anbtn w-100 my-1">
                                        {getagent(item.aid)}
                                      </div>
                                    ) : (
                                      <div
                                        onClick={() => {
                                          setUname(item.cname);
                                          setUpay(item.gross);
                                          setPaid(item.paid);
                                          setUid(item.id);
                                          setStype("ticket");

                                          setSerial(item.serial);
                                          setStatus(item.done);

                                          setPaymondal(true);
                                        }}
                                        class="col-12 btn statusbttn w-100 my-1"
                                        type="button"
                                        data-toggle="modal"
                                      >
                                        Payment Status
                                      </div>
                                    )}
                                    {item.done == 1 ? (
                                      <button
                                        class="btn upbttn w-100 my-1"
                                        disabled
                                      >
                                        Update Info
                                      </button>
                                    ) : (
                                      <Link
                                        to={"/updatet/" + item.id}
                                        class="btn upbttn w-100 my-1"
                                      >
                                        Update Info
                                      </Link>
                                    )}

                                    {item.done == 1 ? (
                                      <button
                                        class="btn btn-primary w-100"
                                        data-toggle="modal"
                                        data-target="#refundnote"
                                        onClick={() => {
                                          refundfetch(item.id, "ticket");
                                          setUname(item.cname);
                                          setRnotemodal(true);
                                        }}
                                      >
                                        Refunded
                                      </button>
                                    ) : (
                                      <div class="row col-12 m-0 p-0 my-1 justify-content-between">
                                        <div class="sStatus text-center">
                                          {item.ctype == "agent" ? (
                                            <img
                                              onClick={() => {
                                                setUname(item.cname);

                                                setAgent(item.aid);

                                                setUpay(item.gross);
                                                setBase(item.base);

                                                setGross(item.gross);

                                                setPaid(item.paid);
                                                setUid(item.id);
                                                setStype("ticket");
                                                setRefundb(item.refund);
                                                setVname(item.vendor);
                                                setSerial(item.serial);
                                                refundfetch(item.id, "ticket");
                                                setAgentrefundmodal(true);
                                              }}
                                              src={refund}
                                              class="staticon"
                                              type="button"
                                              // data-toggle="modal"
                                              // data-target="#agentrefund"
                                              alt="..."
                                            />
                                          ) : (
                                            <img
                                              onClick={() => {
                                                setUname(item.cname);
                                                setUpay(item.gross);
                                                setBase(item.base);
                                                setGross(item.gross);
                                                setPaid(item.paid);
                                                setUid(item.id);
                                                setStype("ticket");
                                                setRefundb(item.refund);
                                                setVname(item.vendor);
                                                setSerial(item.serial);
                                                refundfetch(item.id, "ticket");
                                                setRpaymodal(true);
                                              }}
                                              src={refund}
                                              class="staticon"
                                              type="button"
                                              // data-toggle="modal"
                                              // data-target="#Cpaymentstatus2"
                                              alt="..."
                                            />
                                          )}
                                        </div>

                                        <Link
                                          to={"/reissue/" + item.id}
                                          class="sStatus text-center"
                                        >
                                          <img
                                            src={reissue}
                                            class="staticon"
                                            style={{ cursor: "pointer" }}
                                            alt="..."
                                          />
                                        </Link>

                                        <a
                                          onClick={() => {
                                            setInvoiceid(item.id);
                                            setInvoiceservice(item.type);
                                            setTimeout(() => {
                                              window.print();
                                            }, 100);
                                          }}
                                          class="sStatus text-center"
                                        >
                                          <img
                                            src={download}
                                            class="staticon"
                                            alt="..."
                                          />
                                        </a>
                                      </div>
                                    )}
                                  </div>

                                  <div class="col-lg-1 col-3 my-auto">
                                    {item.status == 0 ? (
                                      <div class="row col-12 m-0 p-0 align-items-center">
                                        <div
                                          class=" sStatusOK-n w-100 text-center my-auto"
                                          onClick={(e) => upstatus(item.id)}
                                        >
                                          {/* <img
                                        src={done}
                                        class="staticon-2"
                                        style={{ cursor: "pointer" }}
                                        alt="Flight Done"
                                      /> */}
                                          <p
                                            class="mb-2 mt-2 text-white"
                                            style={{ fontSize: 13 }}
                                          >
                                            <i class="fa-solid fa-check mx-1"></i>
                                            Paid
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {role == "admin" ? (
                                      <div
                                        class="sStatus-R w-100 text-center my-1"
                                        data-toggle="modal"
                                        onClick={() => {
                                          setAcid(item.id);
                                          setAcit("ticket");
                                        }}
                                        data-target="#delmodel"
                                      >
                                        <img
                                          src={trash}
                                          class="staticon-2"
                                          alt="..."
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div class="row m-0 p-0 border-bottom border-top">
                                  <div class="col-lg-4 col-12 p-2">
                                    <div class="row p-2">
                                      <div class="col my-auto">
                                        <p class="m-0">
                                          <span class="sldesign py-0">
                                            <small>#{item.cserial}</small>
                                          </span>
                                        </p>
                                        <p class="font-weight-bold c_name m-0">
                                          {item.cname}
                                        </p>
                                        <p class="m-0 font-weight-bold cusData">
                                          {item.cnum}
                                        </p>
                                        <p class="m-0 cusData" row="2">
                                          {item.cadd}
                                        </p>
                                        <p class="m-0 cusData">
                                          Passport:{" "}
                                          <span class="font-weight-bold">
                                            {item.passport}
                                          </span>
                                        </p>
                                        <p class="m-0 cusData">
                                          Visa:{" "}
                                          <span class="font-weight-bold">
                                            {item.visa}
                                          </span>
                                        </p>
                                        <Link
                                          class="dprofile"
                                          to={"/ticket/" + item.id}
                                        >
                                          Download Data
                                        </Link>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-4 col-12 dflight_data p-2 pl-3">
                                    <p class="m-0 cusData">
                                      Vendor :{" "}
                                      <span class="font-weight-bold">
                                        {allven.map((y) => (
                                          <>
                                            {y.id == item.vendor
                                              ? y.vname
                                              : null}
                                          </>
                                        ))}
                                      </span>
                                    </p>

                                    <p class="m-0 cusData">
                                      Serial/PNR :{" "}
                                      <span class="font-weight-bold">
                                        {item.serial}
                                      </span>
                                    </p>
                                    <p class="m-0 cusData">
                                      Airlines :{" "}
                                      <span class="font-weight-bold">
                                        {item.airplane}
                                      </span>
                                    </p>
                                    <p class="m-0 cusData">
                                      Flight :{" "}
                                      <span class="font-weight-bold">
                                        {item.flight}
                                      </span>
                                    </p>
                                    <p class="m-0 cusData">
                                      Departure :{" "}
                                      <span class="font-weight-bold">
                                        {item.departure}
                                      </span>
                                    </p>
                                    <p class="m-0 cusData">
                                      Arrival :{" "}
                                      <span class="font-weight-bold">
                                        {item.arrival}
                                      </span>
                                    </p>
                                    <p class="m-0 ">
                                      Issue Date :{" "}
                                      <span class="font-weight-bold">
                                        {item.idate}
                                      </span>
                                    </p>
                                  </div>

                                  <div class="col-lg-3 col-6 my-auto borderRight">
                                    {item.ctype == "agent" ? (
                                      <div class="anbtn w-100 my-1">
                                        {getagent(item.aid)}
                                      </div>
                                    ) : (
                                      <div
                                        onClick={() => {
                                          setUname(item.cname);
                                          setUpay(item.gross);
                                          setPaid(item.paid);
                                          setUid(item.id);
                                          setStype("ticket");

                                          setSerial(item.serial);
                                          setStatus(item.done);

                                          setPaymondal(true);
                                        }}
                                        class="col-12 btn statusbttn w-100 my-1"
                                        type="button"
                                        data-toggle="modal"
                                      >
                                        Payment Status
                                      </div>
                                    )}
                                    {item.done == 1 ? (
                                      <button
                                        class="btn upbttn w-100 my-1"
                                        disabled
                                      >
                                        Update Info
                                      </button>
                                    ) : (
                                      <Link
                                        to={"/updatet/" + item.id}
                                        class="btn upbttn w-100 my-1"
                                      >
                                        Update Info
                                      </Link>
                                    )}

                                    {item.done == 1 ? (
                                      <button
                                        class="btn btn-primary w-100"
                                        data-toggle="modal"
                                        data-target="#refundnote"
                                        onClick={() => {
                                          refundfetch(item.id, "ticket");
                                          setUname(item.cname);
                                          setRnotemodal(true);
                                        }}
                                      >
                                        Refunded
                                      </button>
                                    ) : (
                                      <div class="row col-12 m-0 p-0 my-1 justify-content-between">
                                        <div class="sStatus text-center">
                                          {item.ctype == "agent" ? (
                                            <img
                                              onClick={() => {
                                                setUname(item.cname);

                                                setAgent(item.aid);

                                                setUpay(item.gross);
                                                setBase(item.base);

                                                setGross(item.gross);

                                                setPaid(item.paid);
                                                setUid(item.id);
                                                setStype("ticket");
                                                setRefundb(item.refund);
                                                setVname(item.vendor);
                                                setSerial(item.serial);
                                                refundfetch(item.id, "ticket");
                                                setAgentrefundmodal(true);
                                              }}
                                              src={refund}
                                              class="staticon"
                                              type="button"
                                              // data-toggle="modal"
                                              // data-target="#agentrefund"
                                              alt="..."
                                            />
                                          ) : (
                                            <img
                                              onClick={() => {
                                                setUname(item.cname);
                                                setUpay(item.gross);
                                                setBase(item.base);
                                                setGross(item.gross);
                                                setPaid(item.paid);
                                                setUid(item.id);
                                                setStype("ticket");
                                                setRefundb(item.refund);
                                                setVname(item.vendor);
                                                setSerial(item.serial);
                                                refundfetch(item.id, "ticket");
                                                setRpaymodal(true);
                                              }}
                                              src={refund}
                                              class="staticon"
                                              type="button"
                                              // data-toggle="modal"
                                              // data-target="#Cpaymentstatus2"
                                              alt="..."
                                            />
                                          )}
                                        </div>

                                        <Link
                                          to={"/reissue/" + item.id}
                                          class="sStatus text-center"
                                        >
                                          <img
                                            src={reissue}
                                            class="staticon"
                                            style={{ cursor: "pointer" }}
                                            alt="..."
                                          />
                                        </Link>

                                        <a
                                          onClick={() => {
                                            setInvoiceid(item.id);
                                            setInvoiceservice(item.type);
                                            setTimeout(() => {
                                              window.print();
                                            }, 100);
                                          }}
                                          class="sStatus text-center"
                                        >
                                          <img
                                            src={download}
                                            class="staticon"
                                            alt="..."
                                          />
                                        </a>
                                      </div>
                                    )}
                                  </div>

                                  <div class="col-lg-1 col-3 my-auto">
                                    {item.status == 0 ? (
                                      <div class="row col-12 m-0 p-0 align-items-center">
                                        <div
                                          class=" sStatusOK-n w-100 text-center my-auto"
                                          onClick={(e) => upstatus(item.id)}
                                        >
                                          {/* <img
                                        src={done}
                                        class="staticon-2"
                                        style={{ cursor: "pointer" }}
                                        alt="Flight Done"
                                      /> */}
                                          <p
                                            class="mb-2 mt-2 text-white"
                                            style={{ fontSize: 13 }}
                                          >
                                            All Paid
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {role == "admin" ? (
                                      <div
                                        class="sStatus-R w-100 text-center my-1"
                                        data-toggle="modal"
                                        onClick={() => {
                                          setAcid(item.id);
                                          setAcit("ticket");
                                        }}
                                        data-target="#delmodel"
                                      >
                                        <img
                                          src={trash}
                                          class="staticon-2"
                                          alt="..."
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                <div class="ribbon">
                                  <span>All Paid</span>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
              </>
            ) : null}
          </div>
        </div>

        <Refundmodal
          active={rpaymodal}
          uname={uname}
          upay={upay}
          paid={paid}
          setPaid={setPaid}
          uid={uid}
          stype={stype}
          serial={serial}
          status={status}
          close={setRpaymodal}
        />

        <Agentrefundmodal
          active={agentrefundmodal}
          uname={uname}
          upay={upay}
          paid={paid}
          setPaid={setPaid}
          uid={uid}
          stype={stype}
          serial={serial}
          status={status}
          agents={agents}
          agent={agent}
          close={setAgentrefundmodal}
        />

        <Delmodal acid={acid} acit={acit} />
        <Footer />
      </div>
    </>
  );
}

export default withRouter(Ticket);
