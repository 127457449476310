import axios from "axios";
import { useState } from "react";
import Loading from "../common/loading";
function DelpaymentModal({ active, activeId, coun, setCoun }) {
  const [load, setLoad] = useState(false);
  function delpay(activeId) {
    setLoad(true);
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delpayment",
        {
          id: activeId,
        }
      )
      .then((res) => {
        setLoad(false);
        setCoun(coun + 1);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Loading load={load} />
      <div className={active ? "mp-Modal-backdrop" : ""}>
        <div class={active ? "mp-Modal mp-Modal-active" : "mp-Modal"}>
          <div
            class="modal fade deleteModal animate__animated animate__slideInDown"
            id="delModelinside"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" style={{ top: 20 }}>
              <div class="modal-content bg-light rounded-0">
                <div class="modal-body">
                  <p class="m-0 py-3">Are You Sure to Delete?</p>
                </div>
                <div class="modal-footer py-2">
                  <button
                    type="button"
                    class="btn btn-secondary btndlet"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary btnyes"
                    onClick={() => {
                      delpay(activeId);
                    }}
                    data-dismiss="modal"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DelpaymentModal;
