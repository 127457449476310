import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,  
    Link
  } from "react-router-dom";
 
  import Updateticket from './ticket';
  import Updatevisa from './visa';
  import Updatehotel from './hotel';
  import Updateumrah from './umrah';
  import Updatehajj from './hajj';
  import Updatemedical from './medical';
  import Updatemanpower from './manpower';
  import Updatepassport from './passpoert';
  import Updatea from "./agent";
  import Updatecus from "./customer";
  import Updategt from "./gticket";
  import Updateve from "./vendor";
  import Editstaff from "./staff";
  import Packages from "./packages";


  function Uroute(){
     return (
        <> 
            <Route path="/updatet/:id">
                <Updateticket/>
            </Route>
            <Route path="/updatev/:id">
                <Updatevisa/>
            </Route>
            <Route path="/updateh/:id">
                <Updatehotel/>
            </Route>
            <Route path="/updateu/:id">
                <Updateumrah/>
            </Route>
            <Route path="/updateha/:id">
                <Updatehajj/>
            </Route>
            <Route path="/updateme/:id">
                <Updatemedical/>
            </Route>
            <Route path="/updatem/:id">
                <Updatemanpower/>
            </Route>
            <Route path="/updatep/:id">
                <Updatepassport/>
            </Route>
            <Route path="/updatea/:id">
                <Updatea/>
            </Route>
            <Route path="/editcustomer/:id">
                <Updatecus/>
            </Route>
            <Route path="/updategt/:id">
                <Updategt/>
            </Route> 
            <Route path="/updateve/:id">
                <Updateve/>
            </Route> 
            <Route path="/editstaff/:id">
                <Editstaff/>
            </Route>
            <Route path="/editpackages/:id/:type">
                <Packages/>
            </Route>
        </>
     );
 }

 export default Uroute;


