import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Navigation2 from "./navigation2";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "./common/sidebar";
import Staffreport from "./modals/staffreport";

function Staff() {
  const [alldata, setAlldata] = useState([]);
  const [photo, setPhoto] = useState("");

  const [banks, setBanks] = useState([]);
  const [view, setView] = useState("cash");
  const [type, setType] = useState("Salary");
  const [des, setDes] = useState("");

  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("cash");

  const [bid, setBid] = useState("");
  const [coun, setCoun] = useState(0);
  const [note, setNote] = useState("");
  const [staffId, setStaffid] = useState(0);

  const [smodal, setSmodal] = useState(false);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchstaff"
      )
      .then((res) => {
        setAlldata(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [alldata]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function delstaff() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delstaff",
        {
          id: bid,
        }
      )
      .then((res) => {
        console.log(res.message);
      })
      .catch((err) => console.log(err));
  }

  function payhistory() {
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    var x = des + " " + note;

    const data = new FormData();
    data.append("type", type);
    data.append("description", x);
    data.append("method", ptype);
    data.append("amount", amount);
    data.append("hbank", hbank);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/payexpense",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          setCoun(coun + 1);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <Staffreport active={smodal} close={setSmodal} staffId={staffId} />

      <div
        class="modal fade deleteModal animate__animated animate__slideInDown"
        id="delModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        data-backdrop="static"
        aria-hidden="true"
      >
        <div class="modal-dialog" style={{ top: 20 }} role="document">
          <div class="modal-content datadelM">
            <div class="modal-body">
              <p class="m-0 py-3">Do you want to delete the staff?</p>
            </div>
            <div class="modal-footer py-2">
              <button
                type="button"
                class="btn btn-secondary btndlet"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary btnyes"
                data-dismiss="modal"
                onClick={() => delstaff()}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2">
            <Sidebar />
          </div>

          <div class="col-lg-10 col-12 my-4">
            <div class="row search-mb">
              <div class="col-lg-12 col-12 border px-0">
                <div class="border-bottom bg-light mx-0 d-flex align-items-center">
                  <h4 class="p-2 mb-0">Staff Data</h4>
                  <Link to="/addstaff">
                    <div class="btn btn-darkg mx-3">
                      <div class="m-0">Add New</div>
                    </div>
                  </Link>
                </div>

                <div
                  class="modal fade"
                  id="exampleModalCenter"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                          Staff NID
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body p-0">
                        <img
                          width="100%"
                          src={
                            "https://concorde-api.flydocx.com/" +
                            process.env.REACT_APP_URL +
                            "/images/" +
                            photo
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="modal fade cpayment_status animate__animated animate__zoomIn"
                  data-keyboard="false"
                  data-backdrop="static"
                  id="expense"
                  tabindex="1"
                  role="dialog"
                  aria-labelledby="CpaymentModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog cPaymentDialog" role="document">
                    <div class="modal-content  rounded-0">
                      <div class="modal-header py-2 bg-light">
                        <h5 class="modal-title" id="CpaymentModalLabel">
                          Staff Payment
                        </h5>
                      </div>

                      <div class="pModalbody my-4">
                        <div class="row justify-content-center border">
                          <ul
                            class="nav nav-pills text-center p-0 col-lg-12 co-12"
                            id="pills-tab"
                            role="tablist"
                          >
                            <li class="nav-item w-50">
                              <a
                                class="nav-link active"
                                onClick={() => {
                                  setView("cash");
                                  setPtype("cash");
                                }}
                                id="pills-home-tab"
                                data-toggle="pill"
                                href="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                              >
                                Cash
                              </a>
                            </li>
                            <li class="nav-item w-50">
                              <a
                                class="nav-link"
                                onClick={() => {
                                  setView("bank");
                                  setPtype("bank");
                                }}
                                id="pills-profile-tab"
                                data-toggle="pill"
                                href="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                              >
                                Bank
                              </a>
                            </li>
                          </ul>

                          <form class="my-2 col-lg-12" id="spfrm">
                            {view == "cash" ? (
                              <div class="row align-items-center justify-content-center my-4">
                                <div class="col-lg-4 col-4 ">
                                  <label
                                    for="_0"
                                    class="form-check-label fontTypA"
                                  >
                                    Note
                                  </label>
                                </div>
                                <div class="col-lg-6 col-6 d-flex mb-2">
                                  <input
                                    id=""
                                    name=""
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    onChange={(e) => {
                                      setNote(e.target.value);
                                    }}
                                  />
                                </div>

                                <div class="col-lg-4 col-4 ">
                                  <label
                                    for="_0"
                                    class="form-check-label fontTypA"
                                  >
                                    Cash Payment
                                  </label>
                                </div>
                                <div class="col-lg-6 col-6 d-flex">
                                  <input
                                    id=""
                                    name=""
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    onChange={(e) => {
                                      setAmount(e.target.value);
                                    }}
                                  />
                                  <p class="ml-2 fontTypA">BDT</p>
                                </div>
                              </div>
                            ) : null}

                            {view == "bank" ? (
                              <div class="row align-items-center justify-content-center my-4">
                                <div class="col-lg-4 col-4 ">
                                  <label
                                    for="_0"
                                    class="form-check-label fontTypA"
                                  >
                                    Note
                                  </label>
                                </div>
                                <div class="col-lg-6 col-6 d-flex mb-2">
                                  <input
                                    id=""
                                    name=""
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    onChange={(e) => {
                                      setNote(e.target.value);
                                    }}
                                  />
                                </div>

                                <div class="col-lg-4 col-4 p-auto ">
                                  <label
                                    for="_0"
                                    class="form-check-label fontTypA"
                                  >
                                    Bank Payment
                                  </label>
                                </div>
                                <div class="col-lg-6 col-6 d-flex mb-2">
                                  <input
                                    id=""
                                    name=""
                                    type="text"
                                    class="form-control"
                                    required="required"
                                    onChange={(e) => {
                                      setAmount(e.target.value);
                                    }}
                                  />
                                  <p class=" ml-2 fontTypA">BDT</p>
                                </div>
                                <div class="col-lg-4"></div>
                                <div class="col-lg-6 col-10 d-flex">
                                  <div class="input-group">
                                    <select
                                      id="inputState"
                                      class="form-select form-control"
                                      onChange={(e) => setHbank(e.target.value)}
                                    >
                                      <option class="form-control" value="">
                                        Choose Bank...
                                      </option>
                                      {banks.map((item) => (
                                        <option
                                          value={item.id + "/" + item.bank}
                                        >
                                          {item.bank}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </form>
                        </div>
                      </div>
                      <div class="modal-footer border-0 justify-content-between">
                        <button
                          type="button"
                          class="btn closeBtn"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn paymentUpdate"
                          onClick={() => {
                            payhistory();
                            document.getElementById("spfrm").reset();
                            document.getElementById("spfrm2").reset();
                          }}
                        >
                          Add Expense
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {alldata.map((item) => (
                  <div class="card border-0 rounded-0">
                    <div class="card-body p-0 m-0">
                      <div class="row m-0 p-0 py-2 border-bottom border-top">
                        <div class="col-lg-2 col-4 p-2 my-auto">
                          <img
                            src={
                              "https://concorde-api.flydocx.com/" +
                              process.env.REACT_APP_URL +
                              "/images/" +
                              item.uphoto
                            }
                            className="clientpp"
                          />
                        </div>
                        <div class="col-lg-3 col-8 p-2 my-auto">
                          <p class="font-weight-bold c_name m-0">
                            {item.sname}
                          </p>
                          <p class="font-weight-bold m-0">{item.position}</p>
                          <p class="m-0">
                            Sallary:{" "}
                            <span class="font-weight-bold m-0">
                              {item.salary} BDT
                            </span>
                          </p>
                          <p class="m-0">
                            Reference:{" "}
                            <span class="font-weight-bold m-0">{item.ref}</span>
                          </p>
                        </div>
                        <div class="col-lg-3 col-12 p-2 my-auto borderBoth">
                          <p class="m-0">
                            Contact No:{" "}
                            <span class="font-weight-bold">{item.snum}</span>
                          </p>
                          <p class="m-0">
                            Address:{" "}
                            <span class="font-weight-bold">{item.sadd}</span>
                          </p>
                          <div
                            onClick={() => setPhoto(item.nid)}
                            class="btn additioninfo_bttn w-100 my-1"
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                          >
                            Staff NID
                          </div>
                        </div>
                        <div
                          class="col-lg-3 col-4 p-2 borderRight text-center my-auto"
                          style={{ position: "relative" }}
                        >
                          <div
                            onClick={() =>
                              setDes(item.sname + " Salary Expense")
                            }
                            class="btn makepayment w-100 my-1"
                            data-toggle="modal"
                            data-target="#expense"
                          >
                            Payment
                          </div>

                          <div
                            onClick={() => {
                              setSmodal(true);
                              setStaffid(item.id);
                            }}
                            class="btn btn-danger w-100 my-1"
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            Staff report
                          </div>
                          <Link
                            to={"/editstaff/" + item.id}
                            class="btn upbttn w-100 my-1"
                            data-toggle="modal"
                            data-target="#expense"
                          >
                            Edit Profile
                          </Link>
                        </div>
                        <div class="col-lg-1 col-2 p-2 text-center">
                          <div class="centerelement">
                            <button
                              onClick={() => setBid(item.id)}
                              class="btn btn-danger"
                              data-toggle="modal"
                              data-target="#delModal"
                            >
                              <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Staff;
