import { useState, useEffect } from "react";
import axios from "axios";

import { useHistory } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import Loading from "../../screens/common/loading";

function Addumrah({ cid }) {
  let history = useHistory();

  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [cserial, setCserial] = useState("");

  const [cphoto, setCphoto] = useState("");
  const [customer, setCustomer] = useState([]);

  const [pass, setPass] = useState("");
  const [visa, setVisa] = useState("");
  const [idate, setIdate] = useState("");
  const [vval, setVval] = useState("");
  const [fno, setFno] = useState("");
  const [serial, setSerial] = useState("");
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [hname, setHname] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");

  const [note, setNote] = useState("");
  const [dob, setDob] = useState("");

  const [dloca, setDloca] = useState("");
  const [aloca, setAloca] = useState("");

  const [gpnr, setGpnr] = useState("");

  const [airplane, setAirplane] = useState("");
  const [t1, setT1] = useState("");
  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");
  const [t2, setT2] = useState("");

  const [mofa, setMofa] = useState("");
  const [mno, setMno] = useState("");
  const [trans, setTrans] = useState("");
  const [transno, setTransno] = useState("");
  const [sservice, setSservice] = useState("");
  const [sno, setSno] = useState("");
  const [meal, setMeal] = useState("");
  const [mealno, setMealno] = useState();

  const [ctype, setCtype] = useState("");
  const [show, setShow] = useState(false);
  const [aid, setAid] = useState(0);

  const [agents, setAgents] = useState([]);

  const [edate, setEdate] = useState("");

  const [vendor, setVendor] = useState("");
  const [cof, setCof] = useState("");

  const [vendors, setVendors] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchstaff"
      )
      .then((res) => setStaffs(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => {
        setCustomer(res.data.message);
        setCserial(Number(res.data.lastid) + 1);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomerbyid",
        {
          cid: cid,
        }
      )
      .then((res) => {
        setCname(res.data.suser[0].cname);
        setCadd(res.data.suser[0].cadd);
        setCnum(res.data.suser[0].cnum);
        setDob(res.data.suser[0].dob);
        setCphoto(res.data.suser[0].cphoto);
        setCserial(res.data.suser[0].serial);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, [agents]);

  function handleSubmit() {
    setLoad(true);
    if (ctype == "") {
      alert("Please select a customer type");
      return false;
    }
    var arri = arrival + " " + t1 + ":" + t2;
    var depa = departure + " " + d1 + ":" + d2;

    const data = new FormData();

    data.append("cid", cid);
    data.append("cname", cname);
    data.append("cnum", cnum);
    data.append("cadd", cadd);
    data.append("ctype", ctype);
    data.append("aid", aid);

    data.append("dob", dob);

    data.append("cserial", cserial);
    data.append("passport", pass);
    data.append("visa", visa);
    data.append("idate", idate);
    data.append("vval", vval);
    data.append("fno", fno);
    data.append("serial", serial);
    data.append("departure", depa);
    data.append("arrival", arri);
    data.append("dloca", dloca);
    data.append("aloca", aloca);
    data.append("hname", hname);
    data.append("cin", cin);
    data.append("cout", cout);
    data.append("base", base);
    data.append("gross", gross);
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("airplane", airplane);

    data.append("mofa", mofa);
    data.append("mno", mno);
    data.append("trans", trans);
    data.append("transno", transno);
    data.append("sservice", sservice);
    data.append("sno", sno);
    data.append("meal", meal);
    data.append("mealno", mealno);
    data.append("note", note);
    data.append("gpnr", gpnr);
    data.append("edate", edate);
    data.append("vendor", vendor);
    data.append("cof", cof);
    data.append("user", cphoto);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/adumrah",
        data
      )
      .then((res) => {
        setLoad(false);
        if (res.data.message == null) {
          history.push("/customer/umrah");
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <div class="container border mb-4 bg-light pt-3">
        <div class="pb-3">
          <Loading load={load} />

          <div class="form-group row py-1">
            <label for="" class="col-lg-2 col-12 col-form-label infotext">
              Customer Type
            </label>
            <div class="col-lg-4 col-12 mt-2">
              <div class="form-check form-check-inline">
                <input
                  name="ctype"
                  id="ctype"
                  type="radio"
                  class="form-check-input"
                  value="regular"
                  required="required"
                  onChange={(e) => {
                    setCtype(e.target.value);
                    setShow(false);
                  }}
                />
                <label for="_0" class="form-check-label infotext mr-2">
                  Regular
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  name="ctype"
                  id="ctype"
                  type="radio"
                  class="form-check-input"
                  value="agent"
                  required="required"
                  onChange={(e) => {
                    setCtype(e.target.value);
                    setShow(true);
                  }}
                />
                <label for="_0" class="form-check-label infotext mr-2">
                  From Agent
                </label>
              </div>
            </div>
          </div>

          {ctype == "agent" ? (
            <div class="form-group row py-1">
              <label for="" class="col-lg-2 col-12 col-form-label infotext">
                Agent Name
              </label>
              <div class="col-lg-10 col-12">
                <div class="input-group">
                  {ctype == "agent" ? (
                    <select
                      class="form-control"
                      onChange={(e) => setAid(e.target.value)}
                    >
                      <option>Choose Agent</option>
                      {agents.map((item) => (
                        <option value={item.id}>{item.aname}</option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Airplane
            </label>
            <div class="col-lg-4 col-12 me-auto">
              <select
                id="inputState"
                class="form-select form-control"
                onChange={(e) => setAirplane(e.target.value)}
              >
                <option class="form-control" selected>
                  Choose Airlines
                </option>
                <option>Regent Airways</option>
                <option>US-Bangla Airlines</option>
                <option>Novoair</option>
                <option>Air Arabia</option>
                <option>Air India</option>
                <option>Biman Bangladesh Airlines</option>
                <option>China Eastern Airlines</option>
                <option>China Southern Airlines</option>
                <option>Dragonair</option>
                <option>Etihad Airways</option>
                <option>Fly Emirates</option>
                <option>Fly Dubai</option>
                <option>Jet Airways</option>
                <option>Kuwait Airways</option>
                <option>Malaysia Airlines</option>
                <option>Novoair</option>
                <option>Pakistan International Airlines</option>
                <option>Qatar Airways</option>
                <option>Regent Airways</option>
                <option>Saudi Arabian Airlines</option>
                <option>Singapore Airlines</option>
                <option>SpiceJet</option>
                <option>Thai Airways</option>
                <option>Thai Airways International</option>
                <option>United Airlines</option>
                <option>US-Bangla Airlines</option>
                <option>Yemenia</option>
              </select>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Visa No
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={visa}
                onChange={(e) => setVisa(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Visa Issue Date
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={idate}
                onChange={(e) => setIdate(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Visa Validity
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={vval}
                onChange={(e) => setVval(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Flight No
            </label>
            <div class="col-lg-4 col-6">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={fno}
                onChange={(e) => setFno(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Serial / PNR
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={serial}
                onChange={(e) => setSerial(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              GDS PNR
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                type="text"
                cols="40"
                rows="2"
                class="form-control"
                onChange={(e) => setGpnr(e.target.value)}
              ></input>
            </div>
          </div>

          <i
            class="fa fa-clock-o"
            id="clbtn2"
            data-toggle="modal"
            data-target="#exampleModal2"
            style={{ margin: 2, fontSize: 20, display: "none" }}
          ></i>
          <i
            class="fa fa-clock-o"
            id="clbtn3"
            data-toggle="modal"
            data-target="#exampleModal"
            style={{ margin: 2, fontSize: 20, display: "none" }}
          ></i>

          <div class="form-group row py-1">
            <label for="" class="col-lg-2 col-12 col-form-label infotext">
              Departure
            </label>
            <div class="col-lg-2 col-6 pr-1">
              <input
                id=""
                name=""
                type="text"
                placeholder="Ex. DAC"
                class="form-control"
                required=""
                onChange={(e) => setDloca(e.target.value)}
              />
            </div>
            <div class="col-lg-2 col-6 pl-0">
              <div
                style={{
                  display: "flex",
                  border: "1px solid #ddd",
                  overflow: "auto",
                  padding: 5,
                  borderRadius: 4,
                }}
              >
                <input
                  id=""
                  name=""
                  type="date"
                  placeholder=""
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: 14,
                    height: 26,
                  }}
                  //  class="form-control"
                  required=""
                  onChange={(e) => {
                    setDeparture(e.target.value);
                  }}
                />
              </div>
            </div>

            <label
              for=""
              class="col-lg-2 col-12 marginTop col-form-label infotext"
            >
              Arrival
            </label>
            <div class="col-lg-2 col-6 pr-1">
              <input
                id=""
                name=""
                type="text"
                placeholder="Ex. CGP"
                class="form-control"
                required=""
                onChange={(e) => setAloca(e.target.value)}
              />
            </div>
            <div class="col-lg-2 col-6 pl-0">
              <div
                style={{
                  display: "flex",
                  border: "1px solid #ddd",
                  overflow: "auto",
                  padding: 5,
                  borderRadius: 4,
                }}
              >
                <input
                  id=""
                  name=""
                  type="date"
                  placeholder=""
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    fontSize: 14,
                    height: 26,
                  }}
                  //  class="form-control"
                  required=""
                  onChange={(e) => {
                    setArrival(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Hotel name
            </label>
            <div class="col-lg-10 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={hname}
                onChange={(e) => setHname(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Check in
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={cin}
                onChange={(e) => setCin(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Check-out
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={cout}
                onChange={(e) => setCout(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12  infotext col-form-label"
            >
              Mofa
            </label>
            <div class="col-lg-4 col-12 mt-2">
              <div class="form-check form-check-inline">
                <input
                  name="medical"
                  id="medical"
                  type="radio"
                  class="form-check-input"
                  value="yes"
                  required=""
                  onChange={(e) => setMofa(e.target.value)}
                />
                <label for="_0" class="form-check-label infotext mr-2">
                  Yes
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  name="medical"
                  id="medical"
                  type="radio"
                  class="form-check-input"
                  value="no"
                  required=""
                  onChange={(e) => setMofa(e.target.value)}
                />
                <label for="_0" class="form-check-label infotext mr-2">
                  No
                </label>
              </div>
            </div>

            {mofa == "yes" ? (
              <>
                <label
                  for="textarea"
                  class="col-lg-2 col-12 marginTop infotext col-form-label"
                >
                  Mofa Amount
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id="textarea"
                    name="text"
                    type="number"
                    class="form-control"
                    required=""
                    onChange={(e) => setMno(e.target.value)}
                  ></input>
                </div>
              </>
            ) : null}
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12  infotext col-form-label"
            >
              Transport
            </label>
            <div class="col-lg-4 col-12 mt-2">
              <div class="form-check form-check-inline">
                <input
                  name="trans"
                  id="trans"
                  type="radio"
                  class="form-check-input"
                  value="yes"
                  required=""
                  onChange={(e) => setTrans(e.target.value)}
                />
                <label for="_0" class="form-check-label infotext mr-2">
                  Yes
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  name="trans"
                  id="trans"
                  type="radio"
                  class="form-check-input"
                  value="no"
                  required=""
                  onChange={(e) => setTrans(e.target.value)}
                />
                <label for="_0" class="form-check-label infotext mr-2">
                  No
                </label>
              </div>
            </div>

            {trans == "yes" ? (
              <>
                <label
                  for="textarea"
                  class="col-lg-2 col-12 marginTop infotext col-form-label"
                >
                  Transport Amount
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id="textarea"
                    name="text"
                    type="number"
                    class="form-control"
                    required=""
                    onChange={(e) => setTransno(e.target.value)}
                  ></input>
                </div>
              </>
            ) : null}
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12  infotext col-form-label"
            >
              Special Service
            </label>
            <div class="col-lg-4 col-12 mt-2">
              <div class="form-check form-check-inline">
                <input
                  name="sservice"
                  id="sservice"
                  type="radio"
                  class="form-check-input"
                  value="yes"
                  required=""
                  onChange={(e) => setSservice(e.target.value)}
                />
                <label for="_0" class="form-check-label infotext mr-2">
                  Yes
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  name="sservice"
                  id="sservice"
                  type="radio"
                  class="form-check-input"
                  value="no"
                  required=""
                  onChange={(e) => setSservice(e.target.value)}
                />
                <label for="_0" class="form-check-label infotext mr-2">
                  No
                </label>
              </div>
            </div>

            {sservice == "yes" ? (
              <>
                <label
                  for="textarea"
                  class="col-lg-2 col-12 marginTop infotext col-form-label"
                >
                  Service Amount
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id="textarea"
                    name="text"
                    type="number"
                    class="form-control"
                    required=""
                    onChange={(e) => setSno(e.target.value)}
                  ></input>
                </div>
              </>
            ) : null}
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12  infotext col-form-label"
            >
              Meal
            </label>
            <div class="col-lg-4 col-12 mt-2">
              <div class="form-check form-check-inline">
                <input
                  name="meal"
                  id="meal"
                  type="radio"
                  class="form-check-input"
                  value="yes"
                  required=""
                  onChange={(e) => setMeal(e.target.value)}
                />
                <label for="_0" class="form-check-label infotext mr-2">
                  Yes
                </label>
              </div>

              <div class="form-check form-check-inline">
                <input
                  name="meal"
                  id="meal"
                  type="radio"
                  class="form-check-input"
                  value="no"
                  required=""
                  onChange={(e) => setMeal(e.target.value)}
                />
                <label for="_0" class="form-check-label infotext mr-2">
                  No
                </label>
              </div>
            </div>

            {meal == "yes" ? (
              <>
                <label
                  for="textarea"
                  class="col-lg-2 col-12 marginTop infotext col-form-label"
                >
                  Meal Amount
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id="textarea"
                    name="text"
                    type="number"
                    class="form-control"
                    required=""
                    onChange={(e) => setMealno(e.target.value)}
                  ></input>
                </div>
              </>
            ) : null}
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Additional Info
            </label>
            <div class="col-lg-10 col-12">
              <textarea
                id="textarea"
                name="textarea"
                cols="40"
                rows="2"
                class="form-control"
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Vendor
            </label>
            <div class="col-lg-4 col-12">
              <select
                id="inputState"
                class="form-select form-control"
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
              >
                <option class="form-control" selected>
                  Choose Vendor
                </option>

                {vendors.map((item) => {
                  if (item.type == "umrah") {
                    return <option value={item.id}>{item.vname}</option>;
                  }

                  if (item.type == "all") {
                    return <option value={item.id}>{item.vname}</option>;
                  }
                })}
              </select>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Care Of
            </label>
            <div class="col-lg-4 col-12">
              <select
                class="form-control"
                onChange={(e) => setCof(e.target.value)}
              >
                <option>Choose Staff</option>
                {staffs.map((item) => (
                  <option value={item.id}>{item.sname}</option>
                ))}
              </select>
            </div>
          </div>

          <hr />

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12  infotext col-form-label"
            >
              Buying Price
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="number"
                class="form-control"
                required=""
                value={base}
                onChange={(e) => setBase(e.target.value)}
              ></input>
            </div>
            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Selling Price
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="number"
                class="form-control"
                required=""
                value={gross}
                onChange={(e) => setGross(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              VAT & AIT
            </label>
            <div class="col-lg-4 col-6">
              <input
                id="textarea"
                name="text"
                type="number"
                class="form-control"
                required=""
                placeholder="EX. 0.03"
                value={vat}
                onChange={(e) => {
                  var p =
                    Number(gross) - (Number(base) + Number(e.target.value));
                  setVat(e.target.value);
                  setProfit(p);
                }}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Profit
            </label>
            <div class="col-lg-4 col-6">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                disabled
                value={profit}
                onChange={(e) => setProfit(e.target.value)}
              ></input>
            </div>

            <hr />
          </div>

          <div class="form-group row py-2">
            <div class="col-lg-2"></div>
            <div class="col-lg-10 col-12">
              <button class="w-100 btn btn-primary" onClick={handleSubmit}>
                Add Umrah
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Addumrah;
