import Logo from "../../images/logo.png";
import Paid from "../../images/paids.svg";
function Printhead() {
  return (
    <div
      class="row pt-2 dtranHeader mx-auto onprint"
      style={{ position: "fixed", top: 0, left: 0, right: 0 }}
    >
      <div class="d-flex col-lg-6">
        <img src={Logo} class="inimg" alt="..." />
      </div>

      <div class="col-lg-6 text-right mt-3" style={{ fontSize: 14 }}>
        <p class="m-0">
          <i class="fa fa-globe" aria-hidden="true"></i>{" "}
          {process.env.REACT_APP_ADDRESS}
          <br></br> {process.env.REACT_APP_ADDRESS2}
        </p>
        <p class="m-0">
          <i class="fa fa-phone" aria-hidden="true"></i>{" "}
          <span> {process.env.REACT_APP_PHONE}</span>
        </p>
      </div>
      <hr />
    </div>
  );
}

export default Printhead;
