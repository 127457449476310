import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import client from "../../images/placeholder.jpg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Printhead from "../printhead";

function Passport(props) {
  var id = props.match.params.id;

  let history = useHistory();

  const [cphoto, setCphoto] = useState("");
  const [cserial, setCserial] = useState("");
  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [active, setActive] = useState("");
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [flight, setFlight] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [idate, setIdate] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [arr1, setArr1] = useState("");
  const [arr2, setArr2] = useState("");
  const [de1, setDe1] = useState("");
  const [de2, setDe2] = useState("");
  const [airplane, setAirplane] = useState("");
  const [vendor, setVendor] = useState("");
  const [pval, setPval] = useState("");
  const [country, setCountry] = useState("");
  const [type, setType] = useState("");
  const [visa, setVisa] = useState("");
  const [vval, setVval] = useState("");
  const [hotel, setHotel] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [ttype, setTtype] = useState("");
  const [serial, setSerial] = useState("");
  const [fileData, setFileData] = useState("");
  const [fileData2, setFileData2] = useState("");
  const [fileData3, setFileData3] = useState("");

  const [vfileData, setVfileData] = useState("");
  const [vfileData2, setVfileData2] = useState("");
  const [vfileData3, setVfileData3] = useState("");

  const [doc1, setDoc1] = useState("");
  const [doc2, setDoc2] = useState("");
  const [doc3, setDoc3] = useState("");
  const [paddress, setPaddress] = useState("");
  const [peaddress, setPeaddress] = useState("");
  const [bcertificate, setBcertificate] = useState("");
  const [dob, setDob] = useState("");
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [occu, setOccu] = useState("");
  const [ddate, setDdate] = useState("");
  const [spouse, setSpouse] = useState("");
  const [aid, setAid] = useState("");
  const [agents, setAgents] = useState([]);

  const [userData, setUsereData] = useState("");
  const [vendors, setVendors] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [fil, setFil] = useState("");
  const [cid, setCid] = useState("5");
  const [hphoto, setHphoto] = useState("");

  const [note, setNote] = useState("");
  const [snumber, setSnumber] = useState("");
  const [cof, setCof] = useState("");

  useEffect(() => {
    // if(type == "visa" || type == "domestic" || type == "international"){
    //     setSearch("regular");
    // }

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprev5",
        {
          id: id,
        }
      )
      .then((res) => {
        setCphoto(res.data.message[0].cphoto);
        setCserial(res.data.message[0].cserial);
        setCname(res.data.message[0].cname);
        setCnum(res.data.message[0].cnum);
        setCadd(res.data.message[0].cadd);

        setPassport(res.data.message[0].passport);

        setPaddress(res.data.message[0].padd);
        setPeaddress(res.data.message[0].peadd);
        setFname(res.data.message[0].fname);
        setMname(res.data.message[0].mname);
        setOccu(res.data.message[0].occu);
        setDdate(res.data.message[0].ddate);

        setDob(res.data.message[0].dob);
        setBcertificate(res.data.message[0].bc);

        setBase(res.data.message[0].base);
        setGross(res.data.message[0].gross);
        setVat(res.data.message[0].vat);
        setProfit(res.data.message[0].profit);
        setSpouse(res.data.message[0].spouse);

        setNote(res.data.message[0].note);
        setAid(res.data.message[0].aid);
        setCof(res.data.message[0].cof);

        setSnumber(res.data.message[0].snumber);
      })
      .catch((err) => console.log(err));
  }, []);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }
  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));
  }, [vendors]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => setCustomer(res.data.message))
      .catch((err) => console.log(err));
  }, [customer]);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1500);
  }, []);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div class="container">
        <Printhead />

        <div class="prheadc mt-3">
          <h3>Service Name: Passport</h3>
        </div>

        <div class="dtran cview">
          <div class="d-flex adcst">
            <h5 class="m-0">Customer Data</h5>
          </div>
          <hr />

          <div class="contentC">
            <div class="form-group vcinfo">
              <div class="col-3">
                {cphoto == "" ? (
                  <img src={client} class="clientpp2" />
                ) : (
                  <img
                    src={
                      "https://concorde-api.flydocx.com/" +
                      process.env.REACT_APP_URL +
                      "/images/" +
                      cphoto
                    }
                    class="clientpp2"
                    alt="..."
                  />
                )}
              </div>
              <div class="col-9">
                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Serial
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cserial}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Name
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cname}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Mobile Number
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cnum}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Address
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cadd}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Passport Number
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {passport}</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="d-flex adcst">
                <h5 class="m-0">Service Data</h5>
              </div>
              <hr />

              <div class="form-group row py-1">
                <label for="" class="col-2 col-form-label infotext">
                  Passport Slip
                </label>
                <div class="col-10">
                  <div class="input-group">
                    <p class="font-weight-bold m-0 p-1">: {snumber}</p>
                  </div>
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  Enrollment Date
                </label>
                <div class="col-10">
                  <div class="input-group">
                    <p class="font-weight-bold m-0 p-1">: {ddate}</p>
                  </div>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Date of Birth
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">: {dob}</p>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Father"s name
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {fname}</p>
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  Mother"s name
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {mname}</p>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Spouse"s name
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">: {spouse}</p>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Present Address
                </label>
                <div class="col-10">
                  <div class="input-group">
                    <p class="font-weight-bold m-0 p-1">: {paddress}</p>
                  </div>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Permanent Address
                </label>
                <div class="col-10">
                  <div class="input-group">
                    <p class="font-weight-bold m-0 p-1">: {peaddress}</p>
                  </div>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Occupation
                </label>
                <div class="col-10">
                  <div class="input-group">
                    <p class="font-weight-bold m-0 p-1">: {occu}</p>
                  </div>
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  Birth Certificate
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {bcertificate}</p>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Care Of
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {cof}</p>
                </div>
              </div>
              <hr />
              {aid == 0 ? null : (
                <div class="form-group row">
                  <label for="" class="col-2 col-form-label infotext">
                    Agent Name
                  </label>
                  <div class="col-10">
                    <p class="font-weight-bold m-0 p-1">: {getagent(aid)}</p>
                  </div>
                </div>
              )}
              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Additional Note
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">:{note}</p>
                </div>
              </div>

              {/* <div class="form-group row py-4">
           <label for="" class="col-2 col-form-label">Base Fare</label>
           <div class="col-2 me-auto">
            <p>{base}</p>
           </div>
           <label for="" class="col-2 col-form-label">Gross Fare</label>
           <div class="col-2 me-auto">
             <p>{gross}</p>
           </div>
         </div>
         <div class="form-group row py-4">
           <label for="" class="col-2 col-form-label">VAT & AIT</label>
           <div class="col-2 me-auto">
             <p>{vat}</p>
           </div>
           <label for="" class="col-2 col-form-label">Profit</label>
           <div class="col-2 me-auto">
             <p>{profit}</p>
           </div>
         </div> */}
            </div>
          </div>
        </div>

        <footer class="footer" style={{ position: "fixed", bottom: 0 }}>
          <div class="dflex" style={{ marginLeft: 10, marginRight: 10 }}>
            <p style={{ fontSize: 14, fontWeight: "bold" }}>
              Developed by mPair Technologies Ltd.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default withRouter(Passport);
