import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import client from "../../images/placeholder.svg";
import searchb from "../../images/search.png";
import trash from "../../images/trash.png";
import download from "../../images/download.png";
import axios from "axios";
import Sidebar from "../Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../screens/footer";
import Paymentmodal from "../modals/paymentmodal";
import formatDate from "../functions/timeformat";
import Delmodal from "../modals/delmodal";
import Ginvoice from "../common/invoice";

function Medical({ alldata, type }) {
  const [search, setSearch] = useState("");
  const [csearch, setCsearch] = useState("regular");

  const [photo, setPhoto] = useState("");
  const [banks, setBanks] = useState([]);
  const [stype, setStype] = useState("");
  const [refundb, setRefundb] = useState(0);
  const [re, setRe] = useState(0);
  const [abank, setAbank] = useState("");

  const [uname, setUname] = useState("");
  const [upay, setUpay] = useState("");
  const [paid, setPaid] = useState("");
  const [uid, setUid] = useState(0);
  const [view, setView] = useState("cash");

  const [vname, setVname] = useState("");
  const [serial, setSerial] = useState("");
  const [base, setBase] = useState("");

  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("cash");
  const [tamount, setTamount] = useState(0);
  const [phistory, setPhistory] = useState([]);
  const [acid, setAcid] = useState("");
  const [acit, setAcit] = useState("");

  const [allven, setAllven] = useState([]);
  const [agents, setAgents] = useState([]);

  const [note, setNote] = useState("");

  const [snote, setSnote] = useState("");
  const [samount, setSamount] = useState("");

  const [agent, setAgent] = useState("");

  const [gross, setGross] = useState("");

  const [role, setRole] = useState(false);

  const [status, setStatus] = useState("");

  const [paymodal, setPaymondal] = useState(false);
  const [invoiceId, setInvoiceid] = useState("");
  const [invoiceservice, setInvoiceservice] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setRole(res.data.message[0].role);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorfetch3"
      )
      .then((res) => setAllven(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function upstatus(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upstatusmedical",
        {
          id: x,
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function refundhistory() {
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    document.getElementById("spfrm2").reset();

    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", tamount);
    data.append("refundb", re);
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("name", uname);
    data.append("vname", vname);
    data.append("serial", serial);
    data.append("base", base);
    data.append("gross", gross);
    data.append("note", note);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundhistory",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    toast.success("Refund Addedd", {
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      toastId: "success4",
      autoClose: 2000,
    });

    setPaid(tamount);
    setRefundb(re);
    refundfetch(uid, stype);

    setHbank("");
    setAmount("");
    setTamount(0);
  }

  function refundfetch(x, y) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundfetch",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => {
        setPhistory(res.data.message);
        setSnote(res.data.message[0].note);
        setSamount(res.data.message[0].amount);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }

  return (
    <>
      <ToastContainer />

      <Ginvoice id={invoiceId} service={invoiceservice} />
      <div className="noprint">
        <Paymentmodal
          active={paymodal}
          uname={uname}
          upay={upay}
          paid={paid}
          setPaid={setPaid}
          uid={uid}
          stype={stype}
          serial={serial}
          status={status}
          close={setPaymondal}
        />

        <div class="row search-mb">
          <div class="col-lg-12 col-12 border px-0">
            <div class="border-bottom bg-light mx-0">
              <h4 class="p-2 mb-0">Medical</h4>
            </div>
            <div class="row mx-auto bg-light p-2 border-bottom">
              <div class="d-flex form-group has-search col-lg-9 col-9 m-0 p-0">
                <img src={searchb} class="sChooseIcon2" alt="..." />
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control searchbox"
                  required="required"
                  placeholder="Search Customer"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <div class="col-lg-3 col-3 pr-0">
                <div class="col-12">
                  <input
                    type="date"
                    class="filtBTN-3 m-0 ml-3"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="row mx-auto">
              <ul
                class="nav nav-pills p-0 text-center col-lg-12"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item col-lg-6 col-6 px-0">
                  <a
                    class="nav-link active"
                    id="pills-home-tab"
                    onClick={() => setCsearch("regular")}
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Regular
                  </a>
                </li>
                <li class="nav-item col-lg-6 col-6 px-0">
                  <a
                    class="nav-link"
                    id="pills-profile-tab"
                    onClick={() => setCsearch("agent")}
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="true"
                  >
                    Agent
                  </a>
                </li>
              </ul>
              <hr />
            </div>

            {type == "medical" ? (
              <>
                {alldata
                  .filter((item) => {
                    // if ((item.cname.toLowerCase().includes(search.toLowerCase())) || (item.cnum.toLowerCase().includes(search.toLowerCase())) || (item.cid.toString().includes(search.toLowerCase())) || (item.airplane.toLowerCase().includes(search.toLowerCase()))) {
                    //     return item;
                    // }

                    if (
                      item.cname.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return item;
                    }

                    if (
                      item.cnum.toLowerCase().includes(search.toLowerCase())
                    ) {
                      return item;
                    }

                    if (
                      item.cid
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <>
                      {item.ctype == csearch ? (
                        <div class="card border-0 rounded-0">
                          <div class="card-body p-0 m-0">
                            <div class="row m-0 p-0 border-bottom border-top">
                              {item.status == 0 ? (
                                <></>
                              ) : (
                                <div class="ribbon">
                                  <span>All Paid</span>
                                </div>
                              )}
                              <div class="col-lg-2 col-4 p-2 my-auto">
                                {item.cphoto == "" ? (
                                  <img src={client} class="clientpp" />
                                ) : (
                                  <img
                                    src={
                                      "https://concorde-api.flydocx.com/" +
                                      process.env.REACT_APP_URL +
                                      "/images/" +
                                      item.cphoto
                                    }
                                    class="clientpp"
                                    alt="..."
                                  />
                                )}
                              </div>
                              <div class="col-lg-3 col-12 p-2">
                                <div class="row p-2">
                                  <div class="col my-auto">
                                    <p class="m-0">
                                      <span class="sldesign py-0">
                                        <small>#{item.cserial}</small>
                                      </span>
                                    </p>
                                    <p class="m-0 font-weight-bold c_name">
                                      {item.cname}
                                    </p>
                                    <p class="m-0 font-weight-bold cusData">
                                      {item.cnum}
                                    </p>
                                    <p class="m-0 cusData" row="2">
                                      {item.cadd}
                                    </p>
                                    <Link
                                      class="dprofile"
                                      to={"/medicalprofile/" + item.id}
                                    >
                                      Download Data
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-3 col-12 dflight_data p-2 pl-3">
                                <p class="m-0 cusData">
                                  Country :{" "}
                                  <span class="font-weight-bold">
                                    {item.country}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Passport :{" "}
                                  <span class="font-weight-bold">
                                    {item.passport}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  Visa :{" "}
                                  <span class="font-weight-bold">
                                    {item.visa}
                                  </span>
                                </p>
                                <p class="m-0 cusData">
                                  BMET Number :{" "}
                                  <span class="font-weight-bold">
                                    {item.bmet}
                                  </span>
                                </p>

                                <p class="m-0 cusData">
                                  Delivery Date :{" "}
                                  <span class="font-weight-bold">
                                    {item.ddate}
                                  </span>
                                </p>
                              </div>

                              <div class="col-lg-3 col-8 my-auto borderRight">
                                {item.ctype == "agent" ? (
                                  <div class="anbtn w-100 my-1">
                                    {getagent(item.aid)}
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      setUname(item.cname);
                                      setUpay(item.gross);
                                      setPaid(item.paid);
                                      setUid(item.id);
                                      setStype("medical");
                                      setSerial(item.serial);

                                      setPaymondal(true);
                                    }}
                                    class="btn statusbttn w-100 my-1"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#Cpaymentstatus"
                                  >
                                    Payment Status
                                  </div>
                                )}

                                <Link
                                  to={"/updateme/" + item.id}
                                  class="btn upbttn w-100 my-1"
                                >
                                  Update Info
                                </Link>

                                <div
                                  class="btn sStatusI text-center my-1 text-white"
                                  onClick={() => {
                                    setInvoiceid(item.id);
                                    setInvoiceservice("medical");

                                    setTimeout(() => {
                                      window.print();
                                    }, 100);
                                  }}
                                >
                                  <a class="text-center text-white my-auto">
                                    <img
                                      src={download}
                                      class="staticon m-1"
                                      alt="..."
                                    />
                                    <span class="m-2">Invoice</span>
                                  </a>
                                </div>
                              </div>

                              <div class="col-lg-1 col-4 my-auto">
                                {item.status == 0 ? (
                                  <div class="row col-12 m-0 p-0 align-items-center">
                                    <div
                                      class=" sStatusOK-n w-100 text-center my-auto"
                                      onClick={(e) => upstatus(item.id)}
                                    >
                                      {/* <img
                                        src={done}
                                        class="staticon-2"
                                        style={{ cursor: "pointer" }}
                                        alt="Flight Done"
                                      /> */}
                                      <p
                                        class="mb-2 mt-2 text-white"
                                        style={{ fontSize: 13 }}
                                      >
                                        <i class="fa-solid fa-check mx-1"></i>
                                        Paid
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {role == "admin" ? (
                                  <div
                                    class="sStatus-R w-100 text-center my-1"
                                    data-toggle="modal"
                                    onClick={() => {
                                      setAcid(item.id);
                                      setAcit("manpower");
                                    }}
                                    data-target="#delmodel"
                                  >
                                    <img
                                      src={trash}
                                      class="staticon-2"
                                      alt="..."
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
              </>
            ) : null}
          </div>
        </div>

        <div
          class="modal fade cpayment_status"
          data-keyboard="false"
          data-backdrop="static"
          id="Cpaymentstatus2"
          tabindex="1"
          role="dialog"
          aria-labelledby="CpaymentModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog cPaymentDialog" role="document">
            <div class="modal-content">
              <div class="mHeader">
                <p class="modal-title pModalheadear" id="CpaymentModalLabel">
                  Refund Payment Status
                </p>
              </div>
              <div class="pModalbody">
                <div class="row">
                  <div class="col-4 fontTypA">
                    <p class="mb-2">Customer Name</p>
                  </div>
                  <div class="col-8">
                    <p class="mb-2 font-weight-bold">{uname}</p>
                  </div>
                </div>
                <div class="row CastPay">
                  <div class="col-4 fontTypA">
                    <p class="mb-2">Total Paid</p>
                  </div>
                  <div class="col-8">
                    <p class="mb-2 font-weight-bold">{paid} BDT</p>
                  </div>
                </div>

                <div class="row my-3">
                  <div class="col-4 fontTypA">
                    <p class="m-0">Total Refund</p>
                  </div>

                  <div class="col-8">
                    <p class="mb-0 font-weight-bold">{refundb} BDT</p>
                    {phistory.map((item) => (
                      <p class="pAmountHist m-0">
                        {formatDate(item.date)} {item.type} -{" "}
                        <span>
                          {item.amount} BDT{" "}
                          {item.type == "bank" ? "(" + item.bank + ")" : null}
                        </span>
                        <b>Note :</b>
                        {item.note}
                      </p>
                    ))}
                  </div>
                </div>
              </div>

              <p class="font-weight-bold pModalbody">Refund Payment</p>
              <div class="row justify-content-center">
                <ul
                  class="nav nav-pills text-center p-0 col-lg-8"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item w-50">
                    <a
                      class="nav-link active"
                      onClick={() => {
                        setPtype("cash");
                        setView("cash");
                      }}
                      id="pills-home-tab2"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Cash
                    </a>
                  </li>
                  <li class="nav-item w-50">
                    <a
                      class="nav-link"
                      onClick={() => {
                        setView("bank");
                        setPtype("bank");
                      }}
                      id="pills-profile-tab2"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Bank
                    </a>
                  </li>
                </ul>

                <form
                  class="mb-4 mt-2 col-lg-8"
                  id="spfrm2"
                  style={{ borderTop: "1px solid #bababa" }}
                >
                  {view == "cash" ? (
                    <div class="row align-items-center justify-content-center my-4">
                      <div class="col-4 form-check form-check-inline checkboxSpread">
                        {/* <input
                                            name="serviceSelect"
                                            id="cPValue"
                                            type="radio"
                                            class="form-check-input"
                                            value="cP"
                                            required="required"
                                            onClick={(e) => setPtype("Cash")}

                                        /> */}
                        <label for="_0" class="form-check-label fontTypA">
                          Refund Note
                        </label>
                      </div>

                      <div class="col-6 mb-2 d-flex">
                        <textarea
                          row="2"
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setNote(e.target.value);
                          }}
                        />
                      </div>

                      <div class="col-4 form-check form-check-inline checkboxSpread">
                        {/* <input
                                            name="serviceSelect"
                                            id="cPValue"
                                            type="radio"
                                            class="form-check-input"
                                            value="cP"
                                            required="required"
                                            onClick={(e) => setPtype("Cash")}

                                        /> */}
                        <label for="_0" class="form-check-label fontTypA">
                          Cash Payment
                        </label>
                      </div>

                      <div class="col-6 d-flex">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setTamount(Number(paid) - Number(e.target.value));
                            setRe(Number(refundb) + Number(e.target.value));
                          }}
                        />
                        <p class="ml-2 fontTypA">BDT</p>
                      </div>
                    </div>
                  ) : null}

                  {view == "bank" ? (
                    <div class="row align-items-center justify-content-center my-4">
                      <div class="col-4 form-check form-check-inline checkboxSpread">
                        {/* <input
                                            name="serviceSelect"
                                            id="cPValue"
                                            type="radio"
                                            class="form-check-input"
                                            value="cP"
                                            required="required"
                                            onClick={(e) => setPtype("Cash")}

                                        /> */}
                        <label for="_0" class="form-check-label fontTypA">
                          Refund Note
                        </label>
                      </div>

                      <div class="col-6 mb-2 d-flex">
                        <textarea
                          row="2"
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setNote(e.target.value);
                          }}
                        />
                      </div>

                      <div class="col-2 form-check form-check-inline checkboxSpread">
                        {/* <input
                                            name="serviceSelect"
                                            id="cPValue"
                                            type="radio"
                                            class="form-check-input"
                                            value="cp"
                                            required="required"
                                            onClick={(e) => setPtype("Bank")}

                                        /> */}
                        <label for="_0" class="form-check-label fontTypA">
                          Amount
                        </label>
                      </div>

                      <div class="col-4 d-flex">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setTamount(Number(paid) - Number(e.target.value));
                            setRe(Number(refundb) + Number(e.target.value));
                          }}
                        />
                        <p class=" ml-2 fontTypA">BDT</p>
                      </div>
                      <div class="col-5">
                        <div class="input-group">
                          <select
                            id="inputState"
                            class="form-select form-control"
                            onChange={(e) => setHbank(e.target.value)}
                          >
                            <option class="form-control" selected value="">
                              Choose Bank...
                            </option>
                            {banks.map((item) => (
                              <option value={item.id + "/" + item.bank}>
                                {item.bank}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </form>
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn closeBtn"
                  data-dismiss="modal"
                  onClick={() => {
                    document.getElementById("pills-home-tab2").click();
                    setView("cash");
                    setPtype("cash");
                    setHbank("");
                    setAmount("");
                    setTamount(0);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn paymentUpdate"
                  data-dismiss="modal"
                  onClick={() => {
                    refundhistory();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <Delmodal acid={acid} acit={acit} />
        <Footer />
      </div>
    </>
  );
}

export default withRouter(Medical);
