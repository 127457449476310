import { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import Navigation2 from "../navigation2";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function Addstaff() {
  let history = useHistory();
  const [sname, setSname] = useState("");
  const [snum, setSnum] = useState("");
  const [sadd, setSadd] = useState("");
  const [position, setPosition] = useState("");
  const [salary, setSalary] = useState("");
  const [nid, setNid] = useState("");
  const [fileData, setFileData] = useState("");
  const [userData, setUserData] = useState("");

  const [ref, setRef] = useState("");

  const getFile = (e) => {
    setFileData(e.target.files[0]);
  };

  const getFile2 = (e) => {
    setUserData(e.target.files[0]);
  };

  function handleSubmit(e) {
    e.preventDefault();

    const data = new FormData();
    data.append("sname", sname);
    data.append("snum", snum);
    data.append("sadd", sadd);
    data.append("position", position);
    data.append("salary", salary);
    data.append("ref", ref);

    data.append("foo", fileData);
    data.append("user", userData);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/adstaff",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          toast.success("Staff Added", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });

          history.goBack();
        }
      })
      .catch((err) => console.log(err));
  }
  return (
    <>
      <ToastContainer />
      <Navigation2 />
      <div class="container px-5 py-5">
        <h4>ADD Staff</h4>
        <hr />
        <form onSubmit={handleSubmit} id="create-course-form">
          <div class="form-group row py-2">
            <label for="" class="col-2 col-form-label infotext">
              Satff Name
            </label>
            <div class="col-10">
              <input
                id=""
                name=""
                type="text"
                class="form-control"
                required="required"
                onChange={(e) => setSname(e.target.value)}
              />
            </div>
          </div>
          <div class="form-group row py-2">
            <label for="text" class="col-2 col-form-label infotext">
              Mobile No
            </label>
            <div class="col-10">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">+88</div>
                </div>
                <input
                  id="text"
                  name="text"
                  type="text"
                  class="form-control"
                  required="required"
                  onChange={(e) => setSnum(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div class="form-group row py-2">
            <label for="text" class="col-2 col-form-label infotext">
              Reference
            </label>
            <div class="col-10">
              <div class="input-group">
                <input
                  id="text"
                  name="text"
                  type="text"
                  class="form-control"
                  required="required"
                  onChange={(e) => setRef(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div class="form-group row py-2">
            <label for="textarea" class="col-2 col-form-label infotext">
              Staff Address
            </label>
            <div class="col-10">
              <textarea
                id="textarea"
                name="textarea"
                cols="40"
                rows="2"
                class="form-control"
                onChange={(e) => setSadd(e.target.value)}
                required="required"
              ></textarea>
            </div>
          </div>
          <div class="form-group row py-2">
            <label for="" class="col-2 col-form-label infotext">
              Staff Position
            </label>
            <div class="col-4">
              <div class="input-group">
                <select
                  id="inputState"
                  class="form-select form-control"
                  onChange={(e) => setPosition(e.target.value)}
                >
                  <option class="form-control" selected>
                    Choose Position
                  </option>
                  <option>Manager</option>
                  <option>Accounts</option>
                  <option>Reservation</option>
                  <option>Clerk</option>
                </select>
              </div>
            </div>
            <label for="" class="col-2 col-form-label infotext">
              Staff Salary
            </label>
            <div class="col-4">
              <input
                id=""
                name=""
                type="text"
                class="form-control"
                required="required"
                onChange={(e) => setSalary(e.target.value)}
              />
            </div>
          </div>
          <div class="form-group row py-2 align-items-center">
            <label for="" class="col-2 col-form-label infotext">
              Staff NID
            </label>
            <div class="col-10 me-auto">
              <input
                type="file"
                class="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                onChange={getFile}
              />
            </div>
          </div>

          <div class="form-group row py-2 align-items-center">
            <label for="" class="col-2 col-form-label infotext">
              Staff Photo
            </label>
            <div class="col-10 me-auto">
              <input
                type="file"
                class="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                onChange={getFile2}
              />
            </div>
          </div>

          <div class="form-group row py-2 ps-5">
            <div class="col">
              <div class="d-grid gap-2 text-right">
                <button class="btn btn-primary">ADD Staff</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
export default Addstaff;
