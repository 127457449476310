import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import Logo from "../images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
function Login() {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [bot, setBot] = useState("");
  const [load, setLoad] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      history.push("/dashboard");
    }
  }, [localStorage.getItem("token")]);

  function handleSubmit(e) {
    setLoad(true);
    e.preventDefault();
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/login",
        {
          email: email,
          password: pass,
          bot: bot,
        }
      )
      .then((res) => {
        setLoad(false);
        if (res.data.message) {
          localStorage.setItem("token", res.data.token);
          history.push("/dashboard");
        } else {
          toast.success("Email or password incorrect", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success9",
          });
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <ToastContainer />
      <div class="loginbackimage">
        <div class="container mt-5">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <div class="card col-lg-12 login-card">
                <div class="col-lg-12 my-3 mt-4 text-center">
                  <img
                    class="flydoc_logo_login"
                    alt=""
                    src={Logo}
                    loading="lazy"
                  ></img>
                </div>
                <h4 class="mt-4 text-center">Log In Your Account</h4>
                <div class="container card-body">
                  {/* <div class="card py-2 px-3 demoidcard">
                    <small>
                      <b>Demo Id:</b> demo.flydocx@gmail.com
                    </small>
                    <small>
                      <b>Password:</b> demo@123
                    </small>
                  </div> */}
                  {/* <div class="card py-2 px-3 text-center">
                    <small>
                      <b style={{ fontSize: "14px" }}>Contact for Demo</b>
                    </small>
                    <small>
                      <b>
                        <a href="tel:01748200588" className="text-dark">
                          +880 1748200588
                        </a>
                      </b>
                    </small>
                  </div> */}

                  <form class="my-4" onSubmit={handleSubmit}>
                    <div class="form-group input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          {" "}
                          <i class="fa fa-envelope"></i>{" "}
                        </span>
                      </div>

                      <input
                        name=""
                        class="form-control"
                        onChange={(e) => setBot(e.target.value)}
                        type="hidden"
                        autoComplete="off"
                      />
                      <input
                        name=""
                        class="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="E-Mail"
                        type="mobile_no"
                        autoComplete="off"
                      />
                    </div>
                    <div class="form-group input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          {" "}
                          <i class="fa fa-lock"></i>{" "}
                        </span>
                      </div>
                      <input
                        class="form-control"
                        onChange={(e) => setPass(e.target.value)}
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                      />
                    </div>

                    <div class="form-group">
                      <button type="submit" class="btn btn-primary btn-block">
                        {load ? "Loading..." : "Login"}{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
