import { useState, useEffect } from "react";
import Navigation2 from "./navigation2";
import { withRouter } from "react-router-dom";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

function Reissue(props) {
  let history = useHistory();
  var id = props.match.params.id;

  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [active, setActive] = useState("");
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [flight, setFlight] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [idate, setIdate] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [arr1, setArr1] = useState("");
  const [arr2, setArr2] = useState("");
  const [de1, setDe1] = useState("");
  const [de2, setDe2] = useState("");
  const [airplane, setAirplane] = useState("");
  const [vendor, setVendor] = useState("");
  const [pval, setPval] = useState("");
  const [country, setCountry] = useState("");
  const [type, setType] = useState("");
  const [visa, setVisa] = useState("");
  const [vval, setVval] = useState("");
  const [hotel, setHotel] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [ttype, setTtype] = useState("");
  const [serial, setSerial] = useState("");

  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");

  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");

  const [userData, setUsereData] = useState("");
  const [vendors, setVendors] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [fil, setFil] = useState("");
  const [cid, setCid] = useState("5");
  const [hphoto, setHphoto] = useState("");

  const [i1, setI1] = useState("");
  const [i2, setI2] = useState("");

  const [arri, setArri] = useState("");
  const [depa, setDepa] = useState("");
  const [allven, setAllven] = useState([]);
  const [vnam, setVnam] = useState("");
  const [tranlocation, setTranLocation] = useState("");
  const [trantime, setTrantime] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorfetch3"
      )
      .then((res) => setAllven(res.data.message))
      .catch((err) => console.log(err));
  }, [allven]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprev",
        {
          id: id,
        }
      )
      .then((res) => {
        setPassport(res.data.message[0].passport);
        setNid(res.data.message[0].nid);
        setIdate(res.data.message[0].idate);
        setAirplane(res.data.message[0].airplane);
        setFlight(res.data.message[0].flight);
        setVendor(res.data.message[0].vendor);
        setBase(res.data.message[0].base);
        setGross(res.data.message[0].gross);
        setTranLocation(res.data.message[0].tranlocation);
        setTrantime(res.data.message[0].trantime);
        setVat(res.data.message[0].vat);
        setProfit(res.data.message[0].profit);
        setSerial(res.data.message[0].serial);
        setArri(res.data.message[0].arrival);
        setDepa(res.data.message[0].departure);

        allven.map((y) => {
          if (y.id == vendor) {
            setVnam(y.vname);
          }
        });

        var xp = res.data.message[0].arrival;
        for (var i = 0; i < xp.length; i++) {
          var codeLine = xp[i];
          var firstWord = xp.substr(0, xp.indexOf(" "));
          setArr1(firstWord);
        }

        var dp = xp.replace(xp.substr(0, xp.indexOf(" ")), "");
        var k = new Date(dp.substr(0, 12))
          .toISOString()
          .split("T")[0]
          .slice(0, 10);
        setArr2(k);

        var xp2 = res.data.message[0].departure;
        for (var i = 0; i < xp2.length; i++) {
          var codeLine = xp2[i];
          var firstWord = xp2.substr(0, xp2.indexOf(" "));
          setDe1(firstWord);
        }

        var dp = xp2.replace(xp2.substr(0, xp2.indexOf(" ")), "");
        var k = new Date(dp.substr(0, 12))
          .toISOString()
          .split("T")[0]
          .slice(0, 10);
        setDe2(k);
      })
      .catch((err) => console.log(err));
  }, [id, vnam]);

  function getit() {
    allven.map((y) => {
      if (y.id == vendor) {
        return y.vname;
      }
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const data = new FormData();

    data.append("flight", flight);
    data.append("base", base);
    data.append("gross", gross);
    data.append("idate", idate);
    data.append("tranlocation", tranlocation);
    data.append("trantime", trantime);
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("arrival", arri);
    data.append("departure", depa);
    data.append("serial", serial);
    data.append("id", id);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upservice",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          toast.success("customer Added", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });

          setCid("");
          setCname("");
          setCadd("");
          setCname("");
          document.getElementById("create-course-form").reset();
          history.goBack();
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));
  }, [vendors]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => setCustomer(res.data.message))
      .catch((err) => console.log(err));
  }, [customer]);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div
        class="modal fade hourmodal"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Time
              </h5>
            </div>
            <div class="modal-body row justify-content-center">
              <div class="col-6">
                <div class=" d-flex justify-content-between">
                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="HH"
                    onChange={(e) => setT1(e.target.value)}
                  ></input>

                  <p
                    class="mx-2 my-1 font-weight-bold"
                    style={{ fontSize: 20 }}
                  >
                    :
                  </p>

                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="MM"
                    onChange={(e) => setT2(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <button
                type="button"
                class="btn timeSelect"
                data-dismiss="modal"
                onClick={(e) => {
                  var arrival = arr1 + "  " + arr2 + " " + t1 + ":" + t2;
                  setArri(arrival);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade hourmodal"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Time
              </h5>
            </div>

            <div class="modal-body row justify-content-center">
              <div class="col-6">
                <div class=" d-flex justify-content-between">
                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="HH"
                    onChange={(e) => setD1(e.target.value)}
                  ></input>

                  <p
                    class="mx-2 my-1 font-weight-bold"
                    style={{ fontSize: 20 }}
                  >
                    :
                  </p>

                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="MM"
                    onChange={(e) => setD2(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <button
                type="button"
                class="btn timeSelect"
                data-dismiss="modal"
                onClick={(e) => {
                  var departure = de1 + " " + de2 + " " + d1 + ":" + d2;
                  setDepa(departure);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="addCustomer">
          <div class="d-flex adcst">
            <h3 class="m-0">Re-issue Ticket</h3>
          </div>

          <hr />

          <form onSubmit={handleSubmit} id="create-course-form">
            <div>
              <div class="form-group row py-2">
                <label for="" class="col-2 col-form-label infotext">
                  Passport No
                </label>
                <div class="col-4">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    value={passport}
                    readOnly
                  />
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  NID No
                </label>
                <div class="col-4">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    readOnly
                    value={nid}
                  />
                </div>
              </div>
              <div class="form-group row py-2">
                <label for="" class="col-2 col-form-label infotext">
                  Issue Date
                </label>
                <div class="col-4">
                  <input
                    id=""
                    name=""
                    type="date"
                    class="form-control"
                    required="required"
                    value={idate}
                    onChange={(e) => setIdate(e.target.value)}
                  />
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  Airplane
                </label>
                <div class="col-4">
                  <div class="input-group">
                    <div class="input-group-prepend"></div>
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      required="required"
                      value={airplane}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row py-2">
                <label for="" class="col-2 col-form-label infotext">
                  Departure
                </label>
                <div class="col-2 pr-1">
                  <input
                    id=""
                    name=""
                    type="text"
                    placeholder="Ex. DUBAI"
                    class="form-control"
                    required="required"
                    value={de1}
                    onChange={(e) => setDe1(e.target.value)}
                  />
                </div>
                <div class="col-2 pl-0">
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #ddd",
                      overflow: "auto",
                      padding: 5,
                      borderRadius: 4,
                    }}
                  >
                    <input
                      id=""
                      name=""
                      type="date"
                      placeholder=""
                      style={{
                        border: "none",
                        outline: "none",
                        width: "100%",
                        fontSize: 14,
                      }}
                      //  class="form-control"
                      required="required"
                      value={de2}
                      onChange={(e) => {
                        setDe2(e.target.value);
                        document.getElementById("clbtn2").click();
                      }}
                    />
                    <i
                      class="fa fa-clock-o"
                      id="clbtn2"
                      data-toggle="modal"
                      data-target="#exampleModal2"
                      style={{ margin: 2, fontSize: 20, display: "none" }}
                    ></i>
                  </div>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Arrival
                </label>
                <div class="col-2 pr-1">
                  <input
                    id=""
                    name=""
                    type="text"
                    placeholder="Ex. DAC"
                    class="form-control"
                    required="required"
                    value={arr1}
                    onChange={(e) => setArr1(e.target.value)}
                  />
                </div>
                <div class="col-2 pl-0">
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #ddd",
                      overflow: "auto",
                      padding: 5,
                      borderRadius: 4,
                    }}
                  >
                    <input
                      id=""
                      name=""
                      type="date"
                      placeholder=""
                      style={{
                        border: "none",
                        outline: "none",
                        width: "100%",
                        fontSize: 14,
                      }}
                      //  class="form-control"
                      value={arr2}
                      required="required"
                      onChange={(e) => {
                        setArr2(e.target.value);
                        document.getElementById("clbtn").click();
                      }}
                    />
                    <i
                      class="fa fa-clock-o"
                      id="clbtn"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Transit Route
                </label>
                <div class="col-lg-2 col-6 pr-1">
                  <input
                    id=""
                    name=""
                    type="text"
                    placeholder="Location"
                    class="form-control"
                    required=""
                    value={tranlocation}
                    onChange={(e) => setTranLocation(e.target.value)}
                  />
                </div>

                <div class="col-lg-2 col-6 pl-0">
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #ddd",
                      overflow: "auto",
                      padding: 5,
                      borderRadius: 4,
                    }}
                  >
                    <input
                      id=""
                      name=""
                      type="text"
                      placeholder="1:00 Hour"
                      style={{
                        border: "none",
                        outline: "none",
                        width: "100%",
                        fontSize: 14,
                        height: 26,
                      }}
                      //  class="form-control"
                      required=""
                      value={trantime}
                      onChange={(e) => {
                        setTrantime(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-2">
                <label for="" class="col-2 col-form-label infotext">
                  Flight No
                </label>
                <div class="col-4">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    value={flight}
                    onChange={(e) => setFlight(e.target.value)}
                  />
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Ticket Serial
                </label>
                <div class="col-4 me-auto">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    value={serial}
                    onChange={(e) => setSerial(e.target.value)}
                  />
                </div>
              </div>
              <hr />

              <div class="form-group row py-2">
                <label for="" class="col-2 col-form-label infotext">
                  Vendor Name
                </label>
                <div class="col-4">
                  <div class="input-group">
                    <select
                      id="inputven"
                      class="form-select form-control"
                      value={vendor}
                      // onChange={(e) => setVendor(e.target.value)}
                      readOnly
                    >
                      <option class="form-control">Choose Vendor</option>
                      {vendors.map((item) => (
                        <option value={item.id}>{item.vname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Buying Price
                </label>
                <div class="col-4 me-auto">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    value={base}
                    onChange={(e) => setBase(e.target.value)}
                  />
                </div>
              </div>
              <div class="form-group row py-2">
                <label for="" class="col-2 col-form-label infotext">
                  Selling Price
                </label>
                <div class="col-2 me-auto">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    value={gross}
                    onChange={(e) => setGross(e.target.value)}
                  />
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  VAT & AIT
                </label>
                <div class="col-2 me-auto">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    value={vat}
                    onChange={(e) => {
                      var p =
                        Number(gross) - (Number(base) + Number(e.target.value));
                      setVat(e.target.value);
                      setProfit(p);
                    }}
                  />
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  Profit
                </label>
                <div class="col-2 me-auto">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    value={profit}
                    readOnly
                    onChange={(e) => setProfit(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="form-group row py-2">
              <div class="col-2"></div>
              <div class="col-10">
                <button class="w-100 btn btn-primary">Update</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(Reissue);
