import { useState, useEffect } from "react";
import axios from "axios";
import Navigation2 from "../navigation2";
import { useHistory } from "react-router-dom";

function AddaVendor() {
  let history = useHistory();

  const [vtype, setVtype] = useState("international");
  const [vname, setVname] = useState("");
  const [vnum, setVnum] = useState("");
  const [vadd, setVadd] = useState("");
  const [type, setType] = useState("ticket");
  const [bank1, setBank1] = useState("");
  const [account1, setAccount1] = useState("");
  const [ano1, setAno1] = useState("");
  const [brunch1, setBrunch1] = useState("");

  const [bank2, setBank2] = useState("");
  const [account2, setAccount2] = useState("");
  const [ano2, setAno2] = useState("");
  const [brunch2, setBrunch2] = useState("");

  const [bank3, setBank3] = useState("");
  const [account3, setAccount3] = useState("");
  const [ano3, setAno3] = useState("");
  const [brunch3, setBrunch3] = useState("");

  const [bank4, setBank4] = useState("");
  const [account4, setAccount4] = useState("");
  const [ano4, setAno4] = useState("");
  const [brunch4, setBrunch4] = useState("");

  const [bank5, setBank5] = useState("");
  const [account5, setAccount5] = useState("");
  const [ano5, setAno5] = useState("");
  const [brunch5, setBrunch5] = useState("");
  const [paid, setPaid] = useState("");
  const [vemail, setVemail] = useState("");

  const [bno, setBno] = useState(1);

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/adavendor",
        {
          vtype: vtype,
          vname: vname,
          vnum: vnum,
          vadd: vadd,
          vemail: vemail,
          type: type,
          paid: paid,
          bank1: bank1,
          account1: account1,
          ano1: ano1,
          brunch1: brunch1,

          bank2: bank2,
          account2: account2,
          ano2: ano2,
          brunch2: brunch2,

          bank3: bank3,
          account3: account3,
          ano3: ano3,
          brunch3: brunch3,

          bank4: bank4,
          account4: account4,
          ano4: ano4,
          brunch4: brunch4,

          bank5: bank5,
          account5: account5,
          ano5: ano5,
          brunch5: brunch5,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        console.log(res.data.message);
        history.goBack();
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Navigation2 />

      <br></br>
      <div class="container">
        <div class="addVendor">
          <h3>Add Airlines Vendor</h3>
          <hr />
          <form onSubmit={handleSubmit}>
            <div class="form-group row py-2">
              <label for="" class="col-3 col-form-label infotext">
                Airlines Name
              </label>
              <div class="col-9">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  onChange={(e) => setVname(e.target.value)}
                />
              </div>
            </div>
            <div class="form-group row py-2">
              <label for="text" class="col-3 col-form-label infotext">
                Mobile No
              </label>
              <div class="col-9">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">+88</div>
                  </div>
                  <input
                    id="text"
                    name="text"
                    type="text"
                    class="form-control"
                    onChange={(e) => setVnum(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div class="form-group row py-2">
              <label for="textarea" class="col-3 col-form-label infotext">
                Address
              </label>
              <div class="col-9">
                <textarea
                  id="textarea"
                  name="textarea"
                  cols="40"
                  rows="2"
                  class="form-control"
                  onChange={(e) => setVadd(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="textarea" class="col-3 col-form-label infotext">
                Airlines Email
              </label>
              <div class="col-9">
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => setVemail(e.target.value)}
                ></input>
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="textarea" class="col-3 col-form-label infotext">
                Deposite Balance
              </label>
              <div class="col-9">
                <input
                  id="textarea"
                  name="textarea"
                  cols="40"
                  rows="2"
                  class="form-control"
                  onChange={(e) => setPaid(e.target.value)}
                ></input>
              </div>
            </div>

            {/* Bank info */}
            <div class="form-group row py-2">
              <label for="" class="col-3 col-form-label infotext">
                Bank Name{" "}
              </label>
              <div class="col-9">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  onChange={(e) => setBank1(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-2">
              <label for="" class="col-3 col-form-label infotext">
                Account Name
              </label>
              <div class="col-3">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  onChange={(e) => setAccount1(e.target.value)}
                />
              </div>

              <div class="col-3">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  placeholder="Account Number"
                  onChange={(e) => setAno1(e.target.value)}
                />
              </div>

              <div class="col-3">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  placeholder="Branch Name"
                  onChange={(e) => setBrunch1(e.target.value)}
                />
              </div>
            </div>

            {/* Bank info */}

            {bno > 1 ? (
              <>
                <div class="form-group row py-2">
                  <label for="" class="col-3 col-form-label infotext">
                    Bank Name 2
                  </label>
                  <div class="col-9">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      onChange={(e) => setBank2(e.target.value)}
                    />
                  </div>
                </div>

                <div class="form-group row py-2">
                  <label for="" class="col-3 col-form-label infotext">
                    Account Name 2
                  </label>
                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      onChange={(e) => setAccount2(e.target.value)}
                    />
                  </div>

                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      placeholder="Account Number"
                      onChange={(e) => setAno2(e.target.value)}
                    />
                  </div>

                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      placeholder="Branch"
                      onChange={(e) => setBrunch2(e.target.value)}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {bno > 2 ? (
              <>
                <div class="form-group row py-2">
                  <label for="" class="col-3 col-form-label infotext">
                    Bank Name 3
                  </label>
                  <div class="col-9">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      onChange={(e) => setBank3(e.target.value)}
                    />
                  </div>
                </div>

                <div class="form-group row py-2">
                  <label for="" class="col-3 col-form-label infotext">
                    Account Name 3
                  </label>
                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      onChange={(e) => setAccount3(e.target.value)}
                    />
                  </div>

                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      placeholder="Account Number"
                      onChange={(e) => setAno3(e.target.value)}
                    />
                  </div>

                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      placeholder="Branch"
                      onChange={(e) => setBrunch3(e.target.value)}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {bno > 3 ? (
              <>
                <div class="form-group row py-2">
                  <label for="" class="col-3 col-form-label infotext">
                    Bank Name 4
                  </label>
                  <div class="col-9">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      onChange={(e) => setBank4(e.target.value)}
                    />
                  </div>
                </div>

                <div class="form-group row py-2">
                  <label for="" class="col-3 col-form-label infotext">
                    Account Name 4
                  </label>
                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      onChange={(e) => setAccount4(e.target.value)}
                    />
                  </div>

                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      placeholder="Account Number"
                      onChange={(e) => setAno4(e.target.value)}
                    />
                  </div>

                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      placeholder="Branch"
                      onChange={(e) => setBrunch4(e.target.value)}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {bno > 4 ? (
              <>
                <div class="form-group row py-2">
                  <label for="" class="col-3 col-form-label infotext">
                    Bank Name 5
                  </label>
                  <div class="col-9">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      onChange={(e) => setBank5(e.target.value)}
                    />
                  </div>
                </div>

                <div class="form-group row py-2">
                  <label for="" class="col-3 col-form-label infotext">
                    Account Name 5
                  </label>
                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      onChange={(e) => setAccount5(e.target.value)}
                    />
                  </div>

                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      placeholder="Account Number"
                      onChange={(e) => setAno5(e.target.value)}
                    />
                  </div>

                  <div class="col-3">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      placeholder="Branch"
                      onChange={(e) => setBrunch5(e.target.value)}
                    />
                  </div>
                </div>
              </>
            ) : null}

            <div class="row mb-3">
              <div class="col-3"></div>
              <div class="col-9 text-center">
                {bno > 4 ? null : (
                  <button
                    class="btn btn-primary bg-light"
                    style={{ color: "black" }}
                    onClick={() => setBno(bno + 1)}
                  >
                    Add More Bank
                  </button>
                )}
              </div>
            </div>

            <div class="form-group row py-3 justify-content-end">
              <div class="col-9">
                <div class="d-grid gap-2 text-center">
                  <button class="btn btn-primary w-100 submitbtn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddaVendor;
