import { useState,useEffect } from "react";
import { withRouter,Link } from "react-router-dom";
import Navigation2 from "../navigation2";
import axios from "axios";
import Footer from "../../screens/footer";


function Addinvoice(){

   


  return (
    <>
      <Navigation2/>
      <div class="container">
      <div class="px-5 py-5">
        <h3>Make Invoice</h3>
        <hr />
        <form class=" row">
            <div class="form-group col-lg-5 py-2">
                <div class="row mb-2">

                    <div class="d-flex col-12 form-group has-search align-items-center">
                        <span class="fa fa-search" style={{position:"absolute",right:20,fontSize:18,color:"#777777"}}></span>
                        <input id="" name="" type="text" class="form-control" required="required" placeholder="Search Customer"/>
                    </div>
                    
                </div>
<div class="row mb-4">
                <label for="" class="col-4 col-form-label">Customer Name</label>
                <div class="col-8">
                    <input id="" name="" type="text" class="form-control" required="required" readonly />
                </div>
</div>
<div class="row mb-4">
                <label for="" class="col-4 col-form-label ">Mobile No</label>
                <div class="col-8">
                    <input id="" name="" type="text" class="form-control" required="required" readonly />
                </div>
                </div>
<div class="row mb-4">
                <label for="textarea" class="col-4 col-form-label">Address</label>
                <div class="col-8">
                    <textarea id="textarea" name="textarea" cols="40" rows="2" class="form-control" required="required"
                        readonly></textarea>
                </div>
            </div>
            </div>

            <div class="form-group col-lg-7 py-2">

<div class="row mb-2">
                <label for="textarea" class="col-12 col-form-label font-weight-bold">Service Details</label>
                <div class="col-lg-12">
                    <div class="form-check my-2">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
  <label class="card form-check-label" for="flexCheckDefault">

        <div class="row m-0 align-items-center">
                <div class="col-lg-2 py-3  text-center">
                    <p class="font-weight-bold m-0">#FD243</p>
                </div>

                <div class="col-lg-3 py-3 text-center">
                    <p class="font-weight-bold m-0">13/03/2022</p>
                </div>

                <div class="col-lg-3 py-3 text-center">
                    <p class="font-weight-bold m-0">Ticket</p>
                </div>

                <div class="col-lg-4 py-3">
                    <div class="btn paymbttnD w-100">
                        <p class="m-0">Payment Due</p>
                    </div>
                </div>
            </div>
        
  </label>
</div>

<div class="form-check my-2">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
  <label class="card form-check-label" for="flexCheckDefault">

        <div class="row m-0 align-items-center">
                <div class="col-lg-2 py-3  text-center">
                    <p class="font-weight-bold m-0">#FD243</p>
                </div>

                <div class="col-lg-3 py-3 text-center">
                    <p class="font-weight-bold m-0">01/02/2022</p>
                </div>

                <div class="col-lg-3 py-3 text-center">
                    <p class="font-weight-bold m-0">Ticket</p>
                </div>

                <div class="col-lg-4 py-3">
                    <div class="btn statusbttn w-100">
                        <p class="m-0">Payment Paid</p>
                    </div>
                </div>
            </div>
        
  </label>
</div>
                </div>
            </div>

            <div class="col-lg-12 p-0 mt-2">
                    <div class="text-right">
                        <button class="btn w-25 btn-primary" type="button">Make Invoice</button>
                    </div>
                </div>

            </div>
                
            

        </form>
    </div>
    
        


        






        </div>


        <Footer/>
    </>
  );
}

export default Addinvoice;