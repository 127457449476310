import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Resizer from "react-image-file-resizer";

function Updategt(props) {
  let history = useHistory();

  var id = props.match.params.id;
  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [active, setActive] = useState("");
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [flight, setFlight] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [idate, setIdate] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [arr1, setArr1] = useState("");
  const [arr2, setArr2] = useState("");
  const [de1, setDe1] = useState("");
  const [de2, setDe2] = useState("");
  const [airplane, setAirplane] = useState("");
  const [vendor, setVendor] = useState("");
  const [pval, setPval] = useState("");
  const [country, setCountry] = useState("");
  const [type, setType] = useState("");
  const [visa, setVisa] = useState("");
  const [vval, setVval] = useState("");
  const [hotel, setHotel] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [ttype, setTtype] = useState("");
  const [serial, setSerial] = useState("");
  const [doc1, setDoc1] = useState("");
  const [doc2, setDoc2] = useState("");
  const [doc3, setDoc3] = useState("");

  const [adult, setAdult] = useState("");
  const [infant, setInfant] = useState("");
  const [baby, setBaby] = useState("");

  const [doc4, setDoc4] = useState("");
  const [doc5, setDoc5] = useState("");

  const [show, setShow] = useState(false);

  const [gpnr, setGpnr] = useState("");
  const [edate, setEdate] = useState("");

  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");

  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");

  const [userData, setUsereData] = useState("");
  const [vendors, setVendors] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [fil, setFil] = useState("");
  const [cid, setCid] = useState("5");
  const [hphoto, setHphoto] = useState("");

  const [i1, setI1] = useState("");
  const [i2, setI2] = useState("");
  const [cof, setCof] = useState("");
  const [note, setNote] = useState("");
  const [tnote, setTnote] = useState("");
  const [clist, setClist] = useState([]);

  const getFile = (e) => {
    setDoc1(e.target.files[0]);
  };

  const getFile2 = (e) => {
    setDoc2(e.target.files[0]);
  };

  const getFile3 = (e) => {
    setDoc3(e.target.files[0]);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        900,
        900,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const getFilereal = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    const data = new FormData();
    data.append("id", id);
    data.append("foo", newFile);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/imagereal",
        data
      )
      .then((res) => {
        setDoc1(res.data.message);
        toast.success("Image updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  const getFilereal2 = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    const data = new FormData();
    data.append("id", id);
    data.append("foo", newFile);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/imagereal2",
        data
      )
      .then((res) => {
        setDoc2(res.data.message);
        toast.success("Image updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  const getFilereal3 = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    const data = new FormData();
    data.append("id", id);
    data.append("foo", newFile);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/imagereal3",
        data
      )
      .then((res) => {
        setDoc3(res.data.message);

        toast.success("Image updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  const getFilereal4 = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    const data = new FormData();
    data.append("id", id);
    data.append("foo", newFile);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/imagereal4a",
        data
      )
      .then((res) => {
        setDoc4(res.data.message);

        toast.success("Image updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  const getFilereal5 = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    const data = new FormData();
    data.append("id", id);
    data.append("foo", newFile);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/imagereal5a",
        data
      )
      .then((res) => {
        setDoc5(res.data.message);

        toast.success("Image updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  function gTgross(x) {
    var total = 0;

    clist.map((item) => {
      if (item.gid == x) {
        total = total + item.gross;
      }
    });
    return total;
  }

  function gTbase(x) {
    var total = 0;

    clist.map((item) => {
      if (item.gid == x) {
        total = total + item.base;
      }
    });
    return total;
  }

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchgprev",
        {
          id: id,
        }
      )
      .then((res) => {
        console.log();
        setPassport(res.data.message[0].passport);
        setNid(res.data.message[0].nid);
        setIdate(res.data.message[0].idate);
        setAirplane(res.data.message[0].airline);
        setFlight(res.data.message[0].flight);
        setVendor(res.data.message[0].vendor);
        setBase(res.data.message[0].base);
        setGross(res.data.message[0].gross);
        setVat(res.data.message[0].vat);
        setProfit(res.data.message[0].profit);
        setSerial(res.data.message[0].pnr);
        setType(res.data.message[0].type);
        setTnote(res.data.message[0].tnote);

        setAdult(res.data.message[0].adult);
        setInfant(res.data.message[0].infant);
        setBaby(res.data.message[0].baby);

        setGpnr(res.data.message[0].gpnr);

        setEdate(res.data.message[0].edate);
        setCof(res.data.message[0].cof);

        setDoc1(res.data.message[0].doc1);
        setDoc2(res.data.message[0].doc2);
        setDoc3(res.data.message[0].doc3);

        setDoc4(res.data.message[0].doc4);
        setDoc5(res.data.message[0].doc5);
        setNote(res.data.message[0].note);

        if (res.data.message[0].aid == 0) {
          setShow(true);
        }

        document.getElementById("inputair").value =
          res.data.message[0].airplane;
        document.getElementById("inputven").value = res.data.message[0].vendor;

        setArr1(
          res.data.message[0].arrival.substr(
            0,
            res.data.message[0].arrival.indexOf(" ")
          )
        );
        setDe1(
          res.data.message[0].departure.substr(
            0,
            res.data.message[0].departure.indexOf(" ")
          )
        );

        var fdate = res.data.message[0].arrival;
        fdate = fdate
          .replace(fdate.substr(0, fdate.indexOf(" ")), "")
          .replace(" ", "");
        var k = fdate.substr(0, 11).replace(" ", "");

        console.log("hey" + k);
        setArr2(k.replace(" ", ""));

        var time1 = fdate.replace(fdate.substr(0, 12), "");
        var time12 = time1.substr(0, time1.indexOf(":"));
        setT1(time12);

        var time2 = time1.replace(time12, "").replace(":", "");
        setT2(time2);

        var ddate = res.data.message[0].departure;
        ddate = ddate
          .replace(ddate.substr(0, ddate.indexOf(" ")), "")
          .replace(" ", "");
        var ko = ddate.substr(0, 11).replace(" ", "");
        setDe2(ko.replace(" ", ""));

        var time1 = ddate.replace(ddate.substr(0, 12), "");
        var time12 = time1.substr(0, time1.indexOf(":"));
        setD1(time12);

        var time2 = time1.replace(time12, "").replace(":", "");
        setD2(time2);
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchAllg",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setClist(res.data.clist);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    var arrival = arr1 + "  " + arr2 + " " + t1 + ":" + t2;
    var departure = de1 + " " + de2 + " " + d1 + ":" + d2;

    const data = new FormData();

    data.append("idate", idate);
    data.append("airline", airplane);
    data.append("arrival", arrival);
    data.append("departure", departure);
    data.append("serial", serial);
    data.append("tnote", tnote);
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("token", localStorage.getItem("token"));

    data.append("id", id);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upgticket",
        data
      )
      .then((res) => {
        console.log(res.data.message);
        if (res.data.message == null) {
          toast.success("Ticket updated", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });

          document.getElementById("create-course-form").reset();
        }
      })
      .catch((err) => console.log(err));

    // history.goBack();
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));
  }, [vendors]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => setCustomer(res.data.message))
      .catch((err) => console.log(err));
  }, [customer]);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div
        class="modal fade hourmodal"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Time
              </h5>
            </div>
            <div class="modal-body row justify-content-center">
              <div class="col-6">
                <div class=" d-flex justify-content-between">
                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="HH"
                    value={t1}
                    onChange={(e) => setT1(e.target.value)}
                  ></input>

                  <p
                    class="mx-2 my-1 font-weight-bold"
                    style={{ fontSize: 20 }}
                  >
                    :
                  </p>

                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="MM"
                    value={t2}
                    onChange={(e) => setT2(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <button type="button" class="btn timeSelect" data-dismiss="modal">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade hourmodal"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Time
              </h5>
            </div>

            <div class="modal-body row justify-content-center">
              <div class="col-6">
                <div class=" d-flex justify-content-between">
                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="HH"
                    value={d1}
                    onChange={(e) => setD1(e.target.value)}
                  ></input>

                  <p
                    class="mx-2 my-1 font-weight-bold"
                    style={{ fontSize: 20 }}
                  >
                    :
                  </p>

                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="MM"
                    value={d2}
                    onChange={(e) => setD2(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <button type="button" class="btn timeSelect" data-dismiss="modal">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="addCustomer">
          <div class="d-flex adcst">
            <h3 class="m-0">Update data</h3>
          </div>
          <hr />

          <form onSubmit={handleSubmit} id="create-course-form">
            <div>
              <div class="form-group row py-1"></div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Issue Date
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="date"
                    class="form-control"
                    required=""
                    value={idate}
                    onChange={(e) => setIdate(e.target.value)}
                  />
                </div>
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Airplane
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <div class="input-group-prepend"></div>
                    <select
                      id="inputair"
                      class="form-select form-control"
                      value={airplane}
                      onChange={(e) => setAirplane(e.target.value)}
                    >
                      <option class="form-control" selected>
                        Choose Airlines
                      </option>
                      <option>Air Arabia</option>
                      <option>Air Asia</option>
                      <option>Air India</option>
                      <option>Air KZB Company Ltd.</option>
                      <option>Biman Bangladesh Airlines</option>
                      <option>Bangkok Airlines</option>
                      <option>Bismillah Airlines</option>
                      <option>British Airways</option>
                      <option>Cathay Pacific</option>
                      <option>China Eastern Airlines</option>
                      <option>China Southern Airlines</option>
                      <option>Dragonair</option>
                      <option>Etihad Airways</option>
                      <option>Finnair</option>
                      <option>Fly Emirates</option>
                      <option>Fly Dubai</option>
                      <option>Gulf Air</option>
                      <option>Himalaya Airlines</option>
                      <option>Indigo</option>
                      <option>Jazeera Airways</option>
                      <option>Jet Airways</option>
                      <option>Kuwait Airways</option>
                      <option>Malaysia Airlines</option>
                      <option>Malindo Air</option>
                      <option>Novoair</option>
                      <option>Oman Air</option>
                      <option>Pakistan International Airlines</option>
                      <option>Qatar Airways</option>
                      <option>Regent Airways</option>
                      <option>Salam Air</option>
                      <option>Saudi Arabian Airlines</option>
                      <option>Saudia</option>
                      <option>Singapore Airlines</option>
                      <option>SpiceJet</option>
                      <option>Srilankan Airlines</option>
                      <option>Thai Airways</option>
                      <option>Thai Airways International</option>
                      <option>Turkish Airlines</option>
                      <option>United Airlines</option>
                      <option>US-Bangla Airlines</option>
                      <option>Yemenia</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Departure
                </label>
                <div class="col-lg-2 col-6 pr-1">
                  <input
                    id=""
                    name=""
                    type="text"
                    placeholder="Ex. DUBAI"
                    class="form-control"
                    required=""
                    value={de1}
                    onChange={(e) => setDe1(e.target.value)}
                  />
                </div>
                <div class="col-lg-2 col-6 pl-0">
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #ddd",
                      overflow: "auto",
                      padding: 5,
                      borderRadius: 4,
                    }}
                  >
                    <input
                      id=""
                      name=""
                      type="date"
                      placeholder="Ex. 14:45 24 April"
                      style={{
                        border: "none",
                        outline: "none",
                        width: "100%",
                        fontSize: 14,
                      }}
                      //  class="form-control"
                      required=""
                      value={de2}
                      onChange={(e) => {
                        setDe2(e.target.value);
                        document.getElementById("clbtn2").click();
                      }}
                    />
                    <i
                      class="fa fa-clock-o"
                      id="clbtn2"
                      data-toggle="modal"
                      data-target="#exampleModal2"
                      style={{ margin: 2, fontSize: 20, display: "none" }}
                    ></i>
                  </div>
                </div>

                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Arrival
                </label>
                <div class="col-lg-2 col-6 pr-1">
                  <input
                    id=""
                    name=""
                    type="text"
                    placeholder="Ex. DAC"
                    class="form-control"
                    required=""
                    value={arr1}
                    onChange={(e) => setArr1(e.target.value)}
                  />
                </div>
                <div class="col-lg-2 col-6 pl-0">
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #ddd",
                      overflow: "auto",
                      padding: 5,
                      borderRadius: 4,
                    }}
                  >
                    <input
                      id=""
                      name=""
                      type="date"
                      placeholder="Ex. 14:45 24 April"
                      style={{
                        border: "none",
                        outline: "none",
                        width: "100%",
                        fontSize: 14,
                      }}
                      //  class="form-control"
                      required=""
                      value={arr2}
                      onChange={(e) => {
                        setArr2(e.target.value);
                        document.getElementById("clbtn").click();
                      }}
                    />
                    <i
                      class="fa fa-clock-o"
                      id="clbtn"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      style={{ margin: 2, fontSize: 20, display: "none" }}
                    ></i>
                  </div>
                </div>
              </div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Serial / PNR
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={serial}
                    onChange={(e) => setSerial(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Transit Note
                </label>
                <div class="col-lg-10 col-12">
                  <textarea
                    rows="4"
                    cols="40"
                    type="text"
                    class="form-control"
                    required=""
                    value={tnote}
                    onChange={(e) => setTnote(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <hr />
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Vendor Name
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <select
                      id="inputven"
                      class="form-select form-control"
                      value={vendor}
                      // onChange={(e) => setVendor(e.target.value)}
                      readOnly
                    >
                      <option class="form-control">Choose Vendor</option>
                      {vendors.map((item) => (
                        <option value={item.id}>{item.vname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <label
                  for=""
                  class="col-lg-2 col-4 marginTop infotext col-form-label"
                >
                  Buying Price
                </label>
                <div class="col-lg-4 col-8 marginTop">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={gTbase(id)}
                    onChange={(e) => setBase(e.target.value)}
                    disabled={true}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-4 infotext col-form-label">
                  {show ? "Selling Price" : "Agent Fare"}
                </label>
                <div class="col-lg-2 col-8 marginTop">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={gTgross(id)}
                    onChange={(e) => setGross(e.target.value)}
                    disabled={true}
                  />
                </div>

                <label for="" class="col-lg-2 col-4 infotext col-form-label">
                  VAT & AIT
                </label>
                <div class="col-lg-2 col-8 marginTop">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    placeholder="0.03"
                    required=""
                    value={vat}
                    onChange={(e) => {
                      var p =
                        Number(gTgross()) -
                        (Number(gTbase()) + Number(e.target.value));
                      setVat(e.target.value);
                      setProfit(p);
                    }}
                  />
                </div>
                <label for="" class="col-lg-2 col-4 infotext col-form-label">
                  Profit
                </label>
                <div class="col-lg-2 col-8 marginTop">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={profit}
                    readOnly
                    onChange={(e) => setProfit(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="form-group row py-1">
              <div class="col">
                <div class="d-grid gap-2 text-right">
                  <button class="btn btn-primary w-100">Update</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(Updategt);
