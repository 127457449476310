import { useState, useEffect } from "react";
import axios from "axios";
import Navigation2 from "../navigation2";
import { useHistory } from "react-router-dom";
import Resizer from "react-image-file-resizer";

function Admedical() {
  let history = useHistory();

  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [fil, setFil] = useState("");
  const [cid, setCid] = useState(0);
  const [cserial, setCserial] = useState("");
  const [customer, setCustomer] = useState([]);

  const [pass, setPass] = useState("");
  const [visa, setVisa] = useState("");
  const [idate, setIdate] = useState("");

  const [ddate, setDdate] = useState("");
  const [edate, setEdate] = useState("");

  const [vval, setVval] = useState("");
  const [fno, setFno] = useState("");
  const [serial, setSerial] = useState("");
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [hname, setHname] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");

  const [userData, setUsereData] = useState("");
  const [fileData, setFileData] = useState("");
  const [fileData2, setFileData2] = useState("");
  const [fileData3, setFileData3] = useState("");

  const [dloca, setDloca] = useState("");
  const [aloca, setAloca] = useState("");

  const [note, setNote] = useState("");
  const [dob, setDob] = useState("");

  const [airplane, setAirplane] = useState("");
  const [t1, setT1] = useState("");
  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");
  const [t2, setT2] = useState("");

  const [mofa, setMofa] = useState("");
  const [mno, setMno] = useState("");
  const [trans, setTrans] = useState("");
  const [transno, setTransno] = useState("");
  const [sservice, setSservice] = useState("");
  const [sno, setSno] = useState("");
  const [meal, setMeal] = useState("");
  const [mealno, setMealno] = useState();

  const [gpnr, setGpnr] = useState("");
  const [pedate, setPedate] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => setCustomer(res.data.message))
      .catch((err) => console.log(err));
  }, [customer]);

  const userPhoto = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);
    setUsereData(newFile);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        900,
        900,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const getFile = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    setFileData(newFile);
  };

  const getFile2 = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    setFileData2(newFile);
  };

  const getFile3 = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);
    setFileData3(newFile);
  };

  function handleSubmit() {
    var arri = arrival + " " + t1 + ":" + t2;
    var depa = departure + " " + d1 + ":" + d2;

    const data = new FormData();

    data.append("cid", cid);
    data.append("cname", cname);
    data.append("cnum", cnum);
    data.append("cadd", cadd);

    data.append("dob", dob);

    data.append("cserial", cserial);
    data.append("passport", pass);
    data.append("visa", visa);
    data.append("ddate", ddate);
    data.append("idate", idate);
    data.append("edate", edate);

    data.append("base", base);
    data.append("gross", gross);
    data.append("vat", vat);
    data.append("profit", profit);

    data.append("note", note);
    data.append("gpnr", gpnr);
    data.append("pedate", pedate);
    data.append("token", localStorage.getItem("token"));

    data.append("user", userData);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/admedical",
        data
      )
      .then((res) => {
        console.log(res.data.message);
        if (res.data.message == null) {
          history.push("/medical");
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Navigation2 />

      <div
        class="modal fade hourmodal"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Time
              </h5>
            </div>
            <div class="modal-body row justify-content-center">
              <div class="col-6">
                <div class=" d-flex justify-content-between">
                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="HH"
                    onChange={(e) => setT1(e.target.value)}
                  ></input>

                  <p
                    class="mx-2 my-1 font-weight-bold"
                    style={{ fontSize: 20 }}
                  >
                    :
                  </p>

                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="MM"
                    onChange={(e) => setT2(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <button type="button" class="btn timeSelect" data-dismiss="modal">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade hourmodal"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Time
              </h5>
            </div>

            <div class="modal-body row justify-content-center">
              <div class="col-6">
                <div class=" d-flex justify-content-between">
                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="HH"
                    onChange={(e) => setD1(e.target.value)}
                  ></input>

                  <p
                    class="mx-2 my-1 font-weight-bold"
                    style={{ fontSize: 20 }}
                  >
                    :
                  </p>

                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="MM"
                    onChange={(e) => setD2(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <button type="button" class="btn timeSelect" data-dismiss="modal">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <div class="container">
        <div
          class="modal fade oldcs"
          id="olduser"
          tabindex="-1"
          data-backdrop="static"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog oldCustDialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Select Old Customer
                </h5>
                <button
                  id="hideit"
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <input
                  class="ocsb"
                  type="text"
                  placeholder="Search customer"
                  onChange={(e) => setFil(e.target.value)}
                />
                {customer
                  .filter((item) => {
                    if (item.cname.toLowerCase().includes(fil.toLowerCase())) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <div
                      class="soc my-2 p-2"
                      onClick={() => {
                        setCname(item.cname);
                        setCnum(item.cnum);
                        setCadd(item.cadd);
                        setCid(item.id);
                        setCserial(item.serial);
                        document.getElementById("hideit").click();
                      }}
                    >
                      <p class="m-0">FD#{item.id}</p>
                      <p class="font-weight-bold m-0">{item.cname}</p>
                      <p class=" m-0">{item.cnum}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div class="addVendor">
          <div class="d-flex adcst col-lg-12 col-12">
            <h3>Add Medical</h3>
            <button
              class="old-customer ml-4 font-weight-bold"
              data-toggle="modal"
              data-target="#olduser"
            >
              + Old Customer
            </button>
          </div>

          <hr />

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-3 col-12 infotext col-form-label"
            >
              Customer Serial
            </label>
            <div class="col-lg-9 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={cserial}
                onChange={(e) => setCserial(e.target.value)}
                disabled={cid != 0 ? true : false}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label for="" class="col-lg-3 col-12 col-form-label infotext">
              Customer Name
            </label>
            <div class="col-lg-9 col-12">
              <input
                id=""
                name=""
                type="text"
                class="form-control"
                required=""
                value={cname}
                onChange={(e) => setCname(e.target.value)}
                disabled={cid != 0 ? true : false}
              />
            </div>
          </div>

          <div class="form-group row py-1">
            <label for="text" class="col-lg-3 col-12 infotext col-form-label">
              Customer Mobile No
            </label>
            <div class="col-lg-4 col-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">+88</div>
                </div>
                <input
                  id="text"
                  name="text"
                  type="text"
                  placeholder="018XXXXXXXX"
                  class="form-control"
                  required=""
                  value={cnum}
                  onChange={(e) => setCnum(e.target.value)}
                  disabled={cid != 0 ? true : false}
                />
              </div>
            </div>

            <label
              for="textarea"
              class="col-lg-2 mt-lg-0 mt-4 col-12 infotext col-form-label"
            >
              Date of Birth
            </label>
            <div class="col-lg-3 col-12">
              <input
                type="date"
                class="form-control"
                required=""
                value={dob}
                onChange={(e) => setDob(e.target.value)}
                disabled={cid != 0 ? true : false}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-3 col-12 infotext col-form-label"
            >
              Customer Address
            </label>
            <div class="col-lg-9 col-12">
              <textarea
                id="textarea"
                name="textarea"
                cols="40"
                rows="2"
                class="form-control"
                required=""
                value={cadd}
                onChange={(e) => setCadd(e.target.value)}
                disabled={cid != 0 ? true : false}
              ></textarea>
            </div>
          </div>

          <hr />

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Customer Photo
            </label>
            <div class="col-lg-4 col-12 me-auto">
              <input
                id="textarea"
                name="text"
                type="file"
                class="form-control"
                required=""
                onChange={userPhoto}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Passport No
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Visa No
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                value={visa}
                onChange={(e) => setVisa(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Issue Date
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={idate}
                onChange={(e) => setIdate(e.target.value)}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Delivery Date
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={ddate}
                onChange={(e) => setDdate(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Expiry date
            </label>
            <div class="col-lg-4 col-6">
              <input
                id="textarea"
                name="text"
                type="date"
                class="form-control"
                required=""
                value={edate}
                onChange={(e) => setEdate(e.target.value)}
              ></input>
            </div>
          </div>

          <i
            class="fa fa-clock-o"
            id="clbtn2"
            data-toggle="modal"
            data-target="#exampleModal2"
            style={{ margin: 2, fontSize: 20, display: "none" }}
          ></i>
          <i
            class="fa fa-clock-o"
            id="clbtn3"
            data-toggle="modal"
            data-target="#exampleModal"
            style={{ margin: 2, fontSize: 20, display: "none" }}
          ></i>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Additional Note
            </label>
            <div class="col-lg-10 col-12">
              <textarea
                id="textarea"
                name="textarea"
                cols="40"
                rows="2"
                class="form-control"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Passport Expiry Date
            </label>
            <div class="col-lg-10 col-12">
              <input
                type="date"
                class="form-control"
                value={pedate}
                onChange={(e) => setPedate(e.target.value)}
              ></input>
            </div>
          </div>

          <hr />

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12  infotext col-form-label"
            >
              Buying Price
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="number"
                class="form-control"
                required=""
                value={base}
                onChange={(e) => setBase(e.target.value)}
              ></input>
            </div>
            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Selling Price
            </label>
            <div class="col-lg-4 col-12">
              <input
                id="textarea"
                name="text"
                type="number"
                class="form-control"
                required=""
                value={gross}
                onChange={(e) => setGross(e.target.value)}
              ></input>
            </div>
          </div>

          <div class="form-group row py-1">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              VAT & AIT (%)
            </label>
            <div class="col-lg-4 col-6">
              <input
                id="textarea"
                name="text"
                type="number"
                class="form-control"
                required=""
                placeholder="EX. 0.03"
                value={vat}
                onChange={(e) => {
                  setVat(e.target.value);
                  var q = gross - base;
                  var p = (gross - base) / 100;
                  var p = p * e.target.value;
                  setVat(e.target.value);
                  setProfit(q - p);
                }}
              ></input>
            </div>

            <label
              for="textarea"
              class="col-lg-2 col-12 marginTop infotext col-form-label"
            >
              Profit
            </label>
            <div class="col-lg-4 col-6">
              <input
                id="textarea"
                name="text"
                type="text"
                class="form-control"
                required=""
                disabled
                value={profit}
                onChange={(e) => setProfit(e.target.value)}
              ></input>
            </div>

            <br />
            <br />
            <br />

            <hr />
          </div>
          <hr />

          <div class="form-group row py-2">
            <div class="col-lg-2"></div>
            <div class="col-lg-10 col-12">
              <button class="w-100 btn btn-primary" onClick={handleSubmit}>
                Add Medical
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admedical;
