import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import uploading from "../../images/uploading.gif";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import Compress from "compress.js";

function Updatepassport(props) {
  var id = props.match.params.id;

  let history = useHistory();

  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [active, setActive] = useState("");
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [flight, setFlight] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [idate, setIdate] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [arr1, setArr1] = useState("");
  const [arr2, setArr2] = useState("");
  const [de1, setDe1] = useState("");
  const [de2, setDe2] = useState("");
  const [show, setShow] = useState(false);

  const [fileData, setFileData] = useState("");
  const [fileData2, setFileData2] = useState("");
  const [fileData3, setFileData3] = useState("");
  const [load, setLoad] = useState(false);

  const [vfileData, setVfileData] = useState("");
  const [vfileData2, setVfileData2] = useState("");
  const [vfileData3, setVfileData3] = useState("");

  const [vfileData4, setVfileData4] = useState("");
  const [vfileData5, setVfileData5] = useState("");

  const [doc1, setDoc1] = useState("");
  const [doc2, setDoc2] = useState("");
  const [doc3, setDoc3] = useState("");

  const [doc4, setDoc4] = useState("");

  const [doc5, setDoc5] = useState("");

  const [edate, setEdate] = useState("");
  const [iodate, setIodate] = useState("");

  const [paddress, setPaddress] = useState("");
  const [peaddress, setPeaddress] = useState("");
  const [bcertificate, setBcertificate] = useState("");
  const [dob, setDob] = useState("");
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [occu, setOccu] = useState("");
  const [ddate, setDdate] = useState("");

  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");

  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");

  const [userData, setUsereData] = useState("");
  const [vendors, setVendors] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [fil, setFil] = useState("");
  const [cid, setCid] = useState("5");
  const [hphoto, setHphoto] = useState("");

  const [i1, setI1] = useState("");
  const [i2, setI2] = useState("");

  const [spouse, setSpouse] = useState("");
  const [snumber, setSnumber] = useState("");
  const [cof, setCof] = useState("");
  const [note, setNote] = useState("");

  const getFile = (e) => {
    setDoc1(e.target.files[0]);
  };

  const getFile2 = (e) => {
    setDoc2(e.target.files[0]);
  };

  const getFile3 = (e) => {
    setDoc3(e.target.files[0]);
  };

  const getFile4 = (e) => {
    setDoc4(e.target.files[0]);
  };

  const getFile5 = (e) => {
    setDoc5(e.target.files[0]);
  };

  const getVfile = (e) => {
    setVfileData(e.target.files[0]);
  };

  const getVfile2 = (e) => {
    setVfileData2(e.target.files[0]);
  };

  const getVfile3 = (e) => {
    setVfileData3(e.target.files[0]);
  };

  const getVfile4 = (e) => {
    setVfileData4(e.target.files[0]);
  };

  const getVfile5 = (e) => {
    setVfileData5(e.target.files[0]);
  };

  const userPhoto = (e) => {
    setUsereData(e.target.files[0]);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        900,
        900,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  const getFilereal = async (e) => {
    setLoad(true);

    const file = e.target.files[0];
    const image = await resizeFile(file);

    const newFile = dataURIToBlob(image);

    const data = new FormData();
    data.append("id", id);
    data.append("foo", newFile);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/imagerealp",
        data
      )
      .then((res) => {
        console.log(res.data.message);

        setDoc1(res.data.message);
        setLoad(false);
        toast.success("Image updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  const getFilereal2 = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    const data = new FormData();
    data.append("id", id);
    data.append("foo", newFile);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/imagerealp2",
        data
      )
      .then((res) => {
        setDoc2(res.data.message);
        toast.success("Image updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  const getFilereal3 = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    const data = new FormData();
    data.append("id", id);
    data.append("foo", newFile);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/imagerealp3",
        data
      )
      .then((res) => {
        setDoc3(res.data.message);
        toast.success("Image updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  const getFilereal4 = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    const data = new FormData();
    data.append("id", id);
    data.append("foo", newFile);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/imagerealp4",
        data
      )
      .then((res) => {
        setDoc4(res.data.message);
        console.log(res.data.message);
        toast.success("Image updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  const getFilereal5 = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    const data = new FormData();
    data.append("id", id);
    data.append("foo", newFile);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/imagerealp5",
        data
      )
      .then((res) => {
        setDoc5(res.data.message);
        toast.success("Image updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprev5",
        {
          id: id,
        }
      )
      .then((res) => {
        setNid(res.data.message[0].nid);

        setPaddress(res.data.message[0].padd);
        setPeaddress(res.data.message[0].peadd);
        setFname(res.data.message[0].fname);
        setMname(res.data.message[0].mname);
        setOccu(res.data.message[0].occu);
        setDdate(res.data.message[0].ddate);

        setDob(res.data.message[0].dob);
        setBcertificate(res.data.message[0].bc);

        setSpouse(res.data.message[0].spouse);

        setBase(res.data.message[0].base);
        setGross(res.data.message[0].gross);
        setVat(res.data.message[0].vat);
        setProfit(res.data.message[0].profit);
        setDoc1(res.data.message[0].doc1);
        setDoc2(res.data.message[0].doc2);
        setDoc3(res.data.message[0].doc3);

        setDoc4(res.data.message[0].doc4);
        setDoc5(res.data.message[0].doc5);

        setSnumber(res.data.message[0].snumber);
        setPaddress(res.data.paddress);
        setPassport(res.data.message[0].passport);

        setNote(res.data.message[0].note);

        setCof(res.data.message[0].cof);
        if (res.data.message[0].aid == 0) {
          setShow(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    const data = new FormData();

    data.append("padd", paddress);
    data.append("peadd", peaddress);
    data.append("fname", fname);
    data.append("bc", bcertificate);
    data.append("nid", nid);
    data.append("occu", occu);
    data.append("ddate", ddate);
    data.append("mname", mname);

    data.append("dob", dob);

    data.append("base", base);
    data.append("gross", gross);
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("passport", passport);
    data.append("spouse", spouse);
    data.append("snumber", snumber);
    data.append("cof", cof);
    data.append("note", note);
    data.append("token", localStorage.getItem("token"));

    data.append("id", id);

    // data.append("foo",fileData);
    // data.append("foo2",fileData2);
    // data.append("foo3",fileData3);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/uppass",
        data
      )
      .then((res) => {
        console.log(res.data.message);
        if (res.data.message == null) {
          toast.success("Customer Added", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });

          history.goBack();
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));
  }, [vendors]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => setCustomer(res.data.message))
      .catch((err) => console.log(err));
  }, [customer]);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div
        class="modal fade hourmodal"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Time
              </h5>
            </div>
            <div class="modal-body row justify-content-center">
              <div class="col-6">
                <div class=" d-flex justify-content-between">
                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="HH"
                    value={t1}
                    onChange={(e) => setT1(e.target.value)}
                  ></input>

                  <p
                    class="mx-2 my-1 font-weight-bold"
                    style={{ fontSize: 20 }}
                  >
                    :
                  </p>

                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="MM"
                    value={t2}
                    onChange={(e) => setT2(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <button type="button" class="btn timeSelect" data-dismiss="modal">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade hourmodal"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Select Time
              </h5>
            </div>

            <div class="modal-body row justify-content-center">
              <div class="col-6">
                <div class=" d-flex justify-content-between">
                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="HH"
                    value={d1}
                    onChange={(e) => setD1(e.target.value)}
                  ></input>

                  <p
                    class="mx-2 my-1 font-weight-bold"
                    style={{ fontSize: 20 }}
                  >
                    :
                  </p>

                  <input
                    style={{ width: "50%" }}
                    type="text"
                    placeholder="MM"
                    value={d2}
                    onChange={(e) => setD2(e.target.value)}
                  ></input>
                </div>
              </div>
            </div>

            <div class="mt-2">
              <button type="button" class="btn timeSelect" data-dismiss="modal">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="addCustomer">
          <div class="d-flex adcst">
            <h3 class="m-0">Update data</h3>
          </div>

          <hr />

          {load ? (
            <div class="bg-light upgifback">
              <center>
                <img src={uploading} class="upgif" alt=".." />
              </center>
            </div>
          ) : null}

          <form onSubmit={handleSubmit} id="create-course-form">
            <div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Passport Slip Number
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={snumber}
                    onChange={(e) => setSnumber(e.target.value)}
                  />
                </div>

                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Enrollment Date
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="date"
                    class="form-control"
                    required=""
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for=""
                  class="col-lg-2 col-12 marginTop infotext col-form-label"
                >
                  Additional Note
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={bcertificate}
                    onChange={(e) => setBcertificate(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Permanent Address
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={peaddress}
                    onChange={(e) => setPeaddress(e.target.value)}
                  />
                </div>

                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Spouse Name
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      value={spouse}
                      onChange={(e) => setSpouse(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Father"s Name
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                  />
                </div>

                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Mother"s Name
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      value={mname}
                      onChange={(e) => setMname(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Occupation
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      value={occu}
                      onChange={(e) => setOccu(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Delivery Date
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="date"
                    class="form-control"
                    required=""
                    value={ddate}
                    onChange={(e) => setDdate(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 infotext col-form-label">
                  Additional Note
                </label>
                <div class="col-lg-10 col-12">
                  <textarea
                    rows="4"
                    cols="40"
                    type="text"
                    class="form-control"
                    required=""
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <hr />
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-4 infotext col-form-label">
                  Buying Price
                </label>
                <div class="col-lg-4 col-8">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={base}
                    onChange={(e) => setBase(e.target.value)}
                  />
                </div>
                <label
                  for=""
                  class="col-lg-2 col-4 marginTop infotext col-form-label"
                >
                  {show ? "Selling Price" : "Agent Fare"}
                </label>
                <div class="col-lg-4 col-8 marginTop">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={gross}
                    onChange={(e) => setGross(e.target.value)}
                  />
                </div>
              </div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-4 infotext col-form-label">
                  VAT & AIT
                </label>
                <div class="col-lg-4 col-8">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={vat}
                    onChange={(e) => {
                      var p =
                        Number(gross) - (Number(base) + Number(e.target.value));
                      setVat(e.target.value);
                      setProfit(p);
                    }}
                  />
                </div>
                <label for="" class="col-lg-2 col-4 infotext col-form-label">
                  Profit
                </label>
                <div class="col-lg-4 col-8 marginTop">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    readOnly
                    value={profit}
                    onChange={(e) => setProfit(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="form-group row py-1">
              <div class="col">
                <div class="d-grid gap-2 text-right">
                  <button class="btn btn-primary w-100">Update</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(Updatepassport);
