import { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddPassport(props) {
  var cid = props.match.params.cid;

  let history = useHistory();

  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [active, setActive] = useState("");
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [cphoto, setCphoto] = useState("");
  const [show, setShow] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [pval, setPval] = useState("");
  const [cserial, setCserial] = useState("");
  const [agents, setAgents] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [note, setNote] = useState("");

  // passport

  const [paddress, setPaddress] = useState("");
  const [peaddress, setPeaddress] = useState("");
  const [bcertificate, setBcertificate] = useState("");
  const [dob, setDob] = useState("");
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [occu, setOccu] = useState("");
  const [ddate, setDdate] = useState("");
  const [ctype, setCtype] = useState("");
  const [aid, setAid] = useState("");
  const [cof, setCof] = useState("");
  const [spouse, setSpouse] = useState("");
  const [snumber, setSnumber] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    const data = new FormData();
    data.append("cid", cid);
    data.append("cname", cname);
    data.append("cnum", cnum);
    data.append("cadd", cadd);
    data.append("passport", passport);
    data.append("cserial", cserial);

    data.append("nid", nid);
    data.append("dob", dob);
    data.append("pval", pval);
    data.append("user", cphoto);

    data.append("padd", paddress);
    data.append("peadd", peaddress);
    data.append("fname", fname);
    data.append("bc", bcertificate);

    data.append("occu", occu);
    data.append("mname", mname);

    data.append("ddate", ddate);

    data.append("base", base);
    data.append("gross", gross);
    data.append("vat", vat);
    data.append("profit", profit);

    data.append("spouse", spouse);
    data.append("note", note);
    data.append("snumber", snumber);
    data.append("ctype", ctype);
    data.append("aid", aid);
    data.append("cof", cof);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/adpass",
        data
      )
      .then((res) => {
        console.log(res.data.message);

        if (res.data.message == null) {
          toast.success("customer Added", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });

          setCname("");
          setCadd("");
          setCname("");
          document.getElementById("create-course-form").reset();
          history.push("/");
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchstaff"
      )
      .then((res) => setStaffs(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomerbyid",
        {
          cid: cid,
        }
      )
      .then((res) => {
        setCname(res.data.suser[0].cname);
        setCadd(res.data.suser[0].cadd);
        setCnum(res.data.suser[0].cnum);
        setDob(res.data.suser[0].dob);
        setNid(res.data.suser[0].nid);
        setPassport(res.data.suser[0].passport);
        setPval(res.data.suser[0].pval);
        setCserial(res.data.suser[0].serial);
        setCphoto(res.data.suser[0].cphoto);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <ToastContainer />

      <div class="container border mb-4 bg-light pt-3">
        <div class="pb-3">
          <form onSubmit={handleSubmit} id="create-course-form">
            <div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Customer Type
                </label>
                <div class="col-lg-4 mt-2">
                  <div class="form-check form-check-inline">
                    <input
                      name="ctype"
                      id="fit"
                      type="radio"
                      class="form-check-input"
                      value="regular"
                      required=""
                      checked={ctype == "regular" ? true : false}
                      onChange={(e) => {
                        setCtype(e.target.value);
                        setShow(false);
                      }}
                    />
                    <label for="_0" class="form-check-label infotext mr-2">
                      Regular
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      name="ctype"
                      id="fit"
                      type="radio"
                      class="form-check-input"
                      value="agent"
                      required="required"
                      checked={ctype == "agent" ? true : false}
                      onChange={(e) => {
                        setCtype(e.target.value);
                        setShow(true);
                      }}
                    />
                    <label for="_0" class="form-check-label infotext mr-2">
                      From Agent
                    </label>
                  </div>
                </div>
              </div>

              {ctype == "agent" ? (
                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Agent Name
                  </label>
                  <div class="col-lg-4 col-12">
                    <div class="input-group">
                      {ctype == "agent" ? (
                        <select
                          class="form-control"
                          value={aid}
                          onChange={(e) => setAid(e.target.value)}
                        >
                          <option>Choose Agent</option>
                          {agents.map((item) => (
                            <option value={item.id}>{item.aname}</option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}

              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Slip Number
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    class="form-control"
                    required=""
                    value={snumber}
                    onChange={(e) => setSnumber(e.target.value)}
                  ></input>
                </div>
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Enrollment date
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="date"
                    class="form-control"
                    required=""
                    value={ddate}
                    onChange={(e) => setDdate(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Father's Name
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                  />
                </div>

                <label
                  for=""
                  class="col-lg-2 col-12 marginTop col-form-label infotext"
                >
                  Mother's Name
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      value={mname}
                      onChange={(e) => setMname(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Spouse Name
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={spouse}
                    onChange={(e) => setSpouse(e.target.value)}
                  />
                </div>

                <label
                  for=""
                  class="col-lg-2 col-12 marginTop col-form-label infotext"
                >
                  Occupation
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      value={occu}
                      onChange={(e) => setOccu(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Present Address
                </label>
                <div class="col-lg-10 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={paddress}
                    onChange={(e) => setPaddress(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Permanent Address
                </label>
                <div class="col-lg-10 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={peaddress}
                    onChange={(e) => setPeaddress(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1 align-items-center">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Birth Certificate
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={bcertificate}
                    onChange={(e) => setBcertificate(e.target.value)}
                  />
                </div>

                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Care Of
                </label>
                <div class="col-lg-4 col-12">
                  <select
                    class="form-control"
                    value={cof}
                    onChange={(e) => setCof(e.target.value)}
                  >
                    <option>Choose Staff</option>
                    {staffs.map((item) => (
                      <option value={item.id}>{item.sname}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Additional Note
                </label>
                <div class="col-lg-10 col-12">
                  <textarea
                    id="textarea"
                    name="textarea"
                    cols="40"
                    rows="2"
                    class="form-control"
                    required=""
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  ></textarea>
                </div>
              </div>

              <hr />
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-4 col-form-label infotext">
                  Buying Price
                </label>
                <div class="col-lg-2 col-8 me-auto">
                  <input
                    id=""
                    name=""
                    type="number"
                    class="form-control"
                    required="required"
                    value={base}
                    onChange={(e) => setBase(e.target.value)}
                  />
                </div>
                <label
                  for=""
                  class="col-lg-2 col-4 marginTop col-form-label infotext"
                >
                  {ctype == "agent" ? "Agent Fare" : "Selling Price"}
                </label>
                <div class="col-lg-2 col-8 marginTop me-auto">
                  <input
                    id=""
                    name=""
                    type="number"
                    class="form-control"
                    required="required"
                    value={gross}
                    onChange={(e) => setGross(e.target.value)}
                  />
                </div>
              </div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-4 col-form-label infotext">
                  VAT & AIT
                </label>
                <div class="col-lg-2 col-8 me-auto">
                  <input
                    id=""
                    name=""
                    type="number"
                    class="form-control"
                    required=""
                    placeholder="Ex. 0.03"
                    value={vat}
                    onChange={(e) => {
                      var p =
                        Number(gross) - (Number(base) + Number(e.target.value));
                      setVat(e.target.value);
                      setProfit(p);
                    }}
                  />
                </div>
                <label
                  for=""
                  class="col-lg-2 col-4 marginTop col-form-label infotext"
                >
                  Profit
                </label>
                <div class="col-lg-2 col-8 marginTop me-auto">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    readOnly
                    value={profit}
                    onChange={(e) => setProfit(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div class="form-group row py-2">
              <div class="col-lg-2"></div>
              <div class="col-lg-10 col-12">
                <button class="w-100 btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(AddPassport);
