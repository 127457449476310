import axios from "axios";
function Delmodal({ acid, acit }) {
  function delticket() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delticket",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }
  function delExpense() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delExpense",
        {
          id: acid,
        }
      )
      .then((res) => {
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }
  function delIncome() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delIncome",
        {
          id: acid,
        }
      )
      .then((res) => {
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }

  function deldomticket() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/deldomticket",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }
  function delhajj() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delhajj",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function delhotticket() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delhotticket",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function delpass() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delpass",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function delman() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delman",
        {
          id: acid,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function delpackages() {
    const data = new FormData();
    data.append("id", acid);
    data.append("token", localStorage.getItem("token"));
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/packages/del",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  return (
    <>
      <div
        class="modal fade deleteModal animate__animated animate__slideInDown"
        id="delmodel"
        tabindex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style={{ top: 20 }} role="document">
          <div class="modal-content datadelM">
            <div class="modal-body">
              <p class="m-0 py-3">
                Do You Want to Delete The
                {acit == "expensesdel" ? " Additional Expenses?" : null}
                {acit == "incomedel" ? " Additional income?" : null}
                {acit == "visa" ? " Customer?" : null}
                {acit == "ticket" ? " Customer?" : null}
                {acit == "hotel" ? " Customer?" : null}
                {acit == "passport" ? " Customer?" : null}
                {acit == "manpower" ? " Customer?" : null}
                {acit == "packages" ? " Customer?" : null}
                {acit == "hajj" ? " Customer?" : null}
              </p>
            </div>
            <div class="modal-footer py-2">
              <button
                type="button"
                class="btn btn-secondary btndlet"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary btnyes"
                onClick={() => {
                  if (acit == "visa") {
                    deldomticket();
                  }
                  if (acit == "ticket") {
                    delticket();
                  }

                  if (acit == "hotel") {
                    delhotticket();
                  }

                  if (acit == "passport") {
                    delpass();
                  }

                  if (acit == "manpower") {
                    delman();
                  }

                  if (acit == "packages") {
                    delpackages();
                  }
                  if (acit == "hajj") {
                    delhajj();
                  }
                  if (acit == "expensesdel") {
                    delExpense();
                  }
                  if (acit == "incomedel") {
                    delIncome();
                  }
                }}
                data-dismiss="modal"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Delmodal;
