import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import searchb from "../../images/search.png";
import axios from "axios";

import Footer from "../footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import B2bpaymodal from "../modals/b2bpaymodal";
import Delb2bmodal from "../modals/delb2bmodal";
import Purchaseticket from "../modals/purchaseticket";
import Refundticket from "../modals/refundticket";

function Avendor(props) {
  var type = props.match.params.type;
  const [alldata, setAlldata] = useState([]);
  const [tdata, setTdata] = useState([]);
  const [search, setSearch] = useState("");
  const [term, setTerm] = useState("");
  const [sdata, setSdata] = useState([]);
  const [total, setTotal] = useState("");
  const [paid, setPaid] = useState(0);

  const [uid, setUid] = useState(0);
  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("cash");
  const [tamount, setTamount] = useState(0);
  const [phistory, setPhistory] = useState([]);
  const [stype, setStype] = useState("vendor");
  const [banks, setBanks] = useState([]);
  const [abank, setAbank] = useState("");
  const [b1, setB1] = useState("");
  const [b2, setB2] = useState("");
  const [b3, setB3] = useState("");
  const [deal, setDeal] = useState(0);
  const [name, setName] = useState("");

  const [brunch, setBrunch] = useState("");
  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [ano, setAno] = useState("");

  const [airplane, setAirplane] = useState("");
  const [fno, setFno] = useState("");
  const [de1, setDe1] = useState("");
  const [de2, setDe2] = useState("");
  const [arr1, setArr1] = useState("");
  const [arr2, setArr2] = useState("");
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [price, setPrice] = useState("");
  const [tserial, setTserial] = useState("");
  const [quan, setQuan] = useState("");

  const [vid, setVid] = useState("");
  const [purchasehis, setPurchasehis] = useState([]);

  const [afetch, setAfetch] = useState("");
  const [bfetch, setBfetch] = useState("");

  const [vname, setVname] = useState("");
  const [serial, setSerial] = useState("");
  const [note, setNote] = useState("");

  const [show, setShow] = useState(true);
  const [b2bmodalactive, setB2bmodalactive] = useState(false);
  const [delet, setDeletid] = useState("");
  const [purchasemodalactive, setParchasemodalactive] = useState(false);
  const [refundmodalactive, setRefundmodalactive] = useState(false);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchavendor"
      )
      .then((res) => {
        setAlldata(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [alldata]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function delvendor(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delvendor",
        {
          id: x,
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function delit() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delpur",
        {
          id: afetch,
        }
      )
      .then((res) => sindata(bfetch))
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function fetchall(x, y) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchall",
        {
          term: x,
          type: y,
        }
      )
      .then((res) => {
        console.log(res.data.message);
        setTotal(res.data.message[0].total);
      })
      .catch((err) => console.log(err));
  }

  function refundfetch2(x, y) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundfetch2",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => {
        setPhistory(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }

  function refundhistory() {
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", "");
    data.append("refundb", "");
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("name", vname);
    data.append("vname", vname);
    data.append("serial", serial);
    data.append("base", "");
    data.append("gross", "");
    data.append("note", note);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundhistory3",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    toast.success("Refund Addedd", {
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      toastId: "success4",
      autoClose: 2000,
    });

    refundfetch2(uid, "vendor");

    document.getElementById("spfrm89").reset();
  }

  function historyfetch(x, y) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorfetch",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => setPhistory(res.data.message))
      .catch((err) => console.log(err));
  }

  function sindata(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/sinpurchase",
        {
          id: x,
        }
      )
      .then((res) => setPurchasehis(res.data.message))
      .catch((err) => console.log(err));
  }

  function delrefund(x, y, z) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delrefund",
        {
          id: x,
          price: y,
          key: z,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    refundfetch2(uid, "vendor");
  }

  document.addEventListener("click", () => {
    setShow(false);
  });

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  return (
    <>
      <ToastContainer />

      <div
        class="modal fade bankModal animate__animated animate__zoomIn"
        id="bankModal1"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="bankModalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content bankmodalCont bg-light">
            <div class="modal-header py-2">
              <h5 class="modal-title" id="bankModalLabel1">
                {bank}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body bankback">
              <div class="row">
                <div class="col">
                  <p class="infotext my-2">
                    Branch Name: <span class="font-weight-bold">{brunch}</span>
                  </p>
                  <p class="infotext my-2">
                    Account Name:{" "}
                    <span class="font-weight-bold">{account}</span>
                  </p>
                  <p class="infotext my-2">
                    Account Number: <span class="font-weight-bold">{ano}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div className="col-lg-2"></div>
          <div class="col-lg-10 col-12 my-4">
            <div class="row search-mb">
              <div class="col-lg-12 col-12 border px-0">
                <div class="border-bottom bg-light mx-0 d-flex align-items-center">
                  <h4 class="p-2 mb-0">Airlines Vendor</h4>
                  <Link to="/addavendor">
                    <div class="btn btn-darkg mx-3">
                      <div class="m-0">Add New</div>
                    </div>
                  </Link>
                </div>

                <div class="row mx-auto bg-light p-2 border-bottom">
                  <div class="d-flex form-group has-search col-lg-12 col-12 m-0 p-0">
                    <img src={searchb} class="sChooseIcon2" alt="..." />
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control searchbox"
                      required="required"
                      placeholder="Search Vendor"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                </div>

                {alldata
                  .filter((item) => {
                    if (
                      item.vname.toLowerCase().includes(search.toLowerCase()) ||
                      item.vnum.toLowerCase().includes(search.toLowerCase()) ||
                      item.vtype
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <div class="card border-0 rounded-0">
                      <div class="card-body p-0 m-0">
                        <div class="row m-0 p-0 py-2 border-bottom border-top">
                          <div class="col-lg-4 col-12 pl-3 p-1 my-auto">
                            <p class="font-weight-bold c_name m-0">
                              {item.vname}
                            </p>
                            <p class="m-0 font-weight-bold cusData">
                              {item.nvum}
                            </p>
                            <p class="m-0 cusData" row="2">
                              {item.vadd}
                            </p>
                            <p class="m-0">
                              Type:{" "}
                              <span
                                class="font-weight-bold"
                                style={{ textTransform: "uppercase" }}
                              >
                                {item.type}
                              </span>
                            </p>
                            <p class="m-0">
                              Email:{" "}
                              <span
                                class="font-weight-bold"
                                style={{ textTransform: "uppercase" }}
                              >
                                {item.vemail}
                              </span>
                            </p>
                          </div>

                          <div class="col-lg-2 col-6 borderBoths my-auto">
                            {item.bank1 != "" ? (
                              <div
                                onClick={() => {
                                  setBrunch(item.brunch1);
                                  setBank(item.bank1);
                                  setAccount(item.account1);
                                  setAno(item.ano1);
                                }}
                                class="btn bankinfo_bttn w-100 my-1 w-100"
                                data-toggle="modal"
                                data-target="#bankModal1"
                              >
                                Bank Info: 1
                              </div>
                            ) : null}

                            {item.bank2 != "" ? (
                              <div
                                onClick={() => {
                                  setBrunch(item.brunch2);
                                  setBank(item.bank2);
                                  setAccount(item.account2);
                                  setAno(item.ano2);
                                }}
                                class="btn bankinfo_bttn w-100 my-1 w-100"
                                data-toggle="modal"
                                data-target="#bankModal1"
                              >
                                Bank Info: 2
                              </div>
                            ) : null}

                            {item.bank3 != "" ? (
                              <div
                                onClick={() => {
                                  setBrunch(item.brunch3);
                                  setBank(item.bank3);
                                  setAccount(item.account3);
                                  setAno(item.ano3);
                                }}
                                class="btn bankinfo_bttn w-100 my-1 w-100"
                                data-toggle="modal"
                                data-target="#bankModal1"
                              >
                                Bank Info: 3
                              </div>
                            ) : null}

                            {item.bank4 != "" ? (
                              <div
                                onClick={() => {
                                  setBrunch(item.brunch4);
                                  setBank(item.bank4);
                                  setAccount(item.account4);
                                  setAno(item.ano4);
                                }}
                                class="btn bankinfo_bttn w-100 my-1 w-100"
                                data-toggle="modal"
                                data-target="#bankModal1"
                              >
                                Bank Info: 4
                              </div>
                            ) : null}

                            {item.bank5 != "" ? (
                              <div
                                onClick={() => {
                                  setBrunch(item.brunch5);
                                  setBank(item.bank5);
                                  setAccount(item.account5);
                                  setAno(item.ano5);
                                }}
                                class="btn bankinfo_bttn w-100 my-1 w-100"
                                data-toggle="modal"
                                data-target="#bankModal1"
                              >
                                Bank Info: 5
                              </div>
                            ) : null}
                          </div>

                          <div class="col-lg-3 col-6 my-auto">
                            <div class="btn adddeal w-100 my-1">
                              Total Deal: <span>{item.deal}</span>
                            </div>
                            <div
                              onClick={() => {
                                setUid(item.id);
                                fetchall(item.id, item.type);
                                setPaid(item.paid);
                                setB1(item.bank1);
                                setB2(item.bank2);
                                setB3(item.bank3);
                                setName(item.vname);
                                historyfetch(item.id, "vendor");

                                sindata(item.id);
                                setParchasemodalactive(true);
                              }}
                              class="btn ptbttn w-100 my-1"
                              type="button"
                              // data-toggle="modal"
                              // data-target="#pTicketstatus"
                            >
                              Purchase Ticket
                            </div>

                            <div
                              onClick={() => {
                                setVname(item.vname);

                                setUid(item.id);
                                setStype("vendor");

                                sindata(item.id);
                                setSerial(item.serial);
                                refundfetch2(item.id, "vendor");
                                setRefundmodalactive(true);
                              }}
                              class="btn ptrbttn w-100 my-1"
                              type="button"
                              data-toggle="modal"
                            >
                              Refund Ticket
                            </div>
                          </div>

                          <div class="col-lg-3 col-6 my-auto">
                            <div
                              onClick={() => {
                                setUid(item.id);
                                fetchall(item.id, item.type);
                                setPaid(item.paid);
                                setB1(item.bank1);
                                setB2(item.bank2);
                                setB3(item.bank3);
                                setName(item.vname);
                                historyfetch(item.id, "vendor");
                                setB2bmodalactive(true);
                              }}
                              class="btn statusbttn w-100 my-1"
                              type="button"
                              data-toggle="modal"
                              // data-target="#Vpaymentstatus"
                            >
                              Payment Status
                            </div>

                            {/* <Link to={"/updateve/"+item.id} class="btn upbttn w-100 my-1">
                              Update Info</Link> */}

                            <div class="dropdown show">
                              <a
                                class="btn upbttn w-100 my-1"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Edit Vendor
                              </a>

                              <div
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <Link
                                  to={"/updateve/" + item.id}
                                  class="dropdown-item"
                                  href="#"
                                >
                                  Update Info
                                </Link>
                                <div class="dropdown-divider"></div>
                                <button
                                  class="dropdown-item"
                                  data-toggle="modal"
                                  data-target="#dellog"
                                  onClick={() => setDeletid(item.id)}
                                >
                                  Delete Vendor
                                </button>
                              </div>
                            </div>

                            <Link
                              to={"/airtran/" + item.id}
                              class="btn ptbttn w-100 my-1"
                              type="button"
                              data-toggle="modal"
                              data-target="#pTicketstatus3"
                            >
                              Transaction Report
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <B2bpaymodal
        uid={uid}
        paid={paid}
        setPaid={setPaid}
        name={name}
        stype={stype}
        active={b2bmodalactive}
        close={setB2bmodalactive}
        b1={b1}
        b2={b2}
        b3={b3}
      />

      <Purchaseticket
        name={name}
        uid={uid}
        active={purchasemodalactive}
        close={setParchasemodalactive}
      />

      <Refundticket
        active={refundmodalactive}
        name={name}
        uid={uid}
        stype={stype}
        close={setRefundmodalactive}
      />

      <div
        class="modal fade tPurch_status"
        data-keyboard="false"
        data-backdrop="static"
        id="pTicketstatus3"
        tabindex="1"
        role="dialog"
        aria-labelledby="pTicketModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog tPurchDialog" role="document">
          <div class="modal-content">
            <div class="mHeader">
              <button
                type="button"
                style={{ marginRight: 10, marginTop: 10 }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <p class="modal-title pModalheadear" id="pTicketModalLabel">
                Vendor Transaction
              </p>
              <div class="row ml-3 mb-2">
                <div class="col-lg-3 col-4 fontTypA">
                  <h5 class="mb-2">Vendor Name</h5>
                </div>
                <div class="col-lg-9 col-8">
                  <h5 class="mb-2">{name}</h5>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 px-5">
                <p class="font-weight-bold plmb">Transaction Report</p>
                <div class="ptl">
                  <table class="table ">
                    <thead class="ptlT text-center">
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Service</th>
                        <th scope="col">Payable</th>
                        <th scope="col">Paid</th>
                        <th scope="col">Due</th>
                      </tr>
                    </thead>
                    <tbody class="text-center">
                      {phistory.map((item) => (
                        <tr>
                          <td>{formatDate(item.date)}</td>
                          <td>{item.serial}</td>
                          <td>{item.note}</td>
                          <td>{item.type}</td>
                          <td>{item.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Delb2bmodal did={delet} stype={stype} />

      <Footer />
    </>
  );
}

export default withRouter(Avendor);
