import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
// import Pdf from "react-to-pdf";
import React from "react";
import Paid from "../../images/paids.svg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Printhead from "../common/printhead2";

function PackaeInvoice({ id }) {
  let history = useHistory();

  const ref = React.createRef();

  const [phistory, setPhistory] = useState([]);
  const [date, setDate] = useState("");
  const [paidseal, setPaidseal] = useState(false);
  const [aid, setAid] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [alldata, setAlldata] = useState([]);

  const options = {
    orientation: "potrait",
    unit: "in",
    format: [8, 12],
  };

  function total() {
    var total = 0;
    alldata.map((item) => {
      total = total + item.gross;
    });

    return total;
  }

  useEffect(() => {
    var data = new FormData();
    data.append("id", id);
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/packages/fetchpackagesById",
        data
      )
      .then((res) => {
        setAlldata(res.data.message);
      })
      .catch((err) => console.log(err));

    var data = new FormData();
    data.append("packageid", id);
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/packages/fetchServices",
        data
      )
      .then((res) => {
        setServiceData(res.data.message);
      })
      .catch((err) => console.log(err));

    var data = new FormData();
    data.append("tid", id);
    data.append("stype", "packages");
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/historyfetch",
        data
      )
      .then((res) => {
        setPhistory(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const [role, setRole] = useState(false);
  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setRole(res.data.message[0].name);
      })
      .catch((err) => console.log(err));
  }, [localStorage.getItem("token")]);

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  return (
    <div className="print-hide">
      <div className="container p-0" style={{ position: "relative" }}>
        <i
          className="fa fa-close noprint"
          onClick={() => history.goBack()}
          style={{
            fontSize: 40,
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
          }}
        ></i>

        <br />
        <br />
        <div ref={ref}>
          <Printhead aid={aid} id={id} date={date} />

          <h4 class="my-2 mt-5 font-weight-bold">Service Details</h4>
          <table class="table text-center table-bordered border-dark">
            <thead>
              <tr style={{ fontSize: 20 }}>
                <th width="10%" scope="col">
                  ID
                </th>
                <th width="25%" scope="col">
                  Service Type
                </th>
                <th width="50%" scope="col">
                  Service details
                </th>
                <th width="15%" scope="col">
                  Ammount
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ fontSize: 20 }}>
                <th>FDI{id}</th>
                <td style={{ textTransform: "uppercase" }}>Packages</td>
                <td>
                  <div class="row m-1 text-left">
                    {serviceData.map((item) => (
                      <>
                        {item.service.toLocaleLowerCase().includes("ticket") ? (
                          <div className="card border bg-light rounded-0 mb-3">
                            <p class="border-bottom px-3 py-2 mb-0">
                              <b>Service :</b>
                              <span class="text-uppercase">
                                {" "}
                                {item.service}
                              </span>{" "}
                            </p>
                            <div class="d-flex px-3 py-2 bg-white">
                              <p class="mb-0">
                                <b>Airplane :</b> {item.airplane}
                              </p>
                              <p class="mb-0 mx-5">
                                <b>PNR :</b> {item.pnr}
                              </p>
                              <p class="mb-0">
                                <b>Ticket Serial :</b> {item.tserial}
                              </p>
                            </div>
                          </div>
                        ) : item.service
                            .toLocaleLowerCase()
                            .includes("visa") ? (
                          <div className="card border bg-light rounded-0 mb-3">
                            <p class="border-bottom px-3 py-2 mb-0">
                              <b>Service :</b>
                              <span class="text-uppercase">
                                {" "}
                                {item.service}
                              </span>{" "}
                            </p>
                            <div class="d-flex px-3 py-2 bg-white">
                              <p class="mb-0">
                                <b>Country :</b> {item.country}
                              </p>
                              <p class="mb-0 mx-5">
                                <b>Visa Type :</b> {item.vtype}
                              </p>
                              <p class="mb-0">
                                <b>Stamping Date :</b> {item.sdate}{" "}
                              </p>
                            </div>
                          </div>
                        ) : item.service
                            .toLocaleLowerCase()
                            .includes("hotel") ? (
                          <div className="card border bg-light rounded-0 mb-3">
                            <p class="border-bottom px-3 py-2 mb-0">
                              <b>Service :</b>
                              <span class="text-uppercase">
                                {" "}
                                {item.service}
                              </span>{" "}
                            </p>
                            <div class="d-flex px-3 py-2 bg-white">
                              <p class="mb-0">
                                <b>Country :</b> {item.country}
                              </p>
                              <p class="mb-0 mx-5">
                                <b>Hotel Name :</b> {item.hname}{" "}
                              </p>
                              <p class="mb-0">
                                <b>Check-In :</b> {item.cin}
                              </p>
                            </div>
                          </div>
                        ) : item.service
                            .toLocaleLowerCase()
                            .includes("medical") ? (
                          <div className="card border bg-light rounded-0 mb-3">
                            <p class="border-bottom px-3 py-2 mb-0">
                              <b>Service :</b>
                              <span class="text-uppercase">
                                {" "}
                                {item.service}
                              </span>{" "}
                            </p>
                            <div class="d-flex px-3 py-2 bg-white">
                              <p class="mb-0">
                                <b>Medical Center Name :</b> {item.mcenter}{" "}
                              </p>
                              <p class="mb-0 mx-5">
                                <b>Delivery Date :</b> {item.ddate}
                              </p>
                            </div>
                          </div>
                        ) : item.service
                            .toLocaleLowerCase()
                            .includes("manpower") ? (
                          <div className="card border bg-light rounded-0 mb-3">
                            <p class="border-bottom px-3 py-2 mb-0">
                              <b>Service :</b>
                              <span class="text-uppercase">
                                {" "}
                                {item.service}
                              </span>{" "}
                            </p>
                            <div class="d-flex px-3 py-2 bg-white">
                              <p class="mb-0">
                                <b>Country :</b> {item.country}{" "}
                              </p>
                              <p class="mb-0 mx-5">
                                <b>BMET Number :</b> {item.bmnumber}
                              </p>
                              <p class="mb-0">
                                <b>Delivery Date :</b> {item.ddate}
                              </p>
                            </div>
                          </div>
                        ) : item.service
                            .toLocaleLowerCase()
                            .includes("passport") ? (
                          <div className="card border bg-light rounded-0 mb-3">
                            <p class="border-bottom px-3 py-2 mb-0">
                              <b>Service :</b>
                              <span class="text-uppercase">
                                {" "}
                                {item.service}
                              </span>{" "}
                            </p>
                            <div class="d-flex px-3 py-2 bg-white">
                              <p class="mb-0">
                                <b>Slip Number :</b> {item.snumber}{" "}
                              </p>
                              <p class="mb-0 mx-5">
                                <b>Enrollment Date :</b> {item.enroll}
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </>
                    ))}
                  </div>
                </td>
                <td>
                  <p
                    class="m-0 col-12"
                    style={{ borderBottom: "1px solid grey" }}
                  >
                    {alldata.map((item) => (
                      <>{item.gross}</>
                    ))}{" "}
                    BDT
                  </p>
                </td>
              </tr>

              {alldata.map((item) => (
                <>
                  <tr style={{ fontSize: 20 }}>
                    <td colspan="3" class="text-right py-1">
                      <strong>Subtotal</strong>{" "}
                    </td>
                    <td class="py-1">
                      {" "}
                      {item.gross} <span>BDT</span>
                    </td>
                  </tr>

                  <tr style={{ fontSize: 20 }}>
                    <td colspan="3" class="text-right py-1">
                      <strong>Paid</strong>
                    </td>
                    <td class="py-1">
                      {" "}
                      {item.paid} <span>BDT</span>
                    </td>
                  </tr>
                  <tr style={{ fontSize: 20 }}>
                    <td colspan="3" class="text-right py-1">
                      <strong>Due</strong>
                    </td>
                    <td class="py-1">
                      {Number(item.gross) - Number(item.paid)} <span>BDT</span>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

          <div class="border border-dark my-4">
            <div class="border-bottom border-dark">
              <p class="mx-3 m-0 font-weight-bold" style={{ fontSize: 20 }}>
                Payment History
              </p>
            </div>
            {phistory.map((item) => (
              <p class="mx-3 m-0" style={{ fontSize: 18 }}>
                {formatDate(item.date)} {item.type} -{" "}
                <span>
                  {item.amount} BDT{" "}
                  {item.type == "bank" ? "(" + item.bank + ")" : null}
                </span>
              </p>
            ))}
          </div>

          <p>* VAT & AIT Included</p>
          <center>{paidseal ? <img src={Paid} width="200" /> : null}</center>
        </div>
      </div>
      <footer class="footer" style={{ position: "fixed", bottom: 0 }}>
        <div class="d-flex" style={{ marginLeft: 50, marginRight: 50 }}>
          <p class="border-top border-dark border-t">
            <span class="">Accounts</span>
          </p>

          <p
            class="border-top  border-dark border-t"
            style={{ marginLeft: 250, marginRight: 250 }}
          >
            <span class="">Manager</span>
          </p>

          <p class="border-top border-dark border-t">
            <span class="">Proprietor</span>
          </p>
        </div>
        <div class="dflex" style={{ marginLeft: 50, marginRight: 50 }}>
          <p style={{ fontSize: 14, fontWeight: "bold" }}>
            Developed by mPair Technologies Ltd.
          </p>
          <p
            style={{
              fontSize: 14,
              fontWeight: "bold",
              position: "absolute",
              right: 0,
            }}
          >
            Generated by {role}
          </p>
        </div>
      </footer>
    </div>
  );
}

export default withRouter(PackaeInvoice);
