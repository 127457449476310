import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Navigation2 from "../navigation2";
import Footer from "../footer";
import Logo from "../../images/flydoc.svg";
import axios from "axios";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Pdfooter from "../pdfooter";

function Phistory() {
  const [cin, setCin] = useState([]);
  const [cout, setCout] = useState([]);
  const [expense, setExpense] = useState([]);
  const [refund, setRefund] = useState([]);

  const [bin, setBin] = useState([]);
  const [bout, setBout] = useState([]);
  const [bexpense, setBexpense] = useState([]);
  const [brefund, setBrefund] = useState([]);
  const [payment, setPayment] = useState([]);
  const [activeId, setActiveId] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchpayment"
      )
      .then((res) => setPayment(res.data.message))
      .catch((err) => console.log(err));
  }, [payment]);

  function delpay(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delpayment",
        {
          id: x,
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function printme() {
    window.print();
  }

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  return (
    <>
      <Navigation2 />

      <div class="container p-0 py-4 mb-5 pb-3">
        <div class="col-lg-12 col-12 py-1">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col-lg-6 col-12">
                <h5 class="mt-1">All Payment History</h5>
              </div>
            </div>
          </div>

          <div class="card-body my-2 tableBody ">
            <table class="table table-striped" id="table-to-xls9">
              <thead class="bg-light">
                <tr>
                  <th>Date</th>
                  <th>Payment To</th>
                  <th>Payment Type</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {payment.map((item) => (
                  <tr>
                    <td>{formatDate(item.date)}</td>
                    <td>
                      {item.name} ({item.stype})
                    </td>
                    <td>{item.type}</td>
                    <td>{item.amount}</td>
                    <td>
                      <button
                        data-toggle="modal"
                        data-target="#delmodel"
                        class="tdelbtn"
                        onClick={() => {
                          setActiveId(item.id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div
          class="modal fade datadel"
          id="delmodel"
          tabindex="-1"
          data-backdrop="static"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content datadelM">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Delete Payhistory
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Do you want to delete this data ?</p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => {
                    delpay(activeId);
                  }}
                  data-dismiss="modal"
                >
                  Yes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Phistory;
