import { useEffect } from "react";
import { useState } from "react";
import {
  useHistory,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import Navigation2 from "../navigation2";
import axios from "axios";
import Loading from "../common/loading";

import Packages from "../components/packages";

function Addpackagesnext(props) {
  var id = props.match.params.id;
  let history = useHistory();
  const [aid, setAid] = useState("");

  const [lastid, setLastid] = useState(0);

  const [ctype, setCtype] = useState("");

  const [gross, setGross] = useState("");

  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [update, setUpdate] = useState(0);
  const [load, setLoad] = useState(false);

  const [packages, setPackages] = useState([]);

  const [steps, setSteps] = useState(1);

  const [cname, setCname] = useState("");
  const [passport, setPassport] = useState("");

  const [des, setDes] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/packages/fetchlastId"
      )
      .then((res) => {
        setLastid(res.data.message);
        setUpdate(update + 1);
      })
      .catch((err) => console.log(err));

    const data = new FormData();
    data.append("cid", id);
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomerbyid",
        data
      )
      .then((res) => {
        setCname(res.data.suser[0].cname);
        setPassport(res.data.suser[0].passport);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const data = new FormData();
    data.append("packageid", lastid);
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/packages/fetchServices",
        data
      )
      .then((res) => setPackages(res.data.message))
      .catch((err) => console.log(err));
  }, [update]);

  function getTotalbase() {
    var total = 0;
    packages.map((item) => {
      total = Number(total) + Number(item.base);
    });

    return total;
  }

  function makeSave() {
    setLoad(true);
    const data = new FormData();
    data.append("cid", id);
    data.append("ctype", ctype);
    data.append("aid", aid);
    data.append("base", getTotalbase());
    data.append("gross", gross);
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("des", des);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/packages/addPackages",
        data
      )
      .then((res) => {
        console.log(res.data.message);
        console.log("hello");
        setLoad(false);
        history.push("/customer/packages");
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Navigation2 />

      <Loading load={load} />

      <div class="container">
        <div class="addCustomer mb-5 pb-3">
          <div class="col-lg-12 col-12">
            <div class="border bg-light mb-3">
              <h5 class="m-0 border-bottom px-3 py-2 mb-3">Customer Info</h5>
              <div class="row px-3 ">
                <label for="" class="col-lg-2 col-12 infotext">
                  Customer Serial
                </label>
                <div class="col-lg-4 col-12">
                  <p>: {id}</p>
                </div>
              </div>

              <div class="row px-3">
                <label for="" class="col-lg-2 col-12 infotext">
                  Customer Name
                </label>
                <div class="col-lg-4 col-12">
                  <p>: {cname}</p>
                </div>

                <label for="" class="col-lg-2 col-12 infotext">
                  Passport Number
                </label>
                <div class="col-lg-4 col-12">
                  <p>: {passport}</p>
                </div>
              </div>
            </div>

            {steps == 1 ? (
              <>
                <Packages
                  steps={steps}
                  setSteps={setSteps}
                  id={id}
                  packages={packages}
                  update={update}
                  setUpdate={setUpdate}
                  lastid={lastid}
                  ctype={ctype}
                  setCtype={setCtype}
                  setAid={setAid}
                  aid={aid}
                  des={des}
                  setDes={setDes}
                />
              </>
            ) : null}

            {steps == 2 ? (
              <>
                <div class="border bg-light mb-3 p-3">
                  <div class="form-group row py-1">
                    <label
                      for="textarea"
                      class="col-lg-2 col-12  infotext col-form-label"
                    >
                      Buying Price
                    </label>
                    <div class="col-lg-4 col-12">
                      <input
                        id="textarea"
                        name="text"
                        type="number"
                        class="form-control"
                        required=""
                        value={getTotalbase()}
                        disabled
                      ></input>
                    </div>
                    <label
                      for="textarea"
                      class="col-lg-2 col-12 marginTop infotext col-form-label"
                    >
                      Selling Price
                    </label>
                    <div class="col-lg-4 col-12">
                      <input
                        id="textarea"
                        name="text"
                        type="number"
                        class="form-control"
                        required=""
                        value={gross}
                        onChange={(e) => setGross(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div class="form-group row py-1">
                    <label
                      for="textarea"
                      class="col-lg-2 col-12 infotext col-form-label"
                    >
                      VAT & AIT
                    </label>
                    <div class="col-lg-4 col-6">
                      <input
                        id="textarea"
                        name="text"
                        type="number"
                        class="form-control"
                        required=""
                        placeholder=""
                        value={vat}
                        onChange={(e) => {
                          var p =
                            Number(gross) -
                            (Number(getTotalbase()) + Number(e.target.value));
                          setVat(e.target.value);
                          setProfit(p);
                        }}
                      ></input>
                    </div>

                    <label
                      for="textarea"
                      class="col-lg-2 col-12 marginTop infotext col-form-label"
                    >
                      Profit
                    </label>
                    <div class="col-lg-4 col-6">
                      <input
                        id="textarea"
                        name="text"
                        type="text"
                        class="form-control"
                        required=""
                        disabled
                        value={profit}
                        onChange={(e) => setProfit(e.target.value)}
                      ></input>
                    </div>
                  </div>

                  <div class="form-group row py-2">
                    <div class="col-lg-2"></div>
                    <div class="col-lg-10 col-12">
                      <button
                        class="w-100 btn btn-primary"
                        onClick={() => {
                          makeSave();
                        }}
                      >
                        Add Service
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(Addpackagesnext);
