import { useEffect, useState } from "react";
import Navigation2 from "../navigation2";
import axios from "axios";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import download from "../../images/download.png";
import reissue from "../../images/reissue.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Ginvoice from "../common/invoice";

function Sgticket(props) {
  var id = props.match.params.id;

  const [alldata, setAlldata] = useState([]);
  const [clist, setClist] = useState([]);
  const [allven, setAllven] = useState([]);
  const [agent, setAgent] = useState("");
  const [upay, setUpay] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [paid, setPaid] = useState("");
  const [uid, setUid] = useState("");
  const [stype, setStype] = useState("");
  const [refundb, setRefundb] = useState("");
  const [vname, setVname] = useState("");
  const [serial, setSerial] = useState("");
  const [phistory, setPhistory] = useState([]);
  const [snote, setSnote] = useState("");
  const [samount, setSamount] = useState("");
  const [amount, setAmount] = useState("");
  const [re, setRe] = useState("");
  const [tamount, setTamount] = useState("");
  const [ptype, setPtype] = useState("");
  const [hbank, setHbank] = useState("");
  const [uname, setUname] = useState("");
  const [view, setView] = useState(false);
  const [note, setNote] = useState("");
  const [banks, setBanks] = useState([]);
  const [agents, setAgents] = useState([]);
  const [invoiceId, setInvoiceid] = useState("");
  const [invoiceservice, setInvoiceservice] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/gticketinvoice",
        {
          id: id,
        }
      )
      .then((res) => {
        setAlldata(res.data.message);

        setClist(res.data.customer);
      })
      .catch((err) => console.log(err));
  }, []);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }

  function refundfetch(x, y) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundfetch",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => {
        setPhistory(res.data.message);
        setSnote(res.data.message[0].note);
        setSamount(res.data.message[0].amount);
      })
      .catch((err) => console.log(err));
  }

  function refundhistory() {
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    document.getElementById("spfrm2").reset();

    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", tamount);
    data.append("refundb", re);
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("name", uname);
    data.append("vname", vname);
    data.append("serial", serial);
    data.append("base", base);
    data.append("gross", gross);
    data.append("note", note);
    data.append("gid", id);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/grefundhistory",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    toast.success("Refund Addedd", {
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      toastId: "success4",
      autoClose: 2000,
    });

    setPaid(tamount);
    setRefundb(re);
    refundfetch(uid, stype);

    setHbank("");
    setAmount("");
    setTamount(0);
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorfetch3"
      )
      .then((res) => {
        setAllven(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  return (
    <>
      <Navigation2 />

      <Ginvoice id={invoiceId} service={invoiceservice} />

      <div class="container pb-2 noprint">
        <br />
        <br />

        <div className="card p-3">
          <h4>Service Details</h4>
          {alldata.map((item) => (
            <div className="row">
              <div className="col-lg-6 p-6">
                <p class="m-2 cusData">
                  Vendor :{" "}
                  <span class="font-weight-bold">
                    {allven.map((y) => (
                      <>{y.id == item.vendor ? y.vname : null}</>
                    ))}
                  </span>
                </p>
                <p class="m-2 cusData">
                  Serial/PNR : <span class="font-weight-bold">{item.pnr}</span>
                </p>
                <p class="m-2 cusData">
                  Airlines :{" "}
                  <span class="font-weight-bold">{item.airline}</span>
                </p>
              </div>

              <div className="col-lg-6">
                <p class="m-2 cusData">
                  Issue Date :{" "}
                  <span class="font-weight-bold">{item.idate}</span>
                </p>
                <p class="m-2 cusData">
                  Departure :{" "}
                  <span class="font-weight-bold">{item.departure}</span>
                </p>
                <p class="m-2 cusData">
                  Arrival : <span class="font-weight-bold">{item.arrival}</span>
                </p>
              </div>
            </div>
          ))}

          <hr />
          <h5>Customer Details</h5>
          {clist
            .filter((item) => {
              if (item.gid == id) {
                return item;
              }
            })
            .map((item) => (
              <div
                className="row border-bottom-1 py-2"
                style={{ borderBottom: "1px solid #ddd" }}
              >
                <div className="col-lg-3 ">
                  <p class="m-2 cusData">
                    Name : <span class="font-weight-bold">{item.cname}</span>
                  </p>
                </div>

                <div class="col-lg-3 ">
                  <p class="m-2 cusData">
                    Buing Price :{" "}
                    <span class="font-weight-bold">{item.base} BDT</span>
                  </p>
                </div>
                <div class="col-lg-3">
                  <p class="m-2 cusData">
                    Selling Price :{" "}
                    <span class="font-weight-bold">{item.gross} BDT</span>
                  </p>
                </div>

                <div className="col-lg-3 text-center">
                  {item.done == 1 ? (
                    <button
                      class="btn btn-primary w-100"
                      data-toggle="modal"
                      data-target="#refundnote"
                      onClick={() => {
                        refundfetch(item.id, "gticket");
                      }}
                      className="btn btn-primary"
                    >
                      Refunded
                    </button>
                  ) : (
                    <div class="">
                      {item.ctype == "agent" ? (
                        <button
                          class="btn sStatusI text-white"
                          type="button"
                          data-toggle="modal"
                          data-target="#agentrefund"
                          alt="..."
                          onClick={() => {
                            setAgent(item.aid);
                            setUname(item.cname);
                            setUpay(item.gross);
                            setBase(item.base);

                            setGross(item.gross);

                            setPaid(item.paid);
                            setUid(item.id);
                            setStype("gticket");
                            setRefundb(item.refund);
                            setVname(item.vendor);
                            setSerial(item.serial);
                            refundfetch(item.id, "gticket");
                          }}
                        >
                          Refund
                        </button>
                      ) : (
                        <button
                          class="btn sStatusI text-white"
                          type="button"
                          data-toggle="modal"
                          data-target="#Cpaymentstatus2"
                          alt="..."
                          onClick={() => {
                            setAgent(item.aid);
                            setUname(item.cname);
                            setUpay(item.gross);
                            setBase(item.base);

                            setGross(item.gross);

                            setPaid(item.paid);
                            setUid(item.id);
                            setStype("gticket");
                            setRefundb(item.refund);
                            setVname(item.vendor);
                            setSerial(item.serial);
                            refundfetch(item.id, "gticekt");
                          }}
                        >
                          Refund
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ))}
          <div class="d-flex align-items-center my-3">
            <div>
              <p class="m-0 mr-5">Download</p>
            </div>
            <div class="text-center my-1">
              <button
                onClick={() => {
                  setInvoiceid(id);
                  setInvoiceservice("gticket");

                  setTimeout(() => {
                    window.print();
                  }, 100);
                }}
                class="btn text-white sStatusI  d-flex justify-content-center mt-1 px-4 py-2"
              >
                <p className="mb-0">
                  {alldata.map((item) => (
                    <>{item.ctype == "agent" ? "Bill" : "Invoice"}</>
                  ))}
                </p>
                <img
                  src={download}
                  class="staticonI ml-3"
                  style={{ cursor: "pointer" }}
                  alt="..."
                />
              </button>
            </div>
          </div>
        </div>

        <br />
      </div>

      <div className="noprint">
        <div
          class="modal fade cpayment_status"
          data-keyboard="false"
          data-backdrop="static"
          id="agentrefund"
          tabindex="1"
          role="dialog"
          aria-labelledby="CpaymentModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog cPaymentDialog" role="document">
            <div class="modal-content">
              <div class="mHeader">
                <p class="modal-title pModalheadear" id="CpaymentModalLabel">
                  Refund Payment Status
                </p>
              </div>
              <div class="pModalbody">
                <div class="row">
                  <div class="col-4 fontTypA">
                    <p class="mb-2">Customer Name</p>
                  </div>
                  <div class="col-8">
                    <p class="mb-2 font-weight-bold">{uname}</p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-4 fontTypA">
                    <p class="mb-2">Agent Name</p>
                  </div>
                  <div class="col-8">
                    <p class="mb-2 font-weight-bold">{getagent(agent)}</p>
                  </div>
                </div>
              </div>

              <p class="font-weight-bold pModalbody">Refund Payment</p>
              <div class="row justify-content-center">
                <ul
                  class="nav nav-pills text-center p-0 col-lg-8"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item w-50">
                    <a
                      class="nav-link active"
                      onClick={() => {
                        setView("cash");
                        setPtype("cash");
                      }}
                      id="pills-home-tab"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Cash
                    </a>
                  </li>
                  <li class="nav-item w-50">
                    <a
                      class="nav-link"
                      onClick={() => {
                        setPtype("bank");
                        setView("bank");
                      }}
                      id="pills-profile-tab"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Bank
                    </a>
                  </li>
                </ul>

                <form
                  class="mb-4 mt-2 col-lg-8"
                  id="spfrm2"
                  style={{ borderTop: "1px solid #bababa" }}
                >
                  {view == "cash" ? (
                    <div class="row align-items-center justify-content-center my-4">
                      <div class="col-4 form-check form-check-inline checkboxSpread">
                        <label for="_0" class="form-check-label fontTypA">
                          Refund Note
                        </label>
                      </div>

                      <div class="col-6 mb-2 d-flex">
                        <textarea
                          row="2"
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setNote(e.target.value);
                          }}
                        />
                      </div>

                      <br />

                      <div class="col-4 form-check form-check-inline checkboxSpread">
                        <label for="_0" class="form-check-label fontTypA">
                          Cash Payment
                        </label>
                      </div>

                      <div class="col-6 d-flex">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setTamount(Number(paid) - Number(e.target.value));
                            setRe(Number(refundb) + Number(e.target.value));
                          }}
                        />
                        <p class="ml-2 fontTypA">BDT</p>
                      </div>
                    </div>
                  ) : null}

                  {view == "bank" ? (
                    <div class="row align-items-center justify-content-center my-4">
                      <div class="col-4 form-check form-check-inline checkboxSpread">
                        <label for="_0" class="form-check-label fontTypA">
                          Refund Note
                        </label>
                      </div>

                      <div class="col-6 mb-2 d-flex">
                        <textarea
                          row="2"
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setNote(e.target.value);
                          }}
                        />
                      </div>

                      <br />

                      <div class="col-3 form-check form-check-inline checkboxSpread">
                        <label for="_0" class="form-check-label fontTypA">
                          Amount
                        </label>
                      </div>

                      <div class="col-4 d-flex">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setTamount(Number(paid) - Number(e.target.value));
                            setRe(Number(refundb) + Number(e.target.value));
                          }}
                        />
                        <p class=" ml-2 fontTypA">BDT</p>
                      </div>
                      <div class="col-4 d-flex">
                        <div class="input-group">
                          <select
                            id="inputState"
                            class="form-select form-control"
                            onChange={(e) => setHbank(e.target.value)}
                          >
                            <option class="form-control" selected value="">
                              Choose Bank...
                            </option>
                            {banks.map((item) => (
                              <option value={item.id + "/" + item.bank}>
                                {item.bank}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </form>
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn closeBtn"
                  data-dismiss="modal"
                  onClick={() => {
                    document.getElementById("pills-home-tab").click();
                    setView("cash");
                    setPtype("cash");
                    setHbank("");
                    setAmount("");
                    setTamount(0);
                  }}
                >
                  Close
                </button>

                <button
                  type="button"
                  class="btn paymentUpdate"
                  data-dismiss="modal"
                  onClick={() => {
                    refundhistory();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade cpayment_status"
          data-keyboard="false"
          data-backdrop="static"
          id="Cpaymentstatus2"
          tabindex="1"
          role="dialog"
          aria-labelledby="CpaymentModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog cPaymentDialog" role="document">
            <div class="modal-content">
              <div class="mHeader">
                <p class="modal-title pModalheadear" id="CpaymentModalLabel">
                  Refund Payment Status
                </p>
              </div>
              <div class="pModalbody">
                <div class="row">
                  <div class="col-4 fontTypA">
                    <p class="mb-2">Customer Name</p>
                  </div>
                  <div class="col-8">
                    <p class="mb-2 font-weight-bold">{uname}</p>
                  </div>
                </div>

                {/* <div class="row my-3">
                <div class="col-4 fontTypA">
                  <p class="m-0">Total Refund</p>
                </div>

                <div class="col-8">
                  <p class="mb-0 font-weight-bold">{refundb} BDT</p>
                  {phistory.map((item) => (
                    <p class="pAmountHist m-0">
                      {formatDate(item.date)} {item.type} -{" "}
                      <span>
                        {item.amount} BDT{" "}
                        {item.type == "bank" ? "(" + item.bank + ")" : null}
                      </span>
                      <b>Note :</b>
                      {item.note}
                    </p>
                  ))}
                </div>
              </div> */}
              </div>

              <p class="font-weight-bold pModalbody">Refund Payment</p>
              <div class="row justify-content-center">
                <ul
                  class="nav nav-pills text-center p-0 col-lg-8"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item w-50">
                    <a
                      class="nav-link active"
                      onClick={() => {
                        setPtype("cash");
                        setView("cash");
                      }}
                      id="pills-home-tab2"
                      data-toggle="pill"
                      href="#pills-home"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Cash
                    </a>
                  </li>
                  <li class="nav-item w-50">
                    <a
                      class="nav-link"
                      onClick={() => {
                        setView("bank");
                        setPtype("bank");
                      }}
                      id="pills-profile-tab2"
                      data-toggle="pill"
                      href="#pills-profile"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Bank
                    </a>
                  </li>
                </ul>

                <form
                  class="mb-4 mt-2 col-lg-8"
                  id="spfrm2"
                  style={{ borderTop: "1px solid #bababa" }}
                >
                  {view == "cash" ? (
                    <div class="row align-items-center justify-content-center my-4">
                      <div class="col-4 form-check form-check-inline checkboxSpread">
                        {/* <input
                                            name="serviceSelect"
                                            id="cPValue"
                                            type="radio"
                                            class="form-check-input"
                                            value="cP"
                                            required="required"
                                            onClick={(e) => setPtype("Cash")}

                                        /> */}
                        <label for="_0" class="form-check-label fontTypA">
                          Refund Note
                        </label>
                      </div>

                      <div class="col-6 mb-2 d-flex">
                        <textarea
                          row="2"
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setNote(e.target.value);
                          }}
                        />
                      </div>

                      <div class="col-4 form-check form-check-inline checkboxSpread">
                        {/* <input
                                            name="serviceSelect"
                                            id="cPValue"
                                            type="radio"
                                            class="form-check-input"
                                            value="cP"
                                            required="required"
                                            onClick={(e) => setPtype("Cash")}

                                        /> */}
                        <label for="_0" class="form-check-label fontTypA">
                          Cash Payment
                        </label>
                      </div>

                      <div class="col-6 d-flex">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setTamount(Number(paid) - Number(e.target.value));
                            setRe(Number(refundb) + Number(e.target.value));
                          }}
                        />
                        <p class="ml-2 fontTypA">BDT</p>
                      </div>
                    </div>
                  ) : null}

                  {view == "bank" ? (
                    <div class="row align-items-center justify-content-center my-4">
                      <div class="col-4 form-check form-check-inline checkboxSpread">
                        {/* <input
                                            name="serviceSelect"
                                            id="cPValue"
                                            type="radio"
                                            class="form-check-input"
                                            value="cP"
                                            required="required"
                                            onClick={(e) => setPtype("Cash")}

                                        /> */}
                        <label for="_0" class="form-check-label fontTypA">
                          Refund Note
                        </label>
                      </div>

                      <div class="col-6 mb-2 d-flex">
                        <textarea
                          row="2"
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setNote(e.target.value);
                          }}
                        />
                      </div>

                      <div class="col-2 form-check form-check-inline checkboxSpread">
                        {/* <input
                                            name="serviceSelect"
                                            id="cPValue"
                                            type="radio"
                                            class="form-check-input"
                                            value="cp"
                                            required="required"
                                            onClick={(e) => setPtype("Bank")}

                                        /> */}
                        <label for="_0" class="form-check-label fontTypA">
                          Amount
                        </label>
                      </div>

                      <div class="col-4 d-flex">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required="required"
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setTamount(Number(paid) - Number(e.target.value));
                            setRe(Number(refundb) + Number(e.target.value));
                          }}
                        />
                        <p class=" ml-2 fontTypA">BDT</p>
                      </div>
                      <div class="col-5">
                        <div class="input-group">
                          <select
                            id="inputState"
                            class="form-select form-control"
                            onChange={(e) => setHbank(e.target.value)}
                          >
                            <option class="form-control" selected value="">
                              Choose Bank...
                            </option>
                            {banks.map((item) => (
                              <option value={item.id + "/" + item.bank}>
                                {item.bank}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </form>
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn closeBtn"
                  data-dismiss="modal"
                  onClick={() => {
                    document.getElementById("pills-home-tab2").click();
                    setView("cash");
                    setPtype("cash");
                    setHbank("");
                    setAmount("");
                    setTamount(0);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn paymentUpdate"
                  data-dismiss="modal"
                  onClick={() => {
                    refundhistory();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade cpayment_status"
          id="refundnote"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog cPaymentDialog" role="document">
            <div class="modal-content">
              <div class="mHeader">
                <p class="modal-title pModalheadear" id="CpaymentModalLabel">
                  Group Ticket Refund
                </p>
              </div>

              <div class="pModalbody my-4">
                <div class="row">
                  <div class="col-4 fontTypA">
                    <p class="mb-2">Refund Amount</p>
                  </div>
                  <div class="col-8">
                    <p class="mb-2 font-weight-bold">: {samount} BDT</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 fontTypA">
                    <p class="mb-2">Refund Note</p>
                  </div>
                  <div class="col-8">
                    <p class="mb-2">: {snote}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Sgticket);
