import { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,  
    Link
  } from "react-router-dom";

import AddAgent from "./addagent";
import AddVendor from "./addvendor";
import AddaVendor from "./addavendor";
import Addbank from "./addbank";
import Addgroupcus from "./addgroupcus";
import Addgroupticket from "./addgroupticket";
import Addinvoice from "./addinvoice";
import Addstaff from "./addstaff";
import Admedical from "./Admedical";
import Addpackages from "./addPackages";
import Addpackagesnext from "./addPackagesnext";

function Addcomponent(){
    return(
        <>
            <Route path="/adagent">
                <AddAgent/>
            </Route>
            <Route path="/addvendor">
                <AddVendor/>
            </Route>
            <Route path="/addbank">
                <Addbank/>
            </Route>
            <Route path="/addpackages">
                <Addpackages/>
            </Route>
            <Route path="/addpackagesnext/:id">
                <Addpackagesnext/>
            </Route>
            <Route path="/addgroupcus/:id">
                <Addgroupcus/>
            </Route>
            <Route path="/addgroupticket">
                <Addgroupticket/>
            </Route>
            <Route path="/addinvoice">
                <Addinvoice/>
            </Route>
            <Route path="/addstaff">
                <Addstaff/>
            </Route>
            <Route path="/admedical">
                <Admedical/>
            </Route>
            <Route path="/addavendor">
                <AddaVendor/>
            </Route>

        </>
    )
}

export default Addcomponent;