import axios from "axios";
import { useState } from "react";
import Loading from "../common/loading";
function Delb2bmodal({ did, stype }) {
  const [load, setLoad] = useState(false);
  function delagent() {
    setLoad(true);
    if (stype == "agent") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/delagent",
          {
            id: did,
          }
        )
        .then((res) => {
          setLoad(false);
        })
        .catch((err) => console.log(err));
    }

    if (stype == "vendor") {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/delvendor",
          {
            id: did,
          }
        )
        .then((res) => setLoad(false))
        .catch((err) => console.log(err));
    }
  }
  return (
    <>
      <Loading load={load} />
      <div
        class="modal fade deleteModal animate__animated animate__slideInDown"
        id="dellog"
        tabindex="1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style={{ top: 20 }} role="document">
          <div class="modal-content datadelM">
            <div class="modal-body">
              <p class="m-0 py-3">Do You Want to Delete?</p>
            </div>
            <div class="modal-footer py-2">
              <button
                type="button"
                class="btn btn-secondary btndlet"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                onClick={delagent}
                type="button"
                class="btn btn-primary btnyes"
                data-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Delb2bmodal;
