import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Printhead from "../printhead";

function Ticket(props) {
  let history = useHistory();

  var id = props.match.params.id;
  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [flight, setFlight] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [idate, setIdate] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [arr1, setArr1] = useState("");
  const [arr2, setArr2] = useState("");
  const [de1, setDe1] = useState("");
  const [de2, setDe2] = useState("");
  const [airplane, setAirplane] = useState("");
  const [vendor, setVendor] = useState("");
  const [type, setType] = useState("");
  const [serial, setSerial] = useState("");
  const [doc1, setDoc1] = useState("");
  const [doc2, setDoc2] = useState("");
  const [doc3, setDoc3] = useState("");
  const [tnote, setTnote] = useState("");

  const [edate, setEdate] = useState("");

  const [departure, setDeparturte] = useState("");
  const [arrival, setArrival] = useState("");

  const [cserial, setCserial] = useState("");
  const [gpnr, setGpnr] = useState("");

  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");

  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");

  const [vendors, setVendors] = useState([]);
  const [customer, setCustomer] = useState([]);

  const [note, setNote] = useState("");
  const [aid, setAid] = useState("");
  const [cof, setCof] = useState("");
  const [agents, setAgents] = useState([]);
  const [staff, setStaff] = useState([]);
  const [adult, setAdult] = useState("");
  const [infant, setInfant] = useState("");
  const [baby, setBaby] = useState("");
  const [tranlocation, setTranLocation] = useState("");
  const [trantime, setTrantime] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchstaff"
      )
      .then((res) => setStaff(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }

  function getStaff(x) {
    var i = "";
    staff.map((item) => {
      if (item.id == x) {
        i = item.sname;
      }
    });

    return i;
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprev",
        {
          id: id,
        }
      )
      .then((res) => {
        setNote(res.data.message[0].note);

        setCserial(res.data.message[0].cserial);
        setCname(res.data.message[0].cname);
        setCnum(res.data.message[0].cnum);
        setCadd(res.data.message[0].cadd);

        setPassport(res.data.message[0].passport);
        setNid(res.data.message[0].nid);
        setIdate(res.data.message[0].idate);
        setAirplane(res.data.message[0].airplane);
        setFlight(res.data.message[0].flight);
        setVendor(res.data.message[0].vendor);
        setBase(res.data.message[0].base);
        setGross(res.data.message[0].gross);
        setVat(res.data.message[0].vat);
        setProfit(res.data.message[0].profit);
        setSerial(res.data.message[0].serial);
        setType(res.data.message[0].type);

        setDoc1(res.data.message[0].doc1);
        setDoc2(res.data.message[0].doc2);
        setDoc3(res.data.message[0].doc3);

        setDeparturte(res.data.message[0].departure);
        setArrival(res.data.message[0].arrival);

        setGpnr(res.data.message[0].gpnr);
        setAid(res.data.message[0].aid);

        setCof(res.data.message[0].cof);

        setEdate(res.data.message[0].edate);

        setAdult(res.data.message[0].adult);
        setInfant(res.data.message[0].infant);
        setBaby(res.data.message[0].baby);
        setTnote(res.data.message[0].tnote);
        setTranLocation(res.data.message[0].tranlocation);
        setTrantime(res.data.message[0].trantime);

        document.getElementById("inputair").value =
          res.data.message[0].airplane;
        document.getElementById("inputven").value = res.data.message[0].vendor;

        setArr1(
          res.data.message[0].arrival.substr(
            0,
            res.data.message[0].arrival.indexOf(" ")
          )
        );
        setDe1(
          res.data.message[0].departure.substr(
            0,
            res.data.message[0].departure.indexOf(" ")
          )
        );

        var fdate = res.data.message[0].arrival;
        fdate = fdate
          .replace(fdate.substr(0, fdate.indexOf(" ")), "")
          .replace(" ", "");
        var k = fdate.substr(0, 11).replace(" ", "");

        console.log("hey" + k);
        setArr2(k.replace(" ", ""));

        var time1 = fdate.replace(fdate.substr(0, 12), "");
        var time12 = time1.substr(0, time1.indexOf(":"));
        setT1(time12);

        var time2 = time1.replace(time12, "").replace(":", "");
        setT2(time2);

        var ddate = res.data.message[0].departure;
        ddate = ddate
          .replace(ddate.substr(0, ddate.indexOf(" ")), "")
          .replace(" ", "");
        var ko = ddate.substr(0, 11).replace(" ", "");
        setDe2(ko.replace(" ", ""));

        console.log("hey" + k);

        var time1 = ddate.replace(ddate.substr(0, 12), "");
        var time12 = time1.substr(0, time1.indexOf(":"));
        setD1(time12);

        var time2 = time1.replace(time12, "").replace(":", "");
        setD2(time2);
      })
      .catch((err) => console.log(err));
  }, [id]);

  function handleSubmit(e) {
    e.preventDefault();

    var arrival = arr1 + "  " + arr2 + " " + t1 + ":" + t2;
    var departure = de1 + " " + de2 + " " + d1 + ":" + d2;

    const data = new FormData();

    data.append("passport", passport);
    data.append("nid", nid);
    data.append("flight", flight);
    data.append("base", base);
    data.append("gross", gross);
    data.append("idate", idate);
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("arrival", arrival);
    data.append("departure", departure);
    data.append("airplane", airplane);
    data.append("vendor", vendor);
    data.append("serial", serial);
    data.append("id", id);

    // data.append("foo",fileData);
    // data.append("foo2",fileData2);
    // data.append("foo3",fileData3);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upticket",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          toast.success("Ticket updated", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });

          document.getElementById("create-course-form").reset();
        }
      })
      .catch((err) => console.log(err));

    history.goBack();
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));
  }, [vendors]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => setCustomer(res.data.message))
      .catch((err) => console.log(err));
  }, [customer]);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1000);
  }, []);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div class="container">
        <Printhead />

        <div class="prheadc mt-3">
          <h3>Service Name: Ticket</h3>
        </div>

        <div class="dtran cview">
          <div class="d-flex adcst">
            <h5 class="m-0">Customer Data</h5>
          </div>
          <hr />

          <div class="contentC">
            <div class="form-group row py-2">
              <label for="" class="col-2 col-form-label infotext">
                Serial
              </label>
              <div class="col-10">
                <p class="font-weight-bold m-0 p-1">: {cserial}</p>
              </div>

              <label for="" class="col-2 col-form-label infotext">
                Name
              </label>
              <div class="col-10">
                <p class="font-weight-bold m-0 p-1">: {cname}</p>
              </div>

              <label for="" class="col-2 col-form-label infotext">
                Mobile Number
              </label>
              <div class="col-10">
                <p class="font-weight-bold m-0 p-1">: {cnum}</p>
              </div>

              <label for="" class="col-2 col-form-label infotext">
                Address
              </label>
              <div class="col-10">
                <p class="font-weight-bold m-0 p-1">: {cadd}</p>
              </div>

              <label for="" class="col-2 col-form-label infotext">
                NID No
              </label>
              <div class="col-10">
                <p class="font-weight-bold m-0 p-1">: {nid}</p>
              </div>

              <label for="" class="col-2 col-form-label infotext">
                Passport No
              </label>
              <div class="col-10">
                <p class="font-weight-bold m-0 p-1">: {passport}</p>
              </div>
              <label for="" class="col-2 col-form-label infotext">
                Passport Validity
              </label>
              <div class="col-10">
                <p class="font-weight-bold m-0 p-1">: {edate}</p>
              </div>
            </div>

            <div class="d-flex adcst">
              <h5 class="m-0">Service Data</h5>
            </div>
            <hr />

            <div class="form-group row">
              <label for="" class="col-2 col-form-label infotext">
                Issue Date
              </label>
              <div class="col-4">
                <p class="font-weight-bold m-0 p-1">: {idate}</p>
              </div>
              <label for="" class="col-2 col-form-label infotext">
                Airplane
              </label>
              <div class="col-4">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <p class="font-weight-bold m-0 p-1">: {airplane}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="" class="col-2 col-form-label infotext">
                Departure
              </label>
              <div class="col-4">
                <p class="font-weight-bold m-0 p-1">: {departure}</p>
              </div>

              <label for="" class="col-2 col-form-label infotext">
                Arrival
              </label>
              <div class="col-4">
                <p class="font-weight-bold m-0 p-1">: {arrival}</p>
              </div>
            </div>
            <div class="form-group row">
              <label for="" class="col-2 col-form-label infotext">
                Flight No
              </label>
              <div class="col-4">
                <p class="font-weight-bold m-0 p-1">: {flight}</p>
              </div>

              {type == "international" ? (
                <>
                  <label for="" class="col-2 col-form-label infotext">
                    Transit
                  </label>
                  <div class="col-4">
                    <p class="font-weight-bold m-0 p-1">
                      :{tranlocation} , {trantime}
                    </p>
                  </div>
                </>
              ) : null}
            </div>

            {/* <div class="form-group row">
              <label for="" class="col-2 col-form-label infotext">
                Ticket Type
              </label>
              <div class="col-10 me-auto">
                <p class="font-weight-bold m-0 p-1">
                  : {adult != "" ? "Adult: " + adult + " , " : null}{" "}
                  {baby != "" ? "Child: " + baby + " , " : null}
                  {infant != "" ? "Infant: " + infant + "" : null}{" "}
                </p>
              </div>
            </div> */}

            <div class="form-group row">
              <label for="" class="col-2 col-form-label infotext">
                Ticket Serial
              </label>
              <div class="col-4">
                <p class="font-weight-bold m-0 p-1">: {serial}</p>
              </div>
              <label for="" class="col-2 col-form-label infotext">
                Ticket PNR
              </label>
              <div class="col-4">
                <p class="font-weight-bold m-0 p-1">: {gpnr}</p>
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-2 col-form-label infotext">
                Care Of
              </label>
              <div class="col-4">
                <p class="font-weight-bold m-0 p-1">: {getStaff(cof)}</p>
              </div>
            </div>

            <hr />
            {aid == 0 ? null : (
              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Agent Name
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">: {getagent(aid)}</p>
                </div>
              </div>
            )}
            <div class="form-group row">
              <label for="" class="col-2 col-form-label infotext">
                Vendor Name
              </label>
              <div class="col-10">
                {vendors.map((y) => (
                  <p class="font-weight-bold m-0 pad1">
                    {y.id == vendor ? ": " + y.vname : null}
                  </p>
                ))}
              </div>

              {/* <label for="" class="col-2 col-form-label infotext">Base Fare</label>
             <div class="col-4 me-auto">
               <p>{base}</p>
             </div>
             </div>

             <div class="form-group row">
             <label for="" class="col-2 col-form-label infotext">Gross Fare</label>
             <div class="col-2 me-auto">
               <p>{gross}</p>
             </div>

             <label for="" class="col-2 col-form-label infotext">VAT & AIT (%)</label>
             <div class="col-2 me-auto">
               <p>{vat}</p>
             </div>
             <label for="" class="col-2 col-form-label infotext">Profit</label>
             <div class="col-2 me-auto">
               <p>{profit}</p>
             </div>
           </div> */}
            </div>

            <div class="form-group row">
              <label for="" class="col-2 col-form-label infotext">
                Additional note
              </label>
              <div class="col-10">
                <p class="font-weight-bold m-0 p-2">: {note}</p>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer" style={{ position: "fixed", bottom: 0 }}>
          <div class="dflex" style={{ marginLeft: 10, marginRight: 10 }}>
            <p style={{ fontSize: 14, fontWeight: "bold" }}>
              Developed by mPair Technologies Ltd.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default withRouter(Ticket);
