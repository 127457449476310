import axios from "axios";
import { useState } from "react";
import formatDate from "../functions/timeformat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import refund from "../../images/refund.png";
function Agentrefundmodal({
  uname,
  upay,
  paid,
  setPaid,
  uid,
  stype,
  serial,
  status,
  active,
  agents,
  agent,
  close,
}) {
  const [phistory, setPhistory] = useState([]);
  const [view, setView] = useState("cash");
  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("cash");
  const [tamount, setTamount] = useState(0);
  const [banks, setBanks] = useState([]);
  const [mload, setMload] = useState(false);
  const [abank, setAbank] = useState("");

  const [refundb, setRefundb] = useState(0);
  const [re, setRe] = useState(0);

  const [base, setBase] = useState("");
  const [note, setNote] = useState("");
  const [vname, setVname] = useState("");

  const [snote, setSnote] = useState("");
  const [samount, setSamount] = useState("");

  const [gross, setGross] = useState("");

  const [role, setRole] = useState(false);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }

  function refundhistory() {
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    document.getElementById("spfrm2").reset();

    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", tamount);
    data.append("refundb", re);
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("name", uname);
    data.append("vname", vname);
    data.append("serial", serial);
    data.append("base", base);
    data.append("gross", gross);
    data.append("note", note);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundhistory",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    toast.success("Refund Addedd", {
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      toastId: "success4",
      autoClose: 2000,
    });

    setPaid(tamount);
    setRefundb(re);
    refundfetch(uid, stype);

    setHbank("");
    setAmount("");
    setTamount(0);

    close(false);
  }

  function refundfetch(x, y) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundfetch",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => {
        setPhistory(res.data.message);
        setSnote(res.data.message[0].note);
        setSamount(res.data.message[0].amount);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className={active ? "mp-Modal-backdrop" : ""}>
      <div class={active ? "mp-Modal mp-Modal-active" : "mp-Modal"}>
        <div class="">
          <div class="mHeader">
            <i
              onClick={() => {
                close(false);
                setView("cash");
                setPtype("cash");
                setHbank("");
                setAmount("");
                setTamount(0);
                document.getElementById("pills-home-tab").click();
              }}
              className="fa fa-close cusIcon"
            ></i>

            <p class="modal-title pModalheadear" id="CpaymentModalLabel">
              Refund Payment Status
            </p>
          </div>
          <div class="pModalbody my-4">
            <div class="row">
              <div class="col-4 fontTypA">
                <p class="mb-2">Customer Name</p>
              </div>
              <div class="col-8">
                <p class="mb-2 font-weight-bold">{uname}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-4 fontTypA">
                <p class="mb-2">Agent Name</p>
              </div>
              <div class="col-8">
                <p class="mb-2 font-weight-bold">{getagent(agent)}</p>
              </div>
            </div>
          </div>

          <div class="pModalbody">
            <p class="font-weight-bold border-bottom">Refund Payment</p>
            <div class="row border mx-auto justify-content-center">
              <ul
                class="nav nav-pills text-center p-0 col-lg-12"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item w-50">
                  <a
                    class="nav-link active"
                    onClick={() => {
                      setView("cash");
                      setPtype("cash");
                    }}
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    Cash
                  </a>
                </li>
                <li class="nav-item w-50">
                  <a
                    class="nav-link"
                    onClick={() => {
                      setPtype("bank");
                      setView("bank");
                    }}
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    Bank
                  </a>
                </li>
              </ul>

              <form class="col-lg-12" id="spfrm2">
                {view == "cash" ? (
                  <div class="row align-items-center justify-content-center my-4">
                    <div class="col-4 ">
                      <label for="_0" class="form-check-label fontTypA">
                        Refund Note
                      </label>
                    </div>

                    <div class="col-6 mb-2 d-flex">
                      <textarea
                        row="2"
                        id=""
                        name=""
                        type="text"
                        class="form-control"
                        required="required"
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                      />
                    </div>

                    <div class="col-4 ">
                      <label for="_0" class="form-check-label fontTypA">
                        Cash Payment
                      </label>
                    </div>

                    <div class="col-6 d-flex">
                      <input
                        id=""
                        name=""
                        type="text"
                        class="form-control"
                        required="required"
                        onChange={(e) => {
                          setAmount(e.target.value);
                          setTamount(Number(paid) - Number(e.target.value));
                          setRe(Number(refundb) + Number(e.target.value));
                        }}
                      />
                      <p class="ml-2 fontTypA">BDT</p>
                    </div>
                  </div>
                ) : null}

                {view == "bank" ? (
                  <div class="row align-items-center justify-content-center my-4">
                    <div class="col-4 ">
                      <label for="_0" class="form-check-label fontTypA">
                        Refund Note
                      </label>
                    </div>

                    <div class="col-6 mb-2 d-flex">
                      <textarea
                        row="2"
                        id=""
                        name=""
                        type="text"
                        class="form-control"
                        required="required"
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                      />
                    </div>

                    <br />

                    <div class="col-4 ">
                      <label for="_0" class="form-check-label fontTypA">
                        Amount
                      </label>
                    </div>

                    <div class="col-6 d-flex">
                      <input
                        id=""
                        name=""
                        type="text"
                        class="form-control"
                        required="required"
                        onChange={(e) => {
                          setAmount(e.target.value);
                          setTamount(Number(paid) - Number(e.target.value));
                          setRe(Number(refundb) + Number(e.target.value));
                        }}
                      />
                      <p class=" ml-2 fontTypA">BDT</p>
                    </div>
                    <div class="col-4"></div>
                    <div class="col-6 mt-2 d-flex">
                      <div class="input-group">
                        <select
                          id="inputState"
                          class="form-select form-control"
                          onChange={(e) => setHbank(e.target.value)}
                        >
                          <option class="form-control" selected value="">
                            Choose Bank...
                          </option>
                          {banks.map((item) => (
                            <option value={item.id + "/" + item.bank}>
                              {item.bank}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}
              </form>
            </div>
          </div>

          <div class="justify-content-between pModalbody my-3">
            <button
              type="button"
              class="btn paymentUpdate w-100"
              data-dismiss="modal"
              onClick={() => {
                refundhistory();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agentrefundmodal;
