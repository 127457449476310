import { useState, useEffect, Suspense } from "react";
import { withRouter, Link } from "react-router-dom";
import Navigation2 from "./navigation2";
import searchb from "../images/search.png";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../screens/footer";
import Sidebar from "./common/sidebar";
import IncomeModal from "./modals/incomemodal";
import ExModal from "./modals/expensemodal";
import Delmodal from "./modals/delmodal";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

function Accounts() {
  const [banks, setBanks] = useState([]);
  const [acid, setAcid] = useState("");
  const [acit, setAcit] = useState("");
  const [upinfo, setUpinfo] = useState(0);
  const [expense, setExpense] = useState([]);

  const [income, setIncome] = useState([]);

  const [uid, setUid] = useState(0);

  const [vendors, setVendors] = useState([]);

  const [cin, setCin] = useState(0);
  const [cout, setCout] = useState(0);

  const [bin, setBin] = useState(0);
  const [bout, setBout] = useState(0);
  const [dbank, setDbank] = useState([]);
  const [dbank2, setDbank2] = useState([]);
  const [dbank3, setDbank3] = useState([]);
  const [dbank4, setDbank4] = useState([]);
  const [coun, setCoun] = useState(0);
  const [bname, setBname] = useState("");
  const [branch, setBranch] = useState("");
  const [aname, setAname] = useState("");
  const [anum, setAnum] = useState("");
  const [allven, setAllven] = useState([]);

  const [profit, setProfit] = useState("");
  const [mprofit, setMprofit] = useState("");
  const [yprofit, setYprofit] = useState("");

  const [bid, setBid] = useState("");

  const [inModal, setInmodal] = useState(false);
  const [expModal, setExpModal] = useState(false);

  const [exfilter, setExfilter] = useState("");
  const [infilter, setInfilter] = useState("");

  const [cartcin, setCartcin] = useState("");
  const [cartcout, setCartout] = useState("");

  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("");
  const [hbank, setHbank] = useState("");
  const [tamount, setTamount] = useState("");
  const [stype, setStype] = useState("");
  const [abank, setAbank] = useState("");
  const [pdate, setPdate] = useState("");
  const [pnote, setPnote] = useState("");
  const [name, setName] = useState("");

  const [vstat, setVstat] = useState([]);
  const [astat, setAstat] = useState([]);
  const [update, setUpdate] = useState([]);

  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: "# of Votes",
        data: [Number(cartcin), Number(cartcout), Number(bin), Number(bout)],
        backgroundColor: ["#FFC533", "#F2726F", "#3bb2ed", "#BC95DF"],
        borderColor: ["#FFC533", "#F2726F", "#3bb2ed", "#BC95DF"],
      },
    ],
  };

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/bankfetch"
      )
      .then((res) => setBanks(res.data.message))
      .catch((err) => console.log(err));
  }, [banks]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorStat",
        {
          date: new Date().getFullYear(),
        }
      )

      .then((res) => {
        setVstat(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/agentStat",
        {
          date: new Date().getFullYear(),
        }
      )
      .then((res) => {
        setAstat(res.data.message);
        // setVstat(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorfetch3"
      )
      .then((res) => {
        setAllven(res.data.message);
        setVendors(res.data.message2);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/expensefetch"
      )
      .then((res) => {
        setExpense(res.data.message);
        setIncome(res.data.message2);
        setUpdate(update + 1);
      })
      .catch((err) => console.log(err));
  }, [coun, update]);

  function delbank() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delbank",
        {
          id: bid,
        }
      )
      .then((res) => {})
      .catch((err) => console.log(err));
  }

  function getTo(x) {
    var i = 0;
    var j = 0;
    {
      dbank.map((y) => {
        if (y.bank == x) {
          i = i + y.amount;
        }
      });
      return i;
    }
  }
  function getToven(x) {}
  function getTo2(x) {
    var i = 0;
    {
      dbank2.map((y) => {
        if (y.bank == x) {
          i = i + y.amount;
        }
      });

      dbank3.map((y) => {
        if (y.bank == x) {
          i = i + y.amount;
        }
      });

      dbank4.map((y) => {
        if (y.bank == x) {
          i = i + y.amount;
        }
      });

      return i;
    }
  }

  useEffect(() => {
    var d = new Date();

    var m = d.getMonth() + 1;
    var day = d.getDate();
    if (day < 10) {
      day = "0" + day;
    }

    if (m < 10) {
      m = "0" + m;
    }

    var x = d.getFullYear() + "-" + m + "-" + day;

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchmybank",
        {
          date: x,
        }
      )
      .then((res) => {
        setDbank(res.data.message);
        setDbank2(res.data.message2);
        setDbank3(res.data.message3);
        setDbank4(res.data.message4);
      })
      .catch((err) => console.log(err));
  }, [coun]);

  useEffect(() => {
    var d = new Date();

    var m = d.getMonth() + 1;
    var day = d.getDate();
    if (day < 10) {
      day = "0" + day;
    }

    if (m < 10) {
      m = "0" + m;
    }

    var x = d.getFullYear() + "-" + m + "-" + day;

    var y = d.getFullYear() + "-" + m + "-";

    var z = d.getFullYear() + "-";

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcash",
        {
          date: x,
        }
      )
      .then((res) => {
        setCin(res.data.cin[0].total);
        setCout(
          res.data.cout[0].total2 +
            res.data.refund[0].total3 +
            res.data.expense[0].total4
        );
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprofit",
        {
          date: x,
        }
      )
      .then((res) => {
        setProfit(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprofit",
        {
          date: y,
        }
      )
      .then((res) => {
        setMprofit(res.data.message);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprofit",
        {
          date: z,
        }
      )
      .then((res) => {
        setYprofit(res.data.message);
      })
      .catch((err) => console.log(err));

    chartData();
  }, [coun]);

  function chartData() {
    var d = new Date();

    var m = d.getMonth() + 1;
    var day = d.getDate();
    if (day < 10) {
      day = "0" + day;
    }

    if (m < 10) {
      m = "0" + m;
    }

    var x = d.getFullYear() + "-" + m + "-" + day;

    var y = d.getFullYear() + "-" + m + "-";

    var z = d.getFullYear() + "-";

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcash",
        {
          date: z,
        }
      )
      .then((res) => {
        setCartcin(res.data.cin[0].total);
        setCartout(
          res.data.cout[0].total2 +
            res.data.refund[0].total3 +
            res.data.expense[0].total4
        );
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchdbank",
        {
          date: z,
        }
      )
      .then((res) => {
        setBin(res.data.bin[0].total);

        setBout(res.data.bout[0].total2 + res.data.brefund[0].total3);
      })
      .catch((err) => console.log(err));
  }

  function pfetch2(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprofit",
        {
          date: x,
        }
      )
      .then((res) => {
        setMprofit(res.data.message);
      })
      .catch((err) => console.log(err));
  }
  function venfilter(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/vendorStat",
        {
          date: x,
        }
      )
      .then((res) => {
        setVstat(res.data.message);
      })
      .catch((err) => console.log(err));
  }

  function agentsate(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/agentStat",
        {
          date: x,
        }
      )
      .then((res) => {
        setAstat(res.data.message);
      })
      .catch((err) => console.log(err));
  }
  function pfetch3(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprofit",
        {
          date: x,
        }
      )
      .then((res) => {
        setYprofit(res.data.message);
      })
      .catch((err) => console.log(err));
  }

  function dicash(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcash",
        {
          date: x,
        }
      )
      .then((res) => {
        setCin(res.data.cin[0].total);
        setCout(
          res.data.cout[0].total2 +
            res.data.refund[0].total3 +
            res.data.expense[0].total4
        );
      })
      .catch((err) => console.log(err));
  }

  function dicash2(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchmybank",
        {
          date: x,
        }
      )
      .then((res) => {
        setDbank(res.data.message);
        setDbank2(res.data.message2);
        setDbank3(res.data.message3);
        setDbank4(res.data.message4);
      })
      .catch((err) => console.log(err));
  }

  function cartFill(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcash",
        {
          date: x,
        }
      )
      .then((res) => {
        setCartcin(res.data.cin[0].total);
        setCartout(
          res.data.cout[0].total2 +
            res.data.refund[0].total3 +
            res.data.expense[0].total4
        );
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchdbank",
        {
          date: x,
        }
      )
      .then((res) => {
        setBin(res.data.bin[0].total);

        setBout(res.data.bout[0].total2 + res.data.brefund[0].total3);
      })
      .catch((err) => console.log(err));
  }

  function handleSubmit(e) {
    e.preventDefault();
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/updateinfo",
        {
          bid: bid,
          bname: bname,
          branch: branch,
          aname: aname,
          anum: anum,
        }
      )
      .then((res) => {})
      .catch((err) => console.log(err));
  }

  function getExtotal() {
    var total = 0;
    expense
      .filter((item) => {
        if (item.date.includes(exfilter)) {
          return item;
        }
      })
      .map((item) => {
        total = total + item.amount;
      });

    return total;
  }

  function getIntotal() {
    var total = 0;
    income
      .filter((item) => {
        if (item.date.includes(infilter)) {
          return item;
        }
      })
      .map((item) => {
        total = total + item.amount;
      });

    return total;
  }

  function payhistory() {
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    var sp = "income";
    var ab = "";
    var un = "";
    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", "bank");
    data.append("tamount", tamount);
    data.append("uid", 0);
    data.append("stype", sp);
    data.append("abank", "");
    data.append("name", "Bank transfer");
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/payhistoryTransfer",
        data
      )
      .then((res) => {
        setCoun(coun + 1);
        setAmount("");

        document.getElementById("c2bfrm").reset();
        toast.success("Transfered to Bank", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  }

  function payhistoryb2c() {
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    var sp = "income";
    var ab = "";
    var un = "";
    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", "cash");
    data.append("tamount", tamount);
    data.append("uid", 0);
    data.append("stype", sp);
    data.append("abank", "");
    data.append("name", "Cash transfer");
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/payhistoryTransferb2c",
        data
      )
      .then((res) => {
        document.getElementById("b2cfrm").reset();
        setCoun(coun + 1);
        setAmount("");
        toast.success("Transfered as Cash", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div
        class="modal fade pcmodal animate__animated animate__zoomIn"
        id="delModal"
        data-backdrop="static"
        data-keyboard="false"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog " role="document">
          <div class="modal-content bank_up_modal">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Update Bank Info
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form onSubmit={handleSubmit}>
                <label for="exampleInputEmail1">
                  <small class="font-weight-bold">Bank Name</small>
                </label>
                <input
                  type="text"
                  value={bname}
                  onChange={(e) => setBname(e.target.value)}
                  class="form-control mb-3"
                  placeholder="Bank name"
                  disabled
                />

                <label class="" for="exampleInputEmail1">
                  <small class="font-weight-bold">Branch Name</small>
                </label>
                <input
                  type="text"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  class="form-control mb-3"
                  placeholder="Branch Name"
                />

                <label class="" for="exampleInputEmail1">
                  <small class="font-weight-bold">Account Name</small>
                </label>
                <input
                  type="text"
                  value={aname}
                  onChange={(e) => setAname(e.target.value)}
                  class="form-control mb-3"
                  placeholder=""
                />

                <label class="" for="exampleInputEmail1">
                  <small class="font-weight-bold">Account Number</small>
                </label>
                <input
                  type="text"
                  value={anum}
                  onChange={(e) => setAnum(e.target.value)}
                  class="form-control mb-2"
                  placeholder=""
                />
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn closeBtn " data-dismiss="modal">
                Close
              </button>
              <button
                onClick={handleSubmit}
                class="btn btn-primary updtbtn"
                width="100%"
                data-dismiss="modal"
              >
                Update Info
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div className="col-lg-2">
            <Sidebar />
          </div>
          <div class="col-lg-10 col-12">
            <div class="row mx-auto">
              <div class="col-lg-12 col-12">
                <div class="my-4 expense-table">
                  <div class="card-header bg-primary">
                    <div class="row mt-1 align-items-center">
                      <div class="col-lg-5 col-12">
                        <h5 class="text-white mt-1">
                          Additional Expense |{" "}
                          <span class="font-weight-bold"></span>
                          {Number(getExtotal())} BDT
                        </h5>
                      </div>
                      <div class="col-lg-2 col-6">
                        {/* <div class="btn btn-filter text-dark d-flex m-0 text-center row align-items-center"> */}
                        {/* <div class="col-lg-3 col-8"><p class="m-0">Filter</p></div> */}

                        <input
                          type="date"
                          class="filtBTN px-2 m-0"
                          onChange={(e) => {
                            setExfilter(e.target.value);
                            getTo(e.target.value);
                          }}
                        />

                        {/* </div> */}
                      </div>
                      <div class="col-lg-2 col-5">
                        <div
                          class="btn m-0 btn-dark"
                          data-toggle="modal"
                          data-target="#staticBackdrop"
                          // data-target="#expense"
                          onClick={() => {
                            setExpModal(true);
                          }}
                        >
                          <p>+ Add New</p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-1 d-flex justify-content-end">
                        <a
                          data-toggle="collapse"
                          href="#collapseExpense"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseExpense"
                        >
                          <i
                            class="fa-solid fa-caret-down"
                            style={{ fontSize: 28 }}
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseExpense"
                    class="mt-2 collapse tableBody expense-table2"
                    style={{ height: "300px", overflow: "auto" }}
                  >
                    <table
                      class="table table-bordered"
                      style={{ fontSize: 14 }}
                    >
                      <thead class="thead-light">
                        <tr>
                          <th scope="col" class="py-1">
                            ID
                          </th>
                          <th scope="col" class="py-1">
                            Expense Type
                          </th>
                          <th scope="col" class="py-1">
                            Description
                          </th>
                          <th scope="col" class="py-1">
                            Method
                          </th>
                          <th scope="col" class="py-1">
                            BDT
                          </th>
                          <th scope="col" class="py-1 text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {expense
                          .filter((item) => {
                            if (item.date.includes(exfilter)) {
                              return item;
                            }
                          })
                          .map((item) => (
                            <tr>
                              <td class="py-1">FD{item.id}</td>
                              <td class="py-1">{item.type}</td>
                              <td class="py-1">{item.description}</td>
                              <td class="py-1">
                                {item.method}{" "}
                                {item.bank != "" ? "(" + item.bank + ")" : null}
                              </td>
                              <td class="py-1">{item.amount}</td>
                              <td class="py-1 text-center">
                                <i
                                  type="button"
                                  onClick={() => {
                                    setAcid(item.id);
                                    setAcit("expensesdel");
                                  }}
                                  class="fa fa-trash"
                                  data-toggle="modal"
                                  data-target="#delmodel"
                                ></i>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="my-4 expense-table">
                  <div class="card-header bg-primary">
                    <div class="row mt-1 align-items-center">
                      <div class="col-lg-5 col-12">
                        <h5 class="text-white mt-1">
                          Additional Income |{" "}
                          <span class="font-weight-bold"></span>
                          {Number(getIntotal())} BDT
                        </h5>
                      </div>
                      <div class="col-lg-2 col-6">
                        {/* <div class="btn btn-filter text-dark d-flex m-0 text-center row align-items-center"> */}
                        {/* <div class="col-lg-3 col-8"><p class="m-0">Filter</p></div> */}

                        <input
                          type="date"
                          class="filtBTN px-2 m-0"
                          onChange={(e) => {
                            setInfilter(e.target.value);
                            getTo(e.target.value);
                          }}
                        />

                        {/* </div> */}
                      </div>
                      <div class="col-lg-2 col-5">
                        <div
                          class="btn m-0 btn-dark"
                          data-toggle="modal"
                          // data-target="#expense2"
                          onClick={() => {
                            setInmodal(true);
                          }}
                        >
                          <p>+ Add New</p>
                        </div>
                      </div>
                      <div class="col-lg-3 col-1 d-flex justify-content-end">
                        <a
                          data-toggle="collapse"
                          href="#collapseExpense2"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseExpense"
                        >
                          <i
                            class="fa-solid fa-caret-down"
                            style={{ fontSize: 28 }}
                          ></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseExpense2"
                    class="mt-2 collapse tableBody expense-table2"
                    style={{ height: "300px", overflow: "auto" }}
                  >
                    <table
                      class="table table-bordered"
                      style={{ fontSize: 14 }}
                    >
                      <thead class="thead-light ">
                        <tr>
                          <th scope="col" class="py-1">
                            ID
                          </th>
                          <th scope="col" class="py-1">
                            Income Type
                          </th>
                          <th scope="col" class="py-1">
                            Description
                          </th>
                          <th scope="col" class="py-1">
                            Method
                          </th>
                          <th scope="col" class="py-1">
                            BDT
                          </th>
                          <th scope="col" class="py-1 text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {income
                          .filter((item) => {
                            if (item.date.includes(infilter)) {
                              return item;
                            }
                          })
                          .map((item) => (
                            <tr>
                              <td class="py-1">FD{item.id}</td>
                              <td class="py-1">{item.name}</td>
                              <td class="py-1">{item.abank}</td>
                              <td class="py-1">
                                {item.type}{" "}
                                {item.bank != "" ? "(" + item.bank + ")" : null}
                              </td>
                              <td class="py-1">{item.amount}</td>
                              <td class="py-1 text-center">
                                <i
                                  type="button"
                                  onClick={() => {
                                    setAcid(item.id);
                                    setAcit("incomedel");
                                  }}
                                  className="fa fa-trash"
                                  data-toggle="modal"
                                  data-target="#delmodel"
                                ></i>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="bg-light border">
                  <div class="row align-items-center p-2 px-0 mx-auto border-bottom">
                    <div class="col-lg-2 col-12">
                      <h5 class="mt-1 mb-0">Bank Deal</h5>
                    </div>

                    <div class="col-lg-2 col-7">
                      {/* <div class="btn btn-filter text-dark d-flex m-0 text-center row align-items-center">
                                    <div class="col-3"><p class="m-0">Filter</p></div>
                                    <div class="col-9"> */}
                      <input
                        type="date"
                        class="filtBTN px-2"
                        onChange={(e) => dicash2(e.target.value)}
                      />
                      {/* </div>
                                </div> */}
                    </div>
                    <div class="col-lg-6"></div>
                    <Link to="/addbank" class="col-lg-2 col-5">
                      <div class="btn btn-dark">
                        <div>+ Add Bank</div>
                      </div>
                    </Link>
                  </div>

                  {banks.map((item) => (
                    <div class="card border-0 rounded-0 p-0">
                      <div class="card-body p-0">
                        <div class="row p-0 m-0 border-bottom">
                          <div class="col-lg-4 py-2 col-12">
                            <div class="row my-1">
                              <div class="col-lg-12 col-12">
                                <p class="font-weight-bold b_name m-0">
                                  {item.bank}
                                  <button
                                    onClick={() => {
                                      setBid(item.id);
                                      setBname(item.bank);
                                      setBranch(item.branch);
                                      setAname(item.name);
                                      setAnum(item.ano);
                                    }}
                                    class="bupdtbttn mx-2 "
                                    data-toggle="modal"
                                    data-target="#delModal"
                                  >
                                    Edit
                                  </button>
                                </p>
                                <p class="m-0 b_data">
                                  Branch:{" "}
                                  <span class="font-weight-bold">
                                    {item.branch}
                                  </span>
                                </p>
                                <p class="m-0 b_data">
                                  A/C Name:{" "}
                                  <span class="font-weight-bold">
                                    {item.name}
                                  </span>
                                </p>
                                <p class="m-0 b_data">
                                  A/C No:{" "}
                                  <span class="font-weight-bold">
                                    {item.ano}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-2 col-12 bg-light py-auto">
                            <div class="row">
                              <div class="col mt-4 text-center">
                                <p class="m-0 mb-2">
                                  <span class="bdtText">BDT </span>
                                  <span class="font-weight-bold blanceText">
                                    {item.balance}
                                  </span>
                                </p>
                                <h6 class="ammountType">Balance</h6>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 col-12 py-auto">
                            <div class="row">
                              <div class="col mt-4 text-center">
                                <p class="m-0 mb-2">
                                  <span class="bdtText">BDT </span>
                                  <span class="font-weight-bold blanceText">
                                    {getTo(item.bank)}
                                  </span>
                                </p>
                                <h6 class="ammountType2">Today Bank-In</h6>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 col-12 bg-light">
                            <div class="row">
                              <div class="col mt-4 text-center">
                                <p class="m-0 mb-2">
                                  <span class="bdtText">BDT </span>
                                  <span class="font-weight-bold blanceText">
                                    {getTo2(item.bank)}
                                  </span>
                                </p>
                                <h6 class="ammountType2">Today Bank-Out</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div class="bg-light border my-4">
                  <div class="row align-items-center p-2 px-0 mx-auto border-bottom">
                    <div class="col-lg-2 col-6">
                      <h5 class="mt-1 mb-0">Cash Deal</h5>
                    </div>

                    <div class="col-lg-2 col-6">
                      {/* <div class="btn btn-filter text-dark d-flex m-0 text-center row align-items-center">
                                    <div class="col-3"><p class="m-0">Filter</p></div>
                                    <div class="col-9"> */}
                      <input
                        type="date"
                        class="filtBTN px-2"
                        onChange={(e) => dicash(e.target.value)}
                      />
                      {/* </div>
                                </div> */}
                    </div>
                  </div>

                  <div class="card border-0 rounded-0 p-0 mx-0">
                    <div class="card-body p-0 m-0">
                      <div class="row p-0 m-0">
                        <div class="col-lg-6 col-6 border-right">
                          <div class="row">
                            <div class="col my-auto py-3 text-center">
                              <p class="m-0 mb-2">
                                <span class="bdtText">BDT </span>
                                <span class="font-weight-bold blanceText2">
                                  {Number(cin)}
                                </span>
                              </p>
                              <h5 class="ammountType">Daily Cash-In</h5>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-6">
                          <div class="row">
                            <div class="col my-auto py-3 text-center">
                              <p class="m-0 mb-2">
                                <span class="bdtText">BDT </span>
                                <span class="font-weight-bold blanceText2">
                                  {Number(cout)}
                                </span>
                              </p>
                              <h5 class="ammountType">Daily Cash-Out</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="border my-4 rounded">
                  <div class="border-bottom">
                    <h5 class="mb-0 px-4 py-2">Reports</h5>
                  </div>

                  <div class="row p-4 bg-light mx-auto">
                    <Link to="/clist" class="col text-center">
                      <p class="pt-4 dTra mb-0">
                        Customer<br></br>Service Data
                      </p>
                    </Link>

                    <Link to="/transaction" class="col text-center">
                      <p class="pt-4 dTra mb-0">
                        Daily<br></br>Transaction
                      </p>
                    </Link>
                    <Link to="/duelist" class="col text-center">
                      <p class="pt-4 dTra mb-0">
                        Customer<br></br>Due Payment
                      </p>
                    </Link>

                    <Link to="/phistory" class="col text-center">
                      <p class="pt-4 dTra mb-0">
                        Payment<br></br>History
                      </p>
                    </Link>

                    <Link to="/vat" class="col text-center">
                      <p class="pt-4 dTra mb-0">
                        VAT/AIT<br></br>Report
                      </p>
                    </Link>
                  </div>
                </div>

                <div class="row my-3">
                  <div class="col-lg-6 pr-1">
                    <div class=" border bg-light px-0" style={{ height: 250 }}>
                      <div class="p-2 py-0 px-0 border-bottom">
                        <h5 class="px-3 py-1 mb-0">Service Profit Margin</h5>
                      </div>
                      <div class="row p-4">
                        <div class="col-lg-6">
                          <p style={{ fontSize: 18, fontWeight: 500 }}>
                            Monthly
                          </p>
                          <input
                            type="month"
                            class="filtBTN px-2 w-75"
                            onChange={(e) => pfetch2(e.target.value)}
                          />

                          <p
                            style={{
                              fontSize: 22,
                              fontWeight: "bold",
                              marginTop: 20,
                            }}
                          >
                            {mprofit} BDT
                          </p>
                        </div>
                        <div class="col-lg-6">
                          <p style={{ fontSize: 18, fontWeight: 500 }}>
                            Yearly
                          </p>
                          <select
                            class="filtBTN px-2 w-75"
                            onChange={(e) => pfetch3(e.target.value)}
                          >
                            <option>2022</option>
                            <option>2023</option>
                            <option>2024</option>
                            <option>2025</option>
                            <option>2026</option>
                            <option>2027</option>
                            <option>2028</option>
                            <option>2029</option>
                            <option>2030</option>
                          </select>

                          <p
                            style={{
                              fontSize: 22,
                              fontWeight: "bold",
                              marginTop: 20,
                            }}
                          >
                            {yprofit} BDT
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 pl-1">
                    <div
                      class="border bg-light mx-auto"
                      style={{ height: 250 }}
                    >
                      <div class="p-2 py-0 px-0 border-bottom">
                        <h5 class="px-3 py-1 mb-0">Transaction Statistics</h5>
                      </div>
                      <div class="row px-4 py-3 justify-content-center">
                        <div style={{ width: 150 }}>
                          <Doughnut
                            data={data}
                            width={100}
                            options={{
                              responsive: true,
                              maintainAspectRatio: true,
                              animation: {
                                duration: 100,
                                radius: 3,
                              },
                            }}
                          />
                        </div>
                        <div style={{ float: "right", marginLeft: 40 }}>
                          <input
                            type="month"
                            class="filtBTN px-2 m-2 w-75"
                            onChange={(e) => cartFill(e.target.value)}
                          />

                          <ul class="px-0">
                            <li className="d-flex align-items-center labelfont">
                              <div
                                class="cartBox m-2"
                                style={{ backgroundColor: "#FFC533" }}
                              ></div>
                              Cash In: {cartcin}
                            </li>
                            <li className="d-flex align-items-center labelfont">
                              <div
                                class="cartBox m-2"
                                style={{ backgroundColor: "#F2726F" }}
                              ></div>
                              Cash Out: {cartcout}
                            </li>
                            <li className="d-flex align-items-center labelfont">
                              <div
                                class="cartBox m-2"
                                style={{ backgroundColor: "#3bb2ed" }}
                              ></div>
                              Bank In: {bin}
                            </li>
                            <li className="d-flex align-items-center labelfont">
                              <div
                                class="cartBox m-2"
                                style={{ backgroundColor: "#BC95DF" }}
                              ></div>
                              Bank Out: {bout}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row my-3">
                  <div class="col-lg-6 pr-1">
                    <div class=" border bg-light px-0" style={{ height: 250 }}>
                      <div class="p-2 py-0 px-0 border-bottom">
                        <h5 class="px-3 py-1 mb-0">Cash to Bank</h5>
                      </div>
                      <div class="row px-4 py-3">
                        <div className="container">
                          <form id="c2bfrm">
                            <select
                              onChange={(e) => {
                                setHbank(e.target.value);
                              }}
                              className="form-control"
                            >
                              <option>Choose Bank</option>
                              {banks.map((item) => (
                                <option value={item.id + "/" + item.bank}>
                                  {item.bank}
                                </option>
                              ))}
                            </select>

                            <br />
                            <input
                              type="number"
                              placeholder="Amount"
                              className="form-control"
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                            />
                          </form>

                          <br />
                          <button
                            onClick={() => {
                              payhistory();
                            }}
                            className="btn btn-info w-100"
                          >
                            Transfer to Bank
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 pl-1">
                    <div
                      class="border bg-light mx-auto"
                      style={{ height: 250 }}
                    >
                      <div class="p-2 py-0 px-0 border-bottom">
                        <h5 class="px-3 py-1 mb-0">Bank to Cash</h5>
                      </div>
                      <div class="row px-4 py-3 justify-content-center">
                        <div className="container">
                          <form id="b2cfrm">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                setHbank(e.target.value);
                              }}
                            >
                              <option>Choose Bank</option>
                              {banks.map((item) => (
                                <option value={item.id + "/" + item.bank}>
                                  {item.bank}
                                </option>
                              ))}
                            </select>
                            <br />
                            <input
                              type="number"
                              placeholder="Amount"
                              className="form-control"
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                            />
                          </form>

                          <br />

                          <button
                            onClick={() => payhistoryb2c()}
                            className="btn btn-info w-100"
                          >
                            Transfer to Cash
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row my-3 mb-5">
                  <div class="col-lg-6 pr-1">
                    <div class=" border bg-light px-0">
                      <div class="p-2 py-0 px-0 border-bottom d-flex justify-content-between align-items-start">
                        <h5 class="px-3 py-1 mb-0 ">Vendor Statement</h5>
                        <Link
                          to={"/vens"}
                          class="btn border-bottom border-warning noprint text-dark"
                        >
                          Download
                        </Link>
                      </div>
                      <div style={{ height: 300, overflowY: "scroll" }}>
                        <table class="table">
                          <thead class="acc_reportnav">
                            <tr>
                              <th scope="col" class="  py-1">
                                #
                              </th>
                              <th scope="col" class="  py-1">
                                Vendor
                              </th>
                              <th scope="col" class="  py-1">
                                Type
                              </th>
                              <th scope="col" class="  py-1">
                                Total Deal
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {vstat.map((item, index) => (
                              <tr>
                                <th class="  py-1" scope="row">
                                  {index + 1}
                                </th>
                                <td class="  py-1">{item.vname}</td>
                                <td class="  py-1">{item.type}</td>
                                <td class="text-center py-1">{item.deal}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 pl-1">
                    <div class="border bg-light mx-auto">
                      <div class="p-2 py-0 px-0 border-bottom d-flex justify-content-between align-items-start">
                        <h5 class="px-3 py-1 mb-0">Agent Statement</h5>
                        <Link
                          to={"/agens"}
                          class="btn border-bottom border-warning noprint text-dark"
                        >
                          Download
                        </Link>
                      </div>
                      <div style={{ height: 300, overflowY: "scroll" }}>
                        <table class="table">
                          <thead class="acc_reportnav">
                            <tr>
                              <th scope="col" class="py-1">
                                #
                              </th>
                              <th scope="col" class="py-1">
                                Vendor
                              </th>
                              <th scope="col" class="text-center py-1">
                                Total Deal
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {astat.map((item, index) => (
                              <tr>
                                <th scope="row" class="  py-1">
                                  {index + 1}
                                </th>
                                <td class="py-1">{item.aname}</td>
                                <td class="text-center py-1">{item.deal}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="accback mx-0 px-0"></div> */}
          </div>
        </div>
      </div>
      <Delmodal acid={acid} acit={acit} />
      <ExModal
        active={expModal}
        uid={uid}
        coun={coun}
        setCoun={setCoun}
        close={setExpModal}
      />

      <IncomeModal
        active={inModal}
        uid={uid}
        coun={coun}
        setCoun={setCoun}
        close={setInmodal}
      />

      <Footer />
    </>
  );
}

export default Accounts;
