import { useState, useEffect } from "react";
import formatDate from "../functions/timeformat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import DelpaymentModal from "./delpayment";
import Loading from "../common/loading";

function Staffreport({ active, close, staffId }) {
  const [ticket, setTicket] = useState([]);
  const [visa, setVisa] = useState([]);
  const [hotel, setHotel] = useState([]);
  const [umrah, setUmrah] = useState([]);
  const [date, setDate] = useState("");

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/getCareof",
        {
          staffId: staffId,
          date: new Date().getFullYear(),
        }
      )
      .then((res) => {
        setTicket(res.data.ticket);
        setVisa(res.data.visa);
        setHotel(res.data.hotel);
        setUmrah(res.data.hotel);
        // setReport((item) => [
        //   ...item,
        //   ...res.data.ticket,
        //   ...res.data.visa,
        //   ...res.data.umrah,
        // ]);
      })
      .catch((err) => console.log(err));
  }, [staffId]);

  function staffilter(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/getCareof",
        {
          staffId: staffId,
          date: x,
        }
      )
      .then((res) => {
        // setReport((item) => [
        //   ...item,
        //   ...res.data.ticket,
        //   ...res.data.visa,
        //   ...res.data.umrah,
        // ]);
        setTicket(res.data.ticket);
        setVisa(res.data.visa);
        setHotel(res.data.hotel);
        setUmrah(res.data.hotel);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className={active ? "mp-Modal-backdrop" : ""}>
      <div class={active ? "mp-Modal mp-Modal-active" : "mp-Modal"}>
        <div class="mHeader">
          <i
            onClick={() => {
              close(false);
              document.getElementById("pills-home-tab").click();
            }}
            className="fa fa-close cusIcon"
          ></i>
          <p class="modal-title pModalheadear" id="CpaymentModalLabel">
            Staff Report
          </p>
          <div className=" px-4 py-1 d-flex justify-content-between align-items-center bg-light">
            <div class="d-flex align-items-center">
              <p className="mb-0">Filter</p>
              <input
                type="month"
                class="filtBTN w-75 mx-2"
                onChange={(e) => {
                  staffilter(e.target.value);
                  setDate(e.target.value);
                }}
              />
            </div>
            <p className="mb-0 rounded-pill px-3 py-1 border bg-white">
              <span class="fw-bold">Total Deals:</span>{" "}
              {ticket.length + visa.length + hotel.length + umrah.length}
            </p>
          </div>
        </div>

        <div className="container mt-2">
          <table class="table table-striped">
            <tbody className="border">
              {ticket.map((item) => (
                <tr>
                  <td>{item.cname}</td>
                  <td>{item.cnum}</td>
                  <td>{formatDate(item.date)}</td>
                  <td>
                    <span class="badge badge-dark text-light badge-pill p-2">
                      {item.service}
                    </span>
                  </td>
                </tr>
              ))}
              {visa.map((item) => (
                <tr>
                  <td>{item.cname}</td>
                  <td>{item.cnum}</td>
                  <td>{formatDate(item.date)}</td>
                  <td>
                    <span class="badge badge-dark text-light badge-pill p-2">
                      {item.service}
                    </span>
                  </td>
                </tr>
              ))}
              {hotel.map((item) => (
                <tr>
                  <td>{item.cname}</td>
                  <td>{item.cnum}</td>
                  <td>{formatDate(item.date)}</td>
                  <td>
                    <span class="badge badge-dark text-light badge-pill p-2">
                      {item.service}
                    </span>
                  </td>
                </tr>
              ))}
              {umrah.map((item) => (
                <tr>
                  <td>{item.cname}</td>
                  <td>{item.cnum}</td>
                  <td>{formatDate(item.date)}</td>
                  <td>
                    <span class="badge badge-dark text-light badge-pill p-2">
                      {item.service}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Staffreport;
