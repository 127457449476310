import axios from "axios";
import { useState, useEffect } from "react";
import {
  useHistory,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import Navigation2 from "../navigation2";

function Addgroupcus(props) {
  var id = props.match.params.id;
  const [tuser, setTuser] = useState([]);

  let history = useHistory();
  const [user, setUser] = useState([]);
  const [cname, setCname] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [next, setNext] = useState(false);
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [pnr, setPnr] = useState("");
  const [airplane, setAirplane] = useState("");

  function clearFeild() {
    setCname("");
    setBase("");
    setGross("");
  }
  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchgcustomer",
        {
          id: id,
        }
      )
      .then((res) => {
        setUser(res.data.message);
      })
      .catch((err) => console.log(err));

    const data = new FormData();
    data.append("uid", id);
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchgroupinfobyid",
        data
      )
      .then((res) => {
        setPnr(res.data.message[0].pnr);
        setAirplane(res.data.message[0].airline);
      })
      .catch((err) => console.log(err));
  }, [user]);

  function gcustomer() {
    const data = new FormData();
    data.append("cname", cname);
    data.append("base", base);
    data.append("gross", gross);
    data.append("gid", id);
    data.append("pnr", pnr);
    data.append("airplane", airplane);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/gcustomer",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          setCname("");
          setBase("");
          setGross("");
        }
      })
      .catch((err) => console.log(err));
  }

  function gupdate() {
    const data = new FormData();
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("base", getTbase());
    data.append("gross", getTgross());
    data.append("gid", id);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/gupdate",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          history.push("/");
        }
      });
  }

  function getTbase() {
    var total = 0;

    user.map((item) => {
      total = total + item.base;
    });

    return total;
  }

  function getTgross() {
    var total = 0;

    user.map((item) => {
      total = total + item.gross;
    });

    return total;
  }

  return (
    <>
      <Navigation2 />

      {next ? (
        <>
          <div className="container addCustomer">
            <div class="border bg-light mb-3">
              <h5 class="m-0 border-bottom px-3 py-2 mb-3">Ticket Info</h5>
              <div class="row px-3 ">
                <label for="" class="col-lg-2 col-12 infotext">
                  Airlines
                </label>
                <div class="col-lg-4 col-12">
                  <p>: {airplane}</p>
                </div>
              </div>

              <div class="row px-3">
                <label for="" class="col-lg-2 col-12 infotext">
                  Ticket PNR
                </label>
                <div class="col-lg-4 col-12">
                  <p>: {pnr}</p>
                </div>
              </div>
            </div>

            {user.map((item) => (
              <div className="card rounded-0 mb-2 p-3">
                <p>
                  <b>Customer Name:</b> {item.cname}
                </p>
                <p className="mb-0">
                  <b>Buying Price : </b> {item.base} BDT,{" "}
                  <b>Selling Price : </b> {item.gross} BDT{" "}
                </p>
              </div>
            ))}
          </div>
          <div className="container addCustomer mt-0 mb-5">
            <div class="border bg-light mb-3">
              <h5 class="m-0 border-bottom px-3 py-2 mb-3">Ticket Pricing</h5>
              <div class="form-group row py-1 px-3">
                <label for="" class="col-lg-2 col-4 col-form-label infotext">
                  Buying Price
                </label>
                <div class="col-lg-4 col-8 me-auto">
                  <input
                    id=""
                    name=""
                    type="number"
                    class="form-control"
                    required="required"
                    value={getTbase()}
                    disabled={true}
                  />
                </div>

                <label for="" class="col-lg-2 col-4 col-form-label infotext">
                  Selling Price
                </label>
                <div class="col-lg-4 col-8 me-auto">
                  <input
                    id=""
                    name=""
                    type="number"
                    class="form-control"
                    required="required"
                    value={getTgross()}
                    disabled={true}
                  />
                </div>
              </div>

              <div class="form-group row py-1 px-3">
                <label
                  for=""
                  class="col-lg-2 col-4 marginTop col-form-label infotext"
                >
                  VAT & AIT
                </label>
                <div class="col-lg-4 col-8 marginTop me-auto">
                  <input
                    id=""
                    name=""
                    type="number"
                    class="form-control"
                    placeholder="Ex. 0.03"
                    required=""
                    value={vat}
                    onChange={(e) => {
                      var p =
                        Number(getTgross()) -
                        (Number(getTbase()) + Number(e.target.value));
                      setVat(e.target.value);
                      setProfit(p);
                    }}
                  />
                </div>
                <label
                  for=""
                  class="col-lg-2 col-4 marginTop col-form-label infotext"
                >
                  Profit
                </label>
                <div class="col-lg-4 col-8 marginTop me-auto">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required=""
                    value={profit}
                    readOnly
                    onChange={(e) => setProfit(e.target.value)}
                  />
                </div>
              </div>
              <div className="row px-3 mb-3">
                <div className="col-10 offset-2">
                  <button
                    class="btn btn-primary w-100"
                    style={{ color: "white" }}
                    onClick={() => {
                      gupdate();
                    }}
                  >
                    Save <i className="fa fa-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container addCustomer">
          <div class="border bg-light mb-3">
            <h5 class="m-0 border-bottom px-3 py-2 mb-3">Ticket Info</h5>
            <div class="row px-3 ">
              <label for="" class="col-lg-2 col-12 infotext">
                Airlines
              </label>
              <div class="col-lg-4 col-12">
                <p>: {airplane}</p>
              </div>
            </div>

            <div class="row px-3">
              <label for="" class="col-lg-2 col-12 infotext">
                Ticket PNR
              </label>
              <div class="col-lg-4 col-12">
                <p>: {pnr}</p>
              </div>
            </div>
          </div>

          {user.map((item) => (
            <div className="card rounded-0 mb-2 p-3">
              <p>
                <b>Customer Name:</b> {item.cname}
              </p>
              <p className="mb-0">
                <b>Buying Price : </b> {item.base} BDT, <b>Selling Price : </b>{" "}
                {item.gross} BDT{" "}
              </p>
            </div>
          ))}

          <div class="border bg-light mb-5">
            <h5 class="m-0 border-bottom px-3 py-2 mb-3">Customer Info</h5>

            <div class="form-group row py-2 px-3">
              <label for="" class="col-2 col-form-label infotext">
                Customer Name
              </label>
              <div class="col-10">
                <input
                  id=""
                  name=""
                  type="text"
                  class="form-control"
                  value={cname}
                  onChange={(e) => setCname(e.target.value)}
                />
              </div>
            </div>

            <div class="form-group row py-2 px-3">
              <label for="" class="col-lg-2 col-form-label infotext">
                Buying Price
              </label>
              <div class="col-lg-4">
                <input
                  id=""
                  name=""
                  type="number"
                  class="form-control"
                  value={base}
                  onChange={(e) => setBase(e.target.value)}
                />
              </div>

              <label for="" class="col-lg-2 col-form-label infotext">
                Selling Price
              </label>

              <div class="col-lg-4">
                <input
                  id=""
                  name=""
                  type="number"
                  class="form-control"
                  value={gross}
                  onChange={(e) => setGross(e.target.value)}
                />
              </div>
            </div>

            <div class="row mb-3 p-3">
              <div class="col-2"></div>
              <div class="col-10">
                <button
                  class="btn btn-primary bg-light"
                  style={{ color: "black" }}
                  onClick={() => {
                    gcustomer();
                  }}
                >
                  Add More Customer
                </button>

                <button
                  class="btn btn-primary"
                  style={{ color: "white", marginLeft: 12 }}
                  onClick={() => {
                    gcustomer();
                    setNext(true);
                  }}
                >
                  Next <i className="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(Addgroupcus);
