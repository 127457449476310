import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import client from "../../images/placeholder.jpg";
import Logo from "../../images/flydoc.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Printhead from "../printhead";

function Hajjprofile(props) {
  var id = props.match.params.id;

  let history = useHistory();

  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [fil, setFil] = useState("");
  const [cid, setCid] = useState(0);
  const [cserial, setCserial] = useState("");
  const [customer, setCustomer] = useState([]);

  const [pass, setPass] = useState("");
  const [visa, setVisa] = useState("");
  const [idate, setIdate] = useState("");
  const [vval, setVval] = useState("");
  const [fno, setFno] = useState("");
  const [gpnr, setGpnr] = useState("");
  const [serial, setSerial] = useState("");
  const [departure, setDeparture] = useState("");
  const [arrival, setArrival] = useState("");
  const [hname, setHname] = useState("");
  const [cin, setCin] = useState("");
  const [cout, setCout] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [note, setNote] = useState("");
  const [cphoto, setCphoto] = useState("");

  const [vendor, setVendor] = useState("");
  const [vendors, setVendors] = useState([]);

  const [mno, setMno] = useState("");
  const [trans, setTrans] = useState("");
  const [sno, setSno] = useState("");
  const [mealno, setMealno] = useState("");

  const [edate, setEdate] = useState("");

  const [mnum, setMnum] = useState("");
  const [tnum, setTnum] = useState("");
  const [snum, setSnum] = useState("");
  const [menum, setMenum] = useState("");

  const [aid, setAid] = useState("");
  const [agents, setAgents] = useState([]);
  const [cof, setCof] = useState("");

  useEffect(() => {
    // if(type == "visa" || type == "domestic" || type == "international"){
    //     setSearch("regular");
    // }

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }
  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchshajj",
        {
          id: id,
        }
      )
      .then((res) => {
        setCserial(res.data.message[0].cserial);
        setCname(res.data.message[0].cname);
        setCnum(res.data.message[0].cnum);
        setCadd(res.data.message[0].cadd);

        setPass(res.data.message[0].passport);
        setHname(res.data.message[0].hname);
        setIdate(res.data.message[0].idate);
        setFno(res.data.message[0].fno);
        setGpnr(res.data.message[0].gpnr);
        setVval(res.data.message[0].vval);
        setVisa(res.data.message[0].visa);
        setBase(res.data.message[0].base);
        setGross(res.data.message[0].gross);
        setVat(res.data.message[0].vat);
        setProfit(res.data.message[0].profit);
        setSerial(res.data.message[0].serial);
        setCphoto(res.data.message[0].cphoto);
        setCin(res.data.message[0].cin);
        setCout(res.data.message[0].cout);
        setVendor(res.data.message[0].vendor);

        setMno(res.data.message[0].mofa);
        setTrans(res.data.message[0].trans);

        setSno(res.data.message[0].sservice);
        setMealno(res.data.message[0].meal);

        setEdate(res.data.message[0].edate);
        setNote(res.data.message[0].note);
        setAid(res.data.message[0].aid);

        setMnum(res.data.message[0].mono);
        setTnum(res.data.message[0].transno);
        setSnum(res.data.message[0].sno);
        setMenum(res.data.message[0].mealno);
        setCof(res.data.message[0].cof);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => {
        setVendors(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [vendors]);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1000);
  }, []);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div class="container">
        <Printhead />

        <div class="prheadc mt-3">
          <h3>Service Name: Hajj</h3>
        </div>

        <div class="dtran cview">
          <div class="d-flex adcst">
            <h5 class="m-0">Customer Data</h5>
          </div>
          <hr />

          <div class="contentC">
            <div class="form-group vcinfo">
              <div class="col-3">
                {cphoto == "" ? (
                  <img src={client} class="clientpp2" />
                ) : (
                  <img
                    src={
                      "https://concorde-api.flydocx.com/" +
                      process.env.REACT_APP_URL +
                      "/images/" +
                      cphoto
                    }
                    class="clientpp2"
                    alt="..."
                  />
                )}
              </div>
              <div class="col-9">
                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Serial
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cserial}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Name
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cname}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Mobile Number
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cnum}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Address
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cadd}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Passport No
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {pass}</p>
                  </div>
                </div>
                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Passport Validity
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {edate}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Visa No
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {visa}</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="d-flex adcst">
                <h5 class="m-0">Service Data</h5>
              </div>
              <hr />

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Issue Date
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {idate}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  VISA Validity
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {vval}</p>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Hotel Name
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {hname}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Hotel Check In
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {cin}</p>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Hotel Check Out
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {cout}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Flight No
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {fno}</p>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  GDS PNR
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {gpnr}</p>
                </div>
              </div>

              <div class="d-flex adcst" style={{ marginTop: 50 }}>
                <h5 class="m-0">Additional Service</h5>
              </div>
              <hr />

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Mofa
                </label>
                <div class="col-4">
                  <p
                    class="font-weight-bold m-0 p-1"
                    style={{ textTransform: "uppercase" }}
                  >
                    : {mno} {mno == "yes" ? " ( " + mnum + " BDT )" : null}
                  </p>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Transport
                </label>
                <div class="col-4">
                  <p
                    class="font-weight-bold m-0 p-1"
                    style={{ textTransform: "uppercase" }}
                  >
                    : {trans} {trans == "yes" ? " (" + tnum + " BDT )" : null}
                  </p>
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Special Service
                </label>
                <div class="col-4">
                  <p
                    class="font-weight-bold m-0 p-1"
                    style={{ textTransform: "uppercase" }}
                  >
                    : {sno} {sno == "yes" ? " (" + snum + " BDT )" : null}
                  </p>
                </div>

                <label for="" class="col-2 col-form-label infotext">
                  Meal
                </label>
                <div class="col-4">
                  <p
                    class="font-weight-bold m-0 p-1"
                    style={{ textTransform: "uppercase" }}
                  >
                    : {mealno}{" "}
                    {mealno == "yes" ? " (" + menum + " BDT )" : null}
                  </p>
                </div>
              </div>

              <hr />
              {aid == 0 ? null : (
                <div class="form-group row">
                  <label for="" class="col-2 col-form-label infotext">
                    Agent Name
                  </label>
                  <div class="col-10">
                    <p class="font-weight-bold m-0 p-1">: {getagent(aid)}</p>
                  </div>
                </div>
              )}

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Vendor Name
                </label>
                <div class="col-4">
                  {vendors.map((y) => (
                    <p class="font-weight-bold m-0 p-1">
                      {y.id == vendor ? ": " + y.vname : null}
                    </p>
                  ))}
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Additional note
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">: {note}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Care Of
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">: {cof}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer" style={{ position: "fixed", bottom: 0 }}>
          <div class="dflex" style={{ marginLeft: 10, marginRight: 10 }}>
            <p style={{ fontSize: 14, fontWeight: "bold" }}>
              Developed by mPair Technologies Ltd.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default withRouter(Hajjprofile);
