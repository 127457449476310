import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../screens/footer";
import Loading from "../../screens/common/loading";

function AddTicket({ cid, active }) {
  let history = useHistory();

  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");

  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [flight, setFlight] = useState("");
  const [base, setBase] = useState("");
  const [dob, setDob] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [gross, setGross] = useState("");
  const [idate, setIdate] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [arr, setArr] = useState("");
  const [arrlocation, setArrLocation] = useState("");

  const [de, setDe] = useState("");
  const [delocation, setDeLocation] = useState("");

  const [airplane, setAirplane] = useState("");
  const [vendor, setVendor] = useState("");
  const [pval, setPval] = useState("");
  const [visa, setVisa] = useState("");
  const [serial, setSerial] = useState("");
  const [cphoto, setCphoto] = useState("");
  const [cserial, setCserial] = useState("");
  const [aid, setAid] = useState(0);
  const [agents, setAgents] = useState([]);
  const [note, setNote] = useState("");
  const [userData, setUsereData] = useState("");
  const [vendors, setVendors] = useState([]);
  const [cof, setCof] = useState("");
  const [adult, setAdult] = useState("");
  const [infant, setInfant] = useState("");
  const [baby, setBaby] = useState("");
  const [tranlocation, setTranLocation] = useState("");
  const [trantime, setTrantime] = useState("");
  const [showTran, setShowTran] = useState(false);

  //INTERNATION

  const [tnote, setTnote] = useState("");
  const [ctype, setCtype] = useState("agent");
  const [show, setShow] = useState(false);
  const [gpnr, setGpnr] = useState("");
  const [load, setLoad] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    setLoad(true);
    if (active == "int") {
      var arrival = arrlocation + " " + arr;
      var departure = delocation + " " + de;

      const data = new FormData();
      data.append("cid", cid);
      data.append("cname", cname);
      data.append("cnum", cnum);
      data.append("cadd", cadd);
      data.append("dob", dob);

      data.append("ctype", ctype);
      data.append("aid", aid);
      data.append("passport", passport);

      data.append("cserial", cserial);
      data.append("nid", nid);
      data.append("pval", pval);
      data.append("user", userData);

      data.append("flight", flight);
      data.append("base", base);
      data.append("gross", gross);
      data.append("idate", idate);
      data.append("vat", vat);
      data.append("profit", profit);
      data.append("arrival", arrival);
      data.append("departure", departure);
      data.append("airplane", airplane);
      data.append("vendor", vendor);
      data.append("type", "international");
      data.append("serial", serial);
      data.append("visa", visa);
      data.append("gpnr", gpnr);
      data.append("note", note);
      data.append("edate", pval);
      data.append("cof", cof);
      data.append("adult", adult);
      data.append("infant", infant);
      data.append("baby", baby);
      data.append("tnote", tnote);
      data.append("tranlocation", tranlocation);
      data.append("trantime", trantime);
      data.append("token", localStorage.getItem("token"));

      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/adservice",
          data
        )
        .then((res) => {
          if (res.data.message == null) {
            setLoad(false);
            toast.success("customer Added", {
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              toastId: "success4",
              autoClose: 2000,
            });

            setCname("");
            setCadd("");
            setCname("");

            history.push("/");
          }
        })
        .catch((err) => console.log(err));
    }

    if (active == "dom") {
      var arrival = arrlocation + " " + arr;
      var departure = delocation + " " + de;

      const data = new FormData();
      data.append("cid", cid);
      data.append("cname", cname);
      data.append("cnum", cnum);
      data.append("cadd", cadd);
      data.append("dob", dob);

      data.append("ctype", ctype);
      data.append("aid", aid);
      data.append("passport", passport);

      data.append("cserial", cserial);
      data.append("nid", nid);
      data.append("pval", pval);
      data.append("user", userData);

      data.append("flight", flight);
      data.append("base", base);
      data.append("gross", gross);
      data.append("idate", idate);
      data.append("vat", vat);
      data.append("profit", profit);
      data.append("arrival", arrival);
      data.append("departure", departure);
      data.append("airplane", airplane);
      data.append("vendor", vendor);
      data.append("type", "domestic");
      data.append("serial", serial);
      data.append("visa", visa);
      data.append("gpnr", gpnr);
      data.append("note", note);
      data.append("edate", pval);
      data.append("cof", cof);
      data.append("adult", adult);
      data.append("infant", infant);
      data.append("baby", baby);
      data.append("tnote", tnote);
      data.append("tranlocation", tranlocation);
      data.append("trantime", trantime);
      data.append("token", localStorage.getItem("token"));

      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/adservice",
          data
        )
        .then((res) => {
          if (res.data.message == null) {
            toast.success("customer Added", {
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              toastId: "success4",
              autoClose: 2000,
            });

            setCname("");
            setCadd("");
            setCname("");

            history.push("/");
          }
        })
        .catch((err) => console.log(err));
    }
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchalltypevendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchstaff"
      )
      .then((res) => setStaffs(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomerbyid",
        {
          cid: cid,
        }
      )
      .then((res) => {
        setCname(res.data.suser[0].cname);
        setCadd(res.data.suser[0].cadd);
        setCnum(res.data.suser[0].cnum);
        setDob(res.data.suser[0].dob);
        setNid(res.data.suser[0].nid);
        setPassport(res.data.suser[0].passport);
        setPval(res.data.suser[0].pval);
        setCserial(res.data.suser[0].serial);
        setCphoto(res.data.suser[0].cphoto);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <ToastContainer />

      <div class="container border mb-4 bg-light pt-3">
        <div class="pb-3">
          <Loading load={load} />
          <form onSubmit={handleSubmit} id="create-course-form">
            {active == "dom" ? (
              <div>
                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Customer Type
                  </label>
                  <div class="col-lg-4 mt-2">
                    <div class="form-check form-check-inline">
                      <input
                        name="ctype"
                        id="fit"
                        type="radio"
                        class="form-check-input"
                        value="regular"
                        required=""
                        checked={ctype == "regular" ? true : false}
                        onChange={(e) => {
                          setCtype(e.target.value);
                          setShow(false);
                        }}
                      />
                      <label for="_0" class="form-check-label infotext mr-2">
                        Regular
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        name="ctype"
                        id="fit"
                        type="radio"
                        class="form-check-input"
                        value="agent"
                        required="required"
                        checked={ctype == "agent" ? true : false}
                        onChange={(e) => {
                          setCtype(e.target.value);
                          setShow(true);
                        }}
                      />
                      <label for="_0" class="form-check-label infotext mr-2">
                        From Agent
                      </label>
                    </div>
                  </div>
                </div>

                {ctype == "agent" ? (
                  <div class="form-group row py-1">
                    <label
                      for=""
                      class="col-lg-2 col-12 col-form-label infotext"
                    >
                      Agent Name
                    </label>
                    <div class="col-lg-4 col-12">
                      <div class="input-group">
                        {ctype == "agent" ? (
                          <select
                            class="form-control"
                            value={aid}
                            onChange={(e) => setAid(e.target.value)}
                          >
                            <option>Choose Agent</option>
                            {agents.map((item) => (
                              <option value={item.id}>{item.aname}</option>
                            ))}
                          </select>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Issue Date
                  </label>
                  <div class="col-lg-4 col-12">
                    <input
                      id=""
                      name=""
                      type="date"
                      class="form-control"
                      required=""
                      value={idate}
                      onChange={(e) => setIdate(e.target.value)}
                    />
                  </div>
                  <label
                    for=""
                    class="col-lg-2 col-12 marginTop col-form-label infotext"
                  >
                    Airplane
                  </label>
                  <div class="col-lg-4 col-12">
                    <div class="input-group">
                      <div class="input-group-prepend"></div>
                      <select
                        id="inputState"
                        class="form-select form-control"
                        value={airplane}
                        onChange={(e) => setAirplane(e.target.value)}
                      >
                        <option class="form-control" selected>
                          Choose Airlines
                        </option>
                        <option>Biman Bangladesh Airlines</option>
                        <option>Regent Airways</option>
                        <option>US-Bangla Airlines</option>
                        <option>Novoair</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Departure
                  </label>
                  <div class="col-lg-2 col-6 pr-1">
                    <input
                      id=""
                      name=""
                      type="text"
                      placeholder="Ex. DAC"
                      class="form-control"
                      required=""
                      value={delocation}
                      onChange={(e) => setDeLocation(e.target.value)}
                    />
                  </div>
                  <div class="col-lg-2 col-6 pl-0">
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #ddd",
                        overflow: "auto",
                        padding: 5,
                        borderRadius: 4,
                      }}
                    >
                      <input
                        id=""
                        name=""
                        type="date"
                        placeholder=""
                        style={{
                          border: "none",
                          outline: "none",
                          width: "100%",
                          fontSize: 14,
                          height: 26,
                        }}
                        //  class="form-control"
                        required=""
                        value={de}
                        onChange={(e) => {
                          setDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <label
                    for=""
                    class="col-lg-2 col-12 marginTop col-form-label infotext"
                  >
                    Arrival
                  </label>
                  <div class="col-lg-2 col-6 pr-1">
                    <input
                      id=""
                      name=""
                      type="text"
                      placeholder="Ex. CGP"
                      class="form-control"
                      required=""
                      value={arrlocation}
                      onChange={(e) => setArrLocation(e.target.value)}
                    />
                  </div>
                  <div class="col-lg-2 col-6 pl-0">
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #ddd",
                        overflow: "auto",
                        padding: 5,
                        borderRadius: 4,
                      }}
                    >
                      <input
                        id=""
                        name=""
                        type="date"
                        placeholder=""
                        style={{
                          border: "none",
                          outline: "none",
                          width: "100%",
                          fontSize: 14,
                          height: 26,
                        }}
                        //  class="form-control"
                        required=""
                        value={arr}
                        onChange={(e) => {
                          setArr(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Flight No
                  </label>
                  <div class="col-lg-4 col-12">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      required=""
                      value={flight}
                      onChange={(e) => setFlight(e.target.value)}
                      readOnly
                    />
                  </div>

                  <label
                    for=""
                    class="col-lg-2 col-12 marginTop col-form-label infotext"
                  >
                    Ticket Serial
                  </label>
                  <div class="col-lg-4 col-12 me-auto">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      required=""
                      value={serial}
                      onChange={(e) => setSerial(e.target.value)}
                      readOnly
                    />
                  </div>
                </div>

                <div class="form-group row py-1">
                  <label
                    for=""
                    class="col-lg-2 col-12 marginTop col-form-label infotext"
                  >
                    Ticket PNR
                  </label>
                  <div class="col-lg-4 col-12 me-auto">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      required=""
                      value={gpnr}
                      onChange={(e) => setGpnr(e.target.value)}
                    />
                  </div>

                  <label
                    for=""
                    class="col-lg-2 col-12 marginTop col-form-label infotext"
                  >
                    Care Of
                  </label>
                  <div class="col-lg-4 col-12 me-auto">
                    <select
                      class="form-control"
                      value={cof}
                      onChange={(e) => setCof(e.target.value)}
                    >
                      <option>Choose Staff</option>
                      {staffs.map((item) => (
                        <option value={item.id}>{item.sname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div class="form-group row py-1">
                  <label
                    for="textarea"
                    class="col-lg-2 col-12 infotext col-form-label"
                  >
                    Additional Note
                  </label>
                  <div class="col-lg-10 col-12">
                    <textarea
                      id="textarea"
                      name="textarea"
                      cols="40"
                      rows="2"
                      class="form-control"
                      required=""
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                <hr />

                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Vendor Name
                  </label>
                  <div class="col-lg-4 col-12">
                    <div class="input-group">
                      <select
                        id="inputState"
                        class="form-select form-control"
                        value={vendor}
                        onChange={(e) => setVendor(e.target.value)}
                      >
                        <option class="form-control" selected>
                          Choose Vendor
                        </option>

                        {vendors.map((item) => {
                          if (item.type == "ticket") {
                            return (
                              <option value={item.id}>{item.vname}</option>
                            );
                          }

                          if (item.type == "all") {
                            return (
                              <option value={item.id}>{item.vname}</option>
                            );
                          }
                        })}
                      </select>
                    </div>
                  </div>

                  <label
                    for=""
                    class="col-lg-2 col-4 marginTop col-form-label infotext"
                  >
                    Buying Price
                  </label>
                  <div class="col-lg-4 col-8 marginTop me-auto">
                    <input
                      id=""
                      name=""
                      type="number"
                      class="form-control"
                      required="required"
                      value={base}
                      onChange={(e) => setBase(e.target.value)}
                    />
                  </div>
                </div>

                {show ? (
                  <>
                    <div class="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-2 col-4 marginTop col-form-label infotext"
                      >
                        Agent Fare
                      </label>
                      <div class="col-lg-2 col-8 marginTop me-auto">
                        <input
                          id=""
                          name=""
                          type="number"
                          class="form-control"
                          required="required"
                          value={gross}
                          onChange={(e) => setGross(e.target.value)}
                        />
                      </div>

                      <label
                        for=""
                        class="col-lg-2 col-4 marginTop col-form-label infotext"
                      >
                        VAT & AIT
                      </label>
                      <div class="col-lg-2 col-8 marginTop me-auto">
                        <input
                          id=""
                          name=""
                          type="number"
                          class="form-control"
                          placeholder="Ex. 0.03"
                          required=""
                          value={vat}
                          onChange={(e) => {
                            var p =
                              Number(gross) -
                              (Number(base) + Number(e.target.value));
                            setVat(e.target.value);
                            setProfit(p);
                          }}
                        />
                      </div>
                      <label
                        for=""
                        class="col-lg-2 col-4 marginTop col-form-label infotext"
                      >
                        Profit
                      </label>
                      <div class="col-lg-2 col-8 marginTop me-auto">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required=""
                          value={profit}
                          readOnly
                          onChange={(e) => setProfit(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div class="form-group row py-1">
                    <label
                      for=""
                      class="col-lg-2 col-4 col-form-label infotext"
                    >
                      Selling Price
                    </label>
                    <div class="col-lg-2 col-8 me-auto">
                      <input
                        id=""
                        name=""
                        type="number"
                        class="form-control"
                        required="required"
                        value={gross}
                        onChange={(e) => setGross(e.target.value)}
                      />
                    </div>

                    <label
                      for=""
                      class="col-lg-2 col-4 marginTop col-form-label infotext"
                    >
                      VAT & AIT
                    </label>
                    <div class="col-lg-2 col-8 marginTop me-auto">
                      <input
                        id=""
                        name=""
                        type="number"
                        class="form-control"
                        placeholder="Ex. 0.03"
                        required=""
                        value={vat}
                        onChange={(e) => {
                          var p =
                            Number(gross) -
                            (Number(base) + Number(e.target.value));
                          setVat(e.target.value);
                          setProfit(p);
                        }}
                      />
                    </div>
                    <label
                      for=""
                      class="col-lg-2 col-4 marginTop col-form-label infotext"
                    >
                      Profit
                    </label>
                    <div class="col-lg-2 col-8 marginTop me-auto">
                      <input
                        id=""
                        name=""
                        type="text"
                        class="form-control"
                        required=""
                        value={profit}
                        readOnly
                        onChange={(e) => setProfit(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            {active == "int" ? (
              <div>
                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Customer Type
                  </label>
                  <div class="col-lg-4 col-12 mt-2">
                    <div class="form-check form-check-inline">
                      <input
                        name="ctype"
                        id="fit"
                        type="radio"
                        class="form-check-input"
                        value="regular"
                        required="required"
                        checked={ctype == "regular" ? true : false}
                        onChange={(e) => {
                          setCtype(e.target.value);
                          setShow(false);
                        }}
                      />
                      <label for="_0" class="form-check-label infotext mr-2">
                        Regular
                      </label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input
                        name="ctype"
                        id="fit"
                        type="radio"
                        class="form-check-input"
                        value="agent"
                        required="required"
                        checked={ctype == "agent" ? true : false}
                        onChange={(e) => {
                          setCtype(e.target.value);
                          setShow(true);
                        }}
                      />
                      <label for="_0" class="form-check-label infotext mr-2">
                        From Agent
                      </label>
                    </div>
                  </div>
                </div>

                {ctype == "agent" ? (
                  <div class="form-group row py-1">
                    <label
                      for=""
                      class="col-lg-2 col-12 col-form-label infotext"
                    >
                      Agent Name
                    </label>
                    <div class="col-lg-4 col-12">
                      <div class="input-group">
                        {ctype == "agent" ? (
                          <select
                            class="form-control"
                            value={aid}
                            onChange={(e) => setAid(e.target.value)}
                          >
                            <option>Choose Agent</option>
                            {agents.map((item) => (
                              <option value={item.id}>{item.aname}</option>
                            ))}
                          </select>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div class="form-group row py-0">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Visa No
                  </label>
                  <div class="col-lg-4 col-12 me-auto">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      required=""
                      value={visa}
                      onChange={(e) => setVisa(e.target.value)}
                    />
                  </div>
                </div>

                <div class="form-group row py-0">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Issue Date
                  </label>
                  <div class="col-lg-4 col-12">
                    <input
                      id=""
                      name=""
                      type="date"
                      class="form-control"
                      required=""
                      value={idate}
                      onChange={(e) => setIdate(e.target.value)}
                    />
                  </div>
                  <label
                    for=""
                    class="col-lg-2 col-12 marginTop col-form-label infotext"
                  >
                    Airplane
                  </label>
                  <div class="col-lg-4 col-12">
                    <div class="input-group">
                      <div class="input-group-prepend"></div>
                      <select
                        id="inputState"
                        class="form-select form-control"
                        value={airplane}
                        onChange={(e) => setAirplane(e.target.value)}
                      >
                        <option class="form-control" selected>
                          Choose Airlines
                        </option>
                        <option>Air Arabia</option>
                        <option>Air Asia</option>
                        <option>Air India</option>
                        <option>Air KZB Company Ltd.</option>
                        <option>Biman Bangladesh Airlines</option>
                        <option>Bangkok Airlines</option>
                        <option>Bismillah Airlines</option>
                        <option>British Airways</option>
                        <option>Cathay Pacific</option>
                        <option>China Eastern Airlines</option>
                        <option>China Southern Airlines</option>
                        <option>Dragonair</option>
                        <option>Etihad Airways</option>
                        <option>Finnair</option>
                        <option>Fly Emirates</option>
                        <option>Fly Dubai</option>
                        <option>Gulf Air</option>
                        <option>Himalaya Airlines</option>
                        <option>Indigo</option>
                        <option>Jazeera Airways</option>
                        <option>Jet Airways</option>
                        <option>Kuwait Airways</option>
                        <option>Malaysia Airlines</option>
                        <option>Malindo Air</option>
                        <option>Novoair</option>
                        <option>Oman Air</option>
                        <option>Pakistan International Airlines</option>
                        <option>Qatar Airways</option>
                        <option>Regent Airways</option>
                        <option>Salam Air</option>
                        <option>Saudi Arabian Airlines</option>
                        <option>Saudia</option>
                        <option>Singapore Airlines</option>
                        <option>SpiceJet</option>
                        <option>Srilankan Airlines</option>
                        <option>Thai Airways</option>
                        <option>Thai Airways International</option>
                        <option>Turkish Airlines</option>
                        <option>United Airlines</option>
                        <option>US-Bangla Airlines</option>
                        <option>Yemenia</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Departure
                  </label>
                  <div class="col-lg-2 col-6 pr-1">
                    <input
                      id=""
                      name=""
                      type="text"
                      placeholder="Ex. DHAKA"
                      class="form-control"
                      required=""
                      value={delocation}
                      onChange={(e) => setDeLocation(e.target.value)}
                    />
                    <div class="d-flex align-items-center">
                      <small style={{ color: "#ffa200" }}>
                        Transit avaiable ?
                      </small>
                      <input
                        type="checkbox"
                        class="m-2"
                        onChange={(e) => setShowTran(!showTran)}
                      />
                    </div>
                  </div>
                  <div class="col-lg-2 col-6 pl-0">
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #ddd",
                        overflow: "auto",
                        padding: 5,
                        borderRadius: 4,
                      }}
                    >
                      <input
                        id=""
                        name=""
                        type="date"
                        placeholder="Ex. 14:45 24 April"
                        style={{
                          border: "none",
                          outline: "none",
                          height: 26,
                          width: "100%",
                          fontSize: 14,
                        }}
                        //  class="form-control"
                        required=""
                        value={de}
                        onChange={(e) => {
                          setDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <label
                    for=""
                    class="col-lg-2 col-12 marginTop col-form-label infotext"
                  >
                    Arrival
                  </label>
                  <div class="col-lg-2 col-6 pr-1">
                    <input
                      id=""
                      name=""
                      type="text"
                      placeholder="Ex. DUBAI"
                      class="form-control"
                      required=""
                      value={arrlocation}
                      onChange={(e) => setArrLocation(e.target.value)}
                    />
                  </div>
                  <div class="col-lg-2 col-6 pl-0">
                    <div
                      style={{
                        display: "flex",
                        border: "1px solid #ddd",
                        overflow: "auto",
                        padding: 5,
                        borderRadius: 4,
                      }}
                    >
                      <input
                        id=""
                        name=""
                        type="date"
                        placeholder="Ex. 14:45 24 April"
                        style={{
                          border: "none",
                          outline: "none",
                          height: 26,
                          width: "100%",
                          fontSize: 14,
                        }}
                        //  class="form-control"
                        required=""
                        value={arr}
                        onChange={(e) => {
                          setArr(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {showTran ? (
                  <div class="form-group row py-1">
                    <label
                      for=""
                      class="col-lg-2 col-12 col-form-label infotext"
                    >
                      Transit Route
                    </label>
                    <div class="col-lg-2 col-6 pr-1">
                      <input
                        id=""
                        name=""
                        type="text"
                        placeholder="Location"
                        class="form-control"
                        required=""
                        value={tranlocation}
                        onChange={(e) => setTranLocation(e.target.value)}
                      />
                    </div>

                    <div class="col-lg-2 col-6 pl-0">
                      <div
                        style={{
                          display: "flex",
                          border: "1px solid #ddd",
                          overflow: "auto",
                          padding: 5,
                          borderRadius: 4,
                        }}
                      >
                        <input
                          id=""
                          name=""
                          type="text"
                          placeholder="1:00 Hour"
                          style={{
                            border: "none",
                            outline: "none",
                            width: "100%",
                            fontSize: 14,
                            height: 26,
                          }}
                          //  class="form-control"
                          required=""
                          value={trantime}
                          onChange={(e) => {
                            setTrantime(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Flight No
                  </label>
                  <div class="col-lg-4 col-12">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      required=""
                      value={flight}
                      onChange={(e) => setFlight(e.target.value)}
                    />
                  </div>

                  <label
                    for=""
                    class="col-lg-2 col-12 marginTop col-form-label infotext"
                  >
                    Ticket Serial
                  </label>
                  <div class="col-lg-4 col-12 me-auto">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      required=""
                      value={serial}
                      onChange={(e) => setSerial(e.target.value)}
                    />
                  </div>
                </div>

                <div class="form-group row py-1">
                  <label
                    for=""
                    class="col-lg-2 col-12 marginTop col-form-label infotext"
                  >
                    Ticket PNR
                  </label>
                  <div class="col-lg-4 col-12 me-auto">
                    <input
                      id=""
                      name=""
                      type="text"
                      class="form-control"
                      required=""
                      value={gpnr}
                      onChange={(e) => setGpnr(e.target.value)}
                    />
                  </div>

                  <label
                    for=""
                    class="col-lg-2 col-12 marginTop col-form-label infotext"
                  >
                    Care of
                  </label>
                  <div class="col-lg-4 col-12 me-auto">
                    <select
                      class="form-control"
                      value={cof}
                      onChange={(e) => setCof(e.target.value)}
                    >
                      <option>Choose Staff</option>
                      {staffs.map((item) => (
                        <option value={item.id}>{item.sname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div class="form-group row py-1">
                  <label
                    for="textarea"
                    class="col-lg-2 col-12 infotext col-form-label"
                  >
                    Additional Note
                  </label>
                  <div class="col-lg-10 col-12">
                    <textarea
                      id="textarea"
                      name="textarea"
                      cols="40"
                      rows="2"
                      class="form-control"
                      required=""
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                <hr />

                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Vendor Name
                  </label>
                  <div class="col-lg-4 col-12">
                    <div class="input-group">
                      <select
                        id="inputState"
                        class="form-select form-control"
                        value={vendor}
                        onChange={(e) => setVendor(e.target.value)}
                      >
                        <option class="form-control" selected>
                          Choose Vendor
                        </option>

                        {vendors.map((item) => {
                          if (item.type == "ticket") {
                            return (
                              <option value={item.id}>{item.vname}</option>
                            );
                          }

                          if (item.type == "all") {
                            return (
                              <option value={item.id}>{item.vname}</option>
                            );
                          }
                        })}
                      </select>
                    </div>
                  </div>

                  <label
                    for=""
                    class="col-lg-2 col-4 marginTop col-form-label infotext"
                  >
                    Buying Price
                  </label>
                  <div class="col-lg-4 col-8 marginTop me-auto">
                    <input
                      id=""
                      name=""
                      type="number"
                      class="form-control"
                      required="required"
                      value={base}
                      onChange={(e) => setBase(e.target.value)}
                    />
                  </div>
                </div>

                {show ? (
                  <>
                    <div class="form-group row py-1">
                      <label
                        for=""
                        class="col-lg-2 col-4 marginTop col-form-label infotext"
                      >
                        Agent Fare
                      </label>
                      <div class="col-lg-2 col-8 marginTop me-auto">
                        <input
                          id=""
                          name=""
                          type="number"
                          class="form-control"
                          required="required"
                          value={gross}
                          onChange={(e) => setGross(e.target.value)}
                        />
                      </div>

                      <label
                        for=""
                        class="col-lg-2 col-4 marginTop col-form-label infotext"
                      >
                        VAT & AIT
                      </label>
                      <div class="col-lg-2 col-8 marginTop me-auto">
                        <input
                          id=""
                          name=""
                          type="number"
                          class="form-control"
                          placeholder="Ex. 0.03"
                          required=""
                          value={vat}
                          onChange={(e) => {
                            var p =
                              Number(gross) -
                              (Number(base) + Number(e.target.value));
                            setVat(e.target.value);
                            setProfit(p);
                          }}
                        />
                      </div>
                      <label
                        for=""
                        class="col-lg-2 col-4 marginTop col-form-label infotext"
                      >
                        Profit
                      </label>
                      <div class="col-lg-2 col-8 marginTop me-auto">
                        <input
                          id=""
                          name=""
                          type="text"
                          class="form-control"
                          required=""
                          value={profit}
                          readOnly
                          onChange={(e) => setProfit(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div class="form-group row py-1">
                    <label
                      for=""
                      class="col-lg-2 col-4 col-form-label infotext"
                    >
                      Selling Price
                    </label>
                    <div class="col-lg-2 col-8 me-auto">
                      <input
                        id=""
                        name=""
                        type="number"
                        class="form-control"
                        required="required"
                        value={gross}
                        onChange={(e) => setGross(e.target.value)}
                      />
                    </div>

                    <label
                      for=""
                      class="col-lg-2 col-4 marginTop col-form-label infotext"
                    >
                      VAT & AIT
                    </label>
                    <div class="col-lg-2 col-8 marginTop me-auto">
                      <input
                        id=""
                        name=""
                        type="number"
                        class="form-control"
                        placeholder="Ex. 0.03"
                        required=""
                        value={vat}
                        onChange={(e) => {
                          var p =
                            Number(gross) -
                            (Number(base) + Number(e.target.value));
                          setVat(e.target.value);
                          setProfit(p);
                        }}
                      />
                    </div>
                    <label
                      for=""
                      class="col-lg-2 col-4 marginTop col-form-label infotext"
                    >
                      Profit
                    </label>
                    <div class="col-lg-2 col-8 marginTop me-auto">
                      <input
                        id=""
                        name=""
                        type="text"
                        class="form-control"
                        required=""
                        value={profit}
                        readOnly
                        onChange={(e) => setProfit(e.target.value)}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            <div class="form-group row py-2">
              <div class="col-lg-2"></div>
              <div class="col-lg-10 col-12">
                <button class="w-100 btn btn-primary">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default withRouter(AddTicket);
