import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import ticketI from "../../images/ticketsI.png";
import client from "../../images/placeholder.jpg";
import Logo from "../../images/flydoc.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Printhead from "../printhead";

function Visa(props) {
  var id = props.match.params.id;

  let history = useHistory();

  const [cphoto, setCphoto] = useState("");
  const [cserial, setCserial] = useState("");
  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [flight, setFlight] = useState("");
  const [base, setBase] = useState("");
  const [gross, setGross] = useState("");
  const [idate, setIdate] = useState("");
  const [vat, setVat] = useState("");
  const [profit, setProfit] = useState("");
  const [arr1, setArr1] = useState("");
  const [arr2, setArr2] = useState("");
  const [de1, setDe1] = useState("");
  const [de2, setDe2] = useState("");
  const [airplane, setAirplane] = useState("");
  const [vendor, setVendor] = useState("");
  const [pval, setPval] = useState("");
  const [country, setCountry] = useState("");
  const [type, setType] = useState("");
  const [visa, setVisa] = useState("");
  const [vval, setVval] = useState("");
  const [serial, setSerial] = useState("");

  const [doc1, setDoc1] = useState("");
  const [doc2, setDoc2] = useState("");
  const [doc3, setDoc3] = useState("");
  const [edate, setEdate] = useState("");
  const [iodate, setIodate] = useState("");
  const [vstamp, setVstamp] = useState("");
  const [vdelivery, setVdelivery] = useState("");
  const [fdate, setFdate] = useState("");
  const [mdate, setMdate] = useState("");
  const [medate, setMedate] = useState("");
  const [mestatus, setMestatus] = useState("");
  const [mostatus, setMostatus] = useState("");
  const [postatus, setPostatus] = useState("");
  const [mno, setMno] = useState("");

  const [t1, setT1] = useState("");
  const [t2, setT2] = useState("");

  const [d1, setD1] = useState("");
  const [d2, setD2] = useState("");

  const [userData, setUsereData] = useState("");
  const [vendors, setVendors] = useState([]);
  const [customer, setCustomer] = useState([]);

  const [note, setNote] = useState("");
  const [aid, setAid] = useState("");
  const [agents, setAgents] = useState([]);

  const [cof, setCof] = useState("");
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/allagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchstaff"
      )
      .then((res) => setStaff(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function getagent(x) {
    var i = "";
    agents.map((item) => {
      if (item.id == x) {
        i = item.aname;
      }
    });

    return i;
  }

  function getStaff(x) {
    var i = "";
    staff.map((item) => {
      if (item.id == x) {
        i = item.sname;
      }
    });

    return i;
  }

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchprev2",
        {
          id: id,
        }
      )
      .then((res) => {
        setCphoto(res.data.message[0].cphoto);
        setCserial(res.data.message[0].cserial);
        setCname(res.data.message[0].cname);
        setCnum(res.data.message[0].cnum);
        setCadd(res.data.message[0].cadd);

        setNote(res.data.message[0].note);

        setPassport(res.data.message[0].passport);
        setNid(res.data.message[0].nid);
        setIdate(res.data.message[0].idate);
        setAirplane(res.data.message[0].airplane);
        setFlight(res.data.message[0].flight);
        setVendor(res.data.message[0].vendor);
        setPval(res.data.message[0].pval);
        setVval(res.data.message[0].vval);
        setVisa(res.data.message[0].visa);
        setBase(res.data.message[0].base);
        setGross(res.data.message[0].gross);
        setVat(res.data.message[0].vat);
        setProfit(res.data.message[0].profit);
        setSerial(res.data.message[0].serial);
        setDoc1(res.data.message[0].doc1);
        setDoc2(res.data.message[0].doc2);
        setDoc3(res.data.message[0].doc3);

        setCountry(res.data.message[0].country);
        setType(res.data.message[0].type);
        setEdate(res.data.message[0].edate);
        setIodate(res.data.message[0].idate);
        setVstamp(res.data.message[0].vstamp);
        setVdelivery(res.data.message[0].vdelivery);
        setFdate(res.data.message[0].fdate);
        setMdate(res.data.message[0].mdate);
        setMedate(res.data.message[0].medate);
        setMestatus(res.data.message[0].mestatus);
        setMostatus(res.data.message[0].mostatus);
        setPostatus(res.data.message[0].postatus);
        setAid(res.data.message[0].aid);

        setMno(res.data.message[0].mno);
        setCof(res.data.message[0].cof);

        document.getElementById("inputcoun").value =
          res.data.message[0].country;
        document.getElementById("inputvisa").value = res.data.message[0].type;
      })
      .catch((err) => console.log(err));
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    var arrival = arr1 + "  " + arr2 + " " + t1 + ":" + t2;
    var departure = de1 + " " + de2 + " " + d1 + ":" + d2;

    const data = new FormData();

    data.append("passport", passport);
    data.append("nid", nid);
    data.append("base", base);
    data.append("gross", gross);
    data.append("vat", vat);
    data.append("profit", profit);
    data.append("pval", pval);
    data.append("country", country);
    data.append("type", type);
    data.append("visa", visa);
    data.append("vval", vval);
    data.append("edate", edate);
    data.append("idate", iodate);
    data.append("id", id);
    data.append("vstamp", vstamp);
    data.append("vdelivery", vdelivery);
    data.append("fdate", fdate);
    data.append("mdate", mdate);
    data.append("medate", medate);
    data.append("mestatus", mestatus);
    data.append("mostatus", mostatus);
    data.append("postatus", postatus);

    // data.append("foo",fileData);
    // data.append("foo2",fileData2);
    // data.append("foo3",fileData3);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/upvisa",
        data
      )
      .then((res) => {
        if (res.data.message == null) {
          toast.success("customer Added", {
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            toastId: "success4",
            autoClose: 2000,
          });

          history.goBack();
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));
  }, [vendors]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomer"
      )
      .then((res) => setCustomer(res.data.message))
      .catch((err) => console.log(err));
  }, [customer]);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 1000);
  }, []);

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div class="container">
        <Printhead />

        <div class="prheadc mt-c">
          <h3>Service Name: Visa</h3>
        </div>

        <div class="dtran cview">
          <div class="d-flex adcst">
            <h5 class="m-0">Customer Data</h5>
          </div>
          <hr />

          <div class="contentC">
            <div class="form-group vcinfo">
              <div class="col-3">
                {cphoto == "" ? (
                  <img src={client} class="clientpp2" />
                ) : (
                  <img
                    src={
                      "https://concorde-api.flydocx.com/" +
                      process.env.REACT_APP_URL +
                      "/images/" +
                      cphoto
                    }
                    class="clientpp2"
                    alt="..."
                  />
                )}
              </div>
              <div class="col-9">
                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Serial
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cserial}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Name
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cname}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Mobile Number
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cnum}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Address
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {cadd}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Passport No
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {passport}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    Passport Validity
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {pval}</p>
                  </div>
                </div>

                <div class="form-group row m-0">
                  <label for="" class="col-3 col-form-label infotext">
                    NID No
                  </label>
                  <div class="col-9">
                    <p class="font-weight-bold m-0 p-1">: {nid}</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="d-flex adcst">
                <h5 class="m-0">Service Data</h5>
              </div>
              <hr />

              <div class="form-group row py-2">
                <label for="" class="col-2 col-form-label infotext">
                  VISA Country
                </label>
                <div class="col-4">
                  <div class="input-group">
                    <p class="font-weight-bold m-0 p-1">: {country}</p>
                  </div>
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  VISA type
                </label>
                <div class="col-4">
                  <div class="input-group">
                    <p class="font-weight-bold m-0 p-1">: {type}</p>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  VISA No
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {visa}</p>
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  VISA Validity
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {vval}</p>
                </div>
              </div>
              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Visa Delivery
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {vdelivery}</p>
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  Issue Date
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {vstamp}</p>
                </div>
              </div>

              {/* <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Flight Date
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {fdate}</p>
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  Manpower Date
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {mdate}</p>
                </div>
              </div> */}

              {/* <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Medical Date
                </label>
                <div class="col-4">
                  <p class="font-weight-bold m-0 p-1">: {medate}</p>
                </div>
                
              </div> */}

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Medical Status
                </label>
                <div class="col-4">
                  <p
                    class="font-weight-bold m-0 p-1"
                    style={{ textTransform: "uppercase" }}
                  >
                    : {mestatus}
                  </p>
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  Police Clearance
                </label>
                <div class="col-4">
                  <p
                    class="font-weight-bold m-0 p-1"
                    style={{ textTransform: "uppercase" }}
                  >
                    : {postatus}
                  </p>
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  MOFA Status
                </label>
                <div class="col-4">
                  <p
                    class="font-weight-bold m-0 p-1"
                    style={{ textTransform: "uppercase" }}
                  >
                    : {mostatus}
                  </p>
                </div>
                <label for="" class="col-2 col-form-label infotext">
                  MOFA Number
                </label>
                <div class="col-4">
                  <p
                    class="font-weight-bold m-0 p-1"
                    style={{ textTransform: "uppercase" }}
                  >
                    : {mno}
                  </p>
                </div>
              </div>

              <hr />

              {aid == 0 ? null : (
                <div class="form-group row">
                  <label for="" class="col-2 col-form-label infotext">
                    Agent Name
                  </label>
                  <div class="col-10">
                    <p class="font-weight-bold m-0 p-1">: {getagent(aid)}</p>
                  </div>
                </div>
              )}

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Vendor Name
                </label>
                <div class="col-4">
                  {vendors.map((y) => (
                    <p class="font-weight-bold m-0 p-1">
                      {y.id == vendor ? ": " + y.vname : null}
                    </p>
                  ))}
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Additional note
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">: {note}</p>
                </div>
              </div>

              <div class="form-group row">
                <label for="" class="col-2 col-form-label infotext">
                  Care Of
                </label>
                <div class="col-10">
                  <p class="font-weight-bold m-0 p-1">: {getStaff(cof)}</p>
                </div>
              </div>

              {/* <div class="form-group row py-4">
           <label for="" class="col-2 col-form-label">Base Fare</label>
           <div class="col-2 me-auto">
            <p>{base}</p>
           </div>
           <label for="" class="col-2 col-form-label">Gross Fare</label>
           <div class="col-2 me-auto">
             <p>{gross}</p>
           </div>
         </div>
         <div class="form-group row py-4">
           <label for="" class="col-2 col-form-label">VAT & AIT</label>
           <div class="col-2 me-auto">
             <p>{vat}</p>
           </div>
           <label for="" class="col-2 col-form-label">Profit</label>
           <div class="col-2 me-auto">
             <p>{profit}</p>
           </div>
         </div> */}
            </div>
          </div>
        </div>

        <footer class="footer" style={{ position: "fixed", bottom: 0 }}>
          <div class="dflex" style={{ marginLeft: 10, marginRight: 10 }}>
            <p style={{ fontSize: 14, fontWeight: "bold" }}>
              Developed by mPair Technologies Ltd.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default withRouter(Visa);
