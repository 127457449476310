import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Navigation2 from "../navigation2";
import Logo from "../../images/flydoc.svg";
import axios from "axios";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Pdfooter from "../pdfooter";
import Printhead from "../common/printhead";

function Agenst() {
  const [astat, setAstat] = useState([]);
  const [date, setDate] = useState("");
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/agentStat",
        {
          date: new Date().getFullYear(),
        }
      )
      .then((res) => {
        setAstat(res.data.message);
        // setVstat(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  function agentsate(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/agentStat",
        {
          date: x,
        }
      )
      .then((res) => {
        console.log(res.data.message);
        setAstat(res.data.message);
      })
      .catch((err) => console.log(err));
  }

  function formatDate(y) {
    const date = new Date(y);
    return `${monthNames[date.getMonth()]}`;
  }

  function printme() {
    window.print();
  }

  return (
    <>
      <Navigation2 />

      <Printhead />

      <table style={{ width: "100%" }}>
        <thead style={{ backgroundColor: "white" }}>
          <tr>
            <td class="tranreportheader"></td>
          </tr>
          <tr>
            <td style={{ height: 20 }}></td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div class="container">
                <div class="dtran">
                  <div class="">
                    <h4 class="m-0 text-center ventexth mb-3">
                      Monthly Agent Statement
                    </h4>
                    <p class="onprint text-center">Month: {formatDate(date)}</p>
                  </div>
                  <h4 class="m-0 text-center ventext">
                    Monthly Agent Statement
                  </h4>
                  <div class="row align-items-center noprint justify-content-center bg-light border py-2 mx-auto">
                    <div class="col-lg-10 col-9">
                      <input
                        type="month"
                        class="filtBTN w-25 px-2"
                        onChange={(e) => {
                          agentsate(e.target.value);
                          setDate(e.target.value);
                        }}
                      />
                    </div>

                    <div class="col-lg-2 col-3">
                      <button
                        class="btn btn-warning noprint"
                        onClick={printme}
                        style={{ color: "white", height: 35, paddingTop: 4 }}
                      >
                        Download
                      </button>
                    </div>
                  </div>

                  <div class="prbm">
                    <div class="container px-0 mx-0">
                      <table class="table">
                        <thead class="acc_reportnav">
                          <tr>
                            <th scope="col" class="py-1">
                              #
                            </th>
                            <th scope="col" class="py-1">
                              Vendor
                            </th>
                            <th scope="col" class="text-center py-1">
                              Total Deal
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {astat.map((item, index) => (
                            <tr>
                              <th scope="row" class="  py-1">
                                {index + 1}
                              </th>
                              <td class="py-1">{item.aname}</td>
                              <td class="text-center py-1">{item.deal}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <br /> <br />
                    <br /> <br />
                    <br /> <br />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td style={{ height: "120px" }}></td>
          </tr>
        </tfoot>
      </table>

      <footer class="footer" style={{ position: "fixed", bottom: 0 }}>
        <div class="d-flex" style={{ marginLeft: 50, marginRight: 50 }}>
          <p class="border-top border-dark border-t">
            <span class="">Account</span>
          </p>

          <p
            class="border-top  border-dark border-t"
            style={{ marginLeft: 250, marginRight: 250 }}
          >
            <span class="">Manager</span>
          </p>

          <p class="border-top border-dark border-t">
            <span class="">Proprietor</span>
          </p>
        </div>
        <Pdfooter />
      </footer>
    </>
  );
}

export default Agenst;
