import { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import Navigation2 from "../navigation2";
import Logo from "../../images/flydoc.svg";
import axios from "axios";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Pdfooter from "../pdfooter";
import Printhead from "../common/printhead";

function Clist() {
  const [ticket, setTicket] = useState([]);
  const [agent, setAgent] = useState("");
  const [search, setSearch] = useState("");
  const [passport, setPassport] = useState([]);
  const [visa, setVisa] = useState([]);
  const [hajj, setHajj] = useState([]);
  const [umrah, setUmrah] = useState([]);
  const [medical, setMedical] = useState([]);
  const [man, setMan] = useState([]);
  const [hotel, setHotel] = useState([]);
  const [csearch, setCsearch] = useState("All");

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [done, setDone] = useState("");
  const [vdone, setVdone] = useState("");

  const [alldata, setAlldata] = useState([]);
  const [gticket, setGticket] = useState([]);

  var forVisa = ["PENDING", "APPROVED"];

  var toDay = new Date();

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchticket"
      )
      .then((res) => {
        setTicket(res.data.message);
        setAlldata((item) => [...item, ...res.data.message]);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchpassport"
      )
      .then((res) => {
        setPassport(res.data.message);
        setAlldata((item) => [...item, ...res.data.message]);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchvisa"
      )
      .then((res) => {
        setVisa(res.data.message);
        setAlldata((item) => [...item, ...res.data.message]);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/hajjfetch"
      )
      .then((res) => {
        setHajj(res.data.message);
        setAlldata((item) => [...item, ...res.data.message]);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/umrahfetch"
      )
      .then((res) => {
        setUmrah(res.data.message);
        setAlldata((item) => [...item, ...res.data.message]);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/medicalfetch"
      )
      .then((res) => {
        setMedical(res.data.message);
        setAlldata((item) => [...item, ...res.data.message]);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchman"
      )
      .then((res) => {
        setMan(res.data.message);
        setAlldata((item) => [...item, ...res.data.message]);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchhotel"
      )
      .then((res) => {
        setHotel(res.data.message);
        setAlldata((item) => [...item, ...res.data.message]);
      })
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchAllg"
      )
      .then((res) => {
        setGticket(res.data.message);
        setAlldata((item) => [...item, ...res.data.message]);
      })
      .catch((err) => console.log(err));
  }, []);

  function printme() {
    window.print();
  }

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  return (
    <>
      <Navigation2 />

      <Printhead />

      <table style={{ width: "100%" }}>
        <thead style={{ backgroundColor: "white" }}>
          <tr>
            <td class="tranreportheader"></td>
          </tr>
          <tr>
            <td style={{ height: 20 }}></td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div class="container">
                <div class="dtran">
                  <div class="">
                    <h4 class="m-0 text-center">Daily Client List</h4>
                    <p class="onprint text-center">
                      Customer Type: <span>{csearch}</span> <span> | </span>
                      Date:{" "}
                      <span>
                        {from == "" && to == ""
                          ? formatDate(toDay)
                          : formatDate(from) + "-" + formatDate(to)}
                      </span>
                    </p>
                  </div>

                  <div class="row align-items-center noprint justify-content-center bg-light border py-2 mx-auto">
                    <div class="col-lg-3 col-3">
                      <select
                        className="form-control"
                        onChange={(e) => setCsearch(e.target.value)}
                        style={{ height: 35 }}
                      >
                        <option>All</option>
                        <option>Ticket</option>
                        <option>Passport</option>
                        <option>Visa</option>
                        <option>Hajj</option>
                        <option>Umrah</option>
                        <option>Medical</option>
                        <option>Manpower</option>
                        <option>Hotel</option>
                        <option value="gticket">Group ticket</option>
                      </select>
                    </div>
                    <div class="col-lg-3 col-9">
                      <input
                        type="date"
                        class="filtBTN m-0 mx-2 px-2"
                        onChange={(e) => setFrom(e.target.value)}
                      />
                    </div>
                    <div class="col-lg-1 font-weight-bold text-center">-</div>
                    <div class="col-lg-3 col-9">
                      <input
                        type="date"
                        class="filtBTN m-0 mx-2 px-2"
                        onChange={(e) => setTo(e.target.value)}
                      />
                    </div>

                    <div class="col-lg-2 col-3">
                      <button
                        class="btn btn-warning noprint"
                        onClick={printme}
                        style={{ color: "white", height: 35, paddingTop: 4 }}
                      >
                        Download
                      </button>
                    </div>
                  </div>

                  <div class="prbm">
                    <div class="container px-0 mx-0">
                      <table
                        class="table stranwidth table-bordered"
                        id="table-to-xls2"
                      >
                        <thead class="thead-light">
                          <tr>
                            <td width="5%" scope="col" class="p-1 px-2">
                              SL
                            </td>
                            <td width="10%" scope="col" class="p-1 px-2">
                              Date
                            </td>
                            <td width="20%" scope="col" class="p-1 px-2">
                              Customer Name
                            </td>
                            <td width="20%" scope="col" class="p-1 px-2">
                              Service
                            </td>
                            <td width="25%" scope="col" class="p-1 px-2">
                              Service Details
                            </td>
                            <td
                              width="10%"
                              scope="col"
                              class="p-1 px-2 text-right"
                            >
                              Payable
                            </td>
                            <td
                              width="10%"
                              scope="col"
                              class="p-1 px-2 text-right"
                            >
                              Paid
                            </td>

                            <td
                              width="10%"
                              scope="col"
                              class="p-1 px-2 text-right"
                            >
                              Profit
                            </td>
                          </tr>
                        </thead>

                        <tbody>
                          {alldata
                            .sort((a, b) => {
                              var c = new Date(a.date);
                              var d = new Date(b.date);
                              return c - d;
                            })
                            .filter((item) => {
                              var start = new Date(from);
                              var date = new Date(item.date);
                              var end = new Date(to);

                              if (from == "") {
                                return item;
                              } else {
                                if (start < date && date < end) {
                                  return item;
                                }
                              }
                            })
                            .map((item, index) => (
                              <tr>
                                {csearch.toLocaleLowerCase() == "all" ? (
                                  <>
                                    <td width="5%" scope="row">
                                      {index + 1}
                                    </td>
                                    <td width="10%">{formatDate(item.date)}</td>
                                    <td width="20%">{item.cname}</td>
                                    <td width="20%">
                                      {item.service}{" "}
                                      {item.aid != 0 ? "(Agent)" : null}
                                    </td>
                                    <td width="25%">
                                      {item.service == "ticket" ? (
                                        <>
                                          {"P/N-" +
                                            item.passport +
                                            ", " +
                                            "PNR-" +
                                            item.serial +
                                            ", " +
                                            "A/L-" +
                                            item.airplane +
                                            ", " +
                                            "A/V-" +
                                            item.arrival +
                                            ", " +
                                            "D/P-" +
                                            item.departure}{" "}
                                        </>
                                      ) : item.service == "passport" ? (
                                        <>
                                          {"S/N-" +
                                            item.snumber +
                                            ", " +
                                            "E/D-" +
                                            item.ddate +
                                            ", C/F-(" +
                                            item.bc +
                                            ")"}{" "}
                                        </>
                                      ) : item.service == "visa" ? (
                                        <>
                                          {item.country +
                                            ",P/N-" +
                                            item.passport +
                                            ",V/N-" +
                                            item.visa}{" "}
                                        </>
                                      ) : item.service == "hajj" ||
                                        item.service == "umrah" ? (
                                        <>
                                          {"P/N-" +
                                            item.passport +
                                            ", V/N-" +
                                            item.visa +
                                            ", " +
                                            "A/L-" +
                                            item.airplane +
                                            ", F/D" +
                                            item.departure +
                                            ","}{" "}
                                          <br />
                                          {item.done == 1 ? "(Refunded)" : null}
                                        </>
                                      ) : item.service == "medical" ? (
                                        <>
                                          {"P/N-" +
                                            item.passport +
                                            ", V/N-" +
                                            item.visa +
                                            ", D/D-" +
                                            item.ddate}{" "}
                                        </>
                                      ) : item.service == "manpower" ? (
                                        <>
                                          {"P/N-" +
                                            item.passport +
                                            ", V/N-" +
                                            item.visa +
                                            ", D/D-" +
                                            item.ddate}{" "}
                                        </>
                                      ) : item.service == "hotel" ? (
                                        <>
                                          {item.country +
                                            ", " +
                                            item.hotel +
                                            ", C/I -" +
                                            item.cin +
                                            ", C/O -" +
                                            item.cout}{" "}
                                        </>
                                      ) : null}

                                      <br />

                                      {item.done == 1 ? "(Refunded)" : null}
                                    </td>

                                    {item.service == "ticket" ||
                                    item.service == "umrah" ||
                                    item.service == "hajj" ? (
                                      <>
                                        <td width="10%" class="text-right">
                                          {item.done == 0 ? item.gross : 0}
                                        </td>
                                        <td width="10%" class="text-right">
                                          {item.done == 0
                                            ? item.paid
                                            : "-" + item.refund}{" "}
                                          {item.aid != 0
                                            ? "(Agent payment)"
                                            : null}
                                        </td>
                                        <td width="10%" class="text-right">
                                          {Number(item.profit).toFixed(2)}
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td width="10%" class="text-right">
                                          {item.gross}
                                        </td>
                                        <td width="10%" class="text-right">
                                          {item.paid}{" "}
                                          {item.aid != 0
                                            ? "(Agent payment)"
                                            : null}
                                        </td>

                                        <td width="10%" class="text-right">
                                          {Number(item.profit).toFixed(2)}
                                        </td>
                                      </>
                                    )}
                                  </>
                                ) : null}
                              </tr>
                            ))}

                          {ticket
                            .filter((item) => {
                              var start = new Date(from);
                              var date = new Date(item.date);
                              var end = new Date(to);

                              if (from == "") {
                                return item;
                              } else {
                                if (start < date && date < end) {
                                  return item;
                                }
                              }
                            })
                            .filter((item) => {
                              if (
                                item.status
                                  .toString()
                                  .includes(done?.toString())
                              ) {
                                return item;
                              }
                            })
                            .map((item, index) => (
                              <tr>
                                {csearch.toLocaleLowerCase() == "ticket" ? (
                                  <>
                                    <td width="5%" scope="row">
                                      {index + 1}
                                    </td>
                                    <td width="10%">{formatDate(item.date)}</td>
                                    <td width="20%">{item.cname}</td>
                                    <td width="20%">
                                      TICKET {item.aid != 0 ? "(Agent)" : null}
                                    </td>
                                    <td width="25%">
                                      {"P/N-" +
                                        item.passport +
                                        ", " +
                                        "PNR-" +
                                        item.serial +
                                        ", " +
                                        "A/L-" +
                                        item.airplane +
                                        ", " +
                                        "A/V-" +
                                        item.arrival +
                                        ", " +
                                        "D/P-" +
                                        item.departure}{" "}
                                      <br />
                                      {item.done == 1 ? "(Refunded)" : null}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.done == 0 ? item.gross : 0}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.done == 0
                                        ? item.paid
                                        : "-" + item.refund}{" "}
                                      {item.aid != 0 ? "(Agent payment)" : null}
                                    </td>

                                    <td width="10%" class="text-right">
                                      {Number(item.profit).toFixed(2)}
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            ))}

                          {gticket
                            .filter((item) => {
                              var start = new Date(from);
                              var date = new Date(item.date);
                              var end = new Date(to);

                              if (from == "") {
                                return item;
                              } else {
                                if (start < date && date < end) {
                                  return item;
                                }
                              }
                            })
                            .filter((item) => {
                              if (
                                item.status
                                  .toString()
                                  .includes(done?.toString())
                              ) {
                                return item;
                              }
                            })
                            .map((item, index) => (
                              <tr>
                                {csearch.toLocaleLowerCase() == "gticket" ? (
                                  <>
                                    <td width="5%" scope="row">
                                      {index + 1}
                                    </td>
                                    <td width="10%">{formatDate(item.date)}</td>
                                    <td width="20%">{item.cname}</td>
                                    <td width="20%">
                                      TICKET {item.aid != 0 ? "(Agent)" : null}
                                    </td>
                                    <td width="25%">
                                      {"PNR-" +
                                        item.pnr +
                                        ", " +
                                        "A/L-" +
                                        item.airline +
                                        ", " +
                                        "A/V-" +
                                        item.arrival +
                                        ", " +
                                        "D/P-" +
                                        item.departure}{" "}
                                      <br />
                                      {item.done == 1 ? "(Refunded)" : null}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.done == 0 ? item.gross : 0}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {/* {item.done == 0
                                        ? item.paid
                                        : "-" + item.refund}{" "} */}
                                      {item.paid}
                                      {item.aid != 0 ? "(Agent payment)" : null}
                                    </td>

                                    <td width="10%" class="text-right">
                                      {Number(item.profit).toFixed(2)}
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            ))}

                          {passport
                            .filter((item) => {
                              var start = new Date(from);
                              var date = new Date(item.date);
                              var end = new Date(to);

                              if (from == "") {
                                return item;
                              } else {
                                if (start < date && date < end) {
                                  return item;
                                }
                              }
                            })
                            .map((item, index) => (
                              <tr>
                                {csearch.toLocaleLowerCase() == "passport" ? (
                                  <>
                                    <td width="5%" scope="row">
                                      {index + 1}
                                    </td>
                                    <td width="10%">{formatDate(item.date)}</td>
                                    <td width="20%">{item.cname}</td>
                                    <td width="20%">
                                      PASSPORT{" "}
                                      {item.aid != 0 ? "(Agent)" : null}
                                    </td>
                                    <td width="25%">
                                      {"S/N-" +
                                        item.snumber +
                                        ", " +
                                        "E/D-" +
                                        item.ddate +
                                        ", C/F-(" +
                                        item.bc +
                                        ")"}{" "}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.gross}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.paid}{" "}
                                      {item.aid != 0 ? "(Agent payment)" : null}
                                    </td>

                                    <td width="10%" class="text-right">
                                      {Number(item.profit).toFixed(2)}
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            ))}

                          {visa
                            .filter((item) => {
                              var start = new Date(from);
                              var date = new Date(item.date);
                              var end = new Date(to);

                              if (from == "") {
                                return item;
                              } else {
                                if (start < date && date < end) {
                                  return item;
                                }
                              }
                            })
                            .filter((item) => {
                              if (
                                item.status
                                  .toLocaleLowerCase()
                                  .toString()
                                  .includes(
                                    vdone?.toLocaleLowerCase().toString()
                                  )
                              ) {
                                return item;
                              }
                            })
                            .map((item, index) => (
                              <tr>
                                {csearch.toLocaleLowerCase() == "visa" ? (
                                  <>
                                    <td width="5%" scope="row">
                                      {index + 1}
                                    </td>
                                    <td width="10%">{formatDate(item.date)}</td>
                                    <td width="20%">
                                      {item.cname} {forVisa[done]} {item.status}
                                    </td>
                                    <td width="20%">
                                      VISA {item.aid != 0 ? "(Agent)" : null}
                                    </td>
                                    <td width="25%">
                                      {item.country +
                                        ", P/N-" +
                                        item.passport +
                                        ", V/N-" +
                                        item.visa}{" "}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.gross}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.paid}{" "}
                                      {item.aid != 0 ? "(Agent payment)" : null}
                                    </td>

                                    <td width="10%" class="text-right">
                                      {Number(item.profit).toFixed(2)}
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            ))}

                          {hajj
                            .filter((item) => {
                              var start = new Date(from);
                              var date = new Date(item.date);
                              var end = new Date(to);

                              if (from == "") {
                                return item;
                              } else {
                                if (start < date && date < end) {
                                  return item;
                                }
                              }
                            })
                            .map((item, index) => (
                              <tr>
                                {csearch.toLocaleLowerCase() == "hajj" ? (
                                  <>
                                    <td width="5%" scope="row">
                                      {index + 1}
                                    </td>
                                    <td width="10%">{formatDate(item.date)}</td>
                                    <td width="20%">{item.cname}</td>
                                    <td width="20%">
                                      HAJJ {item.aid != 0 ? "(Agent)" : null}
                                    </td>
                                    <td width="25%">
                                      {"P/N-" +
                                        item.passport +
                                        ", V/N-" +
                                        item.visa +
                                        ", " +
                                        "A/L-" +
                                        item.airplane +
                                        ", F/D" +
                                        item.departure +
                                        ", "}{" "}
                                      <br />
                                      {item.done == 1 ? "(Refunded)" : null}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.done == 0 ? item.gross : 0}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.done == 0
                                        ? item.paid
                                        : "-" + item.refund}{" "}
                                      {item.aid != 0 ? "(Agent payment)" : null}
                                    </td>

                                    <td width="10%" class="text-right">
                                      {Number(item.profit).toFixed(2)}
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            ))}

                          {umrah
                            .filter((item) => {
                              var start = new Date(from);
                              var date = new Date(item.date);
                              var end = new Date(to);

                              if (from == "") {
                                return item;
                              } else {
                                if (start < date && date < end) {
                                  return item;
                                }
                              }
                            })
                            .map((item, index) => (
                              <>
                                {csearch.toLocaleLowerCase() == "umrah" ? (
                                  <tr>
                                    <td width="5%" scope="row">
                                      {index + 1}
                                    </td>
                                    <td width="10%">{formatDate(item.date)}</td>
                                    <td width="20%">{item.cname}</td>
                                    <td width="20%">
                                      UMRAH {item.aid != 0 ? "(Agent)" : null}
                                    </td>
                                    <td width="25%">
                                      {"P/N-" +
                                        item.passport +
                                        ", V/N-" +
                                        item.visa +
                                        ", " +
                                        "A/L-" +
                                        item.airplane +
                                        ", F/D" +
                                        item.departure +
                                        ", "}{" "}
                                      <br />
                                      {item.done == 1 ? "(Refunded)" : null}
                                    </td>
                                    <td width="10%">
                                      {item.done == 0 ? item.gross : 0}
                                    </td>
                                    <td width="10%">
                                      {item.done == 0
                                        ? item.paid
                                        : "-" + item.refund}{" "}
                                      {item.aid != 0 ? "(Agent payment)" : null}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {Number(item.profit).toFixed(2)}
                                    </td>
                                  </tr>
                                ) : null}
                              </>
                            ))}

                          {medical
                            .filter((item) => {
                              var start = new Date(from);
                              var date = new Date(item.date);
                              var end = new Date(to);

                              if (from == "") {
                                return item;
                              } else {
                                if (start < date && date < end) {
                                  return item;
                                }
                              }
                            })
                            .map((item, index) => (
                              <tr>
                                {csearch.toLocaleLowerCase() == "medical" ? (
                                  <>
                                    <td width="5%" scope="row">
                                      {item.cserial}
                                    </td>
                                    <td width="10%">{formatDate(item.date)}</td>
                                    <td width="20%">{item.cname}</td>
                                    <td width="20%">
                                      MEDICAL {item.aid != 0 ? "(Agent)" : null}
                                    </td>
                                    <td width="25%">
                                      {"P/N-" +
                                        item.passport +
                                        ", V/N-" +
                                        item.visa +
                                        ", D/D-" +
                                        item.ddate}{" "}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.gross}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.paid}{" "}
                                      {item.aid != 0 ? "(Agent payment)" : null}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {Number(item.profit).toFixed(2)}
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            ))}

                          {man
                            .filter((item) => {
                              var start = new Date(from);
                              var date = new Date(item.date);
                              var end = new Date(to);

                              if (from == "") {
                                return item;
                              } else {
                                if (start < date && date < end) {
                                  return item;
                                }
                              }
                            })
                            .map((item, index) => (
                              <tr>
                                {csearch.toLocaleLowerCase() == "manpower" ? (
                                  <>
                                    <td width="5%" scope="row">
                                      {index + 1}
                                    </td>
                                    <td width="10%">{formatDate(item.date)}</td>
                                    <td width="20%">{item.cname}</td>
                                    <td width="20%">
                                      MANPOWER{" "}
                                      {item.aid != 0 ? "(Agent)" : null}
                                    </td>
                                    <td width="25%">
                                      {"P/N-" +
                                        item.passport +
                                        ", V/N-" +
                                        item.visa +
                                        ", D/D-" +
                                        item.ddate}{" "}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.gross}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.paid}{" "}
                                      {item.aid != 0 ? "(Agent payment)" : null}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {Number(item.profit).toFixed(2)}
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            ))}

                          {hotel
                            .filter((item) => {
                              var start = new Date(from);
                              var date = new Date(item.date);
                              var end = new Date(to);

                              if (from == "") {
                                return item;
                              } else {
                                if (start < date && date < end) {
                                  return item;
                                }
                              }
                            })
                            .map((item, index) => (
                              <tr>
                                {csearch.toLocaleLowerCase() == "hotel" ? (
                                  <>
                                    <td width="5%" scope="row">
                                      {index + 1}
                                    </td>
                                    <td width="10%">{formatDate(item.date)}</td>
                                    <td width="20%">{item.cname}</td>
                                    <td width="20%">
                                      HOTEL {item.aid != 0 ? "(Agent)" : null}
                                    </td>
                                    <td width="25%">
                                      {item.country +
                                        ", " +
                                        item.hotel +
                                        ", C/I -" +
                                        item.cin +
                                        ", C/O -" +
                                        item.cout}{" "}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.gross}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {item.paid}{" "}
                                      {item.aid != 0 ? "(Agent payment)" : null}
                                    </td>
                                    <td width="10%" class="text-right">
                                      {Number(item.profit).toFixed(2)}
                                    </td>
                                  </>
                                ) : null}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <br /> <br />
                    <br /> <br />
                    <br /> <br />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td style={{ height: "120px" }}></td>
          </tr>
        </tfoot>
      </table>

      <footer class="footer" style={{ position: "fixed", bottom: 0 }}>
        <div class="d-flex" style={{ marginLeft: 50, marginRight: 50 }}>
          <p class="border-top border-dark border-t">
            <span class="">Account</span>
          </p>

          <p
            class="border-top  border-dark border-t"
            style={{ marginLeft: 250, marginRight: 250 }}
          >
            <span class="">Manager</span>
          </p>

          <p class="border-top border-dark border-t">
            <span class="">Proprietor</span>
          </p>
        </div>
        <Pdfooter />
      </footer>
    </>
  );
}

export default Clist;
