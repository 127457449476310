import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../screens/footer";
import AddPassport from "../components/addPassport";
import AddTicket from "../components/addTicket";
import AddVisa from "../components/addVisa";
import AddHotel from "../components/addHotel";
import AddManpower from "../components/addManpower";
import AddMedical from "../components/addMedical";
import Addumrah from "../components/addUmrah";
import AddHajj from "../components/addHajj";

function Adservice(props) {
  var cid = props.match.params.cid;
  const [active, setActive] = useState("dom");

  const [cserial, setCserial] = useState("");
  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [passport, setPassport] = useState("");

  useEffect(() => {
    console.log(cid);
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/userfetch",
        {
          id: cid,
        }
      )
      .then((res) => {
        setCname(res.data.message[0].cname);
        setCnum(res.data.message[0].cnum);
        setCserial(res.data.message[0].serial);
        setPassport(res.data.message[0].passport);
      })
      .catch((err) => console.log(err));
  }, [cid]);

  return (
    <>
      <Navigation2 />

      <div class="container">
        <div class="addCustomer">
          <div class="border bg-light mb-3">
            <h5 class="m-0 border-bottom px-3 py-2 mb-3">Customer Info</h5>
            <div class="row px-3 ">
              <label for="" class="col-lg-2 col-12 infotext">
                Customer Serial
              </label>
              <div class="col-lg-4 col-12">
                <p>: {cserial}</p>
              </div>
            </div>

            <div class="row px-3">
              <label for="" class="col-lg-2 col-12 infotext">
                Customer Name
              </label>
              <div class="col-lg-4 col-12">
                <p>: {cname}</p>
              </div>

              <label for="" class="col-lg-2 col-12 infotext">
                Passport Number
              </label>
              <div class="col-lg-4 col-12">
                <p>: {passport}</p>
              </div>
            </div>
          </div>
          {/* <div class="d-flex adcst col-lg-12 col-12">
            <h3 class="m-0">Add Service</h3>  
          </div> */}

          <div class="form-group row bg-light border mx-auto mb-0">
            <label class="col-lg-12 col-12 border-bottom infotext py-2 mb-0 font-weight-bold">
              Service type
            </label>
            <div class="d-flex col-lg-12 col-12 p-0 m-0 justify-content-between">
              <div
                onClick={() => {
                  setActive("dom");
                }}
                class={
                  active.includes("dom")
                    ? "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread"
                    : "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread2"
                }
              >
                <label for="_0" class="form-check-label infotext mr-2">
                  Domestic Ticket
                </label>
              </div>

              <div
                onClick={() => {
                  setActive("int");
                }}
                class={
                  active.includes("int")
                    ? "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread"
                    : "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread2"
                }
              >
                <label for="_0" class="form-check-label infotext mr-2">
                  International Ticket
                </label>
              </div>

              <div
                onClick={() => {
                  setActive("visa");
                }}
                class={
                  active.includes("visa")
                    ? "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread"
                    : "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread2"
                }
              >
                <label for="_0" class="form-check-label infotext mr-2">
                  Visa
                </label>
              </div>

              <div
                onClick={() => {
                  setActive("hotel");
                }}
                class={
                  active.includes("hotel")
                    ? "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread"
                    : "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread2"
                }
              >
                <label for="_0" class="form-check-label infotext mr-2">
                  Hotel
                </label>
              </div>

              <div
                onClick={() => {
                  setActive("manpower");
                }}
                class={
                  active.includes("manpower")
                    ? "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread"
                    : "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread2"
                }
              >
                <label for="_0" class="form-check-label infotext mr-2">
                  Manpower
                </label>
              </div>

              <div
                onClick={() => {
                  setActive("medical");
                }}
                class={
                  active.includes("medical")
                    ? "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread"
                    : "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread2"
                }
              >
                <label for="_0" class="form-check-label infotext mr-2">
                  Medical
                </label>
              </div>

              <div
                onClick={() => {
                  setActive("umrah");
                }}
                class={
                  active.includes("umrah")
                    ? "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread"
                    : "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread2"
                }
              >
                <label for="_0" class="form-check-label infotext mr-2">
                  Umrah
                </label>
              </div>

              <div
                onClick={() => {
                  setActive("hajj");
                }}
                class={
                  active.includes("hajj")
                    ? "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread"
                    : "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread2"
                }
              >
                <label for="_0" class="form-check-label infotext mr-2">
                  Hajj
                </label>
              </div>
              <div
                onClick={() => {
                  setActive("passport");
                }}
                class={
                  active.includes("passport")
                    ? "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread"
                    : "flex-fill form-check form-check-inline m-0 p-0 checkboxSpread2"
                }
              >
                <label for="_0" class="form-check-label infotext mr-2">
                  Passport
                </label>
              </div>
            </div>
          </div>
        </div>

        {active == "passport" ? (
          <AddPassport />
        ) : active == "int" ? (
          <AddTicket cid={cid} active={active} />
        ) : active == "dom" ? (
          <AddTicket cid={cid} active={active} />
        ) : active == "visa" ? (
          <AddVisa />
        ) : active == "hotel" ? (
          <AddHotel />
        ) : active == "manpower" ? (
          <AddManpower />
        ) : active == "medical" ? (
          <AddMedical />
        ) : active == "umrah" ? (
          <Addumrah cid={cid} />
        ) : active == "hajj" ? (
          <AddHajj cid={cid} pass={passport} />
        ) : null}
      </div>

      <Footer />
    </>
  );
}

export default withRouter(Adservice);
