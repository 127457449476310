import { useState, useEffect } from "react";
import Navigation2 from "../navigation2";
import { withRouter } from "react-router-dom";
import uploading from "../../images/uploading.gif";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import Compress from "compress.js";

function Updatecus(props) {
  var id = props.match.params.id;

  let history = useHistory();

  const [customer, setCustomer] = useState([]);
  const [cname, setCname] = useState("");
  const [cnum, setCnum] = useState("");
  const [cadd, setCadd] = useState("");
  const [passport, setPassport] = useState("");
  const [nid, setNid] = useState("");
  const [pval, setPval] = useState("");
  const [cserial, setCserial] = useState("");
  const [userData, setUsereData] = useState("");
  const [cid, setCid] = useState("");
  const [dob, setDob] = useState("");
  const [fil, setFil] = useState("");
  const [load, setLoad] = useState(false);
  const [cphoto, setCphoto] = useState("");

  const getFilereal3 = async (e) => {
    const file = e.target.files[0];
    const image = await resizeFile(file);
    const newFile = dataURIToBlob(image);

    const data = new FormData();
    data.append("id", id);
    data.append("foo", newFile);

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/profilepicUpdate",
        data
      )
      .then((res) => {
        console.log(res.data.message);
        setCphoto(res.data.message);
        toast.success("Image updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        900,
        900,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  };

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchcustomerbyid",
        {
          cid: id,
        }
      )
      .then((res) => {
        setCname(res.data.suser[0].cname);
        setCadd(res.data.suser[0].cadd);
        setCnum(res.data.suser[0].cnum);
        setDob(res.data.suser[0].dob);
        setNid(res.data.suser[0].nid);
        setPassport(res.data.suser[0].passport);
        setPval(res.data.suser[0].pval);
        setCphoto(res.data.suser[0].cphoto);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    if (cid != "") {
      history.push("/adservice/" + cid);
    } else {
      const data = new FormData();
      data.append("cname", cname);
      data.append("cnum", cnum);
      data.append("cadd", cadd);
      data.append("dob", dob);

      data.append("passport", passport);

      data.append("cserial", cserial);
      data.append("nid", nid);
      data.append("pval", pval);
      data.append("id", id);

      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/updateCus",
          data
        )
        .then((res) => {
          history.goBack();
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      <Navigation2 />
      <ToastContainer />

      <div class="container">
        <div class="addCustomer">
          <div class="d-flex adcst">
            <h3 class="m-0">Update data</h3>
          </div>

          <hr />

          {load ? (
            <div class="bg-light upgifback">
              <center>
                <img src={uploading} class="upgif" alt=".." />
              </center>
            </div>
          ) : null}

          <div class="form-group row py-1">
            <label for="" class="col-lg-3 col-12 col-form-label infotext">
              Customer Name
            </label>

            <div class="col-lg-4 col-12">
              {cphoto == "" ? (
                <input type="file" />
              ) : (
                <label for="uppic3" style={{ position: "relative" }}>
                  <img
                    src={
                      "https://concorde-api.flydocx.com/" +
                      process.env.REACT_APP_URL +
                      "/images/" +
                      cphoto
                    }
                    // class="clientpp"
                    alt="..."
                    class="cimgedit"
                  />
                  <div
                    class="overlay2 text-center py-2"
                    style={{ cursor: "pointer", backgroundColor: "#ddd" }}
                  >
                    Update
                  </div>
                </label>
              )}
            </div>

            <input
              type="file"
              id="uppic3"
              style={{ visibility: "hidden" }}
              onChange={getFilereal3}
            />
          </div>

          <form onSubmit={handleSubmit} id="create-course-form">
            <div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-3 col-12 col-form-label infotext">
                  Customer Name
                </label>
                <div class="col-lg-9 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    value={cname}
                    onChange={(e) => setCname(e.target.value)}
                    disabled={cid != "" ? true : false}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-3 col-12 infotext col-form-label"
                >
                  Customer Mobile No
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">+88</div>
                    </div>
                    <input
                      id="text"
                      name="text"
                      type="text"
                      placeholder="018XXXXXXXX"
                      class="form-control"
                      required=""
                      value={cnum}
                      onChange={(e) => setCnum(e.target.value)}
                      disabled={cid != "" ? true : false}
                    />
                  </div>
                </div>

                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-12 infotext col-form-label"
                >
                  Date of Birth
                </label>
                <div class="col-lg-3 col-12">
                  <input
                    type="date"
                    class="form-control"
                    required=""
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    disabled={cid != "" ? true : false}
                  ></input>
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-3 col-12 infotext col-form-label"
                >
                  Passport No
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="text"
                      name="text"
                      type="text"
                      placeholder="XXXXXXXXXX"
                      class="form-control"
                      required=""
                      value={passport}
                      onChange={(e) => setPassport(e.target.value)}
                      disabled={cid != "" ? true : false}
                    />
                  </div>
                </div>

                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-12 infotext col-form-label"
                >
                  Passport Validity
                </label>
                <div class="col-lg-3 col-12">
                  <input
                    type="date"
                    class="form-control"
                    required=""
                    value={pval}
                    onChange={(e) => setPval(e.target.value)}
                    disabled={cid != "" ? true : false}
                  ></input>
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-3 col-12 infotext col-form-label"
                >
                  Customer Address
                </label>
                <div class="col-lg-9 col-12">
                  <textarea
                    id="textarea"
                    name="textarea"
                    cols="40"
                    rows="2"
                    class="form-control"
                    required=""
                    value={cadd}
                    onChange={(e) => setCadd(e.target.value)}
                    disabled={cid != "" ? true : false}
                  ></textarea>
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-3 col-12 infotext col-form-label"
                >
                  NID
                </label>
                <div class="col-lg-4 col-12 me-auto">
                  <input
                    type="text"
                    class="form-control"
                    value={nid}
                    onChange={(e) => setNid(e.target.value)}
                    disabled={cid != "" ? true : false}
                  />
                </div>
              </div>

              <hr />
            </div>

            <div class="form-group row py-1">
              <div class="col">
                <div class="d-grid gap-2 text-right">
                  <button class="btn btn-primary w-100">Update</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default withRouter(Updatecus);
