import { useState, useEffect } from "react";
import Navigation2 from "./navigation2";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Previlagemodal from "./modals/Previlagemodal";

function Profile() {
  const [aname, setAname] = useState("");
  const [aloction, setAlocation] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [uname, setUname] = useState("");
  const [edit, setEdit] = useState(true);

  const [upname, setUpname] = useState("");
  const [upemail, setUpemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [uppass, setUppass] = useState("");
  const [uprole, setUprole] = useState("");
  const [alldata, setAlldata] = useState([]);

  const [pass, setPass] = useState("");
  const [repass, setRepass] = useState("");
  const [opass, setOpass] = useState("");
  const [search, setSearch] = useState("");
  const [uid, setUid] = useState(0);

  const [preavilageModal, setPrevilagemodal] = useState(false);

  const [activity, setActivity] = useState([]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/activityfetch"
      )
      .then((res) => {
        setActivity(res.data.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/adminfetch",
        {
          id: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setAname(res.data.message[0].agency);
        setEmail(res.data.message[0].email);
        setPhone(res.data.message[0].phone);
        setUname(res.data.message[0].name);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/adminfetch2"
      )
      .then((res) => {
        setAlldata(res.data.message);
      })
      .catch((err) => console.log(err));
  }, [alldata]);

  function handleSubmit(e) {
    e.preventDefault();
    setEdit(!edit);
    if (!edit) {
      axios
        .post(
          "https://concorde-api.flydocx.com/" +
            process.env.REACT_APP_URL +
            "/updateadmin",
          {
            aname: aname,
            uname: uname,
            token: localStorage.getItem("token"),
          }
        )
        .then((res) => {
          if (res.data.message == null) {
            toast.success("Updated !", {
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              toastId: "success4",
              autoClose: 2000,
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  function handleSubmit2() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/newadmin",
        {
          upname: upname,
          upemail: upemail,
          uppass: uppass,
          uprole: uprole,
        }
      )
      .then((res) => {
        setUid(res.data.uid);
        setPrevilagemodal(true);

        document.getElementById("userFeild").reset();
      })
      .catch((err) => console.log(err));
  }

  function deluser(x) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/deluser",
        {
          id: x,
        }
      )
      .then((res) => {
        console.log(res.data.message);
      })
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function passupdate() {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/uppass2",
        {
          token: localStorage.getItem("token"),
          opass: opass,
          pass: pass,
        }
      )
      .then((res) => {
        toast.success("Password changed !", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));
  }

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour "0" should be "12"
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      "  " +
      strTime
    );
  }

  return (
    <>
      <Navigation2 />

      <ToastContainer />

      <Previlagemodal
        active={preavilageModal}
        close={setPrevilagemodal}
        id={uid}
      />

      <div
        class="modal fade pcmodal"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content pcmodalCont">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Change Password
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="col my-2">
                <label for="exampleInputEmail1">Old Password</label>
                <input
                  type="password"
                  class="form-control"
                  onChange={(e) => setOpass(e.target.value)}
                />
              </div>
              <div class="col my-2">
                <label for="exampleInputEmail1">New Password</label>
                <input
                  type="password"
                  class="form-control"
                  onChange={(e) => setPass(e.target.value)}
                />
              </div>

              <div class="col my-2">
                <label for="exampleInputEmail1">Re-type Password</label>
                <input
                  type="password"
                  class="form-control"
                  onChange={(e) => setRepass(e.target.value)}
                />
              </div>

              <div class="col my-3">
                <button
                  class="btn btn-primary w-100"
                  onClick={passupdate}
                  data-dismiss="modal"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <ul
          class="nav nav-pills mb-3 mx-auto justify-content-center my-4 mt-5 bg-white"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              id="pills-agency-profile-tab"
              data-toggle="pill"
              href="#pills-agency-profile"
              role="tab"
              aria-controls="pills-agency-profile"
              aria-selected="true"
            >
              Agency Profile
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="pills-admin-role-tab"
              data-toggle="pill"
              href="#pills-admin-role"
              role="tab"
              aria-controls="pills-admin-role"
              aria-selected="false"
            >
              Admin Role
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="pills-activity-tab"
              data-toggle="pill"
              href="#pills-activity"
              role="tab"
              aria-controls="pills-activity"
              aria-selected="false"
            >
              Activity log
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              id="pills-video-tab"
              data-toggle="pill"
              href="#pills-video"
              role="tab"
              aria-controls="pills-video"
              aria-selected="false"
            >
              How to use
            </a>
          </li>
        </ul>
        <hr />
      </div>

      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          style={{ backgroundColor: "white" }}
          id="pills-agency-profile"
          role="tabpanel"
          aria-labelledby="pills-agency-profile-tab"
        >
          <div class="container">
            <form onSubmit={handleSubmit} id="userFeild">
              <div class="row">
                <div class="col-lg-6 col-12 my-2 mb-3">
                  <label for="exampleInputEmail1">
                    <p class="infotext m-0">User Name</p>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    value={uname}
                    onChange={(e) => setUname(e.target.value)}
                    disabled={edit}
                  />
                </div>

                <div class="col-lg-6 col-12 my-2 mb-3">
                  <label for="exampleInputEmail1 infotext">
                    <p class="infotext m-0">Agency Name</p>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    value={aname}
                    onChange={(e) => setAname(e.target.value)}
                    disabled={edit}
                  />
                </div>

                <div class="col-lg-6 col-12 my-2 mb-3">
                  <label for="exampleInputPassword1 infotext">
                    <p class="infotext m-0">Email</p>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                </div>
                <div class="col-lg-6 col-12 my-2 mb-3">
                  <label for="exampleInputPassword1 infotext">
                    <p class="infotext m-0">Mobile Number</p>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    disabled
                  />
                </div>

                <div class="col-12 my-2 mb-3">
                  <button class="btn w-100 btn-primary" disabled>
                    {edit ? "Edit Info" : "Update Info"}
                  </button>
                </div>

                <div class="col-12 my-2 mb-3">
                  <a
                    class="btn w-100 passcb"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    <p class="centerelement font-weight-bold">
                      Change Password
                    </p>
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          class="tab-pane fade bg-white"
          id="pills-admin-role"
          role="tabpanel"
          aria-labelledby="pills-admin-role-tab"
        >
          <div class="user my-5 bg-white container">
            <div class="row">
              <div class="col-lg-4 col-12">
                <div class="card ctable">
                  <div class="card-header bg-dark rounded-top-5">
                    <h5 class="text-white mt-1 fs-5">Add New User</h5>
                  </div>
                  <div class="card-body form my-2">
                    <label
                      for="exampleFormControlInput1"
                      class="form-label infotext"
                    >
                      User Name
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      onChange={(e) => setUpname(e.target.value)}
                      id="exampleFormControlInput1"
                      placeholder="User name"
                    />
                    <label
                      for="exampleFormControlInput2"
                      class="form-label mt-2 infotext"
                    >
                      Email
                    </label>
                    <input
                      type="tel"
                      class="form-control"
                      id="exampleFormControlInput2"
                      onChange={(e) => setUpemail(e.target.value)}
                      placeholder="Email"
                    />
                    <label
                      for="exampleFormControlInput3"
                      class="form-label mt-2 infotext"
                    >
                      Password
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleFormControlInput3"
                      onChange={(e) => setUppass(e.target.value)}
                      placeholder="Password"
                    />
                    <label
                      for="exampleFormControlInput4"
                      class="form-label mt-2 infotext"
                    >
                      User Role
                    </label>
                    <br></br>
                    <select
                      class="form-select roleselect"
                      aria-label="Default select example"
                      onChange={(e) => setUprole(e.target.value)}
                    >
                      <option selected>Select User Role</option>
                      <option>Manager</option>
                      <option>Accounts</option>
                      <option>Reservation</option>
                    </select>
                  </div>
                  <div class=" my-3 adduserbtn text-center">
                    <button
                      type="submit"
                      class="btn btn-primary w-75"
                      onClick={handleSubmit2}
                    >
                      Add User
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 marginTop col-12">
                <div class="card ctable">
                  <div class="card-header bg-dark rounded-top ">
                    <h5 class="text-white mt-1">User Data</h5>
                  </div>
                  <div class="card-body p-0 m-0">
                    <table class=" table  table-striped table-hover tabletxt text-center">
                      <thead class="table-secondary">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Role</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {alldata
                          ?.filter((item) => {
                            if (item.role != "admin") {
                              return item;
                            }
                          })
                          .map((item) => (
                            <tr>
                              <td>{item.name}</td>
                              <td>{item.email}</td>
                              <td>{item.role}</td>
                              <td>
                                <button
                                  class="btn btn-danger"
                                  onClick={() => deluser(item.id)}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade bg-white"
          id="pills-activity"
          role="tabpanel"
          aria-labelledby="pills-activity"
        >
          <div class="user my-5 bg-white container">
            <input
              type="date"
              class="filtBTN m-0 col-lg-2"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />

            <br />
            <hr />

            <h3>Activity Log</h3>
            {activity
              .filter((item) => {
                if (item.date.includes(search)) {
                  return item;
                }
              })
              .map((item) => (
                <div>
                  <p>
                    <small>{formatDate(item.date)}</small>
                    <br />
                    <b>{item.admin}</b> {item.des}
                  </p>
                  <hr />
                </div>
              ))}
          </div>
        </div>

        <div
          class="tab-pane fade bg-white"
          id="pills-video"
          role="tabpanel"
          aria-labelledby="pills-video-tab"
        ></div>
      </div>
    </>
  );
}

export default Profile;
