import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import formatDate from "../functions/timeformat";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Refundticket({ name, active, uid, stype, close }) {
  const [fno, setFno] = useState("");
  const [serial, setSerial] = useState("");
  const [show, setShow] = useState(false);
  const [purchasehis, setPurchasehis] = useState([]);
  const [note, setNote] = useState("");
  const [hbank, setHbank] = useState("");
  const [amount, setAmount] = useState("");
  const [ptype, setPtype] = useState("cash");
  const [vname, setVname] = useState("");
  const [phistory, setPhistory] = useState([]);

  function refundhistory() {
    if (amount == "") {
      toast.warn("Amount is empty", {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        toastId: "success4",
        autoClose: 2000,
      });
      return false;
    }

    if (ptype == "bank") {
      if (hbank == "") {
        toast.warn("Bank name is empty", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
        return false;
      }
    }

    const data = new FormData();
    data.append("hbank", hbank);
    data.append("amount", amount);
    data.append("ptype", ptype);
    data.append("tamount", "");
    data.append("refundb", "");
    data.append("uid", uid);
    data.append("stype", stype);
    data.append("name", vname);
    data.append("vname", vname);
    data.append("serial", serial);
    data.append("base", "");
    data.append("gross", "");
    data.append("note", note);
    data.append("date", fno);
    data.append("token", localStorage.getItem("token"));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundhistory3",
        data
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    toast.success("Refund Addedd", {
      theme: "dark",
      position: toast.POSITION.TOP_CENTER,
      toastId: "success4",
      autoClose: 2000,
    });

    refundfetch2(uid, "vendor");

    document.getElementById("spfrm89").reset();
  }

  function refundfetch2(x, y) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/refundfetch2",
        {
          tid: x,
          stype: y,
        }
      )
      .then((res) => {
        setPhistory(res.data.message);
        console.log(res.data.message);
      })
      .catch((err) => console.log(err));
  }

  function delrefund(x, y, z) {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/delrefund",
        {
          id: x,
          price: y,
          key: z,
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => console.log(res.data.message))
      .catch((err) => console.log(err));

    refundfetch2(uid, "vendor");
  }

  useEffect(() => {
    refundfetch2(uid, "vendor");
  }, [uid]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/sinpurchase",
        {
          id: uid,
        }
      )
      .then((res) => setPurchasehis(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className={active ? "mp-Modal-backdrop" : ""}>
      <div class={active ? "mp-Modal mp-Modal-active" : "mp-Modal"}>
        <div
          onClick={() => setShow(false)}
          class="tPurch_status"
          data-keyboard="false"
          data-backdrop="static"
          id="pTicketstatus2"
          tabindex="1"
          role="dialog"
          aria-labelledby="pTicketModalLabel"
          aria-hidden="true"
        >
          <div class="" role="document">
            <div class="">
              <div class="mHeader">
                <i
                  onClick={() => {
                    close(false);
                  }}
                  className="fa fa-close cusIcon"
                ></i>

                <p class="modal-title pModalheadear" id="pTicketModalLabel">
                  Refund Ticket
                </p>
              </div>

              <div class="row ml-3 my-3" style={{ fontSize: 16 }}>
                <div class="col-lg-4 col-4">
                  <p class="mb-2 font-weight-bold">Vendor Name</p>
                </div>
                <div class="col-lg-8 col-8">
                  <p class="mb-2 font-weight-bold">{name}</p>
                </div>
              </div>

              <div class="pModalbody border px-0 ">
                <form id="spfrm89">
                  <div>
                    <p class="font-weight-bold bg-light border-bottom px-2 py-2">
                      Make Refund
                    </p>

                    <div class="form-group row py-1 px-2">
                      <label
                        for=""
                        class="col-lg-3 col-4 col-form-label infotext"
                      >
                        Date
                      </label>
                      <div class="col-lg-9 col-12">
                        <input
                          id=""
                          name=""
                          type="date"
                          class="form-control"
                          required="required"
                          onChange={(e) => setFno(e.target.value)}
                        />
                      </div>
                    </div>

                    <div class="form-group row py-1 px-2">
                      <label
                        for=""
                        class="col-lg-3 col-4 col-form-label infotext"
                      >
                        SL. / PNR
                      </label>
                      <div class="col-lg-9 col-12">
                        <input
                          className="form-control"
                          value={serial}
                          onChange={(e) => {
                            setSerial(e.target.value);
                            setShow(true);
                          }}
                        ></input>

                        {show ? (
                          <div class="list-group">
                            {purchasehis
                              .filter((item) => {
                                if (
                                  item.tserial
                                    ?.toLocaleLowerCase()
                                    .includes(serial?.toLocaleLowerCase())
                                ) {
                                  return item;
                                }
                              })
                              .map((item) => (
                                <a
                                  href="#"
                                  onClick={() => {
                                    setShow(false);
                                    setSerial(item.tserial);
                                  }}
                                  class="list-group-item list-group-item-action"
                                >
                                  {item.tserial}
                                </a>
                              ))}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div class="form-group row py-1 px-2">
                      <label
                        for=""
                        class="col-lg-3 col-4 col-form-label infotext"
                      >
                        Quantity
                      </label>
                      <div class="col-lg-3 col-8">
                        <div class="input-group">
                          <input
                            id=""
                            name=""
                            type="text"
                            class="form-control"
                            required="required"
                            onChange={(e) => setNote(e.target.value)}
                          />
                        </div>
                      </div>

                      <label
                        for=""
                        class="col-lg-2 col-4 col-form-label infotext"
                      >
                        Price
                      </label>
                      <div class="col-lg-4 col-8">
                        <div class="input-group">
                          <input
                            id=""
                            name=""
                            type="text"
                            class="form-control"
                            required="required"
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div class="px-2">
                  <button
                    type="button"
                    class="btn paymentUpdate w-100 mb-2"
                    onClick={() => {
                      refundhistory();
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>

              <div
                class="pModalbody border px-0 my-4"
                style={{ height: 300, overflow: "auto" }}
              >
                <p class="font-weight-bold border-bottom p-2 mb-0 bg-light">
                  Refund Ticket
                </p>
                <div class="ptl">
                  <table class="table" style={{ fontSize: 14 }}>
                    <thead class="bg-light text-center">
                      <tr>
                        <th scope="col" class="py-1">
                          Date
                        </th>
                        <th scope="col" class="py-1">
                          SL/PNR
                        </th>
                        <th scope="col" class="py-1">
                          Quantity
                        </th>
                        <th scope="col" class="py-1">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody class="text-center">
                      {phistory.map((item) => (
                        <tr>
                          <td>{formatDate(item.date)}</td>
                          <td>{item.serial}</td>
                          <td>{item.note}</td>
                          <td>{item.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Refundticket;
