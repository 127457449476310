import { useEffect } from "react";
import { useState } from "react";
import {
  useHistory,
  withRouter,
} from "react-router-dom/cjs/react-router-dom.min";
import manpower from "../../images/ManPower.png";
import medical2 from "../../images/Medical.svg";
import axios from "axios";
import Loading from "../common/loading";
function Packages({
  steps,
  setSteps,
  id,
  packages,
  update,
  setUpdate,
  lastid,
  ctype,
  setCtype,
  setAid,
  aid,
  des,
  setDes,
}) {
  let history = useHistory();

  const [active, setActive] = useState("ticket");
  const [show, setShow] = useState(false);
  const [agents, setAgents] = useState([]);

  const [vendors, setVendors] = useState([]);

  const [airplane, setAirplane] = useState("");
  const [pnr, setPnr] = useState("");
  const [tserial, setTserial] = useState("");
  const [arloca, setArloca] = useState("");
  const [ardate, setArdate] = useState("");
  const [vendor, setVendor] = useState("");
  const [country, setCountry] = useState("");
  const [vtype, setVtype] = useState("");
  const [sdate, setSdate] = useState("");
  const [hname, setHname] = useState("");
  const [cin, setCin] = useState("");
  const [mcenter, setMcenter] = useState("");
  const [ddate, setDdate] = useState("");
  const [bmnumber, setBmnumber] = useState("");
  const [snumber, setSnumber] = useState("");
  const [enroll, setEnroll] = useState("");
  const [base, setBase] = useState("");
  const [load, setLoad] = useState(false);
  const [hide, setHide] = useState(false);

  const [services, setServices] = useState([
    {
      service: "Domestic Ticket",
      srt: "Domestic",
      to: "/customer/domestic",
      url: "customer/domestic",
      img: "../images/domTicket.png",
    },
    {
      service: "International Ticket",
      srt: "International",
      to: "/customer/international",
      url: "customer/international",
      img: "../images/intTicket.svg",
    },

    {
      service: "Visa",
      to: "/customer/visa",
      srt: "Visa",
      url: "customer/visa",
      img: "../images/visa.svg",
    },
    {
      service: "Hotel",
      to: "/customer/hotel",
      srt: "Hotel",
      url: "customer/hotel",
      img: "../images/hotel.svg",
    },
    {
      service: "Medical",
      to: "/customer/medical",
      srt: "Medical",
      url: "customer/medical",
      img: medical2,
    },
    {
      service: "Manpower",
      to: "/customer/manpower",
      srt: "Manpower",
      url: "customer/manpower",
      img: manpower,
    },
    {
      service: "Passport",
      to: "/customer/passport",
      srt: "Passport",
      url: "customer/passport",
      img: require("../../images/Passport.png"),
    },
  ]);

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchalltypevendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  function handleSubmit() {
    if (aid != "") {
      var des;

      if (active.toLocaleLowerCase().includes("ticket")) {
        des = "Ticket- Packages, T/S-" + tserial + ", PNR -" + pnr;
        setDes((old) => [...old, " > " + des]);
      }

      if (active.toLocaleLowerCase().includes("visa")) {
        des = "Visa- Packages, S/D-" + sdate + ", " + country;
        setDes((old) => [...old, " > " + des]);
      }

      if (active.toLocaleLowerCase().includes("hotel")) {
        des = "Hotel- Packages, " + country + ", H/N-" + hname;
        setDes((old) => [...old, " > " + des]);
      }

      if (active.toLocaleLowerCase().includes("medical")) {
        des = "Medical- Packages, M/C-" + mcenter + ", D/D-" + ddate + "";
        setDes((old) => [...old, " > " + des]);
      }

      if (active.toLocaleLowerCase().includes("manpower")) {
        des =
          "Manpower- Packages, " + "B/M-" + bmnumber + ", D/D-" + ddate + "";
        setDes((old) => [...old, " > " + des]);
      }

      if (active.toLocaleLowerCase().includes("passport")) {
        des = "Passport- Packages, P/S-" + snumber + ", D/D-" + ddate + "";
        setDes((old) => [...old, " > " + des]);
      }
    }
    setLoad(true);
    const data = new FormData();
    data.append("packageId", Number(lastid));
    data.append("airplane", airplane);
    data.append("pnr", pnr);
    data.append("tserial", tserial);
    data.append("arloca", arloca);
    data.append("ardate", ardate);
    data.append("vendor", vendor);
    data.append("base", base);
    data.append("country", country);
    data.append("vtype", vtype);
    data.append("sdate", sdate);
    data.append("hname", hname);
    data.append("cin", cin);
    data.append("mcenter", mcenter);
    data.append("ddate", ddate);
    data.append("bmnumber", bmnumber);
    data.append("snumber", snumber);
    data.append("enroll", enroll);
    data.append("service", active);
    data.append("ctype", aid);
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/packages/addNew",
        data
      )
      .then((res) => {
        console.log(res.data);
        setUpdate(update + 1);
        setHide(true);
        setLoad(false);
        document.getElementById("frm").reset();
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchalltypevendor"
      )
      .then((res) => setVendors(res.data.message))
      .catch((err) => console.log(err));

    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/fetchagent"
      )
      .then((res) => setAgents(res.data.message))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Loading load={load} />

      <div>
        {packages.map((item) => (
          <>
            {item.service.toLocaleLowerCase().includes("ticket") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Airplane :</b> {item.airplane}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>PNR :</b> {item.pnr}
                  </p>
                  <p class="mb-0">
                    <b>Ticket Serial :</b> {item.tserial}
                  </p>
                </div>
              </div>
            ) : item.service.toLocaleLowerCase().includes("visa") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Country :</b> {item.country}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>Visa Type :</b> {item.vtype}
                  </p>
                  <p class="mb-0">
                    <b>Stamping Date :</b> {item.sdate} {lastid}{" "}
                  </p>
                </div>
              </div>
            ) : item.service.toLocaleLowerCase().includes("hotel") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Country :</b> {item.country}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>Hotel Name :</b> {item.hname}{" "}
                  </p>
                  <p class="mb-0">
                    <b>Check-In :</b> {item.cin}
                  </p>
                </div>
              </div>
            ) : item.service.toLocaleLowerCase().includes("medical") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Medical Center Name :</b> {item.mcenter}{" "}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>Delivery Date :</b> {item.ddate}
                  </p>
                </div>
              </div>
            ) : item.service.toLocaleLowerCase().includes("manpower") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Country :</b> {item.country}{" "}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>BMET Number :</b> {item.bmnumber}
                  </p>
                  <p class="mb-0">
                    <b>Delivery Date :</b> {item.ddate}
                  </p>
                </div>
              </div>
            ) : item.service.toLocaleLowerCase().includes("passport") ? (
              <div className="card border bg-light rounded-0 mb-3">
                <p class="border-bottom px-3 py-2 mb-0">
                  <b>Service :</b>
                  <span class="text-uppercase"> {item.service}</span>{" "}
                </p>
                <div class="d-flex px-3 py-2 bg-white">
                  <p class="mb-0">
                    <b>Slip Number :</b> {item.snumber}{" "}
                  </p>
                  <p class="mb-0 mx-5">
                    <b>Enrollment Date :</b> {item.enroll}
                  </p>
                </div>
              </div>
            ) : null}
          </>
        ))}
      </div>

      <div class="bg-light border mx-auto mb-0 px-3">
        <form id="frm">
          {hide ? null : (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Customer Type
                </label>
                <div class="col-lg-6 col-12 mt-2">
                  <div class="form-check form-check-inline">
                    <input
                      name="ctype"
                      id="fit"
                      type="radio"
                      class="form-check-input"
                      value="regular"
                      required=""
                      onChange={(e) => {
                        setCtype(e.target.value);
                        setShow(false);
                      }}
                    />
                    <label for="_0" class="form-check-label infotext mr-2">
                      Regular
                    </label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input
                      name="ctype"
                      id="fit"
                      type="radio"
                      class="form-check-input"
                      value="agent"
                      required=""
                      onChange={(e) => {
                        setCtype(e.target.value);
                        setShow(true);
                        console.log(e.target.value);
                      }}
                    />
                    <label for="_0" class="form-check-label infotext mr-2">
                      From Agent
                    </label>
                  </div>
                </div>
              </div>

              {ctype == "agent" ? (
                <div class="form-group row py-1">
                  <label for="" class="col-lg-2 col-12 col-form-label infotext">
                    Agent Name
                  </label>
                  <div class="col-lg-10 col-12">
                    <div class="input-group">
                      {ctype == "agent" ? (
                        <select
                          class="form-control"
                          onChange={(e) => setAid(e.target.value)}
                        >
                          <option>Choose Agent</option>
                          {agents.map((item) => (
                            <option value={item.id}>{item.aname}</option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          )}

          <div class="form-group row py-1 mt-3">
            <label
              for="textarea"
              class="col-lg-2 col-12 infotext col-form-label"
            >
              Select Service
            </label>
            <div class="col-lg-10 col-12">
              <select
                onChange={(e) => {
                  setActive(e.target.value);
                }}
                className="form-control"
              >
                {services.map((item) => (
                  <option>{item.service}</option>
                ))}
              </select>
            </div>
          </div>

          {active.toLocaleLowerCase().includes("ticket") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Airplane
                </label>
                <div class="col-lg-4 col-12">
                  <select
                    id="inputState"
                    class="form-select form-control"
                    value={airplane}
                    onChange={(e) => setAirplane(e.target.value)}
                  >
                    <option class="form-control" selected>
                      Choose Airlines
                    </option>
                    <option>Air Arabia</option>
                    <option>Air Asia</option>
                    <option>Air India</option>
                    <option>Air KZB Company Ltd.</option>
                    <option>Biman Bangladesh Airlines</option>
                    <option>Bangkok Airlines</option>
                    <option>Bismillah Airlines</option>
                    <option>British Airways</option>
                    <option>Cathay Pacific</option>
                    <option>China Eastern Airlines</option>
                    <option>China Southern Airlines</option>
                    <option>Dragonair</option>
                    <option>Etihad Airways</option>
                    <option>Finnair</option>
                    <option>Fly Emirates</option>
                    <option>Fly Dubai</option>
                    <option>Gulf Air</option>
                    <option>Himalaya Airlines</option>
                    <option>Indigo</option>
                    <option>Jazeera Airways</option>
                    <option>Jet Airways</option>
                    <option>Kuwait Airways</option>
                    <option>Malaysia Airlines</option>
                    <option>Malindo Air</option>
                    <option>Novoair</option>
                    <option>Oman Air</option>
                    <option>Pakistan International Airlines</option>
                    <option>Qatar Airways</option>
                    <option>Regent Airways</option>
                    <option>Salam Air</option>
                    <option>Saudi Arabian Airlines</option>
                    <option>Saudia</option>
                    <option>Singapore Airlines</option>
                    <option>SpiceJet</option>
                    <option>Srilankan Airlines</option>
                    <option>Thai Airways</option>
                    <option>Thai Airways International</option>
                    <option>Turkish Airlines</option>
                    <option>United Airlines</option>
                    <option>US-Bangla Airlines</option>
                    <option>Yemenia</option>
                  </select>
                </div>

                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-3 infotext col-form-label"
                >
                  Flight Sector
                </label>
                <div class="col-lg-2 col-2">
                  <input
                    type="text"
                    class="form-control"
                    required=""
                    placeholder="Ex. DAC/DXB"
                    onChange={(e) => {
                      setArloca(e.target.value);
                    }}
                  ></input>
                </div>
                <div class="col-lg-2 col-3">
                  <input
                    type="date"
                    class="form-control"
                    required=""
                    onChange={(e) => {
                      setArdate(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  PNR
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => {
                      setPnr(e.target.value);
                    }}
                  />
                </div>
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Ticket Serial
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="text"
                      name="text"
                      type="text"
                      placeholder=""
                      class="form-control"
                      required=""
                      onChange={(e) => {
                        setTserial(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  vendor
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <select
                      onChange={(e) => {
                        setVendor(e.target.value);
                      }}
                      className="form-control"
                    >
                      <option selected>Choose Vendor</option>
                      {vendors.map((item) => (
                        <option value={item.id}>{item.vname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-12 infotext col-form-label"
                >
                  Buying Fare
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    type="number"
                    class="form-control"
                    required=""
                    onChange={(e) => {
                      setBase(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
            </>
          ) : null}
          {active.toLocaleLowerCase().includes("visa") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Country
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <select
                      class="form-select form-control"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option class="form-control" selected>
                        Choose Country
                      </option>
                      <option value="Afganistan">Afghanistan</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antigua & Barbuda">
                        Antigua & Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bonaire">Bonaire</option>
                      <option value="Bosnia & Herzegovina">
                        Bosnia & Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Ter">
                        British Indian Ocean Ter
                      </option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Canary Islands">Canary Islands</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Channel Islands">Channel Islands</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos Island">Cocos Island</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote DIvoire">Cote DIvoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Curaco">Curacao</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="East Timor">East Timor</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands">Falkland Islands</option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Ter">
                        French Southern Ter
                      </option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Great Britain">Great Britain</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="India">India</option>
                      <option value="Iran">Iran</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea North">Korea North</option>
                      <option value="Korea Sout">Korea South</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Laos">Laos</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libya">Libya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macau">Macau</option>
                      <option value="Macedonia">Macedonia</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Midway Islands">Midway Islands</option>
                      <option value="Moldova">Moldova</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Nambia">Nambia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherland Antilles">
                        Netherland Antilles
                      </option>
                      <option value="Netherlands">
                        Netherlands (Holland, Europe)
                      </option>
                      <option value="Nevis">Nevis</option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau Island">Palau Island</option>
                      <option value="Palestine">Palestine</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Phillipines">Philippines</option>
                      <option value="Pitcairn Island">Pitcairn Island</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Republic of Montenegro">
                        Republic of Montenegro
                      </option>
                      <option value="Republic of Serbia">
                        Republic of Serbia
                      </option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russia">Russia</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="St Barthelemy">St Barthelemy</option>
                      <option value="St Eustatius">St Eustatius</option>
                      <option value="St Helena">St Helena</option>
                      <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                      <option value="St Lucia">St Lucia</option>
                      <option value="St Maarten">St Maarten</option>
                      <option value="St Pierre & Miquelon">
                        St Pierre & Miquelon
                      </option>
                      <option value="St Vincent & Grenadines">
                        St Vincent & Grenadines
                      </option>
                      <option value="Saipan">Saipan</option>
                      <option value="Samoa">Samoa</option>
                      <option value="Samoa American">Samoa American</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome & Principe">
                        Sao Tome & Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syria">Syria</option>
                      <option value="Tahiti">Tahiti</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad & Tobago">
                        Trinidad & Tobago
                      </option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks & Caicos Is">
                        Turks & Caicos Is
                      </option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Erimates">
                        United Arab Emirates
                      </option>
                      <option value="United States of America">
                        United States of America
                      </option>
                      <option value="Uraguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Vatican City State">
                        Vatican City State
                      </option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Vietnam">Vietnam</option>
                      <option value="Virgin Islands (Brit)">
                        Virgin Islands (Brit)
                      </option>
                      <option value="Virgin Islands (USA)">
                        Virgin Islands (USA)
                      </option>
                      <option value="Wake Island">Wake Island</option>
                      <option value="Wallis & Futana Is">
                        Wallis & Futana Is
                      </option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zaire">Zaire</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                  </div>
                </div>
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Visa Type
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <select
                      id="inputState"
                      class="form-select form-control"
                      value={vtype}
                      onChange={(e) => setVtype(e.target.value)}
                    >
                      <option class="form-control">Choose Type</option>
                      <option>Tourist</option>
                      <option>Work</option>
                      <option>Labour</option>
                      <option>House Labour</option>
                      <option>Driving</option>
                      <option>Family</option>
                      <option>Business</option>
                      <option>Student</option>
                      <option>Transit</option>
                      <option>Medical</option>
                      <option>Investor</option>
                      <option>Journalist</option>
                      <option>NGO</option>
                      <option>Diplomatic/Official</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Stamping Date
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="text"
                      type="date"
                      class="form-control"
                      required=""
                      onChange={(e) => setSdate(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-3 infotext col-form-label"
                >
                  Vendor
                </label>
                <div class="col-lg-4 col-2">
                  <select
                    onChange={(e) => {
                      setVendor(e.target.value);
                    }}
                    className="form-control"
                  >
                    {vendors.map((item) => (
                      <option value={item.id}>{item.vname}</option>
                    ))}
                  </select>
                </div>
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Buying Fare
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      required=""
                      onChange={(e) => {
                        setBase(e.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {active.toLocaleLowerCase().includes("hotel") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Country
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <select
                      class="form-select form-control"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option class="form-control" selected>
                        Choose Country
                      </option>
                      <option value="Afganistan">Afghanistan</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antigua & Barbuda">
                        Antigua & Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bonaire">Bonaire</option>
                      <option value="Bosnia & Herzegovina">
                        Bosnia & Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Ter">
                        British Indian Ocean Ter
                      </option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Canary Islands">Canary Islands</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Channel Islands">Channel Islands</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos Island">Cocos Island</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote DIvoire">Cote DIvoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Curaco">Curacao</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="East Timor">East Timor</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands">Falkland Islands</option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Ter">
                        French Southern Ter
                      </option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Great Britain">Great Britain</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="India">India</option>
                      <option value="Iran">Iran</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea North">Korea North</option>
                      <option value="Korea Sout">Korea South</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Laos">Laos</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libya">Libya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macau">Macau</option>
                      <option value="Macedonia">Macedonia</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Midway Islands">Midway Islands</option>
                      <option value="Moldova">Moldova</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Nambia">Nambia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherland Antilles">
                        Netherland Antilles
                      </option>
                      <option value="Netherlands">
                        Netherlands (Holland, Europe)
                      </option>
                      <option value="Nevis">Nevis</option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau Island">Palau Island</option>
                      <option value="Palestine">Palestine</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Phillipines">Philippines</option>
                      <option value="Pitcairn Island">Pitcairn Island</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Republic of Montenegro">
                        Republic of Montenegro
                      </option>
                      <option value="Republic of Serbia">
                        Republic of Serbia
                      </option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russia">Russia</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="St Barthelemy">St Barthelemy</option>
                      <option value="St Eustatius">St Eustatius</option>
                      <option value="St Helena">St Helena</option>
                      <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                      <option value="St Lucia">St Lucia</option>
                      <option value="St Maarten">St Maarten</option>
                      <option value="St Pierre & Miquelon">
                        St Pierre & Miquelon
                      </option>
                      <option value="St Vincent & Grenadines">
                        St Vincent & Grenadines
                      </option>
                      <option value="Saipan">Saipan</option>
                      <option value="Samoa">Samoa</option>
                      <option value="Samoa American">Samoa American</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome & Principe">
                        Sao Tome & Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syria">Syria</option>
                      <option value="Tahiti">Tahiti</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad & Tobago">
                        Trinidad & Tobago
                      </option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks & Caicos Is">
                        Turks & Caicos Is
                      </option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Erimates">
                        United Arab Emirates
                      </option>
                      <option value="United States of America">
                        United States of America
                      </option>
                      <option value="Uraguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Vatican City State">
                        Vatican City State
                      </option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Vietnam">Vietnam</option>
                      <option value="Virgin Islands (Brit)">
                        Virgin Islands (Brit)
                      </option>
                      <option value="Virgin Islands (USA)">
                        Virgin Islands (USA)
                      </option>
                      <option value="Wake Island">Wake Island</option>
                      <option value="Wallis & Futana Is">
                        Wallis & Futana Is
                      </option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zaire">Zaire</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                  </div>
                </div>

                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Hotel Name
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => setHname(e.target.value)}
                  />
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Check-In
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="date"
                      name="text"
                      type="date"
                      class="form-control"
                      required=""
                      onChange={(e) => setCin(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-3 infotext col-form-label"
                >
                  Vendor
                </label>
                <div class="col-lg-4 col-2">
                  <div class="input-group">
                    <select
                      onChange={(e) => setVendor(e.target.value)}
                      className="form-control"
                    >
                      {vendors.map((item) => (
                        <option value={item.id}>{item.vname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Buying Fare
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      required=""
                      onChange={(e) => setBase(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {active.toLocaleLowerCase().includes("manpower") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Country
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <select
                      class="form-select form-control"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option class="form-control" selected>
                        Choose Country
                      </option>
                      <option value="Afganistan">Afghanistan</option>
                      <option value="Albania">Albania</option>
                      <option value="Algeria">Algeria</option>
                      <option value="American Samoa">American Samoa</option>
                      <option value="Andorra">Andorra</option>
                      <option value="Angola">Angola</option>
                      <option value="Anguilla">Anguilla</option>
                      <option value="Antigua & Barbuda">
                        Antigua & Barbuda
                      </option>
                      <option value="Argentina">Argentina</option>
                      <option value="Armenia">Armenia</option>
                      <option value="Aruba">Aruba</option>
                      <option value="Australia">Australia</option>
                      <option value="Austria">Austria</option>
                      <option value="Azerbaijan">Azerbaijan</option>
                      <option value="Bahamas">Bahamas</option>
                      <option value="Bahrain">Bahrain</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Barbados">Barbados</option>
                      <option value="Belarus">Belarus</option>
                      <option value="Belgium">Belgium</option>
                      <option value="Belize">Belize</option>
                      <option value="Benin">Benin</option>
                      <option value="Bermuda">Bermuda</option>
                      <option value="Bhutan">Bhutan</option>
                      <option value="Bolivia">Bolivia</option>
                      <option value="Bonaire">Bonaire</option>
                      <option value="Bosnia & Herzegovina">
                        Bosnia & Herzegovina
                      </option>
                      <option value="Botswana">Botswana</option>
                      <option value="Brazil">Brazil</option>
                      <option value="British Indian Ocean Ter">
                        British Indian Ocean Ter
                      </option>
                      <option value="Brunei">Brunei</option>
                      <option value="Bulgaria">Bulgaria</option>
                      <option value="Burkina Faso">Burkina Faso</option>
                      <option value="Burundi">Burundi</option>
                      <option value="Cambodia">Cambodia</option>
                      <option value="Cameroon">Cameroon</option>
                      <option value="Canada">Canada</option>
                      <option value="Canary Islands">Canary Islands</option>
                      <option value="Cape Verde">Cape Verde</option>
                      <option value="Cayman Islands">Cayman Islands</option>
                      <option value="Central African Republic">
                        Central African Republic
                      </option>
                      <option value="Chad">Chad</option>
                      <option value="Channel Islands">Channel Islands</option>
                      <option value="Chile">Chile</option>
                      <option value="China">China</option>
                      <option value="Christmas Island">Christmas Island</option>
                      <option value="Cocos Island">Cocos Island</option>
                      <option value="Colombia">Colombia</option>
                      <option value="Comoros">Comoros</option>
                      <option value="Congo">Congo</option>
                      <option value="Cook Islands">Cook Islands</option>
                      <option value="Costa Rica">Costa Rica</option>
                      <option value="Cote DIvoire">Cote DIvoire</option>
                      <option value="Croatia">Croatia</option>
                      <option value="Cuba">Cuba</option>
                      <option value="Curaco">Curacao</option>
                      <option value="Cyprus">Cyprus</option>
                      <option value="Czech Republic">Czech Republic</option>
                      <option value="Denmark">Denmark</option>
                      <option value="Djibouti">Djibouti</option>
                      <option value="Dominica">Dominica</option>
                      <option value="Dominican Republic">
                        Dominican Republic
                      </option>
                      <option value="East Timor">East Timor</option>
                      <option value="Ecuador">Ecuador</option>
                      <option value="Egypt">Egypt</option>
                      <option value="El Salvador">El Salvador</option>
                      <option value="Equatorial Guinea">
                        Equatorial Guinea
                      </option>
                      <option value="Eritrea">Eritrea</option>
                      <option value="Estonia">Estonia</option>
                      <option value="Ethiopia">Ethiopia</option>
                      <option value="Falkland Islands">Falkland Islands</option>
                      <option value="Faroe Islands">Faroe Islands</option>
                      <option value="Fiji">Fiji</option>
                      <option value="Finland">Finland</option>
                      <option value="France">France</option>
                      <option value="French Guiana">French Guiana</option>
                      <option value="French Polynesia">French Polynesia</option>
                      <option value="French Southern Ter">
                        French Southern Ter
                      </option>
                      <option value="Gabon">Gabon</option>
                      <option value="Gambia">Gambia</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Germany">Germany</option>
                      <option value="Ghana">Ghana</option>
                      <option value="Gibraltar">Gibraltar</option>
                      <option value="Great Britain">Great Britain</option>
                      <option value="Greece">Greece</option>
                      <option value="Greenland">Greenland</option>
                      <option value="Grenada">Grenada</option>
                      <option value="Guadeloupe">Guadeloupe</option>
                      <option value="Guam">Guam</option>
                      <option value="Guatemala">Guatemala</option>
                      <option value="Guinea">Guinea</option>
                      <option value="Guyana">Guyana</option>
                      <option value="Haiti">Haiti</option>
                      <option value="Hawaii">Hawaii</option>
                      <option value="Honduras">Honduras</option>
                      <option value="Hong Kong">Hong Kong</option>
                      <option value="Hungary">Hungary</option>
                      <option value="Iceland">Iceland</option>
                      <option value="Indonesia">Indonesia</option>
                      <option value="India">India</option>
                      <option value="Iran">Iran</option>
                      <option value="Iraq">Iraq</option>
                      <option value="Ireland">Ireland</option>
                      <option value="Isle of Man">Isle of Man</option>
                      <option value="Israel">Israel</option>
                      <option value="Italy">Italy</option>
                      <option value="Jamaica">Jamaica</option>
                      <option value="Japan">Japan</option>
                      <option value="Jordan">Jordan</option>
                      <option value="Kazakhstan">Kazakhstan</option>
                      <option value="Kenya">Kenya</option>
                      <option value="Kiribati">Kiribati</option>
                      <option value="Korea North">Korea North</option>
                      <option value="Korea Sout">Korea South</option>
                      <option value="Kuwait">Kuwait</option>
                      <option value="Kyrgyzstan">Kyrgyzstan</option>
                      <option value="Laos">Laos</option>
                      <option value="Latvia">Latvia</option>
                      <option value="Lebanon">Lebanon</option>
                      <option value="Lesotho">Lesotho</option>
                      <option value="Liberia">Liberia</option>
                      <option value="Libya">Libya</option>
                      <option value="Liechtenstein">Liechtenstein</option>
                      <option value="Lithuania">Lithuania</option>
                      <option value="Luxembourg">Luxembourg</option>
                      <option value="Macau">Macau</option>
                      <option value="Macedonia">Macedonia</option>
                      <option value="Madagascar">Madagascar</option>
                      <option value="Malaysia">Malaysia</option>
                      <option value="Malawi">Malawi</option>
                      <option value="Maldives">Maldives</option>
                      <option value="Mali">Mali</option>
                      <option value="Malta">Malta</option>
                      <option value="Marshall Islands">Marshall Islands</option>
                      <option value="Martinique">Martinique</option>
                      <option value="Mauritania">Mauritania</option>
                      <option value="Mauritius">Mauritius</option>
                      <option value="Mayotte">Mayotte</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Midway Islands">Midway Islands</option>
                      <option value="Moldova">Moldova</option>
                      <option value="Monaco">Monaco</option>
                      <option value="Mongolia">Mongolia</option>
                      <option value="Montserrat">Montserrat</option>
                      <option value="Morocco">Morocco</option>
                      <option value="Mozambique">Mozambique</option>
                      <option value="Myanmar">Myanmar</option>
                      <option value="Nambia">Nambia</option>
                      <option value="Nauru">Nauru</option>
                      <option value="Nepal">Nepal</option>
                      <option value="Netherland Antilles">
                        Netherland Antilles
                      </option>
                      <option value="Netherlands">
                        Netherlands (Holland, Europe)
                      </option>
                      <option value="Nevis">Nevis</option>
                      <option value="New Caledonia">New Caledonia</option>
                      <option value="New Zealand">New Zealand</option>
                      <option value="Nicaragua">Nicaragua</option>
                      <option value="Niger">Niger</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="Niue">Niue</option>
                      <option value="Norfolk Island">Norfolk Island</option>
                      <option value="Norway">Norway</option>
                      <option value="Oman">Oman</option>
                      <option value="Pakistan">Pakistan</option>
                      <option value="Palau Island">Palau Island</option>
                      <option value="Palestine">Palestine</option>
                      <option value="Panama">Panama</option>
                      <option value="Papua New Guinea">Papua New Guinea</option>
                      <option value="Paraguay">Paraguay</option>
                      <option value="Peru">Peru</option>
                      <option value="Phillipines">Philippines</option>
                      <option value="Pitcairn Island">Pitcairn Island</option>
                      <option value="Poland">Poland</option>
                      <option value="Portugal">Portugal</option>
                      <option value="Puerto Rico">Puerto Rico</option>
                      <option value="Qatar">Qatar</option>
                      <option value="Republic of Montenegro">
                        Republic of Montenegro
                      </option>
                      <option value="Republic of Serbia">
                        Republic of Serbia
                      </option>
                      <option value="Reunion">Reunion</option>
                      <option value="Romania">Romania</option>
                      <option value="Russia">Russia</option>
                      <option value="Rwanda">Rwanda</option>
                      <option value="St Barthelemy">St Barthelemy</option>
                      <option value="St Eustatius">St Eustatius</option>
                      <option value="St Helena">St Helena</option>
                      <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                      <option value="St Lucia">St Lucia</option>
                      <option value="St Maarten">St Maarten</option>
                      <option value="St Pierre & Miquelon">
                        St Pierre & Miquelon
                      </option>
                      <option value="St Vincent & Grenadines">
                        St Vincent & Grenadines
                      </option>
                      <option value="Saipan">Saipan</option>
                      <option value="Samoa">Samoa</option>
                      <option value="Samoa American">Samoa American</option>
                      <option value="San Marino">San Marino</option>
                      <option value="Sao Tome & Principe">
                        Sao Tome & Principe
                      </option>
                      <option value="Saudi Arabia">Saudi Arabia</option>
                      <option value="Senegal">Senegal</option>
                      <option value="Seychelles">Seychelles</option>
                      <option value="Sierra Leone">Sierra Leone</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Slovakia">Slovakia</option>
                      <option value="Slovenia">Slovenia</option>
                      <option value="Solomon Islands">Solomon Islands</option>
                      <option value="Somalia">Somalia</option>
                      <option value="South Africa">South Africa</option>
                      <option value="Spain">Spain</option>
                      <option value="Sri Lanka">Sri Lanka</option>
                      <option value="Sudan">Sudan</option>
                      <option value="Suriname">Suriname</option>
                      <option value="Swaziland">Swaziland</option>
                      <option value="Sweden">Sweden</option>
                      <option value="Switzerland">Switzerland</option>
                      <option value="Syria">Syria</option>
                      <option value="Tahiti">Tahiti</option>
                      <option value="Taiwan">Taiwan</option>
                      <option value="Tajikistan">Tajikistan</option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Togo">Togo</option>
                      <option value="Tokelau">Tokelau</option>
                      <option value="Tonga">Tonga</option>
                      <option value="Trinidad & Tobago">
                        Trinidad & Tobago
                      </option>
                      <option value="Tunisia">Tunisia</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Turkmenistan">Turkmenistan</option>
                      <option value="Turks & Caicos Is">
                        Turks & Caicos Is
                      </option>
                      <option value="Tuvalu">Tuvalu</option>
                      <option value="Uganda">Uganda</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Erimates">
                        United Arab Emirates
                      </option>
                      <option value="United States of America">
                        United States of America
                      </option>
                      <option value="Uraguay">Uruguay</option>
                      <option value="Uzbekistan">Uzbekistan</option>
                      <option value="Vanuatu">Vanuatu</option>
                      <option value="Vatican City State">
                        Vatican City State
                      </option>
                      <option value="Venezuela">Venezuela</option>
                      <option value="Vietnam">Vietnam</option>
                      <option value="Virgin Islands (Brit)">
                        Virgin Islands (Brit)
                      </option>
                      <option value="Virgin Islands (USA)">
                        Virgin Islands (USA)
                      </option>
                      <option value="Wake Island">Wake Island</option>
                      <option value="Wallis & Futana Is">
                        Wallis & Futana Is
                      </option>
                      <option value="Yemen">Yemen</option>
                      <option value="Zaire">Zaire</option>
                      <option value="Zambia">Zambia</option>
                      <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                  </div>
                </div>

                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  BMET Number
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => setBmnumber(e.target.value)}
                  />
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Delivery Date
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="date"
                      name="text"
                      type="date"
                      class="form-control"
                      required=""
                      onChange={(e) => setDdate(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-3 infotext col-form-label"
                >
                  Vendor
                </label>
                <div class="col-lg-4 col-2">
                  <div class="input-group">
                    <select
                      onChange={(e) => setVendor(e.target.value)}
                      className="form-control"
                    >
                      {vendors.map((item) => (
                        <option value={item.id}>{item.vname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Buying Fare
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      required=""
                      onChange={(e) => setBase(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {active.toLocaleLowerCase().includes("medical") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Medical Center
                </label>
                <div class="col-lg-10 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => setMcenter(e.target.value)}
                  />
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Delivery Date
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="date"
                      name="text"
                      type="date"
                      class="form-control"
                      required=""
                      onChange={(e) => setDdate(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row py-1">
                <label
                  for="textarea"
                  class="col-lg-2 mt-lg-0 mt-4 col-3 infotext col-form-label"
                >
                  Vendor
                </label>
                <div class="col-lg-4 col-2">
                  <div class="input-group">
                    <select
                      onChange={(e) => setVendor(e.target.value)}
                      className="form-control"
                    >
                      {vendors.map((item) => (
                        <option value={item.id}>{item.vname}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Buying Fare
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      required=""
                      onChange={(e) => setBase(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {active.toLocaleLowerCase().includes("passport") ? (
            <>
              <div class="form-group row py-1">
                <label for="" class="col-lg-2 col-12 col-form-label infotext">
                  Slip Number
                </label>
                <div class="col-lg-4 col-12">
                  <input
                    id=""
                    name=""
                    type="text"
                    class="form-control"
                    required="required"
                    onChange={(e) => setSnumber(e.target.value)}
                  />
                </div>

                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Enrollment Date
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      id="date"
                      name="text"
                      type="date"
                      class="form-control"
                      required=""
                      onChange={(e) => setEnroll(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="form-group row py-1">
                <label
                  for="text"
                  class="col-lg-2 col-12 infotext col-form-label"
                >
                  Buying Fare
                </label>
                <div class="col-lg-4 col-12">
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      required=""
                      onChange={(e) => setBase(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </form>

        <div class="form-group row py-2">
          <div className="col-lg-6"></div>
          <div class="col-lg-3">
            <button
              onClick={() => {
                handleSubmit();
              }}
              class="w-100 btn btn-primary"
            >
              Add More Service
            </button>
          </div>
          <div class="col-lg-3 col-12">
            <button
              onClick={() => {
                handleSubmit();
                setSteps(Number(steps) + 1);
              }}
              class="w-100 btn btn-primary"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Packages);
