import Logo from "../images/logo.png";
function Printhead() {
  return (
    <div class="row pt-2 dtranHeader mx-auto onprint border-bottom">
      <div class="col-lg-6 d-flex">
        <img src={Logo} class="inimg" alt="..." />
        <h2 className="m-3 mt-4">{process.env.REACT_APP_TITLE}</h2>
      </div>
      <div class="col-lg-6 text-right mt-3">
        <p class="m-0">
          <i class="fa fa-globe" aria-hidden="true"></i>{" "}
          {process.env.REACT_APP_ADDRESS}
          <br></br>
          {process.env.REACT_APP_ADDRESS2}
        </p>
        <p class="m-0">
          <i class="fa fa-phone" aria-hidden="true"></i>{" "}
          {process.env.REACT_APP_PHONE}
        </p>
      </div>
    </div>
  );
}

export default Printhead;
