import React, { useEffect, useState } from "react";
import Logo from "../images/logo.png";
import admin from "../images/admin.svg";
import logout_logo from "../images/logout.svg";
import menu from "../images/menu.png";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import umrah from "../images/Umrah.png";
import manpower from "../images/ManPower.png";
import airlines from "../images/airlinesv.png";
import agent from "../images/Agent.png";

import hajj2 from "../images/Hajj.svg";

import medical2 from "../images/Medical.svg";
import Loading from "./common/loading";

function Navigation2() {
  let history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("token") == null) {
      history.push("/");
    }
  }, [localStorage.getItem("token")]);

  var url = window.location.href;
  url = url.replace("http://localhost:3000/", "");

  const handleClick = (event) => {
    document.getElementById("drpbtn").click();
  };

  const [role, setRole] = useState(false);
  const [load, setLoad] = useState(true);
  useEffect(() => {
    axios
      .post(
        "https://concorde-api.flydocx.com/" +
          process.env.REACT_APP_URL +
          "/urole",
        {
          token: localStorage.getItem("token"),
        }
      )
      .then((res) => {
        setLoad(false);
        setRole(res.data.message[0].role);
      })
      .catch((err) => console.log(err));
  }, [localStorage.getItem("token")]);

  return (
    <>
      <Loading load={load} />
      <nav class="navbar navbar-expand-lg">
        <Link to="/dashboard" class="navbar-brand d-flex align-items-center">
          <img src={Logo} alt="logo" class="flyDocLogo" />
          <span>Concorde International</span>
        </Link>
        {/* <h3 class="text-white mt-2 agencyh">{process.env.REACT_APP_TITLE}</h3> */}
        <ul class="navbar-nav ml-auto ">
          <li class="navbar-item admin-mb">
            <Link to="/adcustomer" class="mx-2">
              <button type="button" class="btn btn-secondary nav_btn2 p-0">
                + Add Customer
              </button>
            </Link>

            <button type="button" class="btn btn-secondary nav_btn p-0">
              <a href="#" class="d-flex justify-content-end">
                <p class="admin_text m-0">{role}</p>
                <img src={admin} alt="" class="account navIcons mr-1 p-0" />
              </a>
            </button>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.removeItem("token");
                history.push("/");
              }}
            >
              <img src={logout_logo} alt="" class="account-2 navIcons  ml-2" />
            </a>
          </li>
        </ul>
      </nav>

      <div
        class="modal fade"
        id="dropmenuModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="dropmenuModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog mDialog" role="document">
          <div class="modal-content mContent">
            <div class="modal-body">
              <div class="container">
                <a href="#" class="modal-text">
                  <Link
                    onClick={handleClick.bind(this)}
                    to="/customer/domestic"
                  >
                    <div
                      class={
                        url == "customer/domestic"
                          ? "row align-items-center active "
                          : "row align-items-center "
                      }
                    >
                      <div class="col-2">
                        <img
                          src="../images/domTicket.png"
                          class="dropmenuIcon float-end"
                          alt=""
                        />
                      </div>
                      <div class="col-10">
                        <p class="fs-5 mt-3">Domestic Ticket</p>
                      </div>
                    </div>
                  </Link>
                </a>

                <a href="#" class="modal-text">
                  <Link
                    onClick={handleClick.bind(this)}
                    to="/customer/international"
                  >
                    <div
                      class={
                        url == "customer/international"
                          ? "row align-items-center active "
                          : "row align-items-center"
                      }
                    >
                      <div class="col-2">
                        <img
                          src="../images/intTicket.svg"
                          class="dropmenuIcon float-end"
                          alt=""
                        />
                      </div>
                      <div class="col-10">
                        <p class="fs-5 mt-3">International Ticket</p>
                      </div>
                    </div>
                  </Link>
                </a>

                <a href="#" class="modal-text">
                  <Link onClick={handleClick.bind(this)} to="/customer/visa">
                    <div
                      class={
                        url == "customer/visa"
                          ? "row align-items-center active "
                          : "row align-items-center "
                      }
                    >
                      <div class="col-2">
                        <img
                          src="../images/visa.svg"
                          class="dropmenuIcon float-end"
                          alt=""
                        />
                      </div>
                      <div class="col-10">
                        <p class="fs-5 mt-3">Issue Visa</p>
                      </div>
                    </div>
                  </Link>
                </a>

                <a href="#" class="modal-text">
                  <Link onClick={handleClick.bind(this)} to="/customer/hotel">
                    <div
                      class={
                        url == "customer/hotel"
                          ? "row align-items-center active "
                          : "row align-items-center "
                      }
                    >
                      <div class="col-2">
                        <img
                          src="../images/hotel.svg"
                          class="dropmenuIcon float-end"
                          alt=""
                        />
                      </div>
                      <div class="col-10">
                        <p class="fs-5 mt-3">Hotel</p>
                      </div>
                    </div>
                  </Link>
                </a>

                <a href="#" class="modal-text">
                  <Link onClick={handleClick.bind(this)} to="/umrah">
                    <div
                      class={
                        url == "umrah"
                          ? "row align-items-center active "
                          : "row align-items-center "
                      }
                    >
                      <div class="col-2">
                        <img
                          src={umrah}
                          class="dropmenuIcon float-end"
                          alt=""
                        />
                      </div>
                      <div class="col-10">
                        <p class="fs-5 mt-3">Umrah</p>
                      </div>
                    </div>
                  </Link>
                </a>

                <a href="#" class="modal-text">
                  <Link onClick={handleClick.bind(this)} to="/hajj">
                    <div
                      class={
                        url == "hajj"
                          ? "row align-items-center active "
                          : "row align-items-center "
                      }
                    >
                      <div class="col-2">
                        <img
                          src={hajj2}
                          class="dropmenuIcon float-end"
                          alt=""
                        />
                      </div>
                      <div class="col-10">
                        <p class="fs-5 mt-3">Hajj</p>
                      </div>
                    </div>
                  </Link>
                </a>

                <a href="#" class="modal-text">
                  <Link onClick={handleClick.bind(this)} to="/medical">
                    <div
                      class={
                        url == "medical"
                          ? "row align-items-center active "
                          : "row align-items-center "
                      }
                    >
                      <div class="col-2">
                        <img
                          src={medical2}
                          class="dropmenuIcon float-end"
                          alt=""
                        />
                      </div>
                      <div class="col-10">
                        <p class="fs-5 mt-3">Medical</p>
                      </div>
                    </div>
                  </Link>
                </a>

                <a href="#" class="modal-text">
                  <Link
                    onClick={handleClick.bind(this)}
                    to="/customer/manpower"
                  >
                    <div
                      class={
                        url == "customer/manpower"
                          ? "row align-items-center active"
                          : "row align-items-center "
                      }
                    >
                      <div class="col-2">
                        <img
                          src={manpower}
                          class="dropmenuIcon float-end"
                          alt=""
                        />
                      </div>
                      <div class="col-10">
                        <p class="fs-5 mt-3">Manpower</p>
                      </div>
                    </div>
                  </Link>
                </a>

                <a href="#" class="modal-text">
                  <Link onClick={handleClick.bind(this)} to="/agent">
                    <div
                      class={
                        url == "agent"
                          ? "row align-items-center active "
                          : "row align-items-center "
                      }
                    >
                      <div class="col-2">
                        <img
                          src={agent}
                          class="dropmenuIcon float-end"
                          alt=""
                        />
                      </div>
                      <div class="col-10">
                        <p class="fs-5 mt-3">Reseller Agent</p>
                      </div>
                    </div>
                  </Link>
                </a>

                {role == "Reservation" ? null : (
                  <a href="#" class="modal-text">
                    <Link onClick={handleClick.bind(this)} to="/vendor">
                      <div
                        class={
                          url == "vendor"
                            ? "row align-items-center active "
                            : "row align-items-center"
                        }
                      >
                        <div class="col-2">
                          <img
                            src="../images/vendor.svg"
                            class="dropmenuIcon float-end"
                            alt=""
                          />
                        </div>
                        <div class="col-10">
                          <p class="fs-5 mt-3">Vendor Agency</p>
                        </div>
                      </div>
                    </Link>
                  </a>
                )}

                {role == "Reservation" ? null : (
                  <a href="#" class="modal-text">
                    <Link onClick={handleClick.bind(this)} to="/avendor">
                      <div
                        class={
                          url == "avendor"
                            ? "row align-items-center active"
                            : "row align-items-center"
                        }
                      >
                        <div class="col-2">
                          <img
                            src={airlines}
                            class="dropmenuIcon float-end"
                            alt=""
                          />
                        </div>
                        <div class="col-10">
                          <p class="fs-5 mt-3">Airlines Vendor</p>
                        </div>
                      </div>
                    </Link>
                  </a>
                )}

                {role == "Reservation" ? null : (
                  <a href="#" class="modal-text">
                    <Link onClick={handleClick.bind(this)} to="/accounts">
                      <div
                        class={
                          url == "accounts"
                            ? "row align-items-center active"
                            : "row align-items-center"
                        }
                      >
                        <div class="col-2">
                          <img
                            src="../images/accounts.svg"
                            class="dropmenuIcon float-end"
                            alt=""
                          />
                        </div>
                        <div class="col-10">
                          <p class="fs-5 mt-3">Accounts Management</p>
                        </div>
                      </div>
                    </Link>
                  </a>
                )}
                {/* <a href="#" class="modal-text">
                        <div class="row align-items-center my-1">
                            <div class="col-2">
                              <img src="../images/accounts.svg" class="dropmenuIcon float-end" alt=""/>
                            </div>
                            <div class="col-10">
                                <p class="fs-5 mt-2">Accounts Management</p>
                            </div>
                        </div>
                    </a>

                    <a href="#" class="modal-text">
                        <div class="row align-items-center my-1">
                            <div class="col-2">
                              <img src="../images/invoice.svg" class="dropmenuIcon float-end" alt=""/>
                            </div>
                            <div class="col-10">
                                <p class="fs-5 mt-2">Customer Invoice</p>
                            </div>
                        </div>
                    </a> */}

                {role == "Reservation" || role == "Manager" ? null : (
                  <a href="#" class="modal-text">
                    <Link onClick={handleClick.bind(this)} to="/staff">
                      <div
                        class={
                          url == "staff"
                            ? "row align-items-center active"
                            : "row align-items-center"
                        }
                      >
                        <div class="col-2">
                          <img
                            src="../images/staf.svg"
                            class="dropmenuIcon float-end"
                            alt=""
                          />
                        </div>
                        <div class="col-10">
                          <p class="fs-5 mt-3">Staff Management</p>
                        </div>
                      </div>
                    </Link>
                  </a>
                )}

                {role == "Reservation" || role == "Manager" ? null : (
                  <a href="#" class="modal-text">
                    <Link onClick={handleClick.bind(this)} to="/profile">
                      <div
                        class={
                          url == "profile"
                            ? "row align-items-center active"
                            : "row align-items-center"
                        }
                      >
                        <div class="col-2">
                          <img
                            src="../images/settings.svg"
                            class="dropmenuIcon float-end"
                            alt=""
                          />
                        </div>
                        <div class="col-10">
                          <p class="fs-5 mt-3">Agency Settings</p>
                        </div>
                      </div>
                    </Link>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Navigation2;
