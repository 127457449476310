function Refundnote({ cname, samount, snote, active,uname, close }) {
  return (
    <div className={active ? "mp-Modal-backdrop" : ""}>
      <div class={active ? "mp-Modal mp-Modal-active" : "mp-Modal"}>
        <div className="mHeader">
          <i
            onClick={() => {
              close(false);
            }}
            className="fa fa-close cusIcon"
          ></i>
          <p class="modal-title pModalheadear" id="CpaymentModalLabel">
            Refund Status
          </p>
        </div>

        <div class="pModalbody py-4">
          <div class="row">
            <div class="col-lg-5 fontTypA">
              <p class="mb-2">Customer Name</p>
            </div>
            <div class="col-lg-7">
              <p class="mb-2 font-weight-bold">{uname}</p>
            </div>
          </div>
        </div>
        <div class="pModalbody border ">
          <p class="font-weight-bold p-2 text-center border-bottom bg-light">
            Refund Amount
          </p>
          <p
            class="text-center"
            style={{ fontWeight: 500, fontSize: 40, color: "#ffa200" }}
          >
            {samount}
          </p>
        </div>

        <div class="pModalbody border mt-4">
          <p class="font-weight-bold p-2 text-center border-bottom bg-light">
            Refund Note
          </p>

          <p class="text-center">{snote}</p>
        </div>
      </div>
      {/* <div
    class="modal fade cpayment_status"
    id="refundnote"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog cPaymentDialog" role="document">
      <div class="modal-content">
        <div class="mHeader">
          <p class="modal-title pModalheadear" id="CpaymentModalLabel">
            Ticket Refund
          </p>
        </div>

        <div class="pModalbody my-4">
          <div class="row">
            <div class="col-4 fontTypA">
              <p class="mb-2">Refund Amount</p>
            </div>
            <div class="col-8">
              <p class="mb-2 font-weight-bold">: {samount} BDT</p>
            </div>
          </div>
          <div class="row">
            <div class="col-4 fontTypA">
              <p class="mb-2">Refund Note</p>
            </div>
            <div class="col-8">
              <p class="mb-2">: {snote}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
     </div> */}
    </div>
  );
}

export default Refundnote;
